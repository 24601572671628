/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useCallback, useEffect, useRef, useState } from "react";
import cn from "classnames";
import UserAccountButton from "../UserAccountButton";
import HeaderLinkButton from "../HeaderLinkButton";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import UniversalSearch, { showUniversalSearch } from "../UniversalSearch";
import {
  Tooltip,
  Menu,
  Classes,
  InputGroup,
  Button,
  Hotkey,
  Breadcrumbs,
  Breadcrumb,
  MenuItem,
  Popover
} from "@blueprintjs/core";
import { createMenu, HotkeysDialog, keyboardIcon } from "@teselagen/ui";
import { forEach, identity, startCase } from "lodash";
import ChangeLog from "../ChangeLog";
import ActiveLabWidget from "../ActiveLabWidget";
import actions from "../redux-shared/actions";

import "./style.css";
import ActiveProjectSelectField from "../ActiveProjectSelectField";
import { showDialog } from "../GlobalDialog";
import NavJump, { useNavJumpExtraSearch } from "./NavJump";
import ButtonWithHotkey from "./ButtonWithHotkey";
import { useIsSmallScreen } from "../../src-shared/hooks/useIsSmallScreen";
import { observer } from "mobx-react";
import { activeRecordStore, setActiveRecord } from "../activeRecordStore";
import isUuid from "../../../tg-iso-shared/utils/isUuid";
import classNames from "classnames";
import isMobile from "is-mobile";
import { appHotkeySets } from "../appHotKeys";
import { toolSchemasForLibrary } from "../../src-build/components/LimsTools/toolSchemas";
import settingsTabsInfo from "../SharedAppSettings/settingsTabsInfo";
import { isAdmin } from "../utils/generalUtils";
import { openInNewTab } from "../utils/generalUtils";
import NotificationPopover from "../NotificationUI/NotificationPopover";
import MoveBarcodedItemDialog from "../../src-build/components/Dialogs/MoveBarcodedItemDialog";
import BarcodeHelperDialog from "../../src-build/components/Dialogs/BarcodeHelperDialog";

function EnhancedHeaderLinkButton({ menu, onClick, name, iconName, ...rest }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div
      className={classNames("header-link-button-container", {
        "header-link-button-container-open": isOpen
      })}
    >
      <HeaderLinkButton
        {...{
          isOpen,
          setIsOpen,
          hasMenu: !!menu,
          icon: iconName,
          name,
          ...rest
        }}
      />
      {isOpen && (
        <div {...{ onClick }} style={{ marginLeft: 20 }}>
          {menu}
        </div>
      )}
    </div>
  );
}

const searchHotkey = "mod+shift+space";
export const navJumpHotkey = "mod+j";

export function PlatformHeader({
  loggedIn,
  logout,
  appBody,
  currentUser,
  menuItems,
  location,
  onSearch,
  showLeftSlideOutDrawer,
  setshowLeftSlideOutDrawer,
  leftMenuItems,
  className = "",
  navOnLabChange
}) {
  const darkTheme = useSelector(state => state.platform.ui.theme.dark);
  const dispatch = useDispatch();
  const [showHotkeysDialog, setShowHotkeysDialog] = useState(false);
  const isSmallScreen = useIsSmallScreen();
  const onMobile = isMobile();
  const {
    includeTools: includeToolsInNavJump,
    includeSettings: includeSettingsInNavJump
  } = useNavJumpExtraSearch();

  useEffect(() => {
    document.body.classList.toggle("tg-mobile", onMobile);
  }, [onMobile]);
  useEffect(() => {
    // if the user prefers dark mode on their system then make the app match that preference
    const hasUserSetDarkModePreference = localStorage.getItem("darkMode");
    const userPrefersDarkTheme =
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches;
    if (!hasUserSetDarkModePreference && userPrefersDarkTheme && !darkTheme) {
      dispatch(actions.platform.ui.theme.toggle());
    }
  }, [darkTheme, dispatch]);

  useEffect(() => {
    // TODO maybe move this somewhere else. But altering the body element cannot
    // be done in idiomatic React anyway...
    document.body.classList.toggle(Classes.DARK, darkTheme);
  }, [darkTheme]);
  const toggleDrawer = useCallback(
    () => setshowLeftSlideOutDrawer(!showLeftSlideOutDrawer),
    [setshowLeftSlideOutDrawer, showLeftSlideOutDrawer]
  );

  const { pathname } = location;
  const oldPath = useRef(pathname);

  useEffect(() => {
    // if side drawer is open and then user navigates to a new page, close the drawer on mobile
    if (showLeftSlideOutDrawer && isMobile() && oldPath.current !== pathname) {
      setshowLeftSlideOutDrawer(false);
    }
    oldPath.current = pathname;
  }, [
    toggleDrawer,
    pathname,
    setshowLeftSlideOutDrawer,
    showLeftSlideOutDrawer
  ]);

  const { topWarning, showDocumentation } = window.frontEndConfig;

  const extraMenuItems = [
    { text: "Settings", icon: "cog", navTo: "/settings" },
    ...(showDocumentation
      ? [
          {
            text: "Documentation",
            icon: "book",
            onClick: () => {
              window.location.href = "/user-docs/index.html";
            }
          }
        ]
      : []),
    {
      text: "Release Notes",
      icon: "clipboard",
      onClick: () => {
        showDialog({
          ModalComponent: ChangeLog
        });
      }
    },
    {
      text: darkTheme ? "Light Theme" : "Dark Theme",
      icon: darkTheme ? "flash" : "moon",
      onClick: () => {
        dispatch(actions.platform.ui.theme.toggle());
      }
    }
  ];

  extraMenuItems.push({
    text: "Keyboard Shortcuts",
    hotkey: appHotkeySets.General.showKeyboardShortcuts,
    key: "keyboardShortcuts",
    icon: keyboardIcon,
    onClick: () => setShowHotkeysDialog(true)
  });

  const labChooser = (
    <>
      <ActiveLabWidget
        inSidePanel={isSmallScreen}
        navOnChange={navOnLabChange}
      />
      {window.frontEndConfig.projectTags && <ActiveProjectSelectField />}
      {leftMenuItems}
    </>
  );

  const navSuggestItems = [
    {
      label: "Settings",
      navTo: "/settings"
    }
  ];

  const addSuggestItems = (menuItems, textPrefix = "") => {
    menuItems.forEach(item => {
      const baseLabel = item.name || item.text;
      let path = baseLabel;
      if (textPrefix) {
        path = `${textPrefix} > ${path}`;
      }
      if (!item.disabled) {
        if (item.submenu) {
          addSuggestItems(item.submenu, path);
        } else if (item.navTo) {
          navSuggestItems.push({
            label: baseLabel,
            path: path === baseLabel ? undefined : path,
            navTo: item.navTo
          });
        }
      }
    });
  };

  addSuggestItems(menuItems);

  if (includeToolsInNavJump) {
    toolSchemasForLibrary.forEach(tool => {
      if (!tool.disabled) {
        navSuggestItems.push({
          label: tool.title,
          path: "Tools > " + tool.title,
          navTo: `/tools/${tool.tabId}`
        });
      }
    });
  }
  const currentUserIsAdmin = isAdmin();

  if (includeSettingsInNavJump) {
    forEach(settingsTabsInfo, tab => {
      const hiddenCauseNonAdmin = tab.adminOnly && !currentUserIsAdmin;
      if (tab.divider || tab.isHidden || tab.disabled || hiddenCauseNonAdmin)
        return;
      navSuggestItems.push({
        label: tab.title,
        path: "Settings > " + tab.title,
        navTo: `/settings/${tab.id}`
      });
    });
  }

  specifyActiveMenuItem(menuItems, history);

  const routeLinks = (
    <div className="tg-route-links">
      <Menu className="header-routing-menu">
        {(menuItems || []).map(item => (
          <EnhancedHeaderLinkButton
            data-test="tg-header-routing"
            key={item.name}
            name={item.name}
            className={item.className}
            navTo={item.navTo}
            disabled={item.disabled}
            menuDescription={item.menuDescription}
            activeOn={
              item.submenu
                ? item.submenu
                    .map(item => item.navTo)
                    .concat(item.navTo)
                    .filter(identity)
                : item.activeOn
            }
            icon={item.icon}
            menu={item.submenu ? <Menu>{createMenu(item.submenu)}</Menu> : null}
          />
        ))}
      </Menu>
      <div style={{ minHeight: 50, minWidth: 4 }}></div>
    </div>
  );

  const breadcrumbItems = window.location.pathname
    .split("/")
    .slice(2)
    .map((path, i) => {
      if (path === "") {
        return null;
      }
      const href = window.location.pathname
        .split("/")
        .slice(2, i + 3)
        .join("/");
      return {
        href: `/${href}`,
        text: path
      };
    })
    .filter(item => item);

  const showSidebarBtn = (
    <Tooltip
      content={
        <div style={{ display: "flex", alignItems: "center" }}>
          {showLeftSlideOutDrawer ? "Hide Menu" : "Show Menu"} &nbsp;
          <div
            style={{
              transform: "scale(0.8)"
            }}
          >
            <Hotkey global combo={navJumpHotkey}></Hotkey>
          </div>
        </div>
      }
    >
      <Button
        style={{ marginLeft: showLeftSlideOutDrawer ? 5 : 10, marginRight: 10 }}
        onClick={toggleDrawer}
        className="tg-toggle-left-drawer"
        icon={showLeftSlideOutDrawer ? "chevron-left" : "menu"}
        minimal
      />
    </Tooltip>
  );

  return (
    <div
      style={{
        display: "flex",
        overflow: showLeftSlideOutDrawer ? "hidden" : "auto", //needed to prevent horizontal scroll bar when drawer is open
        height: "100%",
        width: "100%"
      }}
    >
      {loggedIn && (window.Cypress ? showLeftSlideOutDrawer : true) && (
        <div
          className="tg-drawer"
          style={{
            display: showLeftSlideOutDrawer ? "flex" : "none", //tnw: important to use display:none to hide the drawer instead of removing from dom which resets its state
            paddingLeft: 5,
            paddingRight: 5
          }}
        >
          {showLeftSlideOutDrawer && (
            <div
              style={{
                display: "flex",
                minHeight: 50,
                alignItems: "center",
                paddingRight: 10
              }}
            >
              {showSidebarBtn}
              <NavJump
                navSuggestItems={navSuggestItems}
                onSelect={() => toggleDrawer()}
              />
            </div>
          )}
          {isSmallScreen && (
            <div
              style={{
                marginBottom: 5
              }}
            >
              {labChooser}
            </div>
          )}
          {routeLinks}
          <div
            style={{
              height: 10
            }}
          />
        </div>
      )}
      <div
        style={
          {
            // display: showLeftSlideOutDrawer && isMobile() ? "none" : "flex"
          }
        }
        className="platform-header-and-body"
      >
        {loggedIn && (
          <div
            id="platform-header"
            className={cn("header-container", className, {
              "with-tg-top-warning": !!topWarning
            })}
          >
            {topWarning && (
              <div className="tg-top-warning">
                <span>{topWarning}</span>
              </div>
            )}

            <div
              className="header"
              style={{
                justifyContent: "space-between",
                paddingLeft: showLeftSlideOutDrawer ? 10 : 0
              }}
            >
              <div style={{ display: "flex" }}>
                {!showLeftSlideOutDrawer && showSidebarBtn}
                <Link
                  to="/"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  <div className="sidebar-logo">
                    <img src={"/teselagen_square_logo_white.svg"} alt="logo" />
                  </div>
                </Link>
                {!!breadcrumbItems.length && <BreadCrumbsOuter />}
              </div>

              {!isSmallScreen && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center"
                  }}
                >
                  {labChooser}
                </div>
              )}

              {/* Optional search box */}
              {onSearch && (
                <InputGroup
                  className={Classes.ROUND}
                  leftIcon="search"
                  placeholder="Search..."
                  onKeyPress={e => {
                    if (onSearch && e.key === "Enter") {
                      onSearch(e.target.value);
                    }
                  }}
                />
              )}

              <div className="header-btns right-section">
                {!window.frontEndConfig.disabledToolkits
                  .materialsAndInventoryManagement && (
                  <Popover
                    key="barcode"
                    position="bottom-right"
                    minimal
                    content={
                      <Menu>
                        <MenuItem
                          text="Open Barcoded Item"
                          onClick={() => {
                            showDialog({
                              ModalComponent: BarcodeHelperDialog
                            });
                          }}
                        />
                        <MenuItem
                          text="Move Barcoded Item"
                          onClick={() => {
                            showDialog({
                              ModalComponent: MoveBarcodedItemDialog
                            });
                          }}
                        />
                      </Menu>
                    }
                  >
                    <Tooltip content="Open or move item by scanning/entering a barcode">
                      <Button
                        icon="barcode"
                        className="tg-barcode-helper-button"
                        minimal
                      />
                    </Tooltip>
                  </Popover>
                )}
                <NotificationPopover
                  currentUser={currentUser}
                  key="NotificationPopover"
                />
                {/* <Tooltip
                content={darkTheme ? "Light Theme" : "Dark Theme"}
                key="theme"
              >
                <Button
                  data-test="tg-toggle-dark-mode"
                  icon={darkTheme ? "flash" : "moon"}
                  intent={darkTheme ? Intent.WARNING : undefined}
                  className={Classes.MINIMAL}
                  onClick={() => toggleTheme()}
                />
              </Tooltip> */}
                <ButtonWithHotkey
                  tooltip="Show Search"
                  hotkey={searchHotkey}
                  icon="search"
                  onClick={() => showUniversalSearch()}
                  minimal
                  className="tg-universal-search-button"
                />
                <UniversalSearch hotKey={searchHotkey} />
                <Button
                  data-tip="Open TeselaGen Docs"
                  minimal
                  className="tg-help-button"
                  onClick={openHelpDocs}
                  icon="help"
                ></Button>
                <UserAccountButton
                  data-test="tg-user-account"
                  user={currentUser}
                  logout={logout}
                  icon="user"
                  extraItems={extraMenuItems}
                />
              </div>
            </div>
            <HotkeysDialog
              isOpen={showHotkeysDialog}
              onClose={() => setShowHotkeysDialog(false)}
              hotkeySets={appHotkeySets}
            />
          </div>
        )}
        {appBody}
      </div>
    </div>
  );
}

export default withRouter(PlatformHeader);

let CustomCrumb = ({ text }) => {
  const wasUuid = isUuid(text);

  const activeRecordName = activeRecordStore.name;
  return (
    <Breadcrumb
      text={
        wasUuid
          ? activeRecordName
          : startCase(text)
              .replace(/dna/gi, "DNA")
              .replace(/rna/gi, "RNA")
              .replace(/crispr/gi, "CRISPR")
      }
    ></Breadcrumb>
  );
};

CustomCrumb = observer(CustomCrumb);

function BreadCrumbsOuter() {
  useEffect(() => {
    setActiveRecord({
      name: "..."
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  let items = window.location.pathname.split("/").slice(2);
  items = items.map((path, i) => {
    if (path === "") {
      return null;
    }
    const href = window.location.pathname
      .split("/")
      .slice(2, i + 3)
      .join("/");
    return {
      href: `/${href}`,
      text: path,
      isLast: i === items.length - 1
    };
  });
  return (
    <Breadcrumbs
      minVisibleItems={5}
      items={[{}, ...items]}
      breadcrumbRenderer={r => {
        if (!r) return null;
        const { text, href, isLast } = r;
        if (!text) return null;
        const crumb = <CustomCrumb text={text} href={href} />;
        // don't want a link if it is the current page (won't nav and will mess up history)
        if (isLast) return crumb;
        return <Link to={href}>{crumb}</Link>;
      }}
    ></Breadcrumbs>
  );
}

function specifyActiveMenuItem(menuItems) {
  menuItems.forEach(item => {
    if (item.navTo) {
      item.active = item.exactNavToMatch
        ? item.navTo === window.location.pathname.replace("/client", "")
        : window.location.pathname.includes(item.navTo);
    } else if (item.submenu) {
      specifyActiveMenuItem(item.submenu);
    }
  });
}

function openHelpDocs() {
  window.frontEndConfig.localDocs
    ? openInNewTab("html_files/collections/313799-build-module.html")
    : window.open("https://docs.teselagen.com/", "_blank");
}
