/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { isFunction, omit, isObject } from "lodash";
import { deepObjectMapTransform } from "../deepObjectMapTransform";

// tnr: this function goes through a request/response object from a node-red endpoint
// and transforms it based on subtype and other params so it looks nice :)
export function transformSubtypes(obj, subtype, useDisplayVals) {
  if (!isObject(obj)) {
    return obj;
  }
  const iterator = (key, value) => {
    if (isFunction(value)) {
      return [key, value(subtype)];
    }
    if (typeof key === "string" && key.startsWith("display_$$_")) {
      return [key.replace("display_$$_", ""), value[useDisplayVals ? 0 : 1]];
    }
    if (key === "__subtype__") {
      const subTypeValue = deepObjectMapTransform(value[subtype], iterator);
      return subTypeValue;
      // return  value[subtype];
    }
    return [key, value];
  };

  const newObj = deepObjectMapTransform(omit(obj, "__validate__"), iterator);
  return newObj;
}
