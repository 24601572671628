/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { parseExcelToCsv } from "../../utils/fileUtils";
import processSequenceUploadCSVContent from "../processSequenceUploadCSVContent";
import { isoContext } from "@teselagen/utils";
import { filterFilesInZip } from "@teselagen/file-utils";
import { aminoAcidSequenceFileTypes } from "./aminoAcidSequenceFileTypes";
import { rnaFileTypes } from "./rnaFileTypes";
import { sequenceFileTypes } from "./sequenceFileTypes";
import { isCsvOrExcelFile, isExcelFile } from "../../utils/fileUtils";
import { parseSequenceFiles } from "./parseSequenceFiles";
import { readableSequenceType } from "./readableSequenceType";

export async function filterSequenceUploads(
  {
    allSequenceFiles: _allSequenceFiles = [],
    isGenomicRegionUpload,
    isGuideRNA,
    isMaterial, //tnw: if true we've got a CSV file coming in with both material and sequence fields and will need to parse both
    sequences = [],
    isProtein,
    isRNA: _isRNA,
    sequenceTypeCode,
    warnings = []
  },
  ctx = isoContext
) {
  const isRNA = _isRNA || sequenceTypeCode === "RNA";
  const allSequenceFiles = await filterFilesInZip(
    _allSequenceFiles,
    isProtein
      ? aminoAcidSequenceFileTypes
      : isRNA
        ? rnaFileTypes
        : sequenceFileTypes
  );
  const sequenceFiles = [];
  const genbankFiles = [];
  const csvAndExcelFiles = [];

  allSequenceFiles.forEach(file => {
    const ext = file.name.split(".").pop();
    if (isCsvOrExcelFile(file)) {
      csvAndExcelFiles.push(file);
    } else if (ext === "gb" || ext === "gbk") {
      genbankFiles.push(file);
    } else {
      sequenceFiles.push(file);
    }
  });

  if (
    _allSequenceFiles.length &&
    !csvAndExcelFiles.length &&
    !sequenceFiles.length &&
    !genbankFiles.length
  ) {
    throw new Error("No sequence files found.");
  }

  const invalidFiles = [];
  const addError = (parsedSequences, files) => {
    if (parsedSequences.messages) {
      parsedSequences.messages.forEach((messageArray, i) => {
        if (
          messageArray.some(message => message.includes("Illegal character"))
        ) {
          invalidFiles.push(files[i].name);
        }
      });
    }
  };
  const isOligo = sequenceTypeCode === "OLIGO";
  if (sequenceFiles.length) {
    const parsedSequences = await parseSequenceFiles(sequenceFiles, {
      isProtein,
      isOligo,
      isRNA
    });

    addError(parsedSequences, sequenceFiles);
    sequences = sequences.concat(parsedSequences);
  }

  if (genbankFiles.length) {
    const parsedGenbankSequences = await parseSequenceFiles(
      genbankFiles,
      {
        isOligo,
        isRNA,
        isProtein,
        isGenbankFile: true
      },
      ctx
    );
    addError(parsedGenbankSequences, genbankFiles);
    const invalidGenbanks = [];
    parsedGenbankSequences.forEach(gbSeq => {
      if (sequenceTypeCode && gbSeq.sequenceTypeCode !== sequenceTypeCode) {
        invalidGenbanks.push(gbSeq.name);
      }
    });
    if (invalidGenbanks.length) {
      if (isGenomicRegionUpload) {
        throw new Error(
          `Genomic region GenBanks must be linear. The following have invalid sequences: ${invalidGenbanks.join(
            ", "
          )}`
        );
      } else {
        throw new Error(
          `The sequences in the following GenBanks are not ${readableSequenceType(
            sequenceTypeCode
          )}: ${invalidGenbanks.join(", ")}`
        );
      }
    }
    sequences = sequences.concat(parsedGenbankSequences);
  }

  if (csvAndExcelFiles.length) {
    for (const file of csvAndExcelFiles) {
      let csv;
      if (isExcelFile(file)) {
        csv = await parseExcelToCsv(file.originFileObj);
      } else {
        csv = file;
      }
      const invalidLines = [];
      const csvSeqs = await processSequenceUploadCSVContent({
        apolloMethods: ctx,
        isMaterial,
        file: csv,
        filename: file.name,
        invalidLines,
        warnings,
        isProtein,
        sequenceTypeCode,
        isGuideRNA
      });

      sequences = sequences.concat(csvSeqs);
    }
  }
  if (invalidFiles.length) {
    throw new Error(
      `These files have invalid sequence characters: ${invalidFiles.join(
        ", "
      )}.`
    );
  }

  return sequences;
}
