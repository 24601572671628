/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { get, some } from "lodash-es";
import joinUrl from "url-join";

// IMPORTANT
// Hasura endpoints load from the environment variables. During deploy, some env
// vars are set after loading packages and files. Loading them at the top of the
// file might cause errors because the values obtained might not be correct.
// This doesn't happen to this two variables, but it might happen to others.

const TG_CUSTOM_RESOLVERS = [
  // "currentUserQuery",
  // "appSettingsQuery",
  // "usersQuery",
  // "userLoginsQuery",
  // "labQuery",
  // "labsQuery",
  // "labRolesQuery",
  /**
   * The following views are very slow with hasura (at least on cloud-build cy workers)
   */
  "aliquotContainerPathViewsQuery",
  "aliquotUnassignedViews"
];

const DEFAULT_HASURA_CONFIG = {
  gqlRewriter: true
};

export const gqlClientProxy = (operation, options = {}) => {
  const { baseUrl = "/", hasura: hasuraClientConfig } = options;
  const { hasura: hasuraOperationConfig } =
    typeof operation.getContext === "function" ? operation.getContext() : {};

  // We are supporting hasura configs to be passed both from the apolloClient creation (in sort of global fashion),
  // or specific to each gql operation (which will override the hasuraClientConfig) via the
  // apollo methods options (e.g., safeQuery(fragment, { variables: {}, hasura: hasuraOperationConfig } )
  const hasuraConfig = {
    ...DEFAULT_HASURA_CONFIG,
    ...hasuraClientConfig,
    ...hasuraOperationConfig
  };
  const {
    query: { definitions = [] },
    operationName: _operationName
  } = operation;

  const operationName = _operationName || get(definitions, "[0].name.value");

  const isMutation = some(
    definitions,
    definition => definition.operation && definition.operation === "mutation"
  );

  const useHasura =
    !hasuraConfig.disableHasura &&
    !isMutation &&
    !TG_CUSTOM_RESOLVERS.includes(operationName);

  const hasuraUrl = joinUrl(
    hasuraConfig.gqlRewriter ? "hasura/tg/v1/graphql" : "hasura/v1/graphql"
  );
  const gqlUrl = joinUrl(baseUrl, useHasura ? hasuraUrl : "graphql");

  const uri = operationName
    ? `${gqlUrl}?operationName=${operationName}`
    : gqlUrl;

  return uri;
};
