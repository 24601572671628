/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { FC } from "react";
import { compose } from "recompose";
import { withTableParams } from "@teselagen/ui";
import withQuery from "../../../../../src-shared/withQuery";
import { InjectedWithQueryProps } from "../../../../../src-shared/withQuery/types";
import labelMediaFragment from "../../../../../src-shared/graphql/fragments/labels/labelMediaFragment.gql";
import type { LabelMediaFragment } from "../../../../../src-shared/graphql/fragments/labels/labelMediaFragment.gql.generated";
import LabelSettingsPanel from "../LabelSettingsPanel";
import LabelMediaDialog from "./LabelMediaDialog";

interface Props {
  readonly?: boolean;
}

const renderDimension =
  (dimension: string) =>
  (val: string, { [dimension + "Unit"]: unit }: any) =>
    `${val} ${unit.code}`;

const schema = {
  model: "labelMedia",
  fields: [
    {
      path: "name",
      type: "string",
      displayName: "Name"
    },
    {
      path: "height",
      displayName: "Height",
      type: "number",
      render: renderDimension("height")
    },
    {
      path: "width",
      type: "number",
      displayName: "Width",
      render: renderDimension("width")
    }
  ]
};

const LabelMediaPanel: FC<
  InjectedWithQueryProps<Props, LabelMediaFragment, {}, true, "labelMedias">
> = props => {
  return (
    <LabelSettingsPanel
      model="labelMedia"
      Dialog={LabelMediaDialog}
      refetch={props.data.refetch}
      {...props}
    />
  );
};

export default compose<
  InjectedWithQueryProps<Props, LabelMediaFragment, {}, true, "labelMedias">,
  Props
>(
  withTableParams({
    urlConnected: false,
    schema,
    formName: "LabelMediaPanelTable",
    withDisplayOptions: true
  }),
  withQuery(labelMediaFragment, {
    isPlural: true
  })
)(LabelMediaPanel);
