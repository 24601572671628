/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import Promise from "bluebird";
import { isoContext } from "@teselagen/utils";
import { aaSequenceJSONtoGraphQLInput } from "./aaSequenceJSONtoGraphQLInput";
import { basename } from "path";
import { sequenceJSONtoGraphQLInput } from "./sequenceJSONtoGraphQLInput";
import { anyToJson } from "@teselagen/bio-parsers";
import { isBrowser } from "browser-or-node";
import { showAminoAcidStrippedCharacterWarningDialog } from "./../showAminoAcidStrippedCharacterWarningDialog";
import { removeExt } from "../../utils/fileUtils";
import addSequenceTypeOrDefault from "../addSequenceTypeOrDefault";
import { tidyUpDigestPartFields } from "../../../utils/digestPartUtils";
/**
 * Parses sequence files and creates graphql input sequences
 * @param {array} sequenceFiles
 * @param {boolean} options.isProtein - whether they are amino acid sequences
 */
export const parseSequenceFiles = async (
  sequenceFiles,
  options = {},
  ctx = isoContext
) => {
  const {
    isProtein,
    isOligo,
    useFilenameAsSequenceName,
    isRNA,
    sequenceTypeCode,
    isGenbankFile
  } = options;
  try {
    const jsonToGraphql = isProtein
      ? aaSequenceJSONtoGraphQLInput
      : sequenceJSONtoGraphQLInput;
    const parsedSequences = [];
    parsedSequences.filenames = [];
    parsedSequences.messages = [];
    const strippedAAs = [];
    await Promise.map(sequenceFiles, async ({ originFileObj, ...rest }) => {
      let filename = rest.name || originFileObj.name;
      filename = filename && basename(filename);
      const sequenceResults = await anyToJson(originFileObj || rest, {
        acceptParts: true,
        isProtein: isProtein,
        isRNA,
        isOligo,
        primersAsFeatures: true,
        fileName: filename
      });
      let fileNameNoExt, lowerFilename;
      if (filename) {
        fileNameNoExt = removeExt(filename);
        lowerFilename = filename.toLowerCase();
      }

      /**
       * Sequence data results from files may come with digest parts.
       * In case of GenBank files, these will be in the part annotation notes,
       * For TG JSON files, these will be in the part annotation fields.
       *
       * In either case, the 5' and 3' restriction enzyme will come in the form of
       * the enzyme name and/or recognition sequence pattern. We need to find the TG ID based
       * on that and add it to the part annotation.
       */
      for (const sequenceResult of sequenceResults) {
        if (sequenceResult.parsedSequence.parts) {
          for (const part of sequenceResult.parsedSequence.parts) {
            await tidyUpDigestPartFields(
              part,
              { digestInfoInNotes: isGenbankFile },
              ctx
            );
          }
        }
      }

      sequenceResults.forEach(result => {
        result.name =
          useFilenameAsSequenceName && filename
            ? filename.replace(/\.[^/.]+$/, "")
            : result.name;

        if (!result.success)
          throw new Error(
            `Sequence parsing failed for ${filename} - ${(
              result.messages || []
            ).join(" ")}`
          );

        if (isOligo) {
          result.parsedSequence.sequenceTypeCode = "OLIGO";
          if (result.parsedSequence.circular) {
            // eslint-disable-next-line no-throw-literal
            throw {
              mismatchError: `Sequence ${result.parsedSequence.name} cannot be an oligo because the sequence file is circular.`
            };
          }
        } else if (isRNA) {
          result.parsedSequence.sequenceTypeCode = "RNA";
          if (result.parsedSequence.circular) {
            // eslint-disable-next-line no-throw-literal
            throw {
              mismatchError: `Sequence ${result.parsedSequence.name} cannot be RNA because the sequence file is circular.`
            };
          }
        } else if (sequenceTypeCode) {
          result.parsedSequence.sequenceTypeCode = sequenceTypeCode;
          if (
            result.parsedSequence.circular &&
            sequenceTypeCode !== "CIRCULAR_DNA"
          ) {
            // eslint-disable-next-line no-throw-literal
            throw {
              mismatchError: `Sequence ${result.parsedSequence.name} cannot be ${sequenceTypeCode} because the sequence file is circular.`
            };
          }
        } else {
          addSequenceTypeOrDefault(result.parsedSequence);
        }
        const sequenceInput = jsonToGraphql(result.parsedSequence, {
          strippedAATracker: strippedAAs
        });
        if (result.messages && result.messages.length) {
          //tnr: this is confusing.. It's unclear whether sequences.messages is an
          // array of arrays or just an array of strings.. I'm opting for an array of strings
          //OLD CODE:
          // if (!parsedSequences.messages[i]) parsedSequences.messages[i] = [];
          // parsedSequences.messages[i].push(...result.messages);
          //NEW CODE:
          parsedSequences.messages.concat(result.messages);
        }
        // the filename is needed for some plate uploads which have the sequence filename as a column in the csv
        parsedSequences.filenames.push(filename);
        // helper maps for going from sequence name or filename to sequence
        parsedSequences.sequenceNameMap = parsedSequences.sequenceNameMap || {};
        parsedSequences.sequenceFileMap = parsedSequences.sequenceFileMap || {};
        const sm = parsedSequences.sequenceNameMap;
        const fm = parsedSequences.sequenceFileMap;
        const lowerSeqName = (sequenceInput.name || "").toLowerCase();
        if (lowerSeqName) {
          sm[lowerSeqName] = sm[lowerSeqName] || [];
          sm[lowerSeqName].push(sequenceInput);
        }
        if (lowerFilename) {
          fm[lowerFilename] = fm[lowerFilename] || [];
          fm[lowerFilename].push(sequenceInput);
        }
        // add an alias for filename if it isn't the same as the name
        // (the locus only supports 16 characters so this might be a common occurrence)
        // (only do this if a single sequence is in the file)
        if (
          sequenceResults.length === 1 &&
          fileNameNoExt &&
          fileNameNoExt !== sequenceInput.name
        ) {
          sequenceInput.aliases = sequenceInput.aliases || [];
          sequenceInput.aliases.push({
            name: fileNameNoExt
          });
        }
        parsedSequences.push(sequenceInput);
      });
    });
    if (strippedAAs.length && isBrowser) {
      const continueUpload =
        await showAminoAcidStrippedCharacterWarningDialog(strippedAAs);
      if (!continueUpload) {
        throw new Error("Import Cancelled");
      }
    }
    if (parsedSequences.sequenceFileMap) {
      // store with and without extension so that we can match on both
      Object.keys(parsedSequences.sequenceFileMap).forEach(key => {
        const noExt = removeExt(key);
        if (noExt !== key) {
          parsedSequences.sequenceFileMap[noExt] =
            parsedSequences.sequenceFileMap[key];
        }
      });
    }
    return parsedSequences;
  } catch (error) {
    console.error(`2135134513456 error:`, error);
    throw error.mismatchError ? new Error(error.mismatchError) : error;
  }
};
