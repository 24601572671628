/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { showConfirmationDialog } from "@teselagen/ui";
import * as yup from "yup";
import { safeUpsert } from "../apolloMethods";
import { Intent } from "@blueprintjs/core";
import { size, map, keyBy, flatMap } from "lodash";
import integrationTypeSettingsMap from "../../../tg-iso-shared/src/utils/integrationTypeSettingsMap";
import { forEach } from "lodash";
import {
  generateIdsForNodes,
  getFlows,
  getHttpInsByUrlAndTabsAndSubflowsById,
  getTabsAndSubflowsFromNodes
} from "../../../tg-iso-shared/src/utils/nodeRedUtils";
import { NodeRedTabLink } from "../utils/nodeRedUtils";

function getIntegrationTypeSubTypes(integrationType) {
  return integrationTypeSettingsMap[integrationType].subtypes;
}

function getName(name, nameObj) {
  if (nameObj[name]) {
    return getName(name + "_copy", nameObj);
  }
  return name;
}

export function handleIntegrationImport({
  integrationsByCode,
  refetchIntegrations
}) {
  return async ([f]) => {
    try {
      const json = JSON.parse(f.parsedString);
      const headerFmt = yup.array(
        yup.object({
          name: yup.string().required(),
          value: yup.string().required()
        })
      );
      const schema = yup.object({
        name: yup.string().required(),
        // subtype: yup.string(),
        subtype: yup
          .mixed()
          .when("integrationTypeCode", integrationTypeCode => {
            const subtypes = getIntegrationTypeSubTypes(integrationTypeCode);
            return size(subtypes)
              ? yup.mixed().oneOf(subtypes)
              : yup.string().nullable();
          }),
        tgNodeRedFlow: yup.mixed(),
        uniqueIdentifier: yup.string().nullable(),
        includeUserIdHeader: yup.boolean().default(true),
        includeUsernameHeader: yup.boolean().default(true),
        includeLabIdHeader: yup.boolean().default(true),
        includeProjectIdHeader: yup.boolean().default(true),
        includeAuthTokenHeader: yup.boolean().default(true),
        integrationTypeCode: yup
          .mixed()
          .oneOf(Object.keys(integrationTypeSettingsMap)),
        // .oneOf(["IMPORT", "EXPORT"]),
        integrationHeaders: headerFmt,
        integrationEndpoints: yup
          .array(
            yup.object({
              endpointTypeCode: yup.string().required(),
              // externalRecordType: null,
              url: yup.string().nullable(),
              integrationEndpointHeaders: headerFmt
            })
          )
          .min(1)
      });
      if (
        json.includeProjectIdHeader === undefined ||
        json.includeProjectIdHeader === null
      ) {
        //because this flag was added later we need to do some checking to make sure it is included on the json being imported
        json.includeProjectIdHeader = true;
      }
      const fmtJson = schema.validateSync(json, {
        stripUnknown: true
      });

      const nameObj = keyBy(
        integrationsByCode[fmtJson.integrationTypeCode],
        "name"
      );
      const newName = getName(fmtJson.name, nameObj);
      fmtJson.name = newName;

      //there is an attached node-red flow
      if (fmtJson.tgNodeRedFlow) {
        //check if the http ins already exist (if they do show a warning)
        const existingFlows = (await getFlows()).data;
        const conflictUrls = {};
        const { httpInsByUrl } =
          getHttpInsByUrlAndTabsAndSubflowsById(existingFlows);
        map(fmtJson.tgNodeRedFlow, node => {
          let url = node && node.url;
          if (url && url.startsWith("/")) {
            //normalize the url for the http-in
            url = url.slice(1);
          }
          if (httpInsByUrl[url]) {
            conflictUrls[url] = node;
          }
        });
        const idMap = {};

        //strategy: keep all existing tabs, keep some existing subflows deleting any that are overridden by name
        //keep all new tabs and subflows (more details on this below)
        const { tabs: existingTabsById, subflows: existingSubflowsById } =
          getTabsAndSubflowsFromNodes(existingFlows);

        const { tabs: newTabsById, subflows: newSubflowsById } =
          getTabsAndSubflowsFromNodes(fmtJson.tgNodeRedFlow);

        if (
          map(conflictUrls).length &&
          !window.Cypress?.ignoreNodeRedConflictWarning
        ) {
          const confirm = await showConfirmationDialog({
            text: (
              <div>
                <h4> Conflict detected! </h4>
                It looks like there is already a node-red flow that exists with
                the same urls. Importing this flow <i>will</i> cause conflicts.
                <div style={{ marginTop: 15 }}>
                  <b>Conflicting URLs:</b>
                  {map(conflictUrls, (node = {}, url) => {
                    return (
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "66.667% 33.334%"
                        }}
                      >
                        <div>{url}</div>
                        <NodeRedTabLink tabId={node.z}></NodeRedTabLink>
                      </div>
                    );
                  })}
                </div>
              </div>
            ),
            intent: Intent.DANGER,
            confirmButtonText: "Continue Anyways",
            cancelButtonText: "Cancel",
            canEscapeKeyCancel: true
          });
          if (!confirm) {
            return;
          }
        }
        if (map(newSubflowsById).length) {
          const existingSubflowsByName = keyBy(
            existingSubflowsById,
            flow => flow[0].name
          );

          //check if we have overlapping subflows by name
          //we'll override the old with the new if possible, and if not possible duplicate the subflows
          forEach(newSubflowsById, newSubflow => {
            const existingSubflow = existingSubflowsByName[newSubflow[0].name];
            if (existingSubflow) {
              //the number of ins and outs must match up!
              if (
                existingSubflow[0].in?.length === newSubflow[0].in?.length &&
                existingSubflow[0].out?.length === newSubflow[0].out?.length
              ) {
                delete existingSubflowsById[existingSubflow[0].id];
                forEach(existingTabsById, (nodes, id) => {
                  idMap[existingSubflow[0].id] = newSubflow[0].id;
                  existingTabsById[id] = generateIdsForNodes(nodes, idMap);
                });

                forEach(existingSubflowsById, (nodes, id) => {
                  idMap[existingSubflow[0].id] = newSubflow[0].id;
                  existingSubflowsById[id] = generateIdsForNodes(nodes, idMap);
                });
              } else {
                //do nothing, we'll just have a duplicated (by name) subflow
              }
            }
          });
        }
        const newFlows = [
          ...flatMap(existingTabsById),
          ...flatMap(existingSubflowsById),
          ...flatMap(newTabsById),
          ...flatMap(newSubflowsById)
        ];

        await window.serverApi.request(
          `${window.frontEndConfig.nodeRedEditorUrl}/flows`,
          {
            method: "POST",
            data: generateIdsForNodes(newFlows)
          }
        );

        delete fmtJson.tgNodeRedFlow;
      }
      await safeUpsert("integration", fmtJson);

      await refetchIntegrations();
      window.toastr.success(`Integration ${newName} Uploaded Successfully`);
    } catch (e) {
      console.error("error 13t34fb3f:", e);
      window.toastr.error(
        "Error importing the integration file. Check the dev console for more details"
      );
    }
  };
}
