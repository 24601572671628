/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { safeUpsert } from "../apolloMethods";

export const upsertAppSetting = async (appSetting: { [key: string]: any }) => {
  let upsertedAppSetting;
  try {
    const [_upsertedAppSetting] = await safeUpsert(
      ["appSetting", "code type value"],
      appSetting,
      {
        idAs: "code"
      }
    );
    if (!_upsertedAppSetting)
      throw new Error(
        "The update failed so it probably hasn't been created yet. We'll try a create below"
      );
    upsertedAppSetting = _upsertedAppSetting;
  } catch (e) {
    const [_upsertedAppSetting] = await safeUpsert(
      ["appSetting", "code type value"],
      appSetting,
      {
        forceCreate: true,
        idAs: "code"
      }
    );
    upsertedAppSetting = _upsertedAppSetting;
  }

  if (upsertedAppSetting) {
    switch (upsertedAppSetting.code) {
      case "ALLOW_REGULAR_USERS_TO_CREATE_LABS":
        window.frontEndConfig.appSettings.ALLOW_REGULAR_USERS_TO_CREATE_LABS =
          upsertedAppSetting.value as boolean;
        break;
      case "MIN_AUTO_ANNOTATED_FEATURE_LENGTH":
        window.frontEndConfig.appSettings.MIN_AUTO_ANNOTATED_FEATURE_LENGTH =
          upsertedAppSetting.value as number;
        break;
      case "MIN_AUTO_ANNOTATED_PART_LENGTH":
        window.frontEndConfig.appSettings.MIN_AUTO_ANNOTATED_PART_LENGTH =
          upsertedAppSetting.value as number;
        break;
      case "MIN_AUTO_ANNOTATED_OLIGO_LENGTH":
        window.frontEndConfig.appSettings.MIN_AUTO_ANNOTATED_OLIGO_LENGTH =
          upsertedAppSetting.value as number;
        break;
      case "DEFAULT_PHONE_FORMAT_CODE":
        window.frontEndConfig.appSettings.DEFAULT_PHONE_FORMAT_CODE =
          upsertedAppSetting.value as string;
        break;
      default:
        throw new Error(
          `The appSetting code "${upsertedAppSetting.code}" is not handled in the upsertAppSetting method`
        );
    }
  } else {
    console.error("Something went wrong upserting an appSetting", appSetting);
  }
};
