/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose } from "redux";
import { FieldArray } from "redux-form";
import {
  NumericInputField,
  SelectField,
  RadioGroupField,
  CheckboxField
} from "@teselagen/ui";
import dayjs from "dayjs";

import { get, times } from "lodash";
import HeaderWithHelper from "../../../../src-shared/HeaderWithHelper";
import stepFormValues from "../../../../src-shared/stepFormValues";
import GenericSelect from "../../../../src-shared/GenericSelect";
import ReplicatePlateFields from "./ReplicatePlateFields";
import UniversalTransfer from "./SharedFieldComponents/UniversalTransfer";
import { dateModifiedColumn } from "../../../../src-shared/utils/libraryColumns";
import platePreviewColumn from "../../../utils/platePreviewColumn";
import ColumnPlateSelection from "./ColumnPlateSelection";
import UniversalPlateNameTemplateField from "./UniversalPlateNameTemplateField";
import mustache from "mustache";
import { Button } from "@blueprintjs/core";
import unitGlobals from "../../../../../tg-iso-lims/src/unitGlobals";

function Replication(props) {
  const {
    universalTransfers,
    sourcePlates = [],
    toolSchema,
    containerArrayTypes = [],
    newOrExistingReplicates: _newOrExistingReplicates,
    createNewReplicatesUniversal,
    stepFormProps: { change },
    numReplicatesPerPlate,
    replicatePlateNameTemplate,
    globalIncrement
  } = props;
  const newOrExistingReplicates = _newOrExistingReplicates || {};

  function submitReplicateUniversal() {
    if (replicatePlateNameTemplate && numReplicatesPerPlate) {
      const current_date = dayjs().format("l");
      let globalIndex = 0;
      sourcePlates.forEach(plate => {
        let plateType = containerArrayTypes.find(
          cat => cat.id === plate.containerArrayType.id
        );
        if (!plateType) {
          // we are not allowing racks for now so just find a matching format if there is one
          plateType = containerArrayTypes.find(containerArrayType => {
            const properFormat =
              containerArrayType.containerFormatCode ===
              plate.containerArrayType.containerFormatCode;
            return properFormat;
          });
        }

        times(numReplicatesPerPlate, index => {
          let indexToUse = index;
          if (globalIncrement === "acrossAll") {
            indexToUse = globalIndex++;
          }
          const val = mustache.render(replicatePlateNameTemplate, {
            source_plate_name: plate.name,
            incrementing_number: indexToUse + 1,
            incrementing_letter: String.fromCharCode(
              97 + (indexToUse % 26)
            ).toUpperCase(),
            current_date
          });
          change(`replicatePlateInfo.id${plate.id}.${index}.name`, val);
          change(
            `replicatePlateInfo.id${plate.id}.${index}.plateType`,
            plateType
          );
        });
      });
    }
  }

  return (
    <React.Fragment>
      <UniversalTransfer toolSchema={toolSchema} />
      {sourcePlates.length > 1 && (
        <div className="tg-step-form-section column">
          <HeaderWithHelper
            header="Bulk New Replicates"
            helper="Option to specify global fields to create a number of replicates for input plates instead of specifying individually."
            width="100%"
          />
          <CheckboxField
            name="createNewReplicatesUniversal"
            label="Bulk Apply New Replicate Info"
            onFieldSubmit={val => {
              if (val) {
                sourcePlates.forEach(plate => {
                  change(`newOrExistingReplicates.id${plate.id}`, "NEW");
                });
              }
            }}
          />
          {createNewReplicatesUniversal && (
            <div>
              <div style={{ maxWidth: 300 }}>
                <UniversalPlateNameTemplateField
                  name="replicatePlateNameTemplate"
                  tooltipInfo="This can be used to pre-populate the replicate plate names."
                  defaultValue="{{{source_plate_name}}} Replicate {{{incrementing_number}}}"
                  templateVariables={[
                    "source_plate_name",
                    "incrementing_number",
                    "incrementing_letter",
                    "current_date"
                  ]}
                />
                <NumericInputField
                  label="Number of Replicates per Plate"
                  name="numReplicatesPerPlate"
                />
              </div>
              <RadioGroupField
                label="Increment Setting"
                name="globalIncrement"
                inline
                options={[
                  {
                    label: "Increment across all replicates",
                    value: "acrossAll"
                  },
                  {
                    label: "Start increment from 1 for each input plate",
                    value: "reset"
                  }
                ]}
                defaultValue="acrossAll"
              />

              <Button
                text="Apply Replicate Info"
                intent="primary"
                onClick={() => submitReplicateUniversal()}
              />
            </div>
          )}
        </div>
      )}
      <div className="tg-step-form-section column">
        <HeaderWithHelper
          header="Replicate Plate Mapping"
          helper="For each input plate, choose whether you would
              like to create new replicates or replicate onto existing
              plates. If you choose to create new replicates enter the
              plate name, type and barcode. If you choose to replicate
              onto existing plates, specify which plates below. If a
              universal transfer volume has not been entered above,
              specify a transfer volume for each input plate."
          width="100%"
        />
        {sourcePlates.map(plate => {
          const sourcePlateKey = "id" + plate.id;
          return (
            <div key={plate.id} className="tg-flex column">
              <hr className="tg-section-break" />
              <div className="tg-flex justify-space-between">
                <div>
                  <h6>Source Plate:&nbsp;&nbsp;{plate.name}</h6>
                </div>
                {!createNewReplicatesUniversal && (
                  <RadioGroupField
                    name={`newOrExistingReplicates.id${plate.id}`}
                    options={[
                      { label: "Create New Replicates", value: "NEW" },
                      {
                        label: "Replicate onto Existing Plates",
                        value: "EXISTING"
                      },
                      {
                        label: "Replicate onto New Column Plates",
                        value: "NEW_COLUMN"
                      },
                      {
                        label: "Replicate onto Existing Column Plates",
                        value: "EXISTING_COLUMN"
                      }
                    ]}
                    defaultValue="EXISTING"
                  />
                )}
                <div className="input-with-unit-select">
                  <NumericInputField
                    label="Transfer Volume"
                    name={`transferVolumes.id${plate.id}`}
                    min={0}
                    disabled={universalTransfers}
                  />
                  <SelectField
                    name={`transferVolumeUnitCodes.id${plate.id}`}
                    defaultValue="uL"
                    label="none"
                    className="tg-unit-select"
                    options={unitGlobals.getOptionsForSelect("volumetricUnit")}
                    disabled={universalTransfers}
                  />
                </div>
              </div>
              {newOrExistingReplicates &&
                newOrExistingReplicates[sourcePlateKey] === "EXISTING" && (
                  <GenericSelect
                    {...{
                      name: `destinationPlates.id${plate.id}`,
                      schema: ["name", dateModifiedColumn],
                      isMultiSelect: true,
                      minSelected: 1,
                      getButtonText: v =>
                        v?.length
                          ? "Change Selected Plates"
                          : "Replicate onto Existing Plates",
                      fragment: ["containerArray", "id name updatedAt"],
                      additionalDataFragment: [
                        "containerArray",
                        "id name containerArrayType { id name } updatedAt"
                      ],
                      tableParamOptions: {
                        additionalFilter: (_, qb) => {
                          qb.whereAll({
                            id: qb.notInList([plate.id]),
                            "containerArrayType.containerFormatCode": get(
                              plate,
                              "containerArrayType.containerFormatCode"
                            )
                          });
                        }
                      },
                      postSelectDTProps: {
                        formName: `selectedDestinationPlates.${plate.id}`,
                        isSingleSelect: true,
                        schema: platePreviewSchema
                      }
                    }}
                  />
                )}

              <ColumnPlateSelection
                {...{
                  sourcePlates,
                  sourcePlate: plate,
                  toolSchema,
                  containerArrayTypes
                }}
              />

              {newOrExistingReplicates &&
                newOrExistingReplicates[sourcePlateKey] === "NEW" && (
                  <div className="tg-flex column">
                    <FieldArray
                      name={`replicatePlateInfo.id${plate.id}`}
                      toolSchema={toolSchema}
                      component={ReplicatePlateFields}
                      containerArrayTypes={containerArrayTypes}
                      sourcePlate={plate}
                    />
                  </div>
                )}
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
}

export default compose(
  stepFormValues(
    "universalTransfers",
    "sourcePlates",
    "selectedDestinationPlates",
    "newOrExistingReplicates",
    "createNewReplicatesUniversal",
    "numReplicatesPerPlate",
    "replicatePlateNameTemplate",
    "globalIncrement"
  )
)(Replication);

const platePreviewSchema = [
  platePreviewColumn(),
  "name",
  { displayName: "Plate Type", path: "containerArrayType.name" },
  dateModifiedColumn
];
