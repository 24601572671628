/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

// /* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { forEach } from "lodash";
import {
  aliasModels,
  extendedPropertyModels,
  tagModels
} from "../../../constants";
import { getModelFromSubtype } from "../getModelFromSubtype";
import {
  importExportSubtypes as IES,
  customInfoSubtypes
} from "./importExportSubtypes";
import shortid from "shortid";

export const tagsExampleRecord = {
  tags: [
    { name: "topSecret" },
    { name: "folder", tagOption: "Home", tagOptionColor: "purple" },
    { name: "High Value", color: "blue" }
  ]
};
export const exampleRecord = ({ isUpdateCall, alwaysIds } = {}) => {
  const sharedExampleRecordFields = {
    ...((isUpdateCall || alwaysIds) && { id: "1234" }),
    description: "Your description here",
    externalReferenceSystem: "jbei",
    externalReferenceId: shortid()
  };
  const dnaSequenceExampleRecord = {
    ...sharedExampleRecordFields,
    externalReferenceType: IES.DNA_SEQUENCE,
    ...(isUpdateCall && {
      externalReferenceType: undefined, //these shouldn't be required on updates
      externalReferenceSystem: undefined,
      externalReferenceId: undefined
    }),
    isOligo: false,
    externalReferenceUrl: `https://jbei.org/${IES.DNA_SEQUENCE}/289hf9h`,
    name: "example sequence",
    ...(!isUpdateCall && { sequence: "gatagacagaga" }),
    circular: true,
    features: [
      {
        ...(alwaysIds && { id: "8" }),
        start: 0,
        end: 10,
        name: "feat1",
        strand: 1
      },
      ...(isUpdateCall ? [{ id: "4", __remove: true }] : [])
    ]
  };
  const oligoExampleRecord = {
    ...sharedExampleRecordFields,
    externalReferenceType: IES.OLIGO,
    ...(isUpdateCall && {
      externalReferenceType: undefined, //these shouldn't be required on updates
      externalReferenceSystem: undefined,
      externalReferenceId: undefined
    }),
    isOligo: true,
    externalReferenceUrl: `https://jbei.org/${IES.OLIGO}/289hf9h`,
    name: "example oligo",
    ...(!isUpdateCall && { sequence: "gatagacagaga" })
  };
  const dnaMaterialExampleRecord = {
    ...sharedExampleRecordFields,
    externalReferenceType: IES.DNA_MATERIAL,
    externalReferenceUrl: `https://jbei.org/${IES.DNA_MATERIAL}/289hf9h`,
    name: "example material",
    sequence: dnaSequenceExampleRecord
  };
  const aminoAcidExampleRecord = {
    ...sharedExampleRecordFields,
    externalReferenceType: IES.AMINO_ACID,
    externalReferenceUrl: `https://jbei.org/${IES.AMINO_ACID}/289hf9h`,
    name: "example amino acid",
    display_$$_proteinSequence: ["mllasldll", ""],
    features: [
      { start: 0, end: 10, name: "feat1" },
      ...(isUpdateCall ? [{ id: 4, __remove: true }] : [])
    ]
    // proteinSequence: "gatagacagaga"
  };
  const reactionMapExampleRecord = {
    ...sharedExampleRecordFields,
    externalReferenceType: IES.REACTION_MAP,
    externalReferenceUrl: `https://jbei.org/${IES.REACTION_MAP}/289hf9h`,
    version: 1,
    name: "ReactionMap 1",
    reactionType: "Assembly Reaction",
    reactions: [
      {
        name: "Reaction 1",
        inputMaterials: ["ref-is-unique-to-this-file", "vGSv-ZqguGg54is08p5OH"],
        inputReagents: ["215d1c1a-66aa-11ed-9022-0242ac120002"],
        inputReagentsConserved: ["2b6b18ce-66aa-11ed-9022-0242ac120002"],
        outputMaterials: ["2f49d688-66aa-11ed-9022-0242ac120002"],
        outputReagents: ["33517e70-66aa-11ed-9022-0242ac120002"]
      },
      {
        name: "Reaction 2",
        inputMaterials: [
          "39c2f4e6-66aa-11ed-9022-0242ac120002",
          "vGSv-ZqguGg54is08p5OH"
        ],
        outputMaterials: ["3c660da0-66aa-11ed-9022-0242ac120002"]
      }
    ],
    reagents: [
      {
        ref: "215d1c1a-66aa-11ed-9022-0242ac120002",
        name: "reagent 1",
        description: "description for reagent 1",
        isDry: false,
        selectionMethods: ["Actinomycin D"],
        evaporable: true,
        reagentType: "Growth Media",
        targetOrganismGroup: "Bacteria"
      },
      {
        ref: "2b6b18ce-66aa-11ed-9022-0242ac120002",
        name: "reagent 2",
        description: "description for reagent 2",
        isDry: false,
        selectionMethods: ["Actinomycin D"],
        evaporable: true,
        reagentType: "Growth Media"
      },
      {
        ref: "33517e70-66aa-11ed-9022-0242ac120002",
        name: "reagent 3",
        description: "description for reagent 3",
        isDry: false,
        selectionMethods: ["Actinomycin D"],
        evaporable: true,
        reagentType: "Growth Media"
      }
    ],
    materials: [
      {
        ref: "ref-is-unique-to-this-file",
        name: "mat for seq 1",
        materialType: "DNA",
        sequences: ["108hq0"]
      },
      {
        ref: "vGSv-ZqguGg54is08p5OH",
        name: "mat for seq 2",
        materialType: "DNA",
        sequences: ["9a9fha"]
      },
      {
        ref: "2f49d688-66aa-11ed-9022-0242ac120002",
        name: "mat for seq 3",
        materialType: "DNA",
        sequences: ["lkj1lk2jj"]
      },
      {
        ref: "39c2f4e6-66aa-11ed-9022-0242ac120002",
        name: "mat for microbe 1",
        materialType: "MICROBIAL",
        strain: "x09124"
      },
      {
        ref: "3c660da0-66aa-11ed-9022-0242ac120002",
        name: "mat for microbe 2",
        materialType: "MICROBIAL",
        strain: "x09124",
        sequences: ["108hq0", "9a9fha"]
      }
    ],
    strains: [
      {
        ref: "x09124",
        name: "strain 1",
        description: "I am a strain",
        genotype: "strain genotype",
        sequences: ["lkj1lk2jj"],
        biosafetyLevel: "1",
        genus: "Escherichia",
        species: "coli",
        genomeName: "Test Genome",
        targetOrganismGroup: "Bacteria",
        selectionMethods: ["Actinomycin D"],
        growthCondition: {
          name: "growth condition 1",
          description: "this is a growth condition",
          growthMedium: "Test Growth Medium",
          gasComposition: "Test Gas Composition",
          temperature: 96,
          humidity: 40,
          shakerSpeed: 30,
          shakerThrow: 14,
          lengthUnit: "cm"
        }
      }
    ],
    sequences: [
      {
        ref: "108hq0",
        sequenceType: "LINEAR_DNA",
        sequence: "gtactctccccgagagagagaactccccactagggaaatcccctactcccccccc",
        name: "seq 1",
        features: [{ name: "exampleFeat 1", start: 10, end: 20 }]
      },
      {
        ref: "9a9fha",
        sequenceType: "LINEAR_DNA",
        sequence: "gtactctactacgggaaaagggaaaaaatatctactcccccccc",
        name: "seq 2",
        features: [{ name: "exampleFeat 2", start: 10, end: 20 }]
      },
      {
        ref: "lkj1lk2jj",
        sequenceType: "LINEAR_DNA",
        sequence:
          "gtactctccccgagagagagaactccccactagggaaatcccctactccccccccgtactctactacgggaaaagggaaaaaatatctactcccccccc",
        name: "seq 3",
        features: [{ name: "exampleFeat 3", start: 10, end: 20 }]
      }
    ]
  };
  const reactionDesignExampleRecord = {
    ...sharedExampleRecordFields,
    externalReferenceType: IES.REACTION_DESIGN,
    externalReferenceUrl: `https://jbei.org/${IES.REACTION_DESIGN}/289hf9h`,
    version: 2,
    name: "Example Reaction Design",
    reactionMaps: [
      {
        name: "Example Reaction Map 1",
        reactionType: "Assembly Reaction",
        executionOrder: "1.1",
        reactions: [
          {
            inputMaterials: [
              "ref-is-unique-to-this-file",
              "vGSv-ZqguGg54is08p5OH"
            ],
            inputReagents: ["215d1c1a-66aa-11ed-9022-0242ac120002"],
            inputReagentsConserved: ["2b6b18ce-66aa-11ed-9022-0242ac120002"],
            outputMaterials: ["2f49d688-66aa-11ed-9022-0242ac120002"],
            outputReagents: ["33517e70-66aa-11ed-9022-0242ac120002"]
          },
          {
            inputMaterials: [
              "39c2f4e6-66aa-11ed-9022-0242ac120002",
              "vGSv-ZqguGg54is08p5OH"
            ],
            outputMaterials: ["3c660da0-66aa-11ed-9022-0242ac120002"]
          }
        ]
      },
      {
        name: "Example Reaction Map 2",
        reactionType: "Assembly Reaction",
        executionOrder: "1.2",
        reactions: [
          {
            inputMaterials: ["sf89sdf-kjj7sd5-sdjfh7", "ryre6-74jvnv-48fsdm"],
            outputMaterials: ["vncmbcd-74474jdn-cvnv747-ddmv123"]
          }
        ]
      },
      {
        name: "Example Reaction Map 3",
        reactionType: "Transformation",
        executionOrder: "2.1",
        reactions: [
          {
            inputMaterials: [
              "vncmbcd-74474jdn-cvnv747-ddmv123",
              "3c660da0-66aa-11ed-9022-0242ac120002"
            ],
            outputMaterials: ["klasdj-lckas78987-89cahkjd-87ckjsd898c"]
          }
        ]
      }
    ],
    reagents: [
      {
        ref: "215d1c1a-66aa-11ed-9022-0242ac120002",
        name: "reagent 1",
        description: "description for reagent 1",
        isDry: false,
        selectionMethods: ["Actinomycin D"],
        evaporable: true,
        reagentType: "Growth Media",
        targetOrganismGroup: "Bacteria"
      },
      {
        ref: "2b6b18ce-66aa-11ed-9022-0242ac120002",
        name: "reagent 2",
        description: "description for reagent 2",
        isDry: false,
        selectionMethods: ["Actinomycin D"],
        evaporable: true,
        reagentType: "Growth Media"
      },
      {
        ref: "33517e70-66aa-11ed-9022-0242ac120002",
        name: "reagent 3",
        description: "description for reagent 3",
        isDry: false,
        selectionMethods: ["Actinomycin D"],
        evaporable: true,
        reagentType: "Growth Media"
      }
    ],
    materials: [
      {
        ref: "ref-is-unique-to-this-file",
        name: "mat for seq 1",
        materialType: "DNA",
        sequences: ["108hq0"]
      },
      {
        ref: "vGSv-ZqguGg54is08p5OH",
        name: "mat for seq 2",
        materialType: "DNA",
        sequences: ["9a9fha"]
      },
      {
        ref: "2f49d688-66aa-11ed-9022-0242ac120002",
        name: "mat for seq 3",
        materialType: "DNA",
        sequences: ["lkj1lk2jj"]
      },
      {
        ref: "39c2f4e6-66aa-11ed-9022-0242ac120002",
        name: "mat for microbe 1",
        materialType: "MICROBIAL",
        strain: "x09124"
      },
      {
        ref: "3c660da0-66aa-11ed-9022-0242ac120002",
        name: "mat for microbe 2",
        materialType: "MICROBIAL",
        strain: "x09124",
        sequences: ["108hq0", "9a9fha"]
      },
      {
        ref: "sf89sdf-kjj7sd5-sdjfh7",
        name: "mat for seq 4",
        materialType: "DNA",
        sequences: ["3983jksdf8"]
      },
      {
        ref: "ryre6-74jvnv-48fsdm",
        name: "mat for seq 5",
        materialType: "DNA",
        sequences: ["lksjdl687csk"]
      },
      {
        ref: "vncmbcd-74474jdn-cvnv747-ddmv123",
        name: "mat for seq 6",
        materialType: "DNA",
        sequences: ["kjvxlchv78798cxv"]
      },
      {
        ref: "klasdj-lckas78987-89cahkjd-87ckjsd898c",
        name: "mat for microbe 3",
        materialType: "MICROBIAL",
        strain: "x09124",
        sequences: ["108hq0", "9a9fha", "kjvxlchv78798cxv"]
      }
    ],
    strains: [
      {
        ref: "x09124",
        name: "strain 1",
        description: "I am a strain",
        genotype: "strain genotype",
        sequences: ["lkj1lk2jj"],
        biosafetyLevel: "1",
        genus: "Escherichia",
        species: "coli",
        genomeName: "Test Genome",
        targetOrganismGroup: "Bacteria",
        selectionMethods: ["Actinomycin D"],
        growthCondition: {
          name: "growth condition 1",
          description: "this is a growth condition",
          growthMedium: "Test Growth Medium",
          gasComposition: "Test Gas Composition",
          temperature: 96,
          humidity: 40,
          shakerSpeed: 30,
          shakerThrow: 14,
          lengthUnit: "cm"
        }
      }
    ],
    sequences: [
      {
        ref: "108hq0",
        sequenceType: "LINEAR_DNA",
        sequence: "gtactctccccgagagagagaactccccactagggaaatcccctactcccccccc",
        name: "seq 1",
        features: [
          {
            name: "exampleFeat 1",
            start: 10,
            end: 20
          }
        ]
      },
      {
        ref: "9a9fha",
        sequenceType: "LINEAR_DNA",
        sequence: "gtactctactacgggaaaagggaaaaaatatctactcccccccc",
        name: "seq 2",
        features: [
          {
            name: "exampleFeat 2",
            start: 10,
            end: 20
          }
        ]
      },
      {
        ref: "lkj1lk2jj",
        sequenceType: "LINEAR_DNA",
        sequence:
          "gtactctccccgagagagagaactccccactagggaaatcccctactccccccccgtactctactacgggaaaagggaaaaaatatctactcccccccc",
        name: "seq 3",
        features: [
          {
            name: "exampleFeat 3",
            start: 10,
            end: 20
          }
        ]
      },
      {
        ref: "3983jksdf8",
        sequenceType: "LINEAR_DNA",
        sequence: "ctcgactgactcgctagcgcgccgcgctccccctatcgatcgctcgacgccgcggggg",
        name: "seq 4",
        features: [
          {
            name: "exampleFeat 4",
            start: 3,
            end: 8
          }
        ]
      },
      {
        ref: "lksjdl687csk",
        sequenceType: "LINEAR_DNA",
        sequence:
          "tttatatatattcgctagacacacccaaaaatcgctcgatcgtcgctgcgcctcgcgggg",
        name: "seq 5",
        features: [
          {
            name: "exampleFeat 5",
            start: 13,
            end: 28
          }
        ]
      },
      {
        ref: "kjvxlchv78798cxv",
        sequenceType: "LINEAR_DNA",
        sequence:
          "gggtgtgtgtggggccccccaagagaccgacgacctcgctcgccgcgcgcgcgcgcacacgcgcgcgcgggggggggctctctacaca",
        name: "seq 6",
        features: [
          {
            name: "exampleFeat 6",
            start: 2,
            end: 23
          }
        ]
      }
    ]
  };
  const microbialStrainExampleRecord = {
    ...sharedExampleRecordFields,
    externalReferenceType: IES.MICROBIAL_STRAIN,
    externalReferenceUrl: `https://jbei.org/${IES.MICROBIAL_STRAIN}/289hf9h`,
    name: "myNewStrain",
    genotype: "myGenotype",
    display_$$_biosafetyLevel: ["1|2|3|4|N/A", "1"], //this is a little display hack so we can get around the validation
    selectionMethods: ["zeocin", "polymyxin"],
    genus: "Escherichia",
    species: "coli",
    plasmids: [dnaSequenceExampleRecord],
    genomes: [
      {
        name: "seq to create",
        sequence: "gatagacagaga",
        features: [{ start: 0, end: 10, name: "featToCreate" }]
      },
      {
        id: "3",
        name: "edited seq name"
      },
      ...(isUpdateCall ? [{ id: "4", __remove: true }] : [])
    ],
    organismClass: "yeast|eukaryotic|bacteria",
    growthConditionName: "myGrowthConditionName",
    growthConditionDescription: "myGrowthConditionDescription",
    growthMedium: "test growth medium",
    temperature: 38,
    shakerSpeed: 44,
    shakerThrow: 5,
    display_$$_lengthUnit: ["cm|in|mm", "cm"] //this is a little display hack so we can get around the validation
  };

  const aliquotExampleRecord = {
    ...((isUpdateCall || alwaysIds) && { id: "1234" }),
    description: "Your description here",
    volume: 23,
    volumetricUnitCode: "uL",
    dnaMaterial: dnaMaterialExampleRecord
  };

  const tubeExampleRecord = {
    ...sharedExampleRecordFields,
    externalReferenceType: IES.TUBE,
    externalReferenceUrl: `https://jbei.org/${IES.TUBE}/289hf9h`,
    name: "myTube",
    barcode: "Z10023",
    tubeType: "tube",
    aliquot: aliquotExampleRecord
  };

  const plateExampleRecord = {
    ...sharedExampleRecordFields,
    externalReferenceType: IES.PLATE,
    externalReferenceUrl: `https://jbei.org/${IES.PLATE}/289hf9h`,
    name: "myNewPlate",
    barcode: "Z10002",
    plateType: "Generic 96 Well Plate",
    contents: [
      {
        location: "A2",
        aliquot: {
          ...aliquotExampleRecord
        }
      }
    ]
  };
  const microbialMaterialExampleRecord = {
    ...sharedExampleRecordFields,
    externalReferenceType: IES.MICROBIAL_MATERIAL,
    externalReferenceUrl: `https://jbei.org/${IES.MICROBIAL_MATERIAL}/289hf9h`,
    name: "example material",
    strainName: "NEB T7 Express Lys/Iq",
    strain: microbialStrainExampleRecord,
    biosafetyLevel: "2",
    ...(!isUpdateCall && { plasmidNames: ["ssrA_tag_enhance", "pj5_00001"] }),
    plasmids: [dnaSequenceExampleRecord]
  };

  const userRequestExampleRecord = {
    ...sharedExampleRecordFields,
    externalReferenceType: IES.USER_REQUEST,
    externalReferenceUrl: `https://jbei.org/${IES.USER_REQUEST}/289hf9h`,
    name: "example user request",
    feedback: "user request for analyzing sequences",
    status: "In Progress",
    requestType: "j5 Assembly",
    primaryCustomer: "joe smith",
    customers: ["billy bob", "jane doe"],
    dueDate: "10/22/2026",
    strains: [microbialStrainExampleRecord],
    sequences: [dnaSequenceExampleRecord]
  };

  const dnaPartExampleRecord = {
    ...((isUpdateCall || alwaysIds) && { id: "1234" }),
    name: "myPart",
    start: 1,
    end: 4,
    strand: 1,
    partSequence: "gata",
    sequenceId: "j020fj2f0",
    sourceSequence: {
      ...((isUpdateCall || alwaysIds) && { id: "j020fj2f0" }),
      name: "example sequence",
      sequence: "gatagacagaga",
      hash: "H2Pa67ad689asd8asd8na90d0azz",
      circular: true
    }
  };
  const selectionMethodExampleRecord = {
    ...((isUpdateCall || alwaysIds) && { id: "1234" }),
    name: "My Selection Method",
    description: "Uses Kanamycin as a selection marker",
    organismGroups: ["Bacteria"],
    cofactor: "N/A"
  };
  const organismExampleRecord = {
    ...((isUpdateCall || alwaysIds) && { id: "1234" }),
    name: "My Organism",
    description: "My Little Organism"
  };
  const equipmentExampleRecord = {
    ...((isUpdateCall || alwaysIds) && { id: "1234" }),
    name: "myEquipment",
    description: "This is a description",
    locationName: "SF Office",
    equipmentTypeCode: "BUNSEN_BURNER"
  };

  const designPartExampleRecord = {
    ...((isUpdateCall || alwaysIds) && { id: "1234" }),
    designId: 1,
    index: 0,
    dnaPart: dnaPartExampleRecord,
    forcedAssemblyStrategy: "PCR"

    // TODO: multiple example responses is not available for subtypes.
    // // This can be quite useful but logic needs to be refactored at 'EditIntegrationDialog.js' file
    // __tgMultipleExamples__: [
    //   {
    //     __tgTabTitle__: "Update part",
    //     title: "Circular Sequence Fields",
    //     ...{
    //       ...((isUpdateCall || alwaysIds) && { id: "1234" }),
    //       designId: 1,
    //       dnaPart: dnaPartExampleRecord
    //     }
    //   },
    //   {
    //     __tgTabTitle__: "Create part",
    //     title: "Circular Sequence Fields",
    //     ...{
    //       ...((isUpdateCall || alwaysIds) && { id: "1234" }),
    //       designId: 1,
    //       dnaPart: dnaPartExampleRecord
    //     }
    //   }
    // ]
  };

  const designExampleRecord = {
    ...((isUpdateCall || alwaysIds) && { id: "1234" }),
    design: {}
  };

  const proteinMaterialExampleRecord = {
    name: "myProtein"
  };

  const toRet = {
    __subtype__: {
      AMINO_ACID: aminoAcidExampleRecord,
      DESIGN_PART: designPartExampleRecord,
      DESIGN: designExampleRecord,
      DNA_MATERIAL: dnaMaterialExampleRecord,
      DNA_PART: dnaPartExampleRecord,
      USER_REQUEST: userRequestExampleRecord,
      DNA_SEQUENCE: dnaSequenceExampleRecord,
      EQUIPMENT: equipmentExampleRecord,
      MICROBIAL_MATERIAL: microbialMaterialExampleRecord,
      MICROBIAL_STRAIN: microbialStrainExampleRecord,
      OLIGO: oligoExampleRecord,
      ORGANISM: organismExampleRecord,
      PLATE: plateExampleRecord,
      REACTION_DESIGN: reactionDesignExampleRecord,
      REACTION_MAP: reactionMapExampleRecord,
      SELECTION_METHOD: selectionMethodExampleRecord,
      TUBE: tubeExampleRecord,
      PROTEIN_MATERIAL: proteinMaterialExampleRecord
    }
  };

  forEach(toRet.__subtype__, (exampleObj, subtype) => {
    const model = getModelFromSubtype(subtype);
    if (aliasModels.includes(model)) {
      exampleObj.aliases = ["aliasedName1", "someOtherAliasesName"];
    }
    if (tagModels.includes(model)) {
      exampleObj.tags = tagsExampleRecord.tags;
    }
    if (extendedPropertyModels.includes(model)) {
      exampleObj.extendedProperties = [
        {
          name: "jbeiStatus",
          value: "DONE"
        },
        {
          name: "dateSentForValidation",
          value: "6/16/2021 11:41:31"
        }
      ];
    }
  });
  return toRet;
};

export const exampleSimpleRecord = {
  name: "seq 1",
  id: "12151",
  __subtype__: {
    [IES.DNA_SEQUENCE]: {
      circular: true
    }
  }
};

export const searchExample = {
  searchTerm: "kanR",
  customFields: {
    status: "inProgress",
    customSearchTerm: "someName"
  }
};

export const sharedImportResults = {
  results: [
    {
      id: "123",
      name: "seq 1",
      size: subtype => {
        if ([IES.MICROBIAL_MATERIAL, IES.MICROBIAL_STRAIN].includes(subtype)) {
          return;
        }
        return 203;
      },
      description: "...",
      myCustomField: "I'm not a default field"
    }
  ],
  additionalFieldsToDisplay: [
    "myCustomField"
    // {path: "size", isHidden: true} //override the size
    // {path: "special", type: "lookup", displayName: "Special Type" }
  ]
};
const exampleCustomInfoRecords = {};
forEach(customInfoSubtypes, (v, k) => {
  exampleCustomInfoRecords[k] = {
    id: "65",
    internalType: k,
    externalReferenceSystem: "jbei",
    externalReferenceId: "289hf9h",
    externalReferenceType: k,
    externalReferenceUrl: `https://jbei.org/${k}/289hf9h`
  };
});

export const exampleCustomInfoRecord = {
  __subtype__: exampleCustomInfoRecords
};

export const formInputs = {
  formInputs: [
    {
      isRequired: true,
      fieldType: "SelectField",
      name: "status",
      label: "Status",
      options: [
        { label: "Complete", value: "complete" },
        { label: "In Progress", value: "inProgress" },
        { label: "Abandoned", value: "abandoned" }
      ]
    },
    {
      isRequired: true,
      fieldType: "SelectField",
      name: "bioSafetyLevel",
      label: "Biosafety Level",
      options: [
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" }
      ]
    },

    {
      isRequired: true,
      fieldType: "SelectField",
      name: "selectionMarkers",
      label: "Selection Markers",
      isMulti: true,
      defaultValue: "neomycin",
      options: [
        { label: "Kanamycin", value: "kanamycin" },
        { label: "Ampicilin", value: "ampicilin" },
        { label: "Neomycin", value: "neomycin" }
      ]
    },
    {
      isRequired: true,
      fieldType: "SelectField",
      name: "cascadingDropdown",
      label: "Cascading Dropdown",
      cascadeEndpoint: "node-red://get-form-inputs",
      options: [
        { label: "Project 1", value: "p1" },
        { label: "Project 2", value: "p2" },
        { label: "Project 3", value: "p3" }
      ]
    },
    {
      fieldType: "TextareaField",
      name: "summary",
      label: "Summary",
      defaultValue: "This sequence is being exported from TeselaGen"
    }
  ]
};
