/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { compose } from "redux";
import { SHAPE_WIDTH } from "../../constants";
import SBOLIcons from "../../../SBOLIcons/SBOLIcons";

const commonDefaultProps = {
  text: "",
  textColor: "black",
  stroke: "black",
  strokeWidth: 1,
  fill: "none",
  x: 0,
  y: 0,
  onDoubleClick: () => {}
};

class RestrictionEnzymeRecognitionSiteClass extends React.Component {
  static defaultProps = {
    ...commonDefaultProps
  };
  render() {
    let { fill } = this.props;
    const {
      x,
      y,
      beingDragged,
      connectDragSource,
      connectDropTarget,
      isDragging,
      isOver,
      canDrop,
      item
    } = this.props;

    if (isOver && canDrop && item) {
      fill = item.color;
    }

    return compose(
      connectDropTarget,
      connectDragSource
    )(
      <g transform={`translate(${x},${y})`}>
        {!beingDragged && !isDragging && (
          <rect
            className={
              "schematic-promoter-shape schematic-shape " +
              (isDragging ? "schematic-shape-dragging" : "")
            }
            {...{
              width: SHAPE_WIDTH,
              height: SHAPE_WIDTH,
              fill: "none",
              stroke: "none",
              x: 0,
              y: -5
            }}
          />
        )}
        <path
          className={
            "schematic-promoter-shape schematic-shape " +
            (isDragging ? "schematic-shape-dragging" : "")
          }
          fill="none"
          stroke={fill}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
          d={SBOLIcons["RESTRICTION-ENZYME-RECOGNITION-SITE"].path}
        />
      </g>
    );
  }
}

export default RestrictionEnzymeRecognitionSiteClass;
