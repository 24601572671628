/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { useCallback, useState } from "react";
import { InputField } from "@teselagen/ui";
import { Tooltip, Position, Icon, Intent } from "@blueprintjs/core";
import _checkPassword from "../../../../tg-iso-shared/src/utils/checkPassword";

type Props = {
  // Field props
  name: string;
  label?: string;
  isRequired?: boolean;
  autoComplete?: string;
  tooltipError?: boolean;
  tooltipProps?: any;

  checkPassword?: (password: string, options: any) => any; // Only passed in tests, otherwise used tg-iso-shared method
  email?: string;
  showStrength?: boolean;
  leftIcon?: string;
};

type StrengthResult = {
  score: number;
  passed: boolean;
  message: string;
  warning?: string;
  suggestions?: string[];
};

const PasswordWithStrength = (props: Props) => {
  const {
    email,
    checkPassword = _checkPassword,
    showStrength = true,
    ...rest
  } = props;

  const [strengthResult, setStrengthResult] = useState<StrengthResult | null>(
    null
  );

  const validate = useCallback(
    (value: string) => {
      if (showStrength === false || value === undefined) {
        return;
      }

      let _strengthResult = null;
      _strengthResult = checkPassword(value, { email });

      setStrengthResult(_strengthResult);

      if (!_strengthResult.passed) {
        return _strengthResult.message;
      }
    },
    [checkPassword, email, showStrength]
  );

  const { score, passed, message, warning, suggestions } = strengthResult || {};

  const intent =
    score && score <= 1
      ? Intent.DANGER
      : score === 2
        ? Intent.WARNING
        : Intent.SUCCESS;
  const icon =
    score && score <= 1
      ? "error"
      : score === 2
        ? "warning-sign"
        : "tick-circle";

  let tooltipMsg: string | React.JSX.Element | undefined = message;
  if (!passed && (warning || (suggestions && suggestions.length))) {
    tooltipMsg = (
      <div className="strength-message">
        <div>{message}</div>
        <div>{warning}</div>
        <br />
        <ul>{suggestions?.map(sug => <li key={sug}>{sug}</li>)}</ul>
      </div>
    );
  }

  return (
    <InputField
      type="password"
      {...rest}
      validate={validate}
      rightElement={
        showStrength &&
        tooltipMsg && (
          <Tooltip
            content={tooltipMsg}
            className="password-strength"
            position={Position.RIGHT}
            intent={intent}
          >
            <Icon icon={icon} intent={intent} tabIndex={1000} />
          </Tooltip>
        )
      }
    />
  );
};

export default PasswordWithStrength;
