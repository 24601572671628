/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import zxcvbn from "zxcvbn";

/**
 * password strength checker
 * @param {string} password
 * @param {object} options
 * @param {string=} options.email
 * @returns {object} result
 * @returns {number} result.score - password strength score
 * @returns {boolean} result.passed - if password passed the strength test
 * @returns {string} result.message - password strength message
 * @returns {string[]=} result.warning - password warning message
 * @returns {string[]=} result.suggestions - password suggestions
 */
const checkPassword = (password, { email } = {}) => {
  if (!password)
    return {
      score: 0,
      passed: false,
      message: "Very Weak Password"
    };
  if (!email) {
    //filter out any empty keywords
    return {
      score: 0,
      passed: false,
      message: "User email is needed to evaluate password strength"
    };
  }
  // int from 0-4, 4 being the safest
  const result = zxcvbn(
    password,
    ["teselagen", email] //this is the keyword blocklist
  );
  const classes = [
    "Very Weak",
    "Weak",
    "Somewhat Weak",
    "Strong",
    "Very Strong"
  ];

  const out = {
    score: result.score,
    message: `${classes[result.score]} Password`,
    passed: result.score >= 3
  };

  if (result.score <= 2 && result.feedback) {
    out.warning = result.feedback.warning;
    out.suggestions = result.feedback.suggestions;
  }

  return out;
};

export default checkPassword;
