/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";
import externalRecordTypeFragment from "./externalRecordTypeFragment";

export default gql`
  fragment integrationFragment on integration {
    id
    name
    subtype
    includeUserIdHeader
    includeUsernameHeader
    uniqueIdentifier
    includeLabIdHeader
    includeProjectIdHeader
    includeAuthTokenHeader
    integrationTypeCode
    integrationHeaders {
      id
      name
      value
      isEncrypted
    }
    integrationEndpoints {
      id
      externalRecordType {
        ...externalRecordTypeFragment
      }
      url
      endpointTypeCode
      integrationEndpointHeaders {
        id
        name
        value
        isEncrypted
      }
    }
  }
  ${externalRecordTypeFragment}
`;
