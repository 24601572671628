/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { isEqual } from "lodash";
// import { createSelector } from "reselect";
// import { get } from "lodash";
import { ZOOM_LEVELS } from "../redux/reducers/ui/designEditor/tree";
import { getDesignState } from "../../../tg-iso-design/selectors/designStateSelectors";

export const getLastSavedState = state =>
  state.ui.designEditor.general.lastSavedState;

export const getTailOfUndoStack = state => {
  const { undoStack } = state.ui.designEditor.undo;
  return undoStack[undoStack.length - 1];
};

export const getTailOfRedoStack = state => {
  const { redoStack } = state.ui.designEditor.undo;
  return redoStack[redoStack.length - 1];
};

export const hasUnsavedChangesDeep = state =>
  !isEqual(getLastSavedState(state), getDesignState(state));

export const canZoomIn = state =>
  ZOOM_LEVELS.indexOf(state.ui.designEditor.tree.zoom) !== 0;

export const canZoomOut = state =>
  ZOOM_LEVELS.indexOf(state.ui.designEditor.tree.zoom) !==
  ZOOM_LEVELS.length - 1;

export const getDeletedItems = state =>
  state.ui.designEditor.general.deletedItems;
