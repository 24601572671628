/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import Helmet from "react-helmet";
import AdminCallout from "../../../src-shared/SharedAppSettings/AdminCallout";
import ChangeAutoAnnotateMinimumForm from "./Forms/ChangeAutoAnnotateMinimumForm";

const AppConfigurationPanel = () => {
  return (
    <div className="tg-card xtra-pad">
      <Helmet title="Minimum Lengths" />
      <AdminCallout>
        As an admin you can adjust minimum lengths of parts and features.
      </AdminCallout>
      <h3>Minimum Lengths</h3>
      <ChangeAutoAnnotateMinimumForm />
    </div>
  );
};

export default AppConfigurationPanel;
