/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { get } from "lodash";
import { useState } from "react";
import { compose } from "recompose";
import { wrapDialog } from "@teselagen/ui";
import HeaderWithHelper from "../../HeaderWithHelper";
import ApiKeyForm from "../ApiKeyForm";
import { clearApiKey, getApiKey } from "../../auth/utils";

// Service account tokens are by default valid for 1 year.
const ONE_YEAR = 12 * 30 * 24 * 3600 * 1000;

const API_KEY_LABEL = "Token";

const COPY_TOKEN_MESSAGE =
  "Copy the token now, it will not be presented again.";
const TOKEN_MESSAGE_NOT_VISIBLE_MESSAGE =
  "Token is no longer visible. You can generate a new one. Remember to store it.";
const GENERATE_TOKEN_MESSAGE = "Generate a token for: ";

const GenerateTokenForServiceAccountDialog = props => {
  const { refetch, serviceAccount } = props;

  const [token, setToken] = useState(null);
  const [tokenExpiration, setTokenExpiration] = useState(
    serviceAccount?.userLogins[0]?.apiKeyExpiration
  );

  const generateToken = async () => {
    try {
      const res = await getApiKey(ONE_YEAR, serviceAccount.id);
      const { apiKey, apiKeyExpiration } = res;
      setToken(apiKey);
      setTokenExpiration(apiKeyExpiration);
      await refetch();
    } catch (error) {
      console.error(`error -> ${error}`);
      console.error("error:", error.response);
      const msg = get(error, "response.data.error");
      window.toastr.error(msg || "Error creating apiKey");
    }
  };

  const clearToken = async () => {
    try {
      await clearApiKey(serviceAccount.id);
      setToken(null);
      setTokenExpiration(null);
      window.toastr.success(`Cleared Token for ${serviceAccount.email}`);
      await refetch();
    } catch (error) {
      console.error(`error -> ${error}`);
      console.error("error:", error.response);
      const msg = get(error, "response.data.msg");
      window.toastr.error(msg || "Error clearing token");
    }
  };

  const generateTokenMessage = () => {
    return tokenExpiration
      ? token
        ? COPY_TOKEN_MESSAGE
        : TOKEN_MESSAGE_NOT_VISIBLE_MESSAGE
      : GENERATE_TOKEN_MESSAGE + serviceAccount.email;
  };

  return (
    <div
      style={{
        paddingLeft: 20,
        paddingTop: 20,
        display: "flex",
        flexDirection: "column",
        minHeight: 40
      }}
    >
      <ApiKeyForm
        header={
          <HeaderWithHelper header={generateTokenMessage()} width="100%" />
        }
        apiKeyLabel={API_KEY_LABEL}
        apiKey={token}
        apiKeyExpiration={tokenExpiration}
        handleClearApiKey={clearToken}
        handleGenerateApiKey={generateToken}
      />
    </div>
  );
};

export default compose(
  wrapDialog({ title: "Generate Token", style: { width: "60%" } })
)(GenerateTokenForServiceAccountDialog);
