/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import widgetCardStyles from "../../WidgetCardStyles.module.css";
import { EvolveConfig, tooltips } from "../../../../../configs/config";
import LabelWithTooltip from "../../../../../../src-shared/LabelWithTooltip";
import { HTMLSelect } from "@blueprintjs/core";

class ModelResultsWidgetHeader extends Component {
  constructor(props) {
    super(props);

    const { updateWidgetData } = props;

    updateWidgetData({ datapointType: "output" });

    this.state = {
      updateWidgetData: updateWidgetData
    };
  }

  getToolTip() {
    switch (this.props.modelType) {
      case EvolveConfig.constants.PREDICTIVE_MODEL:
        return tooltips.predictiveModelData;
      case EvolveConfig.constants.EVOLUTIVE_MODEL:
        return tooltips.evolutiveModelData;
      case EvolveConfig.constants.GENERATIVE_MODEL:
        return tooltips.generativeModelData;
      default:
        return "";
    }
  }

  changeSelectedFeature = event => {
    const { updateWidgetData } = this.state;
    const selectedDatapointType = event.currentTarget.value;

    updateWidgetData({
      selectedDatapointType:
        selectedDatapointType === "input data" ? "input" : "output"
    });
  };

  generateSelectFromFeatures = () => {
    return (
      <HTMLSelect
        onChange={this.changeSelectedFeature}
        defaultValue="output data"
      >
        {[
          <option key={`${"input"}`}>input data</option>,
          <option key={`${"output"}`}>output data</option>
        ]}
      </HTMLSelect>
    );
  };

  render() {
    return (
      <div className={widgetCardStyles.widgetHeader}>
        <LabelWithTooltip
          label={<h4>Model Data</h4>}
          tooltip={this.getToolTip()}
        />
        {this.generateSelectFromFeatures()}
      </div>
    );
  }
}

export default ModelResultsWidgetHeader;
