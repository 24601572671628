/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
// @ts-nocheck
import queryString from "query-string";
import Cookies from "js-cookie";
import { startsWith } from "lodash";
import isEmail from "validator/lib/isEmail";
import { RegisteredUserFragment } from "./fragments.gql.generated";

const authTokenName = "authToken-teselagen";
let localStorage;

// If we're testing, use a local storage polyfill
if (global.process && process.env.NODE_ENV === "test") {
  localStorage = require("localStorage");
} else {
  // If not, use the browser one
  localStorage = global.window.localStorage;
}

export const setAuthToken = (token: string) => {
  localStorage.setItem(authTokenName, token);
  window.dispatchEvent(new Event("authToken"));
  return token;
};

export const getAuthToken = () => {
  const token = localStorage.getItem(authTokenName);
  return token;
};

export const clearLocalStorageItems = () => {
  localStorage.removeItem("authenticated");
  localStorage.removeItem("azureAuthenticated");
  localStorage.removeItem("authenticated");
  localStorage.removeItem("samlAuthenticated");
  localStorage.removeItem("authenticated");
  localStorage.removeItem("ldapAuthenticated");
  localStorage.removeItem("authToken");
  localStorage.removeItem("authToken-teselagen");
  localStorage.removeItem("auth-error");
  localStorage.removeItem("darkMode");
  localStorage.removeItem("lastSelectedLabId");
  localStorage.removeItem("activeLabId");
  localStorage.removeItem("lastSelectedProjectId");
  localStorage.removeItem("activeProjectId");
  localStorage.removeItem("networkRequestTracker");
  localStorage.removeItem("userEmail");
  localStorage.removeItem("username");
  localStorage.removeItem("userId");
};

export const getApiKey = async (expirationOffset?: number, userId?: string) => {
  let resp;
  if (userId) {
    const queryParams = queryString.stringify({ expirationOffset });
    resp = await window.tgApi({
      method: "PUT",
      url: `/users/${userId}/api-key?${queryParams}`
    });
  } else {
    resp = await window.serverApi.post("/apiKey", {
      expirationOffset: expirationOffset || 3600 * 1000
    });
  }

  return {
    apiKey: resp.data.apiKey,
    apiKeyExpiration: resp.data.apiKeyExpiration
  };
};

export const clearApiKey = async (userId: string) => {
  /* remove apikey from row */
  if (userId) {
    return await window.tgApi({
      method: "DELETE",
      url: `/users/${userId}/api-key`
    });
  }
  return await window.serverApi.delete("/apiKey");
};

export function isPasswordAbleToBeTestedForStrength(password, email) {
  try {
    return (
      !!(password && password.trim().length > 0) && !!(email && isEmail(email))
    );
  } catch (error) {
    return false;
  }
}

export const auth = {
  getAuthToken,
  setAuthToken,

  /**
   * Logs the current user out
   */
  async logout() {
    const darkMode = localStorage.getItem("darkMode") === "true";
    let ssoResponse;
    try {
      if (window.frontEndConfig.azureADLogin) {
        ssoResponse = await window.serverApi.post("/adlogout");
      } else if (window.frontEndConfig.samlLogin) {
        ssoResponse = await window.serverApi.post("/samllogout");
      } else if (window.frontEndConfig.googleLogin) {
        ssoResponse = await window.serverApi.post("/google-logout");
      } else {
        await window.serverApi.post("/auth/logout");
      }
    } catch (error) {
      console.error(error);
    }
    const authError = localStorage.getItem("auth-error");
    clearLocalStorageItems();

    Cookies.remove("auth-token");
    console.warn("Stored reset in progress...");

    window.teGlobalStore.dispatch({
      type: "APOLLO_STORE_RESET",
      observableQueryIds: []
    });

    console.warn("APOLLO REFRESH");

    // persist user darkmode setting
    if (darkMode) {
      localStorage.setItem("darkMode", true);
    }

    if (authError) {
      localStorage.setItem("auth-error", authError);
    }

    if (ssoResponse && ssoResponse.data.logoutUrl) {
      window.location.replace(ssoResponse.data.logoutUrl);
    } else {
      if (window.frontEndConfig.logoutRedirect) {
        const logoutRedirect = window.frontEndConfig.logoutRedirect;
        if (startsWith(logoutRedirect.toLowerCase(), "http")) {
          window.location.href = logoutRedirect;
        } else {
          //assume it's relative to server
          window.location.href = logoutRedirect;
        }
      } else {
        window.location.href = "/client/login";
      }
    }
  },

  /**
   * Registers a user, to be used by the admin panel
   */
  register(data: {
    firstName: string;
    lastName: string;
    password: string;
    confirmPassword: string;
    email: string;
    userRoles: {
      appRoleCode: string;
    }[];
    labRoles: {
      labId: string;
      roleCode: string;
    }[];
  }) {
    const route = "/register";

    return window.serverApi.request<RegisteredUserFragment>({
      method: "POST",
      url: route,
      data
    });
  },

  /** Manual user sign up */
  signUp(
    route: string,
    data: {
      email: string;
      password: string;
      firstName: string;
      lastName: string;
      token?: string;
    }
  ) {
    if (data.token) {
      const tokenPayload = parseJWT(data.token);

      if (tokenPayload.type === "azure") {
        data.type = tokenPayload.type;
        data.oid = tokenPayload.oid;
      }

      if (tokenPayload.type === "saml") {
        data.type = tokenPayload.type;
        data.nameID = tokenPayload.nameID;
        data.nameIDFormat = tokenPayload.nameIDFormat;
      }

      if (tokenPayload.type === "google") {
        data.type = tokenPayload.type;
        data.oid = tokenPayload.oid;
      }
    }

    return window.serverApi<RegisteredUserFragment>({
      method: "POST",
      url: route,
      data
    });
  },

  changePassword(data: unknown, userId: string) {
    return window.serverApi.request({
      method: "POST",
      url: "/change-password",
      data: {
        ...data,
        userId
      }
    });
  }
};

/**
 * Parse a given JWT to extract its data. Note that this doesn't check the authenticity
 * of the token (we can't do so on the client - such checks must be done server-side).
 *
 * For testing purposes, we can generate a fake JWT that can be read by the
 * above function parseJWT() using:
 *
 * window.fakeJWT = function(data) {
 *   return (
 *     "xx." +
 *     btoa(JSON.stringify(data))
 *       .replace(/\+/g, "-")
 *       .replace(/\//g, "_") +
 *     ".xx"
 *   );
 * };
 *
 */
export function parseJWT(token) {
  try {
    return JSON.parse(
      atob(token.split(".")[1].replace(/-/g, "+").replace(/_/g, "/"))
    );
  } catch (error) {
    console.error("Couldn't parse token", { token, error });
    return {};
  }
}
