/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { useEffect, useState } from "react";
import { Callout, MenuItem } from "@blueprintjs/core";
import { compose } from "recompose";
import { getMergedFeatureMap } from "@teselagen/sequence-utils";
import { isAdmin } from "./utils/generalUtils";
import AbstractLibrary from "./AbstractLibrary";
import { showDialog } from "./GlobalDialog";
import AddFeatureTypeDialog from "./AddFeatureTypeDialog";
import { map } from "lodash";
import { safeDelete, safeQuery } from "./apolloMethods";
import { showConfirmationDialog } from "@teselagen/ui";
import { updateFeatureTypeOverrides } from "../../tg-iso-shared/src/utils/updateFeatureTypeOverrides";

const schema = {
  model: "featureTypeOverride",
  fields: [
    {
      path: "name",
      type: "string",
      displayName: "Name"
    },
    {
      path: "color",
      type: "string",
      sortDisabled: true,
      filterDisabled: true,
      render: v => {
        return (
          v && (
            <div
              style={{
                height: 20,
                width: 40,
                background: v,
                border: "1px solid #182026",
                borderRadius: 5
              }}
            />
          )
        );
      }
    },

    {
      path: "isGenbankStandardType",
      type: "boolean"
      // displayName: "Is Gen Created"
    },
    // {
    //   path: "genbankEquivalentType",
    //   type: "string"
    // },
    {
      path: "isHidden",
      type: "boolean"
    },
    {
      path: "updatedAt",
      type: "timestamp",
      displayName: "Last Updated"
      // sortFn: (v, a) => {
      //   console.log(`v,a:`, v, a);
      //   if
      //   return v;
      // }
    }
  ]
};

function FeatureTypeOverrides(props) {
  const [entities, setEntities] = useState([]);

  const refetch = async () => {
    await updateFeatureTypeOverrides({ safeQuery });
    setEntities(
      map(getMergedFeatureMap(), f => ({
        ...f,
        // isUserCreated: f.isGenbankStandardType,
        updatedAt: f.updatedAt,
        __typename: "featureTypeOverride"
      }))
    );
  };

  useEffect(() => {
    //get the freshest overrides on mount
    refetch();
  }, []);

  const onNewItemClick = () => {
    showDialog({
      ModalComponent: AddFeatureTypeDialog,
      modalProps: {
        refetch
      }
    });
  };

  const editFeatureType = record => {
    showDialog({
      ModalComponent: AddFeatureTypeDialog,
      modalProps: {
        refetch,
        initialValues: {
          ...record
          // projectMembers: getProjectMembers(record),
          // projectAdmins: getProjectAdmins(record)
        }
      }
    });
  };

  const contextMenu = ({
    deleteMenuItem,
    selectedRecords
    // editExtendedPropertiesMenuItem
  }) => {
    if (!isAdmin) return;

    const menuItems = [];
    if (selectedRecords.length === 1) {
      menuItems.push(
        <MenuItem
          icon="edit"
          key="edit"
          onClick={() => editFeatureType(selectedRecords[0])}
          text="Edit"
        />
      );
    }
    let hasGenbankDefaults = false;
    let areAllOverridden = true;
    selectedRecords.forEach(r => {
      if (r.isGenbankStandardType) hasGenbankDefaults = true;
      if (!r.isOverridden) areAllOverridden = false;
    });
    if (areAllOverridden) {
      menuItems.push(
        <MenuItem
          icon="history"
          key="revert"
          onClick={async () => {
            const confirm = await showConfirmationDialog({
              text:
                "Are you sure you want to revert any changes to these default feature(s)? You cannot undo this action",
              intent: "danger",
              confirmButtonText: "Revert",
              cancelButtonText: "Cancel",
              canEscapeKeyCancel: true
            });
            if (confirm) {
              await safeDelete(
                "featureTypeOverride",
                selectedRecords.map(s => s.id)
              );
              await refetch();
              window.toastr.success("Reverted Successfully");
            }
          }}
          text="Revert Back to Default"
        />
      );
    }

    if (!hasGenbankDefaults) {
      menuItems.push(deleteMenuItem);
    }
    // if (editExtendedPropertiesMenuItem) {
    //   menuItems.push(editExtendedPropertiesMenuItem);
    // }

    return menuItems;
  };

  // const defaultFeatureTypes = map(
  //   getFeatureColors({ onlyIncludeGenbankCompatible: true }),
  //   (color, type) => ({
  //     __typename: "featureTypeOverride",
  //     id: type,
  //     name: type,
  //     color,
  //     isGenbankStandardType: true
  //   })
  // );

  // featureTypeOverrides

  return (
    <div className="tg-card">
      {isAdmin() && (
        <Callout intent="primary" style={{ marginBottom: 10 }}>
          As an admin you can add/edit custom feature types or remove the
          default ones
        </Callout>
      )}

      <AbstractLibrary
        {...props}
        tableParams={{
          formName: "featureTypeOverrideTable",
          onRefresh: refetch,
          isInfinite: true,
          schema,
          entities,
          defaults: {
            order: ["-lastUpdated"]
          }
        }}
        isInfinite
        // extraTableParams={{

        // }}
        compact
        libraryName="featureType"
        contextMenu={contextMenu}
        onNewItemClick={isAdmin && onNewItemClick}
        onDoubleClick={isAdmin && editFeatureType}
      />
    </div>
  );
}

export default compose()(FeatureTypeOverrides);
