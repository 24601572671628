/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import classNames from "classnames";
import { InjectedFormProps, reduxForm } from "redux-form";
import { startCase } from "lodash";
import { compose } from "recompose";
import { Classes } from "@blueprintjs/core";
import { DialogFooter, ReactSelectField, wrapDialog } from "@teselagen/ui";
import type { InjectedWrapDialogProps } from "@teselagen/ui";
import GenericSelect from "../../../../../src-shared/GenericSelect";
import { queryData } from "./queryData";
import { SourceDatabase } from "..";
import { Link } from "react-router-dom";

type InjectedProps = InjectedFormProps<DialogFormData> &
  InjectedWrapDialogProps & {
    sourceType: string;
    addSource: (sources: SourceDatabase[]) => void;
    existingSources: SourceDatabase[];
    edit: boolean;
  };

type DialogFormData = {
  sources: SourceDatabase[];
};

const AddSourceDialog = (props: InjectedProps) => {
  const {
    hideModal,
    handleSubmit,
    sourceType,
    addSource,
    existingSources,
    edit
  } = props;

  const saveData = (values: DialogFormData) => {
    let { sources } = values;
    if (!Array.isArray(sources)) {
      sources = [sources];
    }

    if (sources.every(source => source?.type === sourceType)) {
      addSource(sources);
    } else {
      const newSources = sources.map(source => ({
        id: source.id,
        name: source.name,
        sourceType: sourceType !== "sourceDatabase" ? source?.type : "",
        size: source.size,
        sequence: source.sequence,
        type: sourceType
      }));

      addSource(newSources);
    }
    hideModal();
  };

  return (
    <React.Fragment>
      <div
        className={classNames(Classes.DIALOG_BODY)}
        data-test={`tgDialog_${startCase(sourceType)}`}
      >
        <div>
          {sourceType.includes("Group") && (
            <span>
              To add{" "}
              <Link
                to={
                  sourceType === "partGroup"
                    ? "/settings/part-management/part-groups"
                    : "/settings/feature-management/feature-groups"
                }
                target="_blank"
              >
                {`${startCase(sourceType.replace("Group", " Groups"))}`}
              </Link>
              , they must be created first.
            </span>
          )}
          {sourceType === "referenceDatabase" ? ( // TODO: replace this once we implement https://github.com/TeselaGen/lims/issues/12511
            <ReactSelectField
              label="Select one of the available things"
              name="sources"
              multi={edit}
              defaultValue={
                edit && existingSources?.filter(s => s.type === sourceType)
              }
              options={[
                {
                  id: "snapgene",
                  name: "SnapGene",
                  type: "referenceDatabase"
                }
              ]
                ?.filter(
                  source =>
                    edit || !existingSources.some(sdb => sdb.id === source.id)
                )
                .map(source => ({
                  value: source,
                  label: source.name
                }))}
            />
          ) : (
            <GenericSelect
              {...{
                defaultValue: existingSources?.filter(
                  s => s.type === sourceType
                ),
                nameOverride: queryData[sourceType].nameOverride
              }}
              name={"sources"}
              schema={queryData[sourceType].schema}
              postSelectDTProps={{
                formName: "newSources",
                schema: queryData[sourceType].schema,
                noRemoveButton: !edit
              }}
              tableParamOptions={{
                additionalFilter: queryData[sourceType].additionalFilter
              }}
              fragment={queryData[sourceType].fragment}
              isMultiSelect
            />
          )}
        </div>
      </div>
      <DialogFooter
        onClick={handleSubmit(saveData)}
        hideModal={hideModal}
        disabled={false}
        text="Save"
      />
    </React.Fragment>
  );
};

export default compose<any, any>(
  reduxForm({ form: "addSourceDialog" }),
  wrapDialog(({ sourceType, edit }) => ({
    title: `${edit ? "Edit" : "Add"} ${startCase(sourceType)}`
  }))
)(AddSourceDialog);
