/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { allowedCsvFileTypes } from "../../utils/fileUtils";
import {
  getFeatureTypes,
  filterSequenceString
} from "@teselagen/sequence-utils";

export const sharedFileTypesAndDescriptions = ({
  isProtein,
  isRNA,
  isOligo,
  isDNA,
  isMaterial
} = {}) => [
  {
    type: "zip",
    description: "Any of the following types, just compressed",
    exampleFile: isProtein
      ? "exampleProtein.zip"
      : isRNA
        ? "exampleRna.zip"
        : "exampleGenbank.zip"
  },
  {
    type: "json",
    description: "TeselaGen JSON Format",
    exampleFile: isProtein
      ? "exampleTgJson_protein.json"
      : isRNA
        ? "exampleTgJson_rna.json"
        : "exampleTgJson.json"
  },
  {
    type: ["fasta", "fas", "fa", "fna", "ffn", "txt"],
    description: "Fasta Format",
    exampleFile: isProtein ? "proteinFasta.fasta" : "pj5_0001.fasta"
  },
  {
    type: allowedCsvFileTypes.map(t => t.replace(".", "")),
    description: "TeselaGen CSV Format",
    extendedPropTypes: [isProtein ? "amino acid sequence" : "sequence"],
    validateAgainstSchema: {
      name: isMaterial ? "materialUploadExample" : "sequenceUploadExample",
      requireAllOrNone: [["Feature", "FeatureType"]],
      // description: `This upload accepts custom fields for the DNA sequences. Add a custom field name to this header (ex. 'ext-sequence-Date of Purchase') and values to the rows. To add additional sequence custom fields just duplicate the column header.`,
      fields: [
        {
          isRequired: true,
          path: "Name",
          description: "The name of the sequence",
          example: "pj5_0001",
          isUnique: true
        },
        {
          isRequired: true,
          path: "Sequence",
          isUnique: true,
          description: `The ${
            isProtein ? "AA" : isRNA ? "rna" : "dna"
          } sequence of the ${isMaterial ? "material" : "sequence"}`,
          example: isProtein
            ? "MGLVDFPIALFHAAPSLVQETTPEEKARGVCVVLGAHNITGRIQVSDRDWLTVGSTLPGVYVARVEKGVVTGDVVIADPNRGTIKVKGAVEEYL"
            : "ATGCATTGAGGACCTAACCATATCTAA",
          format: (s, { name } = {}) => {
            if (!s) return;
            const [f] = filterSequenceString(s, {
              name,
              isRNA,
              isProtein
            });
            // if (f !== s) {
            //   window.toastr.warning(
            //     `Stripped invalid characters from sequence ${name || ""}`,
            //     { timeout: window.Cypress ? 3000 : 60000 }
            //   );
            // }
            return f;
          }
        },
        {
          path: "Aliases",
          description: `A comma-separated list of alternative names or identifiers for the ${
            isMaterial ? "material" : "sequence"
          }`,
          example: "alias 1, alias 2"
        },
        {
          path: "Tags",
          description:
            "A comma-separated list of keywords or labels that describe the sequence. These should match existing tags",
          example: "tag1, tag2"
        },
        {
          path: "Description",
          description: "A short description or summary of the sequence",
          example: "Short example sequence to show off CSV import"
        },
        ...(isOligo
          ? []
          : isDNA
            ? [
                {
                  displayName: "Circular/Linear",
                  path: "Circular/Linear",
                  isRequired: true,
                  type: "dropdown",
                  values: ["Circular", "Linear"],
                  example: "Circular"
                }
              ]
            : isProtein
              ? [
                  {
                    path: "designId",
                    description:
                      "An identifier for the design of the protein sequence",
                    example: "1234"
                  },
                  {
                    path: "pdbId",
                    description:
                      "The Protein Data Bank (PDB) ID for the protein structure, if available",
                    example: "6GCT"
                  },
                  {
                    path: "uniprotId",
                    description:
                      "The UniProt ID for the protein sequence, if available",
                    example: "P12345"
                  },
                  {
                    path: "hisTagLoc",
                    displayName: "His Tag Location",
                    description:
                      "The location of the histidine tag, if present in the protein sequence",
                    example: "N-terminal"
                  },
                  {
                    path: "isoPoint",
                    displayName: "AA PI (Isoelectric Point)",
                    description:
                      "The isoelectric point of the amino acid sequence",
                    example: "6.8",
                    type: "number"
                  },
                  {
                    path: "cofactor",
                    description:
                      "Any cofactors or prosthetic groups bound to the protein, if applicable",
                    example: "heme"
                  }
                ]
              : []),
        {
          path: "Part",
          description:
            "A name for a part that spans the entire sequence (optional)",
          example: "Example Part"
        },
        {
          path: "PartTags",
          example: "tag1, tag2",
          description:
            "A comma-separated list of keywords or labels that describe the part of the sequence specified in the Part field (optional)"
        },
        {
          path: "Feature",
          description:
            "A name for a feature that spans the entire sequence (optional)",
          example: "Example Feature"
        },
        {
          path: "FeatureType",
          description: "The type or category of the feature (optional)",
          type: "dropdown",
          values: getFeatureTypes(),
          example: "CDS"
        }
      ]
    }
  },

  ...(isProtein
    ? [
        {
          type: ["gp", "genpep", "txt"],
          description: "Genbank Format",
          exampleFile: "protein_example.gp"
        }
      ]
    : [
        ...(isRNA
          ? []
          : [
              {
                type: "geneious",
                description: "Geneious Format",
                exampleFile: "pAN7-1.geneious"
              }
            ]),
        {
          type: "ab1",
          description: "Sequence Trace Format",
          exampleFile: "ab1_trace_example.ab1"
        },

        {
          type: "dna",
          description: "SnapGene DNA Format",
          exampleFile: isRNA
            ? "snapgene_example_linear.dna"
            : "snapgene_example.dna"
        },
        {
          type: ["gb", "gbk", "txt"],
          description: "Genbank Format",
          exampleFile: "pj5_0001.gb"
        },
        {
          type: ["xml", "rdf"],
          description: "SBOL XML Format",
          exampleFile: "sbolExample.xml"
        }
      ])
];
