/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useEffect } from "react";
import PlatformHeader from "../PlatformHeader";
import getAppColor from "../utils/getAppColor";
import { socketWrapper } from "../socket/socketWrapper";
import { menuItems } from "../utils/menuItems";

function Header({
  loggedIn,
  appBody,
  logout,
  currentUser,
  history,
  showLeftSlideOutDrawer,
  setshowLeftSlideOutDrawer
}) {
  useEffect(() => {
    if (currentUser?.id) {
      socketWrapper.init(currentUser);
    }
  }, [currentUser]);

  return (
    <PlatformHeader
      loggedIn={loggedIn}
      appBody={appBody}
      logout={logout}
      backgroundColor={getAppColor()}
      appName={window.frontEndConfig.appTitle || "TeselaGen"}
      menuItems={menuItems}
      history={history}
      onSearch={null}
      showLeftSlideOutDrawer={showLeftSlideOutDrawer}
      setshowLeftSlideOutDrawer={setshowLeftSlideOutDrawer}
    />
  );
}

export { Header };
