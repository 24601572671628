/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { getApolloMethods } from "@teselagen/apollo-methods";
import { cloneDeep } from "lodash";
import { flow, types } from "mobx-state-tree";
import client from "../../../src-shared/apolloClient";
import { isAdmin } from "../../../src-shared/utils/generalUtils";
import { schema } from "./schema";

const { query } = getApolloMethods(client);

const categoryStore = types
  .model("categoryStore", {
    label: types.optional(types.string, ""),
    content: types.union(types.array(types.frozen()), types.frozen()),
    isOpen: types.optional(types.boolean, false),
    isHidden: types.optional(types.boolean, false)
  })
  .actions(self => ({
    open() {
      self.isOpen = !self.isOpen;
    }
  }))
  .views(self => ({
    get isMulti() {
      return (
        self.content.length && self.content.filter(l => !l.isHidden).length > 1
      );
    }
  }));

export const metaDataStore = types
  .model("metaDataStore", {
    categories: types.optional(types.map(categoryStore), schema().main),
    selection: types.optional(types.frozen(), {}),
    loading: types.optional(types.boolean, false),
    entities: types.optional(types.array(types.frozen()), []),
    currentPage: types.optional(types.number, 0),
    totalResults: types.optional(types.number, 0),
    currentPageSize: types.optional(types.number, 10)
  })
  .views(self => ({
    get getKeys() {
      return Array.from(self.categories.keys());
    },
    get getValues() {
      return Array.from(self.categories.keys())
        .filter(key => !self.categories.get(key).isHidden)
        .map(key => ({
          key: key,
          content: self.categories.get(key)
        }));
    },
    get allowCUD() {
      // Only Admins can run CUD operations on metadata records.
      // Which metadata is going to be affected by this restriction is defined on the 'restricted' property
      // at within the 'content' object of each metadata object in "./schema".
      return isAdmin() || !self.selection.restricted;
    }
  }))
  .actions(self => {
    const fetch = flow(function*(
      tableParams = { pageNumber: 1, pageSize: self.currentPageSize }
    ) {
      const { pageNumber = 1, pageSize = self.currentPageSize } = tableParams;
      try {
        self.loading = true;
        const results = yield query(
          [self.selection.schema.model, self.selection.query],
          {
            variables: {
              pageNumber: Number(pageNumber),
              pageSize: Number(pageSize)
            }
          }
        );
        self.entities = results;
        self.totalResults = results.totalResults;
        self.currentPageSize = Number(pageSize);
        self.currentPage = pageNumber;
        self.loading = false;
      } catch (error) {
        console.error(error);
      }
    });
    return {
      fetch,
      select(_item) {
        // NOTE: in simple mode, some fields are hidden, so we select the first non-hidden field.
        const item = _item.length ? _item.filter(it => !it.isHidden)[0] : _item;
        self.selection = cloneDeep(item);
        fetch({ pageNumber: 1, pageSize: self.currentPageSize });
      },
      getFromRoute(match) {
        const {
          params: { subsection: tab }
        } = match;
        if (self.categories.get(tab)) {
          self.categories.get(tab).open();

          if (self.categories.get(tab).isMulti) {
            self.select(self.categories.get(tab).content.at(-1));
          } else {
            self.select(self.categories.get(tab).content);
          }
        }
      }
    };
  });
