/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

/**
 * NOTE 1: not sure the best way to represent this yet, but for now its
 * represented like follows:
 *
 * The first level keys of the object correspond to the models we want to enable locking for,
 * and their values correspond to the children of those models that we want to enable "child locking" for.
 *
 * NOTE 2: lockable models was design months ago (https://github.com/TeselaGen/lims/pull/10303)
 * but never put into use. The design implemetation has changed to include child locking ability.
 * Most of the models that were elected as lockable models are still shown below but commented, as we're going
 * to begin with just a handful of them first.
 */
export const lockableModelTree = {
  design: ["sequence"],
  sequence: ["sequenceFeature", "part"],
  sequenceFeature: [],
  part: [],
  aminoAcidSequence: [],
  sample: []
  // additiveMaterial: [],
  // aliquot: [],
  // aliquotContainer: [],
  // containerArray: [],
  // customerRequest: [],
  // dataSet: [],
  // dataTable: [],
  // equipmentItem: [],
  // functionalProteinUnit: [],
  // genome: [],
  // j5Report: [],
  // job: [],
  // lot: [],
  // labProtocol: [],
  // material: [],
  // plateMapGroup: [],
  // reactionMap: [],
  // strain: [],
  // workflowRun: [],
  // worklist: []
};

export const lockableModels = Object.keys(lockableModelTree);
