/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { compose } from "recompose";
import { withTableParams } from "@teselagen/ui";
import withQuery from "../withQuery";
import React from "react";
import Helmet from "react-helmet";
import AbstractLibrary from "../AbstractLibrary";
import { MenuItem } from "@blueprintjs/core";
import { getActiveLabId } from "@teselagen/auth-utils";
import { isAdmin, isLabAdmin } from "../utils/generalUtils";
import { deleteWithQuery } from "../apolloMethods";
import { showDialog } from "../GlobalDialog";
import appGlobals from "../appGlobals";
import { getUserLabs } from "../utils/labUtils";
import { AddLabMemberDialog } from "./AddLabMember";
import { userFirstNameWithIconColumn } from "../utils/libraryColumns";

const schema = {
  model: "user",
  fields: [
    userFirstNameWithIconColumn,
    {
      type: "string",
      displayName: "Last Name",
      path: "lastName"
    },
    { path: "email", type: "string", displayName: "Email" }
  ]
};

class SingleLabPanel extends React.Component {
  constructor(props) {
    super(props);
    const currentLab = getUserLabs(this.props.currentUser).find(
      l => l.id === getActiveLabId()
    );
    this.myLab = currentLab || {};
  }
  contextMenuItems = ({ selectedRecords }) => {
    const isAdminOrLabAdmin = isAdmin() || isLabAdmin();
    if (!isAdminOrLabAdmin) return [];
    const activeLabId = getActiveLabId();
    const includesSelf = selectedRecords.some(
      r => r.id === appGlobals.currentUser.id
    );
    const removeFromLabMenuItem = (
      <MenuItem
        key="removeFromLab"
        disabled={includesSelf}
        text="Remove From Lab"
        icon="trash"
        onClick={async () => {
          try {
            await deleteWithQuery("labRole", {
              userId: selectedRecords.map(r => r.id),
              labId: activeLabId
            });
            await this.refetch();
          } catch (error) {
            console.error("error:", error);
            window.toastr.error("Error removing users from lab.");
          }
        }}
      />
    );
    return [removeFromLabMenuItem];
  };

  refetch = () => {
    return this.props.tableParams.onRefresh();
  };

  showAddLabMemberDialog = () => {
    showDialog({
      ModalComponent: AddLabMemberDialog,
      modalProps: {
        refetch: this.refetch,
        labId: getActiveLabId(),
        isSingleLabMode: true
      }
    });
  };

  render() {
    if (!this.myLab || !this.myLab.id) {
      return (
        <div>
          <Helmet title="My Lab Settings" />
          <h3>No Lab</h3>
          <h5>
            You do not have a lab selected. Please add yourself to a lab group
            to adjust settings here.
          </h5>
        </div>
      );
    }

    const { currentUser } = this.props;
    const additionalButtons = [];

    // not supported for now
    // if (
    //   isAdmin() ||
    //   (isLabAdmin() &&
    //     getActiveLabId() !== null &&
    //     window.frontEndConfig.enableLabAdminsAddUsers)
    // ) {
    //   additionalButtons.push(
    //     <Button
    //       minimal
    //       icon="add"
    //       key="addLabMember"
    //       text="Add Lab Member"
    //       onClick={this.showAddLabMemberDialog}
    //     />
    //   );
    // }

    return (
      <div className="tg-card">
        <Helmet title="My Lab" />
        <h3>My Lab ({this.myLab.name})</h3>
        <AbstractLibrary
          {...this.props}
          extraTableParams={{
            currentUser
          }}
          noRoute
          contextMenu={this.contextMenuItems}
          additionalButtons={additionalButtons}
          noNewItem
        />
      </div>
    );
  }
}
export default compose(
  withTableParams({
    schema,
    formName: "singleLabUserTable",
    urlConnected: false,
    additionalFilter: () => {
      return {
        "labRoles.labId": getActiveLabId()
      };
    }
  }),
  withQuery(["user", "id firstName lastName email"], {
    isPlural: true
  })
)(SingleLabPanel);
