/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { getOveHotkeyDefs } from "@teselagen/ove";
import designEditorHotkeys from "../src-design/components/HierarchicalDesign/hotkeys";

export const appHotkeySets = {
  General: {
    showKeyboardShortcuts: "mod+shift+K",
    toggleAssignDefaultsMode: "shift+d"
  },
  "Design Editor": designEditorHotkeys,
  "Vector Editor": getOveHotkeyDefs({
    store: window.teGlobalStore,
    editorName: "SequenceEditor"
  })
  // ... add sets as needed
};
