/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";
import oligoFragment from "../../../graphql/fragments/oligoFragment";
import { sequenceSampleStatusFragment } from "../../../../src-shared/fragments/sequenceSampleStatusFragment";

const sequenceJ5ReportFragment = gql`
  fragment sequenceJ5ReportFragment on sequence {
    id
    name
    circular
    description
    size
    hash
    polynucleotideMaterialId
    isJ5Sequence
    isInLibrary
    parts {
      id
      cid
      name
    }
    j5AssemblyPiece {
      id
      name
    }
    sequenceFeatures {
      id
      start
      end
      type
      name
      strand
    }
    sequenceAvailableInventoryView {
      id
      sampleCount
      containerCount
    }
  }
`;

export const j5InputSequenceFragment = gql`
  fragment j5InputSequenceFragment on j5InputSequence {
    id
    isStock
    sequence {
      ...sequenceJ5ReportFragment
      sequenceSampleStatusView {
        ...sequenceSampleStatusFragment
      }
    }
    j5InputParts {
      id
      part {
        id
        cid
        name
        start
        end
        strand
      }
    }
  }
  ${sequenceJ5ReportFragment}
  ${sequenceSampleStatusFragment}
`;

export const j5InputPartFragment = gql`
  fragment j5InputPartFragment on j5InputPart {
    id
    part {
      id
      name
      start
      end
      strand
      sequence {
        id
        name
        size
      }
      size
    }
  }
`;

export const j5RunConstructFragment = gql`
  fragment j5RunConstructFragment on j5RunConstruct {
    id
    name
    numWarnings
    partNames
    sequence {
      id
      name
      size
      circular
      hash
      isJ5Sequence
      isInLibrary
      polynucleotideMaterialId
      sequenceAvailableInventoryView {
        id
        sampleCount
        containerCount
      }
      sequenceSampleStatusView {
        ...sequenceSampleStatusFragment
      }
    }
    j5ConstructAssemblyPieces {
      id
      index
      assemblyPiece {
        id
        name
        type
        j5AssemblyPieceParts {
          id
          index
          j5InputPart {
            id
            part {
              id
              name
            }
          }
        }
        sequence {
          id
          size
        }
      }
    }
  }
  ${sequenceSampleStatusFragment}
`;

export const j5DirectSynthesisFragment = gql`
  fragment j5DirectSynthesisFragment on j5DirectSynthesis {
    id
    name
    cost
    sequence {
      ...sequenceJ5ReportFragment
      sequenceSampleStatusView {
        ...sequenceSampleStatusFragment
      }
    }
  }
  ${sequenceJ5ReportFragment}
  ${sequenceSampleStatusFragment}
`;

export const j5ReportRecordFragment = gql`
  fragment j5ReportRecordFragment on j5Report {
    id
    user {
      id
      username
    }
    j5ReportSubsetJ5ReportSubsetJoins {
      id
      parentReport {
        id
        name
      }
    }
    j5RunConstructs {
      id
    }
    j5ReportSubsetJoins {
      id
      subsetReport {
        id
        name
      }
    }
    j5File {
      id
      path
    }
    microserviceQueue {
      id
      microserviceIoFiles {
        id
        fileUrl
      }
    }
    designId
    design {
      id
      name
    }
    originalDesign {
      id
      name
    }
    originalDesignId
    isHierarchical
    outputCardName
    assemblyBatchId
    idFromOriginalAssemblyBatch
    assemblyReactionEnzymes {
      id
      restrictionEnzyme {
        id
        name
      }
    }
    treePosition
    name
    version
    assemblyMethod
    assemblyType
    createdAt
    dateRan
    updatedAt
  }
`;

const pcrTemplateFragment = gql`
  fragment pcrTemplateFragment on sequence {
    ...sequenceJ5ReportFragment
    j5DirectSynthesis {
      ...j5DirectSynthesisFragment
    }
    j5InputSequence {
      ...j5InputSequenceFragment
    }
  }
  ${sequenceJ5ReportFragment}
  ${j5InputSequenceFragment}
  ${j5DirectSynthesisFragment}
`;

export const j5PcrReactionFragment = gql`
  fragment j5PcrReactionFragment on j5PcrReaction {
    id
    name
    note
    oligoDeltaTm
    oligoDeltaTm3Prime
    oligoMeanTm
    oligoMeanTm3Prime
    pcrProductSequence {
      ...sequenceJ5ReportFragment
    }
    primaryTemplate {
      ...pcrTemplateFragment
    }
    secondaryTemplate {
      ...pcrTemplateFragment
    }
    forwardPrimer {
      ...oligoFragment
    }
    reversePrimer {
      ...oligoFragment
    }
  }
  ${sequenceJ5ReportFragment}
  ${oligoFragment}
  ${pcrTemplateFragment}
`;

const oligoJ5Fragment = gql`
  fragment oligoJ5Fragment on j5Oligo {
    id
    name
    sequence {
      fullSequence
      ...sequenceJ5ReportFragment
    }
  }
  ${sequenceJ5ReportFragment}
`;

export const j5AnnealedOligoFragment = gql`
  fragment j5AnnealedOligoFragment on j5AnnealedOligo {
    id
    name
    tm
    topOligo {
      ...oligoJ5Fragment
    }
    bottomOligo {
      ...oligoJ5Fragment
    }
    sequence {
      ...sequenceJ5ReportFragment
      sequenceSampleStatusView {
        ...sequenceSampleStatusFragment
      }
    }
  }
  ${oligoJ5Fragment}
  ${sequenceJ5ReportFragment}
  ${sequenceSampleStatusFragment}
`;

export const j5AssemblyPieceFragment = gql`
  fragment j5AssemblyPieceFragment on j5AssemblyPiece {
    id
    cid
    name
    type
    overhangWithNext
    overhangWithPrevious
    relativeOverhang
    extra3PrimeCpecBps
    extra5PrimeCpecBps
    cpecTmNext
    j5AssemblyPieceParts {
      id
      index
      j5InputPart {
        id
        j5InputSequence {
          id
          sequence {
            id
            name
          }
        }
        part {
          id
          name
        }
      }
    }
    assemblyPieceConstructCountView {
      id
      constructCount
    }
    sequence {
      ...sequenceJ5ReportFragment
      sequenceSampleStatusView {
        ...sequenceSampleStatusFragment
      }
    }
  }
  ${sequenceJ5ReportFragment}
  ${sequenceSampleStatusFragment}
`;
