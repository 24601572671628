/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { getReverseComplementSequenceString } from "@teselagen/sequence-utils";

export const getSequenceOfFeature = (feature, seqStr) => {
  let featSeq =
    feature.start <= feature.end
      ? seqStr.slice(feature.start, feature.end + 1)
      : seqStr.slice(feature.start) + seqStr.slice(0, feature.end + 1);
  if (feature.strand === -1)
    featSeq = getReverseComplementSequenceString(featSeq);
  return featSeq;
};
