/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { compose } from "recompose";
import { reduxForm } from "redux-form";
import { Classes } from "@blueprintjs/core";
import {
  DialogFooter,
  FileUploadField,
  BlueprintError,
  wrapDialog
} from "@teselagen/ui";

import { keyBy, uniq } from "lodash";
import { throwFormError } from "../../../src-shared/utils/formUtils";
import { safeUpsert, safeQuery } from "../../../src-shared/apolloMethods";

import {
  allowedCsvFileTypes,
  cleanCommaSeparatedCell,
  parseCsvOrExcelFile
} from "../../../../tg-iso-shared/src/utils/fileUtils";

import caseInsensitiveFilter from "../../../../tg-iso-shared/src/utils/caseInsensitiveFilter";
import { getDownloadTemplateFileHelpers } from "../../../src-shared/components/DownloadTemplateFileButton";

class UploadItemWithNameAndDescription extends Component {
  onSubmit = async values => {
    const { hideModal, refetch } = this.props;
    try {
      const { data } = await parseCsvOrExcelFile(
        values.selectionMethodsCsv[0],
        {
          csvParserOptions: {
            lowerCaseHeaders: true
          }
        }
      );
      const namesToCheck = [];
      let targetOrganismToCheck = [];
      data.forEach((row, index) => {
        const { name, "target organism groups": targetOrganisms } = row;
        if (!name || !name.trim()) {
          throw new Error(`Row ${index + 1} did not provide a name.`);
        }
        const targetOrganismClassNames = cleanCommaSeparatedCell(
          targetOrganisms
        );
        targetOrganismToCheck.push(...targetOrganismClassNames);
        row.targetOrganismClassNames = targetOrganismClassNames;
        namesToCheck.push(name.trim());
      });

      if (!namesToCheck.length) return;
      const existing = await safeQuery(["selectionMethod", `id name`], {
        variables: {
          filter: caseInsensitiveFilter("selectionMethod", "name", namesToCheck)
        }
      });
      const keyedExisting = keyBy(existing, item => item.name.toLowerCase());

      targetOrganismToCheck = uniq(targetOrganismToCheck);
      const targetOrganisms = targetOrganismToCheck.length
        ? await safeQuery(["targetOrganismClass", "id name"], {
            variables: {
              filter: caseInsensitiveFilter(
                "targetOrganismClass",
                "name",
                targetOrganismToCheck
              )
            }
          })
        : [];
      const keyedTargetOrganisms = keyBy(targetOrganisms, i =>
        i.name.toLowerCase()
      );

      const newItems = [];
      let hadDuplicate = false;
      data.forEach((row, index) => {
        const { name, cofactor, description, targetOrganismClassNames } = row;
        if (keyedExisting[name.toLowerCase()]) {
          hadDuplicate = true;
          return;
        }
        keyedExisting[name.toLowerCase()] = true;
        const selectionMethodTargetOrganismClasses = [];
        targetOrganismClassNames.forEach(toc => {
          const target = keyedTargetOrganisms[toc.toLowerCase()];
          if (!target) {
            throw new Error(
              `Row ${index +
                1} specifies the target organism ${toc} which was not found`
            );
          }
          selectionMethodTargetOrganismClasses.push({
            targetOrganismClassId: target.id
          });
        });

        const newItem = {
          name,
          cofactor,
          description,
          selectionMethodTargetOrganismClasses
        };
        newItems.push(newItem);
      });
      await safeUpsert("selectionMethod", newItems);
      await refetch();
      if (hadDuplicate) {
        window.toastr.warning("Duplicates were skipped.");
      }
      hideModal();
    } catch (error) {
      console.error("error:", error);
      throwFormError(error.message || `Error uploading selection methods.`);
    }
  };

  render() {
    const { hideModal, handleSubmit, submitting, error } = this.props;
    return (
      <React.Fragment>
        <div className={Classes.DIALOG_BODY}>
          <FileUploadField
            isRequired
            fileLimit={1}
            accept={getDownloadTemplateFileHelpers({
              type: allowedCsvFileTypes,
              fileName: "selectionMethods",
              headers: [
                "name",
                "cofactor",
                "description",
                "target organism groups"
              ]
            })}
            name="selectionMethodsCsv"
          />
          <BlueprintError error={error} />
        </div>
        <DialogFooter
          submitting={submitting}
          hideModal={hideModal}
          onClick={handleSubmit(this.onSubmit)}
        />
      </React.Fragment>
    );
  }
}

export default compose(
  wrapDialog(() => {
    return {
      title: `Upload Selection Methods`
    };
  }),
  reduxForm({
    form: "uploadSelectionMethods"
  })
)(UploadItemWithNameAndDescription);
