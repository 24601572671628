/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { capitalize } from "lodash";
import { reduxForm } from "redux-form";
import { useState } from "react";
import { compose } from "recompose";
import {
  DialogFooter,
  InputField,
  tgFormValues,
  wrapDialog
} from "@teselagen/ui";
import HeaderWithHelper from "../../HeaderWithHelper";
import LabForUserForm from "../../auth/components/labForUserForm";
import { COMMON_LAB_ID } from "@teselagen/auth-utils";

const validateServiceAccountName = serviceAccountName => {
  if (serviceAccountName) {
    if (serviceAccountName.includes(" ")) {
      return "Please avoid spaces";
    }
    if (serviceAccountName.includes("@")) {
      return "Please avoid the '@' character";
    }
  }
};

const CreateNewServiceAccountDialog = props => {
  const { handleSubmit, hideModal, refetch, serviceAccountName } = props;

  const [submitting, setSubmitting] = useState(false);

  const serviceAccountEmailGenerator = serviceAccountName => {
    const [clientName] = window.location.hostname.split(".");
    return `${serviceAccountName}@${clientName}-serviceaccount.com`;
  };

  const onSubmit = async values => {
    setSubmitting(true);
    const labId = values.existingLab.id;
    try {
      await window.tgApi({
        method: "POST",
        url: "/users",
        data: {
          email: serviceAccountEmailGenerator(values.serviceAccountName),
          firstName: capitalize(values.serviceAccountName),
          lastName: "Service Account",
          userRoles: [
            { appRoleCode: "SERVICE" },
            ...(labId === COMMON_LAB_ID ? [{ appRoleCode: "PUBLISHER" }] : [])
          ],
          // NOTE: we might want to configure service accounts to have ADMIN lab roles in da future.
          ...(labId && { labRoles: [{ labId, roleCode: "MEMBER" }] })
        }
      });
      window.toastr.success("Service account created");
    } catch (error) {
      console.error(error);
      window.toastr.error("Error creating service account");
    }
    await refetch();
    hideModal();
    setSubmitting(false);
  };

  return (
    <div style={{ padding: 20 }}>
      <InputField
        isRequired
        label="Service Account Name"
        name="serviceAccountName"
        validate={validateServiceAccountName}
      />
      <HeaderWithHelper
        helper={
          <span>
            <b>Service account email:</b>{" "}
            {`${
              serviceAccountName
                ? serviceAccountEmailGenerator(serviceAccountName)
                : "Not defined"
            }`}{" "}
          </span>
        }
        width="100%"
      />
      {/* Service Accounts can only be created into an existing lab for now. */}
      <LabForUserForm onlyExistingLabs={true} allowCommonLab={true} />
      <DialogFooter
        hideModal={hideModal}
        onClick={handleSubmit(onSubmit)}
        submitting={submitting}
      />
    </div>
  );
};

export default compose(
  wrapDialog({
    title: "New Service Account"
  }),
  reduxForm({
    form: "newServiceAccount"
  }),
  tgFormValues("serviceAccountName")
)(CreateNewServiceAccountDialog);
