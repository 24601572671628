/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose } from "recompose";
import Helmet from "react-helmet";
import { Button, MenuItem } from "@blueprintjs/core";
import { showDialog } from "../../GlobalDialog";
import GenerateTokenForServiceAccountDialog from "./generateTokenForServiceAccountDialog";
import CreateNewServiceAccountDialog from "./createNewServiceAccountDialog";
import AbstractLibrary from "../../AbstractLibrary";
import libraryEnhancer from "../../libraryEnhancer";
import { isAdmin } from "../../utils/generalUtils";
import { size, uniq } from "lodash";
import { formatDateTime } from "../../utils/dateUtils";

const schema = [
  {
    path: "email",
    displayName: "Email"
  },
  {
    path: "userRoles.appRole.name",
    displayName: "User Roles",
    render: (_value, { userRoles }) => {
      if (!userRoles.length) return "Member";
      return userRoles.map(({ appRole }) => appRole.name).join(", ");
    }
  },
  {
    path: "labRoles",
    displayName: "Lab",
    render: labRoles => {
      // NOTE: service accounts belong to only one lab for the moment.
      const [firstLabName] = uniq(labRoles.map(labRole => labRole.lab?.name));
      return firstLabName || <i>Lab not assigned</i>;
    }
  },
  {
    path: "userLogins",
    displayName: "Token Expiration"
  }
];

const ServiceAccountsPanel = props => {
  const openGenerateTokenDialog = serviceAccount =>
    showDialog({
      ModalComponent: GenerateTokenForServiceAccountDialog,
      modalProps: {
        serviceAccount,
        refetch: props.refetch
      }
    });

  const onNewItemClick = () => {
    return showDialog({
      ModalComponent: CreateNewServiceAccountDialog,
      modalProps: {
        refetch: props.refetch
      }
    });
  };

  const onDoubleClick = selectedRecord =>
    openGenerateTokenDialog(selectedRecord);

  const contextMenuItems = contextMenuItems => {
    const { selectedRecords, deleteMenuItem } = contextMenuItems;
    const menuItems = [];
    if (isAdmin()) {
      if (selectedRecords.length === 1) {
        menuItems.push(
          <MenuItem
            key="editUser"
            icon="key"
            text="Generate Token"
            onClick={() => openGenerateTokenDialog(selectedRecords[0])}
          />
        );
      }
      menuItems.push(deleteMenuItem);
    }
    return menuItems;
  };

  const renderTokenExpiration = (userLogins, serviceAccount) => {
    if (size(userLogins) && userLogins[0]?.apiKeyExpiration) {
      const [{ apiKeyExpiration }] = userLogins;
      return formatDateTime(apiKeyExpiration);
    }
    return (
      <Button
        icon="key"
        text="Generate Token"
        small
        onClick={() => openGenerateTokenDialog(serviceAccount)}
      />
    );
  };

  return (
    <div>
      <Helmet title="Service Accounts" />
      <div className="tg-card xtra-pad">
        <h3>Service Accounts</h3>
        <AbstractLibrary
          {...props}
          extraTableParams={{
            cellRenderer: {
              userLogins: renderTokenExpiration
            }
          }}
          noNewItem={!isAdmin()}
          onNewItemClick={onNewItemClick}
          contextMenu={contextMenuItems}
          libraryName="serviceAccount"
          onDoubleClick={onDoubleClick}
        />
      </div>
    </div>
  );
};

export default compose(
  libraryEnhancer({
    schema,
    /**
     * NOTE: we could have this information by
     * extending the DM to let users be linked to users.
     */
    noUser: true,
    noAddedBy: true,
    /** */
    fragment: [
      "user",
      "id email userRoles { id appRoleCode appRole { code name } } userLogins { id apiKeyExpiration } labRoles { id roleCode lab { id name } }"
    ],
    additionalFilter: (_, qb) =>
      qb.whereAll({
        id: qb
          .related("userRole.userId", true)
          .whereAll({ appRoleCode: "SERVICE" })
      })
  })
)(ServiceAccountsPanel);
