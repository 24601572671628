/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { compose } from "recompose";
import { reduxForm } from "redux-form";
import { tgFormValues } from "@teselagen/ui";
import { Classes } from "@blueprintjs/core";
import {
  InputField,
  TextareaField,
  CheckboxField,
  DialogFooter
} from "@teselagen/ui";
import PositionRows from "../../PositionRows";
import { asyncValidateName } from "../../../../src-shared/utils/formUtils";

function ContainerTypeDialog({
  hideModal,
  handleSubmit,
  submitting,
  isPositional
}) {
  return (
    <form onSubmit={handleSubmit}>
      <div className={Classes.DIALOG_BODY}>
        <InputField name="name" label="Name" isRequired />
        <TextareaField name="description" label="Description" />
        <CheckboxField
          name="isPositional"
          label="Container Type is Positional?"
        />
        {isPositional && <PositionRows name="containerDefaultPositions" />}
      </div>
      <DialogFooter {...{ hideModal, submitting }} />
    </form>
  );
}

export default compose(
  reduxForm({
    form: "ContainerTypeDialog",
    ...asyncValidateName
  }),
  tgFormValues("isPositional")
)(ContainerTypeDialog);
