/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useContext, useState } from "react";
import compose from "lodash/fp/compose";
import { FileUploadField } from "@teselagen/ui";
import { Button, Intent, Icon } from "@blueprintjs/core";
import { reduxForm } from "redux-form";
import { showDeleteAlert } from "../utils/deleteUtils";
import { safeDelete, safeQuery, safeUpsert } from "../apolloMethods";
import { showDialog } from "../GlobalDialog";
import CropImageDialog from "../CropImageDialog";
import getAvatarIconPath from "../utils/getAvatarIconPath";
import CurrentUserContext from "../context/CurrentUserContext";

const userFragment = [
  "user",
  `id
  avatarFile {
    id
    path
  }
  avatarFileId`
];

const ChangeAvatar = () => {
  const { currentUser, refetchCurrentUser } = useContext(CurrentUserContext);
  const [removingAvatar, setRemovingAvatar] = useState(false);

  const showAvatarEditor = file => {
    if (file.error) {
      return window.toastr.error("Error uploading the file");
    }

    const saveImageFile = async croppedFileBlob => {
      const file = new File([croppedFileBlob], "cropped-avatar");
      const data = new FormData();
      data.append("file", file);
      const res = await window.serverApi.request({
        method: "POST",
        url: "/user_uploads/",
        data
      });
      const uploadedFile = res.data[0];
      const { path, encoding, originalname, size, mimetype } = uploadedFile;
      const newUserFile = await safeUpsert("userFile", {
        path,
        encoding,
        originalname,
        size,
        mimetype,
        userId: currentUser.id
      });
      await safeUpsert(userFragment, {
        id: currentUser.id,
        avatarFileId: newUserFile[0].id
      });
      refetchCurrentUser();
    };

    showDialog({
      ModalComponent: CropImageDialog,
      modalProps: {
        image: file.originalFileObj,
        saveImageFile
      }
    });
  };

  const beforeUpload = fileList => {
    const file = fileList[0];
    if (!file) return false;
    const isJPGorPNG = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJPGorPNG) {
      window.toastr && window.toastr.error("Please upload a PNG or JPG file");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      window.toastr &&
        window.toastr.error("Please upload an image smaller than 2MB!");
    }
    const validImage = isJPGorPNG && isLt2M;
    if (validImage) {
      showAvatarEditor(file);
    }
    // stop upload
    return false;
  };

  const onRemoveClick = async () => {
    setRemovingAvatar(true);
    try {
      const shouldDelete = await showDeleteAlert({
        model: "avatar"
      });
      if (shouldDelete) {
        const trimmedPath = getAvatarIconPath(currentUser);

        await safeDelete("userFile", currentUser.avatarFileId);
        await safeQuery(userFragment, {
          variables: {
            id: currentUser.id
          }
        });
        // is stored in our s3
        if (trimmedPath.includes("user_uploads")) {
          try {
            await window.serverApi.request({
              method: "DELETE",
              url: trimmedPath.replace("/build", "")
            });
          } catch (error) {
            console.error(`error:`, error);
          }
        }
        refetchCurrentUser();
      }
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error deleting logo.");
    }
    setRemovingAvatar(false);
  };

  const path = currentUser?.avatarFile?.path || "";
  const hasAvatar = !!path;
  const trimmedPath = getAvatarIconPath(currentUser);
  // const baseURI = localStorage.getItem("getURI");

  return (
    <div>
      <div className="tg-avatar-upload-container">
        <FileUploadField
          showUploadList={false}
          beforeUpload={beforeUpload}
          fileLimit={1}
          name="changeAvatar"
          className="tg-avatar-upload"
          accept={[".jpg", ".jpeg", ".png"]}
          contentOverride={() => {
            return hasAvatar ? (
              <img
                src={trimmedPath}
                alt=""
                className="avatar"
                crossOrigin="Anonymous"
              />
            ) : (
              <Icon icon="plus" />
            );
          }}
        />
        <div>Please upload a .jpg or .png less than 2MB in size</div>
      </div>
      <div className="settings-footer" style={{ marginTop: "0" }}>
        <Button
          intent={Intent.PRIMARY}
          disabled={!hasAvatar}
          loading={removingAvatar}
          onClick={onRemoveClick}
          text="Remove Avatar"
        />
      </div>
    </div>
  );
};

export default compose(
  reduxForm({
    enableReinitialize: true,
    form: "changeAvatar"
  })
)(ChangeAvatar);
