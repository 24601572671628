/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import aliquotHydrationFragment from "../../../graphql/fragments/aliquotHydrationFragment";
import gql from "graphql-tag";

export const dehydrationToolPlateFragment = gql`
  fragment dehydrationToolPlateFragment on containerArray {
    id
    name
    barcode {
      id
      barcodeString
    }
    containerArrayType {
      id
      containerFormat {
        code
        is2DLabeled
        columnCount
      }
    }
    aliquotContainers {
      id
      rowPosition
      columnPosition
      aliquot {
        ...aliquotHydrationFragment
      }
    }
  }
  ${aliquotHydrationFragment}
`;
