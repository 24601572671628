/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { tidyUpSequenceData } from "@teselagen/sequence-utils";
import { pick, get, map } from "lodash";
import { getMaxSeqLen } from "./getMaxSeqLen";
import { computeSequenceHash } from "./computeSequenceHash";
import getExternalReferenceFieldsAndTags from "../../utils/getExternalReferenceFieldsAndTags";
import { getDigestPartFields } from "../../../utils/digestPartUtils";
import sequenceStringToFragments from "../sequenceStringToFragments";

export const sequenceJSONtoGraphQLInput = (sequenceJSON, options = {}) => {
  // This might be called with partial sequence data for updating
  const { name, keepIds } = options;
  let sequenceTypeCode;
  if (sequenceJSON.sequenceTypeCode) {
    sequenceTypeCode = sequenceJSON.sequenceTypeCode;
  } else if (sequenceJSON.circular) {
    sequenceTypeCode = "CIRCULAR_DNA";
  } else {
    sequenceTypeCode = "LINEAR_DNA";
  }
  sequenceJSON = tidyUpSequenceData(sequenceJSON, {
    doNotProvideIdsForAnnotations: true
  });

  const sequenceFeatures = sequenceJSON.features || [];
  sequenceJSON.sequence = (sequenceJSON.sequence || "").replace(/\s+/g, "");
  if (sequenceTypeCode === "RNA") {
    sequenceJSON.sequence = (sequenceJSON.sequence || "").replace(/t/gi, "u");
  }
  const size = get(sequenceJSON, "sequence.length") || sequenceJSON.size;
  const seqName = name || sequenceJSON.name;
  // limit to 100,000 bps unless genome
  if (sequenceTypeCode !== "GENOME") {
    const maxLen = getMaxSeqLen();
    if (size > maxLen) {
      throw new Error(
        `Sequence ${seqName} is greater than ${maxLen} base pairs and can not be uploaded to this system.`
      );
    }
  }
  const hash = computeSequenceHash(sequenceJSON.sequence, sequenceTypeCode);
  const toRet = {
    ...(keepIds && { id: sequenceJSON.id }),
    name: seqName,
    isCds: sequenceJSON.isCds,
    ...getExternalReferenceFieldsAndTags(sequenceJSON),
    chromatogramData: sequenceJSON.chromatogramData,
    circular:
      sequenceJSON.circular === false
        ? false
        : sequenceJSON.circular || sequenceTypeCode === "CIRCULAR_DNA",
    sequenceTypeCode,
    parts:
      sequenceJSON.parts &&
      map(sequenceJSON.parts, part => {
        part.notes = part.notes || {};
        part.notes["tag"] = part.notes["tag"] || [];
        return {
          ...(keepIds && { id: part.id }),
          taggedItems: part.notes["tag"].map(tn => ({
            tag: { name: tn }
          })),
          start: part.start,
          end: part.end,
          name: part.name,
          strand: part.strand,
          ...(part.isDigestPart && { ...getDigestPartFields(part) })
        };
      }),
    // externalRecordIdentifiers: sequenceJSON.externalRecordIdentifiers,
    ...(sequenceJSON.sequence && {
      size,
      hash,
      sequenceFragments: sequenceStringToFragments(sequenceJSON.sequence)
    }),
    tags: sequenceJSON.tags,
    sequenceFeatures: sequenceFeatures.map(feature =>
      pick(feature, [
        ...(keepIds ? ["id"] : []),
        "start",
        "end",
        "name",
        "strand",
        "arrowheadType",
        "type",
        "locations",
        "notes"
      ])
    ),
    description: sequenceJSON.description || ""
  };
  return toRet;
};
