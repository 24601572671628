/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useContext } from "react";
import { compose } from "recompose";
import { InjectedFormProps, SubmissionError, reduxForm } from "redux-form";
import type {
  InjectedTgFormValuesProps,
  InjectedWrapDialogProps
} from "@teselagen/ui";
import { tgFormValues, wrapDialog } from "@teselagen/ui";
import { noop } from "lodash";
import { Classes } from "@blueprintjs/core";
import {
  SelectField,
  InputField,
  BlueprintError,
  DialogFooter
} from "@teselagen/ui";
import AsyncValidateFieldSpinner from "../../AsyncValidateFieldSpinner";
import { get } from "lodash";
import userManagementFragment from "../../UserManagementPanel/userManagementFragment";
import PasswordWithStrength from "../components/PasswordWithStrength";
import "../style.css";
import { safeDelete, safeUpsert, safeQuery } from "../../apolloMethods";
import LabForUserForm from "../components/labForUserForm";
import CurrentUserContext from "../../context/CurrentUserContext";
import { auth, isPasswordAbleToBeTestedForStrength } from "../utils";
import isEmail from "validator/lib/isEmail";
import checkPassword from "../../../../tg-iso-shared/src/utils/checkPassword";
import { userRoleFragment } from "../utils/fragments.gql";

type FormProps = {
  email: string;
  password: string;
  confirmPassword: string;
  firstName: string;
  lastName: string;
  userRole: "ADMIN" | "MEMBER";
  labOptionForUser: "existingLab" | "newLab" | undefined;
  labGroupName: string;
  existingLab: { id: string };
};

type Props = {
  adminAddUser?: boolean;
  adminEditUser?: boolean;
  afterEdit?: (editedUser: any) => void;
  refetch?: () => void;
  originalUserBeingEdited?: any;
};

type StrengthResult = {
  score: number;
  passed: boolean;
  message: string;
  warning?: string;
  suggestions?: string[];
};

const validate = (values: FormProps) => {
  const errors: {
    email?: string;
    password?: string;
    confirmPassword?: string;
  } = {};
  if (values.email && !isEmail(values.email)) {
    errors.email = "Invalid email address";
  }

  if (
    values.password &&
    values.password.length &&
    values.email &&
    values.email.length
  ) {
    const result = checkPassword(values.password, {
      email: values.email
    }) as StrengthResult;
    if (result.score <= 2) {
      errors.password = result.message || "Weak password";
    }
  }

  if (values.password && values.password !== values.confirmPassword) {
    errors.confirmPassword = "Passwords do not match";
  }
  return errors;
};

async function asyncValidate(
  { email }: { email: string },
  _dispatch: any,
  { initialValues }: { initialValues: FormProps }
) {
  if (initialValues && initialValues.email === email) return;
  const usersWithEmail = await safeQuery(["user", "id"], {
    variables: {
      filter: {
        email
      }
    }
  });
  if (usersWithEmail.length) {
    const error = { email: "Email is already in use." };
    throw error;
  }
}

const RegisterComponent = (
  props: InjectedFormProps<FormProps> &
    InjectedTgFormValuesProps<{
      password: string;
      email: string;
      username: string;
      labOptionForUser: "existingLab" | "newLab" | undefined;
    }> &
    InjectedWrapDialogProps &
    Props
) => {
  const { refetchCurrentUser } = useContext(CurrentUserContext) as {
    refetchCurrentUser: () => void;
  };
  const {
    adminAddUser,
    adminEditUser,
    afterEdit,
    asyncValidating,
    email,
    error,
    handleSubmit,
    hideModal = noop,
    labOptionForUser,
    originalUserBeingEdited,
    password: enteredPassword,
    refetch = noop,
    submitting,
    valid
  } = props;

  const onSubmit = async (data: FormProps) => {
    // finish the on submit

    try {
      let editedUser;
      if (adminEditUser) {
        const userToUpsert: Partial<FormProps> = {
          ...data
        };

        delete userToUpsert.userRole;
        delete userToUpsert.labOptionForUser;
        delete userToUpsert.labGroupName;
        delete userToUpsert.existingLab;

        if (originalUserBeingEdited.email !== userToUpsert.email) {
          await window.serverApi.request({
            method: "POST",
            url: "/change-email",
            data: {
              userEmailToEdit: originalUserBeingEdited.email,
              newEmail: userToUpsert.email
            }
          });
        }

        delete userToUpsert.email;

        await safeDelete(
          "userRole",
          originalUserBeingEdited.userRoles &&
            originalUserBeingEdited.userRoles.map(
              ({ id }: { id: string }) => id
            )
        );
        await safeUpsert(userRoleFragment, {
          appRoleCode: data.userRole,
          userId: originalUserBeingEdited.id
        });
        editedUser = await safeUpsert(userManagementFragment, {
          ...userToUpsert
        });
      } else {
        let labId;
        if (data.labOptionForUser === "existingLab") {
          labId = data.existingLab.id;
        } else {
          const [newLab] = await safeUpsert<{ id: string }>("lab", {
            name: data.labGroupName
          });
          labId = newLab.id;
        }

        const userToUpsert = {
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          password: data.password,
          confirmPassword: data.confirmPassword,
          userRoles: [
            {
              appRoleCode: data.userRole
            }
          ],
          labRoles: [
            {
              roleCode: "MEMBER",
              labId
            }
          ]
        };

        await auth.register(userToUpsert);
      }

      if (!adminEditUser) {
        refetchCurrentUser();
        await refetch();
      }
      if (afterEdit) await afterEdit(editedUser);

      hideModal();
    } catch (err) {
      console.error("err:", err);
      let error = err.message;
      try {
        error = JSON.parse(err.request.responseText).error;
      } catch (e) {
        error = e;
      }
      const errorMsg = "Error creating user: ";
      throw new SubmissionError({
        _error: errorMsg + error
      });
    }
  };

  const renderLabOptions = () => {
    // when an admin is creating a new user they can choose a lab or create a new lab
    if (adminAddUser) {
      return <LabForUserForm labOptionForUser={labOptionForUser} />;
    }
    return null;
  };

  const isLocalAuth = !!get(window, "frontEndConfig.localLogin");
  const tooltipProps = {
    className: "full-width-tooltip",
    position: "left"
  };
  const loginRequiresPassword = window.frontEndConfig;
  const canTestPasswordStrength = isPasswordAbleToBeTestedForStrength(
    enteredPassword,
    email
  );

  return (
    <>
      <div className={Classes.DIALOG_BODY}>
        {(adminAddUser || adminEditUser) && (
          <SelectField
            name="userRole"
            label="User Role"
            // type="email"
            defaultValue="MEMBER"
            options={[
              { label: "Member", value: "MEMBER" },
              { label: "Admin", value: "ADMIN" }
            ]}
            tooltipError
            tooltipProps={tooltipProps}
          />
        )}

        {/* https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autoComplete */}
        <InputField
          name="email"
          label="Email"
          type="email"
          isRequired
          autoComplete="email"
          tooltipError={!adminAddUser}
          disabled={!isLocalAuth}
          rightElement={
            <AsyncValidateFieldSpinner validating={asyncValidating} />
          }
          tooltipProps={tooltipProps}
        />
        <InputField
          name="firstName"
          isRequired
          label="First Name"
          autoComplete="given-name"
          tooltipError={!adminAddUser}
          tooltipProps={tooltipProps}
        />
        <InputField
          name="lastName"
          isRequired
          label="Last Name"
          autoComplete="family-name"
          tooltipError={!adminAddUser}
          tooltipProps={tooltipProps}
        />
        {!adminEditUser && loginRequiresPassword && (
          <>
            <PasswordWithStrength
              label="Password"
              name="password"
              isRequired
              autoComplete="new-password"
              email={email}
              showStrength={canTestPasswordStrength}
              tooltipError={!adminAddUser}
              tooltipProps={tooltipProps}
            />

            <InputField
              name="confirmPassword"
              type="password"
              isRequired
              label="Confirm Password"
              autoComplete="new-password"
              tooltipError={!adminAddUser}
              tooltipProps={tooltipProps}
            />
          </>
        )}
        {renderLabOptions()}
        {error && <BlueprintError error={error} />}
      </div>
      <DialogFooter
        hideModal={hideModal}
        loading={submitting}
        disabled={!valid}
        onClick={handleSubmit(onSubmit)}
      />
    </>
  );
};

export const RegisterFormDialog = compose<
  InjectedFormProps<FormProps> &
    InjectedTgFormValuesProps<{
      password: string;
      email: string;
      username: string;
      labOptionForUser: "existingLab" | "newLab" | undefined;
    }> &
    InjectedWrapDialogProps &
    Props,
  Props
>(
  wrapDialog({ title: "Add User" }),
  reduxForm({
    form: "registrationForm",
    enableReinitialize: true,
    asyncBlurFields: ["email"],
    validate,
    asyncValidate
  }),
  tgFormValues("password", "email", "username", "labOptionForUser")
)(RegisterComponent);
