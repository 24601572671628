/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { round } from "lodash";
import { InfoHelper } from "@teselagen/ui";
import { getSequence } from "../../../tg-iso-shared/src/utils/getSequence";
import {
  getAssemblyPiecePartsContained,
  getExtraBpsFields,
  getOverhangFields
} from "@teselagen/j5-utils";
import getSampleStatusMessage from "../utils/getSampleStatusMessage";
import LabelWithTooltip from "../LabelWithTooltip";
import { Colors, Icon, Intent, Popover } from "@blueprintjs/core";
import J5LogMessagesView from "../J5LogMessagesView";
import { get } from "lodash";

function linkedColBuild(col) {
  return [col];
}

export const lengthBp = "Length (bp)";

const sampleStatusColumn = () => [
  {
    displayName: "Sample Status",
    render: (v, r) => {
      const sequence = r.sequence || r.oligo?.sequence;
      return getSampleStatusMessage(sequence?.sequenceSampleStatusView);
    }
  }
];

const getDefaultSchemas = (isGoldenGate, isGibson) => {
  return {
    j5PcrReactions: {
      fields: [
        { path: "id", type: "string", displayName: "ID", isHidden: true },
        { path: "name", type: "string", displayName: "Name" },
        {
          path: "pcrProductSequence.j5AssemblyPiece.id",
          type: "string",
          displayName: "Assembly Piece ID",
          isHidden: true
        },
        {
          path: "pcrProductSequence.j5AssemblyPiece.name",
          type: "string",
          displayName: "Assembly Piece Name"
        },
        {
          path: "primaryTemplate.id",
          type: "string",
          isHidden: true,
          displayName: "Primary Template ID"
        },
        {
          path: "primaryTemplate.name",
          type: "string",
          displayName: "Primary Template Name"
        },
        {
          path: "secondaryTemplate.id",
          isHidden: true,
          type: "string",
          displayName: "Secondary Template ID"
        },
        {
          path: "secondaryTemplate.name",
          type: "string",
          displayName: "Secondary Template Name"
        },
        {
          path: "forwardPrimer.id",
          isHidden: true,
          type: "string",
          displayName: "Forward Oligo ID"
        },
        {
          path: "forwardPrimer.name",
          type: "string",
          displayName: "Forward Oligo Name"
        },
        {
          path: "reversePrimer.id",
          isHidden: true,
          type: "string",
          displayName: "Reverse Oligo ID"
        },
        {
          path: "reversePrimer.name",
          type: "string",
          displayName: "Reverse Oligo Name"
        },
        { path: "note", type: "string", displayName: "Notes", isHidden: true },
        {
          path: "oligoMeanTm",
          type: "number",
          displayName: "Mean Tm (°C)",
          render: v => round(v, 2)
        },
        {
          path: "oligoDeltaTm",
          type: "number",
          displayName: "Delta Tm (°C)",
          render: v => round(v, 2)
        },
        {
          path: "oligoMeanTm3Prime",
          type: "number",
          isHidden: true,
          displayName: "Mean Tm 3' (°C)",
          render: v => round(v, 2)
        },
        {
          path: "oligoDeltaTm3Prime",
          type: "number",
          isHidden: true,
          displayName: "Delta Tm 3' (°C)",
          render: v => round(v, 2)
        },
        {
          path: "pcrProductSequence.size",
          type: "string",
          displayName: lengthBp
        }
      ]
    },
    j5OligoSyntheses: {
      fields: [
        { path: "id", type: "string", isHidden: true, displayName: "ID" },
        { path: "name", type: "string", displayName: "Name" },
        savedSeqName({ isOligo: true }),
        {
          path: "oligo.sequence.size",
          type: "number",
          displayName: lengthBp
        },
        {
          path: "oligo.sequence",
          type: "string",
          displayName: "Sequence",
          render: (sequence = { sequenceFragments: [] }) => {
            return getSequence(sequence);
          }
        },
        {
          path: "sequence3Prime",
          type: "string",
          displayName: "Annealing Sequence"
        },
        {
          path: "j5TargetParts",
          displayName: "Target Parts",
          render: (j5TargetParts = []) =>
            j5TargetParts.map(j5tp => j5tp.j5InputPart.part?.name).join(", ")
        },
        { path: "tm", type: "number", displayName: "Tm (°C)" },
        { path: "tm3Prime", type: "number", displayName: "Tm 3' Only (°C)" },
        {
          path: "cost",
          type: "number",
          displayName: "Cost (USD)"
        },
        ...sampleStatusColumn(),
        ...linkedColBuild({
          path: "oligo.sequence.polynucleotideMaterialId",
          type: "boolean",
          displayName: "Linked Material"
        })
      ]
    },
    j5AnnealedOligos: {
      fields: [
        { path: "id", type: "string", isHidden: true, displayName: "ID" },
        { path: "name", type: "string", displayName: "Name" },
        savedSeqName(),
        {
          path: "sequence.fullSequence",
          type: "string",
          displayName: "Annealed Fragment"
        },
        {
          path: "sequence.size",
          type: "number",
          displayName: lengthBp
        },
        { path: "tm", type: "number", displayName: "Tm (°C)" },
        { path: "sequence.tm", type: "number", displayName: "Seq Tm (°C)" },

        {
          path: "topOligo.id",
          isHidden: true,
          type: "string",
          displayName: "Top Oligo Id"
        },
        {
          path: "topOligo.name",
          type: "string",
          displayName: "Top Oligo Name"
        },
        {
          path: "topOligo.sequence.fullSequence",
          type: "string",
          displayName: "Top Oligo Bps"
        },
        {
          path: "bottomOligo.id",
          isHidden: true,
          type: "string",
          displayName: "Bottom Oligo Id"
        },
        {
          path: "bottomOligo.name",
          type: "string",
          displayName: "Bottom Oligo Name"
        },
        {
          path: "bottomOligo.sequence.fullSequence",
          type: "string",
          displayName: "Bottom Oligo Bps"
        },
        ...sampleStatusColumn(),
        ...linkedColBuild({
          path: "sequence.polynucleotideMaterialId",
          type: "boolean",
          displayName: "Linked Material"
        })
      ]
    },
    j5AssemblyPieces: {
      fields: getJ5AssemblyPiecesFields(isGoldenGate, isGibson)
    },
    j5RunConstructs: {
      fields: [
        {
          path: "id",
          type: "string",
          isHidden: true,
          displayName: "Construct ID"
        },
        {
          path: "name",
          type: "string",
          displayName: "Construct Name",
          render: (v, j5RunConstruct, b, props) => {
            const { j5LogMessages, j5ReportVersion } = props;
            if (!j5LogMessages) return v;
            if (!j5ReportVersion) return v;

            let filteredJ5Messages;
            if (parseInt(j5ReportVersion) === 4) {
              filteredJ5Messages = j5LogMessages;
            } else {
              filteredJ5Messages = j5LogMessages
                .map(msg => {
                  const toReturn = { ...msg };
                  toReturn.j5LogMessageJoins = msg.j5LogMessageJoins.filter(
                    join => {
                      const inputPartIds = [];
                      const assemblyPieceIds = [];

                      j5RunConstruct.j5ConstructAssemblyPieces.forEach(
                        j5cap => {
                          assemblyPieceIds.push(j5cap.assemblyPiece.id);
                          j5cap.assemblyPiece.j5AssemblyPieceParts.forEach(
                            j5app => {
                              inputPartIds.push(j5app.j5InputPart.id);
                            }
                          );
                        }
                      );

                      if (
                        "construct_" + join.j5RunConstructId ===
                        j5RunConstruct.id
                      ) {
                        return true;
                      } else if (
                        inputPartIds.indexOf(join.j5InputPartId) > -1
                      ) {
                        return true;
                      } else if (
                        assemblyPieceIds.indexOf(join.j5AssemblyPieceId) > -1
                      ) {
                        return true;
                      }
                      return false;
                    }
                  );
                  return toReturn;
                })
                .filter(msg => msg.j5LogMessageJoins.length > 0);
            }

            if (filteredJ5Messages.length === 0) return v;
            return (
              <>
                <Popover
                  content={
                    <div
                      style={{
                        userSelect: "text",
                        padding: 20
                      }}
                    >
                      <div style={{ fontSize: "1.5em", color: Colors.GOLD3 }}>
                        Warnings:
                      </div>
                      <br />
                      <J5LogMessagesView j5LogMessages={filteredJ5Messages} />
                    </div>
                  }
                >
                  <Icon
                    className="tg-j5-warning"
                    style={{ marginRight: 5 }}
                    intent="warning"
                    icon="warning-sign"
                  ></Icon>
                </Popover>
                {v}
              </>
            );
          }
        },
        savedSeqName(),
        { path: "sequence.size", type: "number", displayName: lengthBp },
        {
          path: "partsContainedNames",
          type: "string",
          displayName: "Parts Contained"
        },
        {
          path: "warnings",
          type: "number",
          displayName: "Warnings",
          render: (v, j5RunConstruct) => {
            const totalWarnings = j5RunConstruct.numWarnings;
            return totalWarnings > 0 ? (
              <LabelWithTooltip
                label={totalWarnings}
                icon="warning-sign"
                tooltipMargin={8}
                intent={Intent.WARNING}
                tooltip="There are warnings that can be viewed by opening the construct"
              />
            ) : (
              totalWarnings
            );
          }
        },
        ...sampleStatusColumn(),
        ...linkedColBuild({
          path: "sequence.polynucleotideMaterialId",
          type: "boolean",
          displayName: "Linked Material"
        })
      ]
    },
    j5InputSequences: {
      fields: [
        {
          path: "sequence.id",
          isHidden: true,
          type: "string",
          displayName: "ID"
        },
        { path: "sequence.name", type: "string", displayName: "Name" },
        { path: "sequence.circular", type: "boolean", displayName: "Circular" },
        { path: "sequence.size", type: "number", displayName: lengthBp },
        {
          path: "sequence.description",
          type: "string",
          displayName: "Description"
        },
        ...sampleStatusColumn(),
        ...linkedColBuild({
          path: "sequence.polynucleotideMaterialId",
          type: "boolean",
          displayName: "Linked Material"
        })
      ]
    },
    j5DirectSyntheses: {
      fields: [
        {
          path: "id",
          isHidden: true,
          type: "string",
          displayName: "ID"
        },
        { path: "name", type: "string", displayName: "Name" },
        savedSeqName({ isOligo: true }),
        { path: "sequence.size", type: "number", displayName: lengthBp },
        {
          path: "sequence.j5AssemblyPiece.id",
          type: "string",
          displayName: "Assembly Piece ID",
          isHidden: true
        },
        {
          path: "sequence.j5AssemblyPiece.name",
          type: "string",
          displayName: "Assembly Piece Name"
        },
        { path: "cost", type: "number", displayName: "Cost (USD)" },
        ...sampleStatusColumn(),
        ...linkedColBuild({
          path: "sequence.polynucleotideMaterialId",
          type: "boolean",
          displayName: "Linked Material"
        })
      ]
    },
    j5InputParts: {
      fields: [
        {
          path: "part.id",
          isHidden: true,
          type: "string",
          displayName: "ID"
        },
        { path: "part.name", type: "string", displayName: "Name" },
        {
          path: "part.sequence.name",
          type: "string",
          displayName: "Source"
        },
        {
          path: "part.strand",
          type: "number",
          displayName: "Reverse Complement",
          render: v => (v === 1 ? "False" : "True")
        },
        {
          path: "part.start",
          type: "number",
          displayName: "Start (bp)",
          render: n => n + 1
        },
        {
          path: "part.end",
          type: "number",
          displayName: "End (bp)",
          render: n => n + 1
        },
        { path: "size", type: "number", displayName: lengthBp }
      ]
    }
  };
};

const idField = {
  path: "id",
  type: "string",
  isHidden: true,
  displayName: "ID"
};
const nameField = {
  path: "name",
  type: "string"
};
const digestSourceField = {
  path: "j5AssemblyPieceParts.j5InputPart.j5InputSequence.sequence.name",
  displayName: "Digest Source",
  render: (v, r) => {
    if (r.type !== "Digest Linearized") return "N/A";
    return get(
      r,
      "j5AssemblyPieceParts[0].j5InputPart.j5InputSequence.sequence.name"
    );
  }
};
const sourceField = { path: "type", type: "string", displayName: "Source" };

const partsContainedField = {
  path: "j5AssemblyPieceParts",
  displayName: "Parts Contained",
  render: (_, piece) => getAssemblyPiecePartsContained(piece)
};

const getJ5AssemblyPiecesFields = (isGoldenGate, isGibson) => [
  idField,
  nameField,
  savedSeqName(),
  sourceField,
  digestSourceField,
  {
    path: "assemblyPieceConstructCountView.constructCount",
    type: "number",
    width: 130, // so that info doesn't get hidden under filter menu
    renderTitleInner: (
      <span style={{ display: "flex" }}>
        Multiplicity
        <InfoHelper
          content="Number of assemblies that use this piece."
          style={{ marginLeft: 5 }}
        />
      </span>
    ),
    displayName: "Multiplicity"
  },
  partsContainedField,
  ...getOverhangFields(isGoldenGate, isGibson),
  ...getExtraBpsFields(isGibson),
  ...sampleStatusColumn(),
  ...linkedColBuild({
    path: "sequence.polynucleotideMaterialId",
    type: "boolean",
    displayName: "Linked Material"
  }),
  { path: "sequence.size", type: "number", displayName: lengthBp },
  {
    path: "cpecTmNext",
    type: "number",
    displayName: "CPEC Tm Next",
    isHidden: true
  }
];

export default getDefaultSchemas;

export const getDigestFragmentFields = (isGoldenGate, isGibson) => [
  {
    path: "sequence.id",
    displayName: "Digest Piece ID",
    isHidden: true
  },
  {
    path: "sequence.name",
    displayName: "Name"
  },
  {
    displayName: "Length (bp)",
    path: "sequence.size"
  },
  {
    ...idField,
    displayName: "Assembly Piece ID"
  },
  {
    ...nameField,
    displayName: "Assembly Piece Name"
  },
  digestSourceField,
  partsContainedField,
  ...getOverhangFields(isGoldenGate, isGibson)
];

const savedSeqName = ({ isOligo } = {}) => ({
  path: isOligo ? "oligo.sequence.name" : "sequence.name",
  type: "number",
  render: (v, construct) => {
    const seq = isOligo ? construct?.oligo?.sequence : construct?.sequence;
    if (seq?.isInLibrary) {
      return v;
    }
    return "Not Yet Saved";
  },
  displayName: `Saved ${isOligo ? "Oligo" : "Sequence"}`
});
