/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { InputField, RadioGroupField } from "@teselagen/ui";
import GenericSelect from "../../GenericSelect";
import { COMMON_LAB_ID } from "@teselagen/auth-utils";
import QueryBuilder from "tg-client-query-builder";
type Props = {
  onlyExistingLabs?: boolean;
  allowCommonLab?: boolean;
  labOptionForUser?: "newLab" | "existingLab";
};

const LabForUserForm = (props: Props) => {
  const {
    onlyExistingLabs,
    labOptionForUser = "existingLab",
    allowCommonLab = false
  } = props;

  return (
    <>
      {onlyExistingLabs ? (
        <h6>
          <b>
            <i>Lab</i>
          </b>
        </h6>
      ) : (
        <RadioGroupField
          defaultValue="existingLab"
          label="Lab"
          inline
          options={[
            {
              label: "Existing Lab",
              value: "existingLab"
            },
            {
              label: "Create New Lab",
              value: "newLab"
            }
          ]}
          name="labOptionForUser"
        />
      )}
      {labOptionForUser === "newLab" ? (
        <InputField name="labGroupName" isRequired label="Lab Group Name" />
      ) : (
        <GenericSelect
          schema={["name"]}
          name={"existingLab"}
          isRequired
          asReactSelect
          fragment={["lab", "id name"]}
          queryOptions={{
            variables: { sort: ["name"] }
          }}
          tableParamOptions={
            allowCommonLab
              ? undefined
              : {
                  additionalFilter: (_: any, qb: QueryBuilder) => {
                    qb.whereAll({
                      id: qb.notInList([COMMON_LAB_ID])
                    });
                  }
                }
          }
        />
      )}
    </>
  );
};

export default LabForUserForm;
