/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useState } from "react";
import { compose } from "redux";
import { Switch } from "@blueprintjs/core";

import Helmet from "react-helmet";
import { get } from "lodash";
import withQuery from "../withQuery";
import { safeUpsert } from "../apolloMethods";

const notificationSettingFragment = [
  "notificationSetting",
  "id userId isActive notificationTypeCode channelType updatedAt"
];

export default compose(
  withQuery(
    [
      "notificationType",
      "code name smsByDefault emailByDefault toastrByDefault inAppNotificationByDefault"
    ],
    {
      showLoading: true,
      isPlural: true,
      options: props => ({
        variables: {
          ...props.variables,
          pageSize: 1000000
        }
      })
    }
  ),
  withQuery(notificationSettingFragment, {
    isPlural: true,
    showLoading: true,
    options: props => ({
      variables: {
        ...props.variables,
        filter: {
          userId:
            get(props, "currentUser.user.id") || get(props, "currentUser.id")
        },
        sort: ["-updatedAt"],
        pageSize: 1000000
      }
    })
  })
)(function NotificationSettings(props) {
  const [loading, setLoading] = useState(false);

  const {
    notificationTypes,
    notificationSettings,
    refetchNotificationSettings,
    currentUser
  } = props;
  return (
    <div>
      <Helmet title="Notification Settings" />
      <div className="tg-card xtra-pad">
        <h3>My Notification Settings</h3>
        {!notificationTypes ? (
          <div>No Notification Types Set Up Yet!</div>
        ) : (
          [...notificationTypes]
            .sort(nt => nt.name)
            .filter(
              nt => !window.frontEndConfig.hiddenNotificationSettings?.[nt.name]
            )
            .map(nt => {
              const settingsOfType = (notificationSettings || []).filter(
                ns => ns.notificationTypeCode === nt.code
              );

              return (
                <div style={{ marginBottom: 25 }} key={nt.code}>
                  <h4 style={{ /* marginBottom: -10, */ display: "flex" }}>
                    {/* <h6 ><Switch checked={checked}></Switch></h6> &nbsp; */}
                    {nt.name}:
                  </h4>
                  {[
                    // {
                    //   channelType: "sms",
                    //   type: "smsByDefault",
                    //   name: "Sms"
                    // },
                    {
                      channelType: "email",
                      type: "emailByDefault",
                      name: "Email"
                    },

                    {
                      channelType: "inAppNotification",
                      type: "inAppNotificationByDefault",
                      name: "In-App Notification"
                    }
                    // {
                    //   channelType: "toastr",
                    //   type: "toastrByDefault",
                    //   name: "Toastr"
                    // }
                  ].map(({ type, name, channelType }) => {
                    let checked = nt[type];
                    let existingSetting = {};

                    const settingsForChannel = settingsOfType.filter(
                      s => s.channelType === channelType
                    );

                    if (settingsForChannel.length) {
                      existingSetting = settingsForChannel[0];
                      checked = existingSetting.isActive;
                    }
                    return (
                      <Switch
                        disabled={loading}
                        style={{ marginLeft: 30 }}
                        data-test={`notification-setting-toggle-${nt.code}-${channelType}`}
                        label={
                          <span style={{ display: "inline" }}>{name} </span>
                        }
                        onChange={async () => {
                          try {
                            setLoading(true);
                            await safeUpsert("notificationSetting", {
                              id: existingSetting
                                ? existingSetting.id
                                : undefined,
                              userId: currentUser.id,
                              notificationTypeCode: nt.code,
                              channelType: channelType,
                              isActive: !checked
                            });
                            await refetchNotificationSettings();
                            window.toastr.success("Settings Saved!", {
                              key: "notificationSettingsSave"
                            });
                          } catch (error) {
                            console.error("error:", error);
                            window.toastr.error("Error saving setting");
                          }
                          setLoading(false);
                        }}
                        key={type}
                        checked={checked}
                      />
                    );
                  })}
                </div>
              );
            })
        )}
      </div>
    </div>
  );
});
