/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { AnchorButton, Popover } from "@blueprintjs/core";
import React from "react";
import { popoverOverflowModifiers } from "./utils/generalUtils";
import "./DisabledToolkitPopover.css";
import classNames from "classnames";

type Props = {
  disabled?: {
    toolkitName?: string;
    disabledToolkitUrl?: string;
  };
};

export const DisabledToolkitMessage = ({ disabled }: Props) => {
  return (
    <div style={{ padding: 20, maxWidth: 250 }}>
      <h4>Unlock the {disabled?.toolkitName} Toolkit. </h4>
      <h6>
        Learn more about how it can help your lab or contact sales to get
        started.
      </h6>
      <div style={{}}>
        <AnchorButton
          rel="noopener noreferrer"
          target="_blank"
          href={disabled?.disabledToolkitUrl}
        >
          Learn More
        </AnchorButton>{" "}
        &nbsp;
        <AnchorButton
          href={
            "https://teselagen.com/?utm_campaign=Product%20Unlock&utm_source=teselagen_app"
          }
          rel="noopener noreferrer"
          target="_blank"
          intent="primary"
        >
          Contact Sales
        </AnchorButton>
      </div>
    </div>
  );
};

export const DisabledToolkitPopover = ({
  disabled,
  className = "",
  children,
  forButton
}: {
  disabled: {
    toolkitName: string;
    disabledToolkitUrl: string;
  };
  className?: string;
  forButton?: boolean;
  children: React.ReactNode;
}) => (
  <Popover
    modifiers={popoverOverflowModifiers}
    minimal
    disabled={!disabled}
    className={classNames(className, { "full-width-tooltip": !forButton })}
    content={<DisabledToolkitMessage disabled={disabled} />}
  >
    {disabled ? (
      <>
        {children}
        <div
          data-tip="Learn More"
          className={classNames("tg-toolkitDisabledIcon", {
            "tg-toolkitDisabledIconButton": forButton
          })}
        >
          <img src="/Upgrade badge.png"></img>
        </div>
      </>
    ) : (
      children
    )}
  </Popover>
);
