/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { compose } from "recompose";
import Helmet from "react-helmet";
import { withTableParams, DataTable } from "@teselagen/ui";
import withQuery from "../../../src-shared/withQuery";

import { MenuItem, Button, Intent, Classes } from "@blueprintjs/core";
import { pick } from "lodash";
import AbstractLibrary from "../../../src-shared/AbstractLibrary";
import { deleteRecordsHelper } from "../../utils";
import { dateModifiedColumn } from "../../../src-shared/utils/libraryColumns";
import { showDialog } from "../../../src-shared/GlobalDialog";
import gql from "graphql-tag";

const schema = {
  model: "vendor",
  fields: [
    { path: "name", type: "string", displayName: "Name" },
    { path: "webAddress", type: "string", displayName: "URL" },
    dateModifiedColumn
  ]
};

class VendorPanel extends Component {
  // add create vendor dialog
  openEditWindow = record => {
    showDialog({
      modalType: "CREATE_NEW_VENDOR",
      modalProps: {
        refetch: this.refetch,
        initialValues: record
      }
    });
  };

  refetch = async () => {
    const {
      selectedEntities = [],
      refetchVendors,
      selectTableRecords
    } = this.props;
    await refetchVendors();
    if (selectedEntities.length) {
      selectTableRecords([]);
      selectTableRecords([selectedEntities[0].id]);
    }
  };

  createOrEditCatalogItem = record => {
    const { selectedEntities = [] } = this.props;
    const selectedVendor = selectedEntities[0];
    // create new vendor catalog
    showDialog({
      modalType: "CREATE_NEW_CATALOG_TYPE",
      modalProps: {
        refetch: this.refetch,
        selectedVendor,
        ...(record && {
          initialValues: {
            ...pick(record, [
              "id",
              "name",
              "description",
              "orderableItemTypeCode"
            ])
          }
        })
      }
    });
  };

  contextMenu = ({ selectedRecords }) => {
    const menuItems = [];
    const deleteRecords = () =>
      deleteRecordsHelper({
        records: selectedRecords,
        refetch: this.refetch
      });

    const deleteMenuItem = (
      <MenuItem
        key="delete"
        icon="trash"
        onClick={deleteRecords}
        text="Delete"
      />
    );
    if (selectedRecords.length === 1) {
      const record = selectedRecords[0];
      const recordType = selectedRecords[0].__typename;
      menuItems.push(
        <MenuItem
          key="edit"
          icon="edit"
          onClick={() =>
            recordType === "catalogItemType"
              ? this.createOrEditCatalogItem(record)
              : this.openEditWindow(record)
          }
          text="Edit"
        />
      );
    }
    menuItems.push(deleteMenuItem);
    return menuItems;
  };

  render() {
    const { selectedEntities = [] } = this.props;
    const selectedVendor = selectedEntities[0];
    const selectedVendorCatalogTypes =
      selectedVendor && selectedEntities[0].catalogItemTypes;
    return (
      <div className="tg-card">
        <Helmet title="Vendors" />
        <AbstractLibrary
          {...this.props}
          // removing edit and delete from context menu
          contextMenu={() => {}}
          onDoubleClick={() => {}}
          libraryName="vendor"
          onNewItemClick={this.openEditWindow}
        />
        <i
          className="tg-flex justify-space-around"
          style={{ marginBottom: 20 }}
        >
          Select a vendor to display its catalog items.
        </i>
        {selectedVendor && <hr className="tg-section-break" />}
        {selectedVendor && (
          <DataTable
            tableName="Catalog Items"
            formName="selectedCatalogItems"
            entities={selectedVendorCatalogTypes}
            contextMenu={this.contextMenu}
            onDoubleClick={this.createOrEditCatalogItem}
            schema={[
              "name",
              "description",
              { path: "orderableItemType.name", displayName: "Category" }
            ]}
          >
            <Button
              intent={Intent.SUCCESS}
              className={Classes.MINIMAL}
              text="Create Catalog Item"
              icon="add"
              onClick={() => this.createOrEditCatalogItem()}
            />
          </DataTable>
        )}
      </div>
    );
  }
}

const fragment = gql`
  fragment vendorPanelFragment on vendor {
    id
    name
    notes
    webAddress
    createdAt
    updatedAt
    catalogItemTypes {
      id
      name
      description
      orderableItemTypeCode
      orderableItemType {
        code
        name
      }
    }
    user {
      id
      username
    }
  }
`;

export default compose(
  withTableParams({
    urlConnected: false,
    schema,
    withSelectedEntities: true,
    formName: "vendorLibrary",
    withDisplayOptions: true,
    defaults: {
      pageSize: 10
    }
  }),
  withQuery(fragment, {
    isPlural: true
  })
)(VendorPanel);
