/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { get, groupBy, size, flatMap, some } from "lodash";
import { compose } from "recompose";
import { getRequestHeaderKeys } from "@teselagen/auth-utils";
import modelNameToLink from "../../../src-shared/utils/modelNameToLink";
import {
  isExcelFile,
  parseCsvFile,
  removeExt,
  encodeFilesForRequest,
  jsonToCsv
} from "../../../../tg-iso-shared/src/utils/fileUtils";
import StepForm from "../../../src-shared/StepForm";
import UploadDataFile from "./UploadDataFileStep";
import { throwFormError } from "../../utils/formUtils";
import { addTaggedItemsBeforeCreate } from "../../../../tg-iso-shared/src/tag-utils";
import { dataValidator } from "../../utils/experimentData/validators";

/**
 * Uploads the CSV file representation of datagrids to the server.
 * @param {Array} datagrids - An array of datagrids to upload.
 * @returns {Promise<Array>} - A promise that resolves to the updated datagrids.
 */
async function uploadDatagridFileRepresentation(datagrids) {
  for (let index = 0; index < datagrids.length; index++) {
    const datagrid = datagrids[index];
    const csvFile = datagrid.csvFile;
    const data = new FormData();
    data.append("file", csvFile);
    const result = await window.serverApi.request({
      method: "POST",
      headers: {
        ...getRequestHeaderKeys(),
        "Content-Type": "multipart/form-data"
      },
      withCredentials: true,
      url: "/test-routes/files",
      data
    });
    delete datagrid.csvFile;
    datagrid["fileId"] = get(result, "data.id");
  }
  return datagrids;
}

/**
 * Removes all empty columns at the end of a table.
 * A column is considered empty if its header is empty and all its cells in the data rows are either empty or undefined.
 *
 *
 * @param {string[]} headers - The headers of the table.
 * @param {Array[]} data - The data of the table, where each element is a row represented as an array of cell values.
 */
function removeEmptyColumnsAtEnd(dataCells) {
  // First, get cells mapped into an array by column position ():
  const dataCellsByColumn = groupBy(dataCells, "columnPosition");
  // Get the max column index
  const maxColumnIndex = Math.max(
    ...Object.keys(dataCellsByColumn).map(Number)
  );

  let outDataCells = dataCells;

  // Iterate the columns in reverse to find and remove all empty columns at the end and eliminate them.
  for (let columnIndex = maxColumnIndex; columnIndex >= 0; columnIndex--) {
    const isColumnEmpty = dataCellsByColumn[columnIndex].every(
      cell => cell.value === "" || cell.value === undefined
    );

    if (isColumnEmpty) {
      // Remove the column from the dataCells
      outDataCells = outDataCells.filter(
        cell => cell.columnPosition !== columnIndex
      );
    } else {
      // Stop the loop when a non-empty column is found
      break;
    }
  }
  return outDataCells;
}

const saveDataGrids = async datagrids => {
  for (let index = 0; index < datagrids.length; index++) {
    const datagrid = datagrids[index];
    try {
      const result = await window.serverApi.request({
        method: "POST",
        headers: getRequestHeaderKeys(),
        withCredentials: true,
        url: `/test-routes/data-grids`,
        data: datagrid
      });
      datagrid["id"] = get(result, "data.id");
    } catch (error) {
      const toastMessage =
        get(error, "response.data.error") || "Error saving data grid.";
      window.toastr.error(toastMessage);
      console.error(error);
      return false;
    }
  }
  return datagrids;
};

function validFileParser(files, dataParserId) {
  let allFilesAreCsv = true;
  files.forEach(file => {
    // If no data parser is selected

    if (isExcelFile(file)) {
      allFilesAreCsv = false;
    }
  });
  // If no parser and an uploaded file has an excel extension, throw an error.
  if (!dataParserId || dataParserId === -1) {
    if (!allFilesAreCsv) {
      window.toastr.error("A data parser must be selected.");
      return false;
    }
  }

  return true;
}

const SuccessPageInnerContent = props => {
  const { dataGrids } = props;
  // When a single datagrids is created, auto select it
  // when deciding to directly go to the import into assay tool link.

  let defaultDataGrid = "";
  if (dataGrids.length === 1) {
    defaultDataGrid = `?dataGridId=${get(dataGrids, "[0].id")}`;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
      }}
    >
      <h4>
        Your data file has been parsed and saved into{" "}
        <Link to={modelNameToLink("dataGrid")}>
          {size(dataGrids)} Data Grid(s).
        </Link>
      </h4>
      <h6>
        Run the{" "}
        <a
          href={`/client/tools/import-experiment-data-into-assay${defaultDataGrid}`}
        >
          Import Experiment Data into Assay
        </a>{" "}
        Tool to import them
      </h6>
    </div>
  );
};

class DataGridTool extends Component {
  state = {
    submitted: false,
    submitLoading: false,
    parserValidationErrors: []
  };

  async runParser(files, dataParserId) {
    const encodedFiles = await encodeFilesForRequest(files);
    let nodeResponse;
    try {
      nodeResponse = await window.triggerIntegrationRequest({
        endpointId: dataParserId,
        data: { encodedFiles },
        method: "POST",
        timeout: 60000,
        // maxContentLength: Infinity,
        // maxBodyLength: Infinity,
        headers: {
          "x-int-req-timeout": 60 * 1000,
          "x-int-req-max-body-length": 100 * 1024 ** 2
        }
      });
    } catch (error) {
      const source = get(error, "response.data.source");
      const message = get(error, "response.data.message");
      const _message =
        source && message
          ? message
          : "Error with Data Parser response. Please head to the Integration Server and inspect the NodeRed Flow response.";
      this.setState({
        submitted: true,
        parserValidationErrors: [
          {
            code: "yup-validation",
            name:
              files.length === 1
                ? files[0].name
                : files[0].name + " and others",
            validationErrors: [
              {
                message: _message
              }
            ]
          }
        ]
      });
      throw new Error("Error with Data Parser response.");
    }

    // Filters empty datagrids
    const datagrids = get(nodeResponse, "data.datagrids")
      .filter(datagrid => size(datagrid.dataCells))
      .map(datagrid => {
        let fileName = files.map(file => file.name).join(", ");
        if (datagrid.fileName) {
          fileName = datagrid.fileName;
        }
        return {
          ...datagrid,
          name: `${removeExt(fileName)}:${datagrid.name}`,
          description: `Generated from file(s): ${fileName}`
        };
      });

    if (!size(datagrids)) {
      const msg =
        "No data grids where created. \nReview that the selected Data Parser correctly supports the data format of the uploaded file";
      window.toastr.warning(msg, { timeout: 10 * 1000 });
      console.error(msg);
    }
    return datagrids;
  }

  /**
   * Converts the data from datagrids into CSV format, and add it into csvData property.
   * @param {Array} datagrids - The array of datagrids to include CSV files in.
   * @returns {Array} - The updated datagrids array.
   */
  async includeCsvFiles(datagrids) {
    datagrids.forEach(datagrid => {
      // Remove all empty columns at the end (right side) of the table.
      datagrid.dataCells = removeEmptyColumnsAtEnd(datagrid.dataCells);

      const { dataCells } = datagrid;
      const headers = dataCells
        .filter(cell => cell.rowPosition === 0)
        .map(row => row.value);

      // Arrange data grid cell values as an array of rows.
      // NOTE: this uses a groupBy to group them by row, and then
      // maps the object into an array of rows with the cell values
      // TODO: maybe there is a faster way performing this transformation.
      const dataRows = groupBy(
        dataCells.filter(cell => cell.rowPosition > 0),
        cell => cell.rowPosition
      );
      const data = Object.keys(dataRows).map(rowIndex =>
        dataRows[rowIndex].map(row => row.value)
      );

      // Form the object needed by the jsonToCsv function which uses papaparse.unparse function.
      const jsonData = {
        fields: headers,
        data
      };
      const csv = jsonToCsv(jsonData);

      datagrid["csvData"] = csv;
    });

    const parserValidationErrors = [];
    for (let index = 0; index < datagrids.length; index++) {
      const datagrid = datagrids[index];
      const { name, csvData } = datagrid;

      const { isValid, validationErrors } = await dataValidator({
        csvString: csvData,
        validators: {
          options: {
            // Allows rows to have empty cells.
            row: { allowEmpty: true }
          }
        }
      });
      if (isValid) {
        const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
        datagrid["csvFile"] = new File([blob], `${name}.csv`);
        delete datagrid.csvData;
      } else {
        parserValidationErrors.push({
          name,
          validationErrors
        });
      }
    }
    this.setState({
      submitted: true,
      parserValidationErrors
    });
    return datagrids;
  }

  /**
   * Parses CSV files and returns an array of data grids.
   *
   * This is only for CSV files with the proper format.
   *
   * @param {File[]} csvFiles - An array of CSV files to parse.
   * @returns {Promise<Object[]>} An array of data grids containing parsed CSV data.
   * @throws {Error} If there is an error parsing the CSV file.
   */
  async includeCsvData(csvFiles) {
    try {
      const outputDataGrids = [];
      for (let index = 0; index < csvFiles.length; index++) {
        const csvFile = csvFiles[index];
        const csvData = await parseCsvFile(csvFile);
        const headers = Object.keys(csvData.data[0]);
        const cellValues = [
          headers,
          ...csvData.data.map(row => headers.map(header => row[header]))
        ];
        const dataCells = flatMap(
          cellValues.map((row, rowPosition) => {
            return row.map((cellValue, columnPosition) => {
              return {
                rowPosition,
                columnPosition,
                value: cellValue
              };
            });
          })
        );

        outputDataGrids.push({
          name: csvFile.name.split(".")[0],
          description: "No data parsed used.",
          dataCells
        });
      }

      // Add csvFile property from processed datacells
      return await this.includeCsvFiles(outputDataGrids);
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error parsing CSV file.");
      throw error;
    }
  }

  onSubmit = async values => {
    const { dataParserId, uploadedFiles, tags } = values;
    // const uploadedFile = uploadedFiles[0];
    this.setState({
      submitLoading: true
    });
    try {
      if (validFileParser(uploadedFiles, dataParserId)) {
        let dataGrids;
        if (dataParserId) {
          // If a parser is selected, the file is sent to the parser.
          dataGrids = await this.runParser(uploadedFiles, dataParserId);
          dataGrids = await this.includeCsvFiles(dataGrids);
          if (some(dataGrids, dataGrid => !dataGrid.csvFile)) return false;
        } else {
          // If no parser is selected, datagrid is directly parsed from the file.
          dataGrids = await this.includeCsvData(uploadedFiles);
        }
        // Upload a copy of the datagrid, a csv representation, to the server.
        dataGrids = await uploadDatagridFileRepresentation(dataGrids);
        dataGrids = await saveDataGrids(
          addTaggedItemsBeforeCreate(dataGrids, tags)
        );

        // The full dataGrid object comes a lot of exta content that doesn't need to be passed.
        const dataGridsMetaData = dataGrids.map(dataGrid => ({
          id: dataGrid.id
        }));
        return { dataGrids: dataGridsMetaData };
      }
    } catch (error) {
      console.error(error);
      window.toastr.error("Error uploading data");
      throwFormError(error.message || "Error uploading data");
    } finally {
      this.setState({
        submitLoading: false
      });
    }
  };

  render() {
    const {
      toolIntegrationProps,
      toolSchema,
      isToolIntegrated,
      initialValues
    } = this.props;
    const steps = [
      {
        title: "Upload Data File",
        Component: UploadDataFile,
        withCustomFooter: true,
        props: {
          submitted: this.state.submitted,
          submitLoading: this.state.submitLoading,
          parserValidationErrors: this.state.parserValidationErrors,
          clearSubmissionState: () => {
            this.setState({
              submitted: false,
              submitLoading: false,
              parserValidationErrors: []
            });
          }
        }
      }
    ];

    return (
      <StepForm
        toolIntegrationProps={toolIntegrationProps}
        enableReinitialize={isToolIntegrated}
        steps={steps}
        validate={this.validate}
        toolSchema={toolSchema}
        onSubmit={this.onSubmit}
        initialValues={initialValues}
        successPageInnerContent={SuccessPageInnerContent}
      />
    );
  }
}

export default compose()(DataGridTool);
