/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { compose } from "recompose";
import { noop, some } from "lodash";
import { reduxForm } from "redux-form";
import {
  InputField,
  DialogFooter,
  ReactColorField,
  SwitchField
} from "@teselagen/ui";
import { Classes } from "@blueprintjs/core";
import { safeUpsert } from "./apolloMethods";
import { wrapDialog } from "@teselagen/ui";
import { getMergedFeatureMap } from "@teselagen/sequence-utils";

export default compose(
  wrapDialog(({ initialValues = {} }) => {
    return {
      title:
        initialValues.id || initialValues.isGenbankStandardType
          ? "Edit Feature Type"
          : "Create Feature Type"
    };
  }),
  reduxForm({
    form: "createFeatureType",
    validate: (values, { initialValues = {} }) => {
      const { name } = values;
      const errors = {};

      if (
        initialValues.name !== name &&
        some(
          getMergedFeatureMap(),
          t => t?.name?.toLowerCase() === name?.toLowerCase()
        )
      ) {
        errors.name = "This name is already in use";
      }

      return errors;
    }
  })
)(function AddFeatureTypeDialog(props) {
  const {
    handleSubmit,
    submitting,
    hideModal,
    initialValues = {}
    // asyncValidating
  } = props;
  const isGenbankStandardType = initialValues.isGenbankStandardType;
  const onSubmit = async values => {
    const { refetch = noop, hideModal = noop, initialValues = {} } = props;
    try {
      const toUpsert = {
        id: initialValues.id,
        name: values.name,
        color: values.color,
        description: values.description,
        isHidden: values.isHidden,
        genbankEquivalentType: values.genbankEquivalentType
      };
      await safeUpsert("featureTypeOverride", toUpsert);
      await refetch();
      hideModal();
      window.toastr.success(
        `Successfully ${
          isGenbankStandardType || initialValues.id ? "edited" : "created"
        } ${
          values.name
        }. Please refresh any other pages you may have open to see these changes reflected in the UI`
      );
    } catch (err) {
      console.error("err:", err);
      window.toastr.error(
        `Error ${initialValues.id ? "editing" : "creating"} feature type.`
      );
    }
  };
  return (
    <div>
      <div className={Classes.DIALOG_BODY}>
        <div className="tg-flex align-center">
          <div
            style={{
              flex: 1
            }}
          >
            <InputField
              disabled={isGenbankStandardType}
              name="name"
              label="Name"
              format={v => {
                if (!v) return v;
                return (
                  v
                    // .toLowerCase()
                    .replaceAll(" ", "_")
                    .replace(/[^a-zA-Z0-9-_]/g, "")
                );
              }}
              isRequired
            />
          </div>
          <div className="tg-flex-separator" />
          <ReactColorField name="color" label="Color" isRequired />
        </div>
        {isGenbankStandardType && (
          <SwitchField
            tooltipInfo={
              "Remove this built-in feature type from the user's view in the application"
            }
            label="Is Hidden"
            name="isHidden"
          ></SwitchField>
        )}
        {/* <TextareaField name="description" label="Description" /> */}
        {/* {!isGenbankStandardType && (
          <SelectField
            tooltipInfo="This value is only used as a fill in for your custom feature type when exporting back to a genbank file"
            defaultValue="misc_feature"
            options={getFeatureTypes()}
            label="Genbank Equivalent Feature Type"
            name="genbankEquivalentType"
          ></SelectField>
        )} */}
      </div>
      <DialogFooter
        onClick={handleSubmit(onSubmit)}
        submitting={submitting}
        hideModal={hideModal}
      />
    </div>
  );
});
