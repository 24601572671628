/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { cloneDeep } from "lodash";

export const getSequenceSequence = sequence => {
  const mappedSequence = cloneDeep(sequence);
  if (!mappedSequence) return null;
  if (mappedSequence.sequence) return mappedSequence.sequence.replace(/,/g, "");
  else if (mappedSequence.sequenceFragments) {
    return mappedSequence.sequenceFragments
      .sort((a, b) => a.index - b.index)
      .map(({ fragment }) => fragment)
      .join("")
      .replace(/,/g, "");
  }
  return null;
};
