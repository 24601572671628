/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { noop } from "lodash";
import Tag from "../Tag";
import TagsRenderingWrapper from "../TagsRenderingWrapper";

class TagOrTagOptions extends Component {
  onClick = (tagId, tagOptionId) => {
    const { onClick = noop } = this.props;
    onClick({ tagId, tagOptionId });
  };

  renderTags = () => {
    const { record, selected, secondarySelected, showDescTooltip, onClick } =
      this.props;
    const hasSelection = !!onClick;

    if (record.tagOptions.length) {
      return record.tagOptions.map(tagOption => (
        <Tag
          tooltip={showDescTooltip && tagOption.description}
          key={tagOption.id}
          style={{ marginRight: hasSelection ? undefined : 3 }}
          hasSelection={hasSelection}
          selected={selected && selected === tagOption.id}
          secondarySelected={
            secondarySelected && secondarySelected === tagOption.id
          }
          onClick={() => this.onClick(record.id, tagOption.id)}
          name={`${record.name}: ${tagOption.name}`}
          color={tagOption.color}
        />
      ));
    } else {
      return (
        <Tag
          {...record}
          tooltip={showDescTooltip && record.description}
          onClick={() => this.onClick(record.id)}
          selected={selected}
          secondarySelected={secondarySelected}
          hasSelection={hasSelection}
        />
      );
    }
  };

  render() {
    return <TagsRenderingWrapper>{this.renderTags()}</TagsRenderingWrapper>;
  }
}

export default TagOrTagOptions;
