/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useState } from "react";
import { Intent, Button } from "@blueprintjs/core";
import copy from "copy-to-clipboard";

// We don't really know what the api key is,
// this is just for ux purposes.
const API_KEY_HIDDEN = "******** **** **** **** ************";

function ApiKeyForm(props) {
  const {
    apiKey,
    apiKeyExpiration,
    header,
    apiKeyLabel = "OTP",
    handleGenerateApiKey,
    handleClearApiKey
  } = props;

  const [submitting, setSubmitting] = useState(false);

  const copyToClipboard = toCopy => {
    !window.Cypress && copy(toCopy);
    window.toastr.success(`${apiKeyLabel} Copied`);
  };

  const displayToken = () => {
    return apiKeyExpiration ? (
      apiKey ? (
        <span id="userApiKey">{apiKey}</span>
      ) : (
        <span>
          <b>{API_KEY_HIDDEN}</b>
        </span>
      )
    ) : (
      <span>
        <i>Click on Generate {apiKeyLabel}</i>
      </span>
    );
  };

  const callHandler = async handler => {
    setSubmitting(true);
    await handler();
    setSubmitting(false);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column"
      }}
    >
      <div>
        {header}
        <br />
        <div className="tg-flex align-center" style={{ marginBottom: 10 }}>
          <div
            style={{
              display: "flex",
              width: "100px"
            }}
          >
            <Button
              intent={Intent.PRIMARY}
              text="Copy"
              disabled={!apiKey}
              onClick={() => {
                copyToClipboard(apiKey);
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              width: "400px"
            }}
          >
            {displayToken()}
          </div>
          {apiKeyExpiration && (
            <div>Expires: {`${new Date(apiKeyExpiration)}`}</div>
          )}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignSelf: "flex-end",
          marginRight: 20
        }}
      >
        <Button
          id="clear-api-key-button"
          style={{ marginRight: 10, alignSelf: "flex-end" }}
          onClick={() => callHandler(handleClearApiKey)}
          loading={submitting}
          disabled={!apiKeyExpiration}
          text={`Clear ${apiKeyLabel}`}
        />
        <Button
          id="generate-api-key-button"
          style={{ alignSelf: "flex-end" }}
          intent={Intent.PRIMARY}
          onClick={() => callHandler(handleGenerateApiKey)}
          loading={submitting}
          text={`Generate ${apiKeyLabel}`}
        />
      </div>
    </div>
  );
}

export default ApiKeyForm;
