/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { noop } from "lodash";
import QueryBuilder from "tg-client-query-builder";
import modelNameToReadableName from "../utils/modelNameToReadableName";
import pluralIfNeeded from "../utils/pluralIfNeeded";
import { isoContext } from "@teselagen/utils";

// these models don't need the alert because they either have their own, or something else...
const noAlertModels = ["container", "equipmentItem"];
export async function deleteRecordsHelper(
  {
    records,
    handleDelete,
    beforeDelete = noop,
    afterDelete = noop,
    onWarning = noop,
    onFinish = noop,
    confirmDeleteAlert = noop,
    noDeleteAlert,
    showProgressToast,
    showDeleteAlert,
    refetch
  },
  ctx = isoContext
) {
  const { safeQuery, safeDelete, safeUpsert } = ctx;
  const model = records[0].__typename;
  let clearProgressToast;
  try {
    let continueDelete = true,
      shouldShowDeleteAlert = true;
    const isCode = !records[0].id && !!records[0].code;
    const recordIds = records.map(r => (isCode ? r.code : r.id));
    const upperReadableName = modelNameToReadableName(model, {
      plural: records.length > 1,
      upperCase: true
    });
    const locationTypeModels = [
      "equipmentType",
      "containerType",
      "locationType"
    ];

    const createAlert = async (baseModel, targetModel) => {
      shouldShowDeleteAlert = false;
      let msg;
      if (records.length > 1) {
        msg = `These ${modelNameToReadableName(baseModel, { plural: true })} are linked to ${modelNameToReadableName(
          targetModel,
          {
            plural: true
          }
        )}. Are you sure you want to delete them?`;
      } else {
        msg = `This ${modelNameToReadableName(baseModel)} is linked to a ${modelNameToReadableName(
          targetModel
        )}. Are you sure you want to delete it?`;
      }
      continueDelete = await confirmDeleteAlert(msg, { canContinue: true });
    };

    if (locationTypeModels.includes(model)) {
      const hasEquipmentItems = records.some(
        record => record.equipmentItems && record.equipmentItems.length > 0
      );
      const hasContainers = records.some(
        record => record.containers && record.containers.length > 0
      );
      const hasLocations = records.some(
        record => record.locations && record.locations.length > 0
      );
      if (hasContainers || hasEquipmentItems || hasLocations) {
        return window.toastr.error(
          `Cannot delete ${modelNameToReadableName(model)} that is in use.`
        );
      }
    } else if (model === "site") {
      const hasLocation = records.some(
        r => r.locations && r.locations.length > 0
      );
      if (hasLocation) {
        return window.toastr.error(`Cannot delete site that is in use.`);
      }
    } else if (model === "location") {
      const hasEquipment = records.some(
        r => r.equipmentItems && r.equipmentItems.length > 0
      );
      if (hasEquipment) {
        return window.toastr.error(`Cannot delete location that is in use.`);
      }
    } else if (model === "part") {
      // warn if parts are linked to a design
      const designs = await safeQuery(["design", "id name"], {
        useHasura: true,
        variables: {
          where: {
            elements: {
              partId: {
                _in: recordIds
              }
            }
          }
        }
      });
      if (designs.length) {
        shouldShowDeleteAlert = false;
        continueDelete = await confirmDeleteAlert(
          `Are you sure you want to delete ${pluralIfNeeded(
            "part",
            recordIds
          )} that ${records.length > 1 ? "are" : "is"} used by ${pluralIfNeeded("design", designs)}: ${designs.map(n => n.name).join(", ")}.`,
          {
            canEscapeKeyCancel: true,
            confirmButtonText: "OK",
            canContinue: true
          }
        );
      }
    } else if (model === "sequence") {
      // warn if trying to delete sequences that are linked to materials
      const qb = new QueryBuilder("aliquot");
      const filter = qb
        .whereAll({
          "sample.material.polynucleotideMaterialSequence.id": recordIds
        })
        .orWhereAll({
          "sample.material.microbialMaterialMicrobialMaterialPlasmids.polynucleotideMaterial.polynucleotideMaterialSequence.id":
            recordIds
        })
        .toJSON();
      const aliquots = await safeQuery(["aliquot", "id"], {
        variables: {
          pageSize: 1,
          filter
        }
      });
      if (aliquots.length > 0) {
        const warningMessage = `Cannot delete ${modelNameToReadableName(model, {
          plural: true
        })} that are linked to materials with aliquots.`;
        return onWarning(warningMessage);
      } else {
        const qb = new QueryBuilder("material");
        const filter = qb
          .whereAll({
            "polynucleotideMaterialSequence.id": recordIds
          })
          .orWhereAll({
            "microbialMaterialMicrobialMaterialPlasmids.polynucleotideMaterial.polynucleotideMaterialSequence.id":
              recordIds
          })
          .toJSON();
        // qb.whereAny()
        const materials = await safeQuery(["material", "id"], {
          variables: {
            pageSize: 1,
            filter
          }
        });

        // warn if trying to delete seqs that are linked to designs
        const designs = await safeQuery(["design", "id name"], {
          useHasura: true,
          variables: {
            where: {
              elements: {
                part: {
                  sequenceId: {
                    _in: recordIds
                  }
                }
              }
            }
          }
        });
        if (designs.length && materials.length) {
          shouldShowDeleteAlert = false;
          continueDelete = await confirmDeleteAlert(
            `Are you sure you want to delete ${pluralIfNeeded(
              "sequence",
              recordIds
            )} that ${records.length > 1 ? "are" : "is"} used by ${pluralIfNeeded("design", designs)} (${designs.map(n => n.name).join(", ")}) and is linked to${records.length > 1 ? "" : " a"} ${pluralIfNeeded("material", records)}?`,
            {
              canEscapeKeyCancel: true,
              confirmButtonText: "OK",
              canContinue: true
            }
          );
        } else if (designs.length) {
          shouldShowDeleteAlert = false;
          continueDelete = await confirmDeleteAlert(
            `Are you sure you want to delete ${pluralIfNeeded(
              "sequence",
              recordIds
            )} that ${records.length > 1 ? "are" : "is"} used by ${pluralIfNeeded("design", designs)}: ${designs.map(n => n.name).join(", ")}?`,
            {
              canEscapeKeyCancel: true,
              confirmButtonText: "OK",
              canContinue: true
            }
          );
        } else if (materials.length) {
          await createAlert("sequence", "material");
        } else {
          const sequenceCds = await safeQuery(
            ["sequenceCodingSequence", "id"],
            {
              variables: {
                pageSize: 1,
                filter: {
                  sequenceId: recordIds
                }
              }
            }
          );
          if (sequenceCds.length) {
            await createAlert("sequence", "aminoAcidSequence");
          }
        }
      }
    } else if (model === "extendedProperty") {
      shouldShowDeleteAlert = false;

      const getExistenceCheck = async table => {
        const res = await safeQuery([table, "id"], {
          variables: {
            pageSize: 1,
            filter: {
              extendedPropertyId: records.map(r => r.id)
            }
          }
        });
        return !!res.length;
      };

      const hasAnyValue =
        (await getExistenceCheck("extendedValue")) ||
        (await getExistenceCheck("extendedCategoryValue")) ||
        (await getExistenceCheck("extendedMeasurementValue"));

      if (hasAnyValue) {
        const msg = `${pluralIfNeeded(
          "This",
          records
        )} extended ${pluralIfNeeded("property", records)} ${pluralIfNeeded(
          "is",
          records
        )} in use. Are you sure you want to delete ${pluralIfNeeded(
          "this",
          records
        )} extended ${pluralIfNeeded(
          "property",
          records
        )}? You will not be able to restore ${
          records.length > 1 ? "them" : "it"
        } later.`;
        continueDelete = await confirmDeleteAlert(msg, {
          canEscapeKeyCancel: true,
          canContinue: true
        });
      } else {
        shouldShowDeleteAlert = true;
      }
    } else if (model === "material") {
      const aliquotsWithMaterial = await safeQuery(["aliquot", "id"], {
        variables: {
          pageSize: 1,
          filter: {
            "sample.materialId": recordIds
          }
        }
      });
      if (aliquotsWithMaterial.length) {
        let msg;
        if (recordIds.length > 1) {
          msg =
            "Cannot delete materials because they are linked to aliquots in the system. Please remove these first before deleting the materials.";
        } else {
          msg =
            "Cannot delete material because it is linked to aliquots in the system. Please remove these first before deleting the material.";
        }
        await confirmDeleteAlert(msg, {
          canEscapeKeyCancel: true,
          cancelButtonText: null,
          confirmButtonText: "OK",
          canContinue: false
        });
        return;
      } else {
        const qb = new QueryBuilder("reaction");
        const filter = qb
          .whereAny(
            {
              "reactionInputs.inputMaterialId": recordIds
            },
            {
              "reactionOutputs.outputMaterialId": recordIds
            }
          )
          .toJSON();
        const reactions = await safeQuery(["reaction", "id"], {
          variables: {
            pageSize: 1,
            filter: filter
          }
        });
        if (reactions.length) {
          await confirmDeleteAlert(
            `Cannot delete ${pluralIfNeeded(
              "material",
              recordIds
            )} that ${pluralIfNeeded("is", recordIds)} used by a reaction map.`,
            {
              canEscapeKeyCancel: true,
              confirmButtonText: "OK",
              cancelButtonText: null,
              canContinue: false
            }
          );
          return;
        } else {
          const fpus = await safeQuery(["materialFpu", "id"], {
            variables: {
              pageSize: 1,
              filter: {
                polynucleotideMaterialId: recordIds
              }
            }
          });
          if (fpus.length) {
            await createAlert("material", "functionalProteinUnit");
          }
        }
      }
    } else if (model === "additiveMaterial" || model === "lot") {
      let filter;
      if (model === "additiveMaterial") {
        const qb = new QueryBuilder("additive");
        filter = qb
          .whereAll({
            additiveMaterialId: recordIds
          })
          .orWhereAll({
            "lot.additiveMaterialId": recordIds
          })
          .toJSON();
      } else {
        filter = {
          lotId: recordIds
        };
      }
      const additivesWithAdditiveMaterialOrLot = await safeQuery(
        ["additive", "id"],
        {
          variables: {
            pageSize: 1,
            filter
          }
        }
      );
      if (additivesWithAdditiveMaterialOrLot.length) {
        let msg;
        const readableName = modelNameToReadableName(model, {
          plural: recordIds.length > 1
        });
        if (recordIds.length > 1) {
          msg = `Cannot delete ${readableName} because they are linked to additives in the system. Please remove these first before deleting the ${readableName}.`;
        } else {
          msg = `Cannot delete ${readableName} because it is linked to additives in the system. Please remove these first before deleting the ${readableName}.`;
        }
        await confirmDeleteAlert(msg, {
          canEscapeKeyCancel: true,
          confirmButtonText: "OK",
          cancelButtonText: null,
          canContinue: false
        });
        return;
      } else if (model === "additiveMaterial") {
        const qb = new QueryBuilder("reaction");
        const filter = qb
          .whereAny(
            {
              "reactionInputs.inputAdditiveMaterialId": recordIds
            },
            {
              "reactionOutputs.outputAdditiveMaterialId": recordIds
            }
          )
          .toJSON();
        const reactions = await safeQuery(["reaction", "id"], {
          variables: {
            pageSize: 1,
            filter: filter
          }
        });
        if (reactions.length) {
          await confirmDeleteAlert(
            `Cannot delete ${pluralIfNeeded(
              "reagent",
              recordIds
            )} that ${pluralIfNeeded("is", recordIds)} used by a reaction map.`,
            {
              canEscapeKeyCancel: true,
              confirmButtonText: "OK",
              cancelButtonText: null,
              canContinue: false
            }
          );
          return;
        }
      }
    } else if (model === "sample") {
      const aliquotsWithSample = await safeQuery(["aliquot", "id"], {
        variables: {
          pageSize: 1,
          filter: {
            sampleId: recordIds
          }
        }
      });
      if (aliquotsWithSample.length) {
        let msg;
        if (recordIds.length > 1) {
          msg =
            "Cannot delete samples because they are linked to aliquots in the system. Please remove these first before deleting the samples.";
        } else {
          msg =
            "Cannot delete sample because it is linked to aliquots in the system. Please remove these first before deleting the sample.";
        }
        await confirmDeleteAlert(msg, {
          canEscapeKeyCancel: true,
          confirmButtonText: "OK",
          cancelButtonText: null,
          canContinue: false
        });
        return;
      }
    } else if (model === "location") {
      const equipmentAtLocation = await safeQuery(["equipmentItem", "id"], {
        variables: {
          pageSize: 1,
          filter: {
            locationId: recordIds
          }
        }
      });
      if (equipmentAtLocation.length) {
        const msg = `Cannot delete location${
          recordIds.length > 1 ? "s" : ""
        } because there is equipment at ${
          recordIds.length > 1 ? "these" : "this"
        } location${recordIds.length > 1 ? "s" : ""}.`;
        await confirmDeleteAlert(msg, {
          canEscapeKeyCancel: true,
          confirmButtonText: "OK",
          cancelButtonText: null,
          canContinue: false
        });
        return;
      }
    } else if (model === "functionalProteinUnit") {
      const qb = new QueryBuilder("aliquot");
      const filter = qb
        .whereAll({
          "sample.material.functionalProteinUnit.id": recordIds
        })
        .toJSON();
      const aliquots = await safeQuery(["aliquot", "id"], {
        variables: {
          pageSize: 1,
          filter
        }
      });
      if (aliquots.length > 0) {
        return window.toastr.error(
          `Cannot delete ${modelNameToReadableName(model, {
            plural: true
          })} that are linked to materials with aliquots.`
        );
      }
    }

    if (
      shouldShowDeleteAlert &&
      !noAlertModels.includes(model) &&
      !noDeleteAlert
    ) {
      continueDelete = await showDeleteAlert({
        model,
        plural: records.length > 1,
        records
      });
    }
    if (continueDelete) {
      if (records.length > 1 && showProgressToast) {
        clearProgressToast = await showProgressToast(
          `Deleting ${upperReadableName}...`
        );
      }
      await beforeDelete(records);
      if (model === "sequence") {
        const normalSequenceIds = records
          .filter(s => s.isJ5Sequence === false && s.isInLibrary === true)
          .map(ns => ns.id);

        const savedConstructIds = records
          .filter(s => s.isJ5Sequence === true && s.isInLibrary === true)
          .map(sc => sc.id);
        await safeDelete(model, normalSequenceIds);
        await safeUpsert(
          model,
          savedConstructIds.map(id => ({
            id,
            isInLibrary: false
          }))
        );
      } else if (handleDelete) {
        await handleDelete(records);
      } else {
        await safeDelete(model, recordIds, { isCode });
      }
      await afterDelete(records);
      if (refetch) {
        await refetch();
      } else if (onFinish) {
        await onFinish({ model, records });
      }
    }
  } catch (error) {
    if (error.message === "userCancelled") return;
    console.error("error:", error);
    window.toastr.error(`Error deleting ${modelNameToReadableName(model)}`);
  }
  if (clearProgressToast) {
    clearProgressToast();
  }
}
