/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { change } from "redux-form";
import ReactJson from "react-json-view";
import classNames from "classnames";
import HeaderWithHelper from "../../../components/HeaderWithHelper";
import { Intent, Button } from "@blueprintjs/core";
import { InputField, TextareaField } from "@teselagen/ui";
import { EvolveConfig } from "../../../configs/config";
import cypressTags from "../../../configs/cypressTags.json";
import createModelStepFormStyles from "../createModelStepFormStyles.module.css";
import SelectDescriptorsAndTargets from "../selectDescriptorsAndTargets";
import SelectAminoAcidSequences from "../selectAminoAcidSequences";
import stepFormValues from "../../../../src-shared/stepFormValues";

class ConfigurationStep extends React.PureComponent {
  state = {
    isValid: false
  };

  renderAdvancedConfigurations = modelConfiguration => {
    const {
      stepFormProps: { change }
    } = this.props;

    return (
      <React.Fragment>
        <div className={classNames("tg-form-section", "column")}>
          <HeaderWithHelper
            header="Model Configuration"
            helper="Tune your Generative model hyper-paramters."
          />
          <ReactJson
            src={modelConfiguration}
            onEdit={updatedConfig => {
              change("modelConfiguration", updatedConfig.updated_src);
            }}
          />
        </div>
      </React.Fragment>
    );
  };

  render() {
    const { Footer, footerProps } = this.props;

    const setIsValid = booleanValue => {
      this.setState({
        isValid: booleanValue
      });
    };

    const advancedModeEnabled =
      this.props.modelDescription ===
      EvolveConfig.constants.GENERATIONS_ADVANCED_MODE_SECRET;

    return (
      <React.Fragment>
        <div className="tg-step-form-section column">
          <div className="tg-flex justify-space-between">
            <HeaderWithHelper header="Name and description" />
          </div>
          <InputField
            isRequired
            name="modelName"
            placeholder="Name your analysis."
            label="Name"
            className={createModelStepFormStyles.textFieldName}
          />
          <TextareaField
            id={cypressTags.modelDescription}
            name="modelDescription"
            label="Description"
            placeholder="Give your analysis a description."
          />
        </div>
        {this.props.modelType === EvolveConfig.constants.GENERATIVE_MODEL ? (
          <SelectAminoAcidSequences {...this.props} setIsValid={setIsValid} />
        ) : (
          <SelectDescriptorsAndTargets
            {...this.props}
            setIsValid={setIsValid}
          />
        )}
        {advancedModeEnabled &&
          this.renderAdvancedConfigurations(this.props.modelConfiguration)}
        <Footer
          {...footerProps}
          submitting={false}
          nextButton={
            <Button
              id={cypressTags.STEP_2_SUBMIT_BUTTON_ID}
              type="submit"
              intent={Intent.SUCCESS}
              text="Submit"
              disabled={!this.state.isValid}
            />
          }
        />
      </React.Fragment>
    );
  }
}

export default compose(
  stepFormValues(
    "modelData",
    "isStandAlone",
    "modelDataColumnSchema",
    "aminoAcidSequencesColumnName",
    "descriptorList",
    "targetList",
    "unassignedList",
    "selectedColumns",
    "descriptorTable",
    "unassignedTable",
    "targetTable",
    "modelType",
    "dataModule",
    "dataFromFileUpload",
    "modelName",
    "modelDescription",
    "showTableWithData",
    "validationObjectDescriptors",
    "validationObjectTargets",
    "modelConfiguration",
    "filterInvalidSequences",
    "filteredSequences"
  ),
  connect(null, { changeFormValue: change })
)(ConfigurationStep);
