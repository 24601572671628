/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { Icon, IconName, MenuItem } from "@blueprintjs/core";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { MenuItemLink, InfoHelper } from "@teselagen/ui";
import { startsWith } from "lodash";
import classNames from "classnames";
import { DisabledToolkitPopover } from "../DisabledToolkitPopover";

type Props = {
  icon?: IconName;
  name: string;
  navTo?: string;
  menuDescription?: string;
  className?: string;
  activeOn?: string[] | (() => boolean);
  disabled: {
    toolkitName: string;
    disabledToolkitUrl: string;
  } & true;
  isOpen: boolean;
  exactNavToMatch?: boolean;
  onNav?: () => void;
  setIsOpen?: (isOpen: boolean) => void;
  hasMenu: boolean;
};

const HeaderLinkButton = ({
  icon,
  name,
  navTo,
  menuDescription,
  className,
  activeOn: _activeOn,
  disabled,
  history,
  isOpen,
  exactNavToMatch,
  onNav,
  setIsOpen,
  hasMenu
}: Props & RouteComponentProps) => {
  let isActive = false;
  const activeOn = _activeOn || (navTo && [navTo]);
  if (activeOn) {
    if (activeOn instanceof Array) {
      const baseStr = history.createHref({ pathname: "/" });
      isActive = activeOn.some(str => {
        if (!str) return false;
        let windowPath = window.location.pathname;

        let pathStr = history.createHref({ pathname: str });
        if (!startsWith(pathStr, "/")) {
          pathStr = "/" + pathStr;
        }

        if (!startsWith(windowPath, "/")) {
          windowPath = "/" + windowPath;
        }
        if (pathStr === baseStr) {
          return windowPath === pathStr;
        }
        return exactNavToMatch
          ? pathStr === windowPath
          : startsWith(windowPath, pathStr);
      });
    } else if (typeof activeOn === "function") {
      isActive = activeOn();
    } else {
      console.error(
        "activeOn should either be an array of path substrings or a function"
      );
    }
  }

  const MenuItemComp = navTo ? MenuItemLink : MenuItem;

  return (
    <>
      <DisabledToolkitPopover disabled={disabled}>
        <MenuItemComp
          {...(navTo ? { navTo } : {})} //tnw - important to spread it like this to avoid react unrecognized prop warning
          id={navTo ? navTo : name}
          disabled={disabled}
          active={isActive}
          icon={icon}
          labelElement={
            !hasMenu ? null : isOpen ? (
              <Icon
                icon="chevron-down"
                iconSize={20}
                className={"header-link-button-icon"}
              />
            ) : (
              <Icon
                icon="chevron-right"
                iconSize={20}
                className={"header-link-button-icon"}
              />
            )
          }
          text={
            <div
              style={{
                cursor: "pointer",
                width: "100%",
                color: "inherit",
                textDecoration: "none",
                display: "flex"
              }}
            >
              {name}
              {isOpen && menuDescription && (
                <InfoHelper style={{ marginLeft: 10, opacity: 0.5 }}>
                  {menuDescription}
                </InfoHelper>
              )}
            </div>
          }
          className={classNames("header-link-button", {
            "header-link-button-open": isOpen,
            className
          })}
          onClick={() => {
            if (setIsOpen) {
              setIsOpen(!isOpen);
            }
            if (navTo) {
              onNav && onNav();
            }
          }}
        />
      </DisabledToolkitPopover>
    </>
  );
};

export default withRouter(HeaderLinkButton);
