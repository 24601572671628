/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { handleActions } from "redux-actions";
import actions from "../../redux-shared/actions";

const initialState = {
  active: false
};

export const segment = handleActions(
  {
    [actions.platform.segment.active.toggle as string]: ({ active }) => ({
      active: !active
    })
  },
  initialState
);
