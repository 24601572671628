/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

export default gql`
  fragment externalRecordTypeFragment on externalRecordType {
    id
    name
    urlTemplate
    identifierTypeCode
    externalSourceSystem {
      name
      url
      id
    }
  }
`;
