/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose } from "redux";
import { withTableParams } from "@teselagen/ui";
import withQuery from "../../../../../src-shared/withQuery";

import labelFormatFragment from "../../../../graphql/fragments/labels/labelFormatFragment";
import LabelSettingsPanel from "../LabelSettingsPanel";
import dataTableSelectFilterMenu from "../../../../../src-shared/dataTableSelectFilterMenu";

const FilterMenu = dataTableSelectFilterMenu({
  options: [
    {
      value: "Plate",
      label: "Plate"
    },
    {
      value: "Tube",
      label: "Tube"
    }
  ],
  paramKey: "labelType",
  label: "Type"
});

const schema = {
  model: "labelFormat",
  fields: [
    {
      path: "name",
      type: "string",
      displayName: "Name"
    },
    {
      path: "isContainerArray",
      displayName: "Type",
      type: "boolean",
      FilterMenu,
      filterKey: "labelType",
      filterIsActive: currentParams => currentParams.labelType,
      render: isContainerArray => (isContainerArray ? "Plate" : "Tube")
    },
    {
      path: "labelFormatLabelMedias.labelMedia.name",
      displayName: "Label Media",
      render: (v, r) => r.labelFormatLabelMedias.map(j => j.labelMedia.name)
    }
  ]
};

export function getDialogInitialValues(x) {
  if (!x) return x;
  return {
    ...x,
    labelMediaIds: x.labelFormatLabelMedias.map(y => y.labelMedia.id)
  };
}

function LabelFormatPanel(props) {
  return (
    <LabelSettingsPanel
      model="labelFormat"
      refetch={props.data.refetch}
      getDialogInitialValues={getDialogInitialValues}
      {...props}
    />
  );
}

export default compose(
  withTableParams({
    urlConnected: false,
    schema,
    formName: "LabelFormatPanelTable",
    withDisplayOptions: true,
    additionalFilter: (props, qb, currentParams) => {
      qb.andWhereAll({
        ...(currentParams.labelType && {
          isContainerArray: currentParams.labelType === "Plate"
        })
      });
    }
  }),
  withQuery(labelFormatFragment, {
    isPlural: true
  })
)(LabelFormatPanel);
