/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { SHAPE_HEIGHT, SHAPE_EXTRA_WIDTH, SHAPE_WIDTH } from "../../constants";
import SBOLIcons from "../../../SBOLIcons/SBOLIcons";
import determineBlackOrWhiteTextColor from "../../../../../src-shared/utils/determineBlackOrWhiteTextColor";

const commonDefaultProps = {
  text: "",
  textColor: "black",
  stroke: "black",
  strokeWidth: 1,
  fill: "none",
  x: 0,
  y: 0,
  onDoubleClick: () => {}
};

class OriginClass extends React.Component {
  static defaultProps = {
    ...commonDefaultProps
  };
  render() {
    let { fill, textColor } = this.props;
    const {
      text,
      stroke,
      strokeWidth,
      onDoubleClick,
      alternativeContent,
      isOver,
      canDrop,
      item
    } = this.props;

    if (isOver && canDrop && item) {
      fill = item.color;
      textColor = determineBlackOrWhiteTextColor(item.color);
    }

    return (
      <g>
        <path
          transform={`translate(${SHAPE_EXTRA_WIDTH / 2}, ${SHAPE_HEIGHT / 2})`}
          fill={fill}
          stroke={stroke}
          strokeWidth={strokeWidth}
          d={SBOLIcons["ORIGIN-OF-REPLICATION"].path}
        />
        {alternativeContent || (
          <text
            style={{ cursor: "pointer" }}
            x={(SHAPE_WIDTH + SHAPE_EXTRA_WIDTH) / 2}
            y={-SHAPE_HEIGHT}
            textAnchor="middle"
            alignmentBaseline="middle"
            fill={textColor}
            onDoubleClick={onDoubleClick}
          >
            {text}
          </text>
        )}
      </g>
    );
  }
}

export default OriginClass;
