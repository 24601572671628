/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { Button, Intent } from "@blueprintjs/core";
import { InputField, ReactSelectField } from "@teselagen/ui";
import { reduxForm } from "redux-form";
import { compose, withProps } from "recompose";
import { safeUpsert } from "../apolloMethods";
import fullUserFragment from "../graphql/fragments/fullUserFragment.gql";

import "./style.css";
import PhoneInput, {
  getFullPhoneNumber,
  normalizePhoneNumber,
  validatePhoneNumber
} from "../PhoneInput";
import { parsePhoneNumber } from "react-phone-number-input";

const ChangeProfileForm = ({
  currentUser,
  refetchCurrentUser,
  handleSubmit,
  submitting
}) => {
  return (
    <form
      onSubmit={handleSubmit(async data => {
        try {
          data.id = currentUser.id;
          const values = {
            ...data,
            phoneNumber: getFullPhoneNumber(data)
          };
          delete values.countryCode;
          await safeUpsert(fullUserFragment, values);
          await refetchCurrentUser();
          window.toastr.success("Profile successfully updated");
        } catch (error) {
          console.error("error:", error);
          window.toastr.error("Error updating profile");
        }
      })}
    >
      <InputField
        label="First Name"
        name="firstName"
        placeholder="Enter first name..."
        leftIcon="user"
      />
      <InputField
        label="Last Name"
        name="lastName"
        placeholder="Enter last name..."
        leftIcon="user"
      />
      <PhoneInput
        label="Phone Number"
        showErrorIfUntouched
        name="phoneNumber"
      />
      {!window.frontEndConfig.disabledToolkits.advancedMolecularBiology && (
        <ReactSelectField
          label="Preferred Design Editor View Mode"
          name="preferredDesignViewMode"
          options={[
            {
              label: "standard",
              value: "classic"
            },
            {
              label: "horizontal (hierarchical)",
              value: "horizontal"
            },
            {
              label: "vertical (hierarchical)",
              value: "vertical"
            }
          ]}
          defaultValue="classic"
        />
      )}
      <div className="settings-footer">
        <Button
          intent={Intent.PRIMARY}
          type="submit"
          loading={submitting}
          text="Update Profile"
        />
      </div>
    </form>
  );
};

const validate = values => {
  const errors = {};
  if (!values.firstName) {
    errors.firstName = "Required";
  }
  if (!values.lastName) {
    errors.lastName = "Required";
  }
  errors.phoneNumber = validatePhoneNumber(values);

  return errors;
};

const formConnectedChangeProfileForm = compose(
  withProps(({ currentUser }) => {
    const phoneVals = {
      phoneNumber: currentUser.phoneNumber
    };
    if (currentUser.phoneNumber) {
      const parsed = parsePhoneNumber(currentUser.phoneNumber);
      if (parsed) {
        if (parsed.country) {
          phoneVals.countryCode = parsed.country;
          phoneVals.phoneNumber = normalizePhoneNumber(
            parsed.nationalNumber,
            parsed.country
          );
        }
      }
    }
    return {
      initialValues: {
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
        preferredDesignViewMode: currentUser.preferredDesignViewMode,
        ...phoneVals
      }
    };
  }),
  reduxForm({
    enableReinitialize: true,
    form: "ChangeProfileForm",
    validate
  })
)(ChangeProfileForm);

export default formConnectedChangeProfileForm;
