/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { useEffect, useCallback } from "react";

import GenericSelect from "../../../../../src-shared/GenericSelect";
import { compose } from "recompose";

import stepFormValues from "../../../../../src-shared/stepFormValues";
import { RadioGroupField, ReactSelectField } from "@teselagen/ui";
import { getFeatureTypes } from "@teselagen/sequence-utils";
import { size } from "lodash";
import { safeQuery } from "../../../../../src-shared/apolloMethods";
import { targetSequenceFragment } from "../fragments.gql";
import type { TargetSequenceFragment } from "../fragments.gql.generated";
import { InjectedStepFormValuesProps } from "../../../../../src-shared/InjectedStepFormValuesProps";
import { InjectedStepFormProps } from "../../../../../src-shared/StepForm";
import { FormData } from "..";
import HeaderWithHelper from "../../../HeaderWithHelper";

type InjectedProps = {
  sequenceIds: string;
  annotationTypes: Object;
} & InjectedStepFormValuesProps<
  FormData,
  "targetSequences" | "annotationType" | "featureType"
> &
  InjectedStepFormProps<FormData>;

const targetSequenceSchema = [
  "name",
  "description",
  {
    path: "size",
    render: (value: TargetSequenceFragment["size"]) => `${value} bp`
  }
];

const SelectTargetsStep = (props: InjectedProps) => {
  const {
    stepFormProps: { change },
    targetSequences,
    annotationTypes,
    annotationType,
    Footer,
    footerProps,
    sequenceIds
  } = props;

  const getSequences = useCallback(
    async (sequenceIds: string) => {
      const targetSequences = await safeQuery<TargetSequenceFragment>(
        targetSequenceFragment,
        {
          useHasura: true,
          variables: {
            where: { id: { _in: sequenceIds.split(",") } }
          }
        }
      );
      change("targetSequences", targetSequences);
    },
    [change]
  );

  useEffect(() => {
    if (size(sequenceIds)) {
      getSequences(sequenceIds);
    }
  }, [sequenceIds, getSequences]);

  const renderSequenceSelect = (
    <div className="tg-step-form-section column">
      <HeaderWithHelper header="Select Target sequences to annotate" />
      <div>
        <GenericSelect
          {...{
            name: "targetSequences",
            isRequired: true,
            isMultiSelect: true,
            schema: targetSequenceSchema,
            fragment: targetSequenceFragment,
            postSelectDTProps: {
              formName: "seqAnnotateToolTargetSequences",
              schema: targetSequenceSchema
            },
            onFieldSubmit: (values: TargetSequenceFragment[]) => {
              change("targetSequences", values);
            }
          }}
        />
      </div>
    </div>
  );

  const renderTypeSelect = (
    <div className="tg-step-form-section column">
      <HeaderWithHelper header="What type of annotations would you like to add?" />
      <div className="tg-flex">
        <RadioGroupField
          data-test="annotationType"
          name="annotationType"
          defaultValue={annotationType || "sequenceFeature"}
          onChange={(value: string) =>
            value === "part" && change("featureType", null)
          }
          options={Object.entries(annotationTypes).map(([value, label]) => ({
            label,
            value
          }))}
        />
        <div style={{ margin: "5px 0 0 20px" }}>
          <ReactSelectField
            tooltipInfo="The Default Feature Type is used ONLY if the feature being added does NOT already have an annotation type. If no Default Feature Type is chosen, the misc_feaure type will be applied to any newly added features that don't already have a type."
            data-test="featureType"
            inlineLabel
            creatable
            className="column"
            name="featureType"
            label="Choose a default feature type (Optional)"
            placeholder="Select..."
            disabled={annotationType !== "sequenceFeature"}
            options={getFeatureTypes()}
          />
        </div>
      </div>
    </div>
  );

  return (
    <React.Fragment>
      {renderSequenceSelect}
      {targetSequences && renderTypeSelect}
      <Footer
        {...footerProps}
        text="Next"
        disabled={!targetSequences || !annotationType}
      />
    </React.Fragment>
  );
};

export default compose<any, any>(
  stepFormValues("targetSequences", "annotationType", "featureType")
)(SelectTargetsStep);
