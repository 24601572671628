/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { showConfirmationDialog } from "@teselagen/ui";
import { Button, Callout, Intent } from "@blueprintjs/core";
import { map } from "lodash";
import { deleteRecordsHelper } from "../utils/deleteUtils";
import {
  deleteFlowsAndSubflows,
  getHttpInsByUrlAndTabsAndSubflowsById,
  getNodeRedInfoForIntegrationsById
} from "../../../tg-iso-shared/src/utils/nodeRedUtils";
import { NodeRedTabLink } from "../utils/nodeRedUtils";
import { forEach } from "lodash";
import { pickBy } from "lodash";

export function handleDeleteIntegrationClick({
  setDeleting,
  deleting,
  integration,
  integrations,
  exportIntegration,
  refetchIntegrations,
  setBundleRefetchCounter,
  bundleRefetchCounter
}) {
  return async () => {
    setDeleting({
      ...deleting,
      [integration.id]: true
    });
    try {
      const intIdToFlow = await getNodeRedInfoForIntegrationsById(integrations);
      const {
        tabsByIdForInt = {},
        conflictTabsByIdForInt = {},
        subflowsByIdForInt
      } = intIdToFlow[integration.id];
      const subflowIdsToRemove = {};

      const tabsAndConflictTabsByIdForInt = {
        ...tabsByIdForInt,
        ...conflictTabsByIdForInt
      };

      let deleteAssociatedNodeRedFlows;
      // ask the user if they want to delete associated node-red flows (will auto delete subflows unique to those flows as well)
      const shouldPrompt = map(tabsAndConflictTabsByIdForInt, t => {
        t.inUseByTheseOtherIntegrations = {};
      }).length;

      if (shouldPrompt) {
        //check if the flows are in use by another integration and warn if they are
        map(
          intIdToFlow,
          (
            { tabsByIdForInt: otherIntTabsById, integration: otherIntegration },
            intId
          ) => {
            if (intId === integration.id) {
              return;
            }
            map(otherIntTabsById, (t, id) => {
              if (tabsAndConflictTabsByIdForInt[id]) {
                tabsAndConflictTabsByIdForInt[id].inUseByTheseOtherIntegrations[
                  intId
                ] = otherIntegration;
              }
            });
          }
        );

        const {
          subflowsById,
          tabIdsToSubflowIds
        } = getHttpInsByUrlAndTabsAndSubflowsById(intIdToFlow.__allNodes);

        // we need to find all the subflows that are no longer linked to remaining tabs and get rid of them
        const notRemovedTabsToSubflowIds = pickBy(
          tabIdsToSubflowIds,
          (a, tabid) => {
            // if (subflowsById[tabid]) {
            //   //subflow
            //   forEach(tabsAndConflictTabsByIdForInt, (t, tid)=>{
            //     const subflowIdMap = tabIdsToSubflowIds[tid] || {}
            //     if (subflowIdMap[tabid]) {

            //     }
            //   })
            // }
            // if (subflowsById[tabid]) return false;
            return (
              !tabsAndConflictTabsByIdForInt[tabid] &&
              !subflowsByIdForInt[tabid]
            );
          }
        );
        const subflowsInUseByNonDeletedTabs = {};
        forEach(notRemovedTabsToSubflowIds, subflows => {
          map(subflows, (a, subflowId) => {
            subflowsInUseByNonDeletedTabs[subflowId] = true;
          });
        });
        //we need to find which subflows to delete based on the tabs being deleted
        //we don't want to delete any subflows that are still in use by a tab
        //subflows can also link to other subflows so we don't want to inadvertantly delete one that is in use by a linked subflow

        //loop thru the tabs that will be deleted
        forEach(tabsAndConflictTabsByIdForInt, (t, id) => {
          map(tabIdsToSubflowIds[id], (a, subflowId) => {
            if (!subflowsInUseByNonDeletedTabs[subflowId]) {
              subflowIdsToRemove[subflowId] = true;
            }
          });
        });

        let inUseByOthers;
        deleteAssociatedNodeRedFlows = await showConfirmationDialog({
          text: (
            <div>
              <h5>
                Do you want to delete the following node-red flows associated
                with this integration?
              </h5>
              {map(
                tabsAndConflictTabsByIdForInt,
                ({ label, id, inUseByTheseOtherIntegrations }) => {
                  if (map(inUseByTheseOtherIntegrations).length) {
                    inUseByOthers = true;
                  }
                  return (
                    <div key={id}>
                      <NodeRedTabLink
                        text={
                          <span>
                            {label}{" "}
                            {map(inUseByTheseOtherIntegrations).length ? (
                              <span>
                                (Also in use by{" "}
                                {map(
                                  inUseByTheseOtherIntegrations,
                                  int => int.name
                                ).join(", ")}
                                )
                              </span>
                            ) : null}
                          </span>
                        }
                        tabId={id}
                      ></NodeRedTabLink>
                    </div>
                  );
                }
              )}{" "}
              {map(subflowIdsToRemove, (a, id) => (
                <div style={{ display: "flex" }} key={id}>
                  Subflow - &nbsp;
                  <NodeRedTabLink
                    text={subflowsById[id].name}
                    tabId={id}
                  ></NodeRedTabLink>{" "}
                </div>
              ))}
              {/* {!isEmpty(subflowIdsToRemove) && (
                <div>
                </div>
              )} */}
              <br></br>
              <Callout intent="primary">
                We recommend deleting the node-red flows, but make sure you have
                a back-up saved if you've added custom logic to your node-red
                flows.<br></br>
                <Button
                  style={{ marginTop: 10 }}
                  icon="download"
                  onClick={exportIntegration}
                  small
                >
                  Download a backup
                </Button>
              </Callout>{" "}
              {inUseByOthers ? (
                <Callout style={{ marginTop: 10 }} intent="danger">
                  Also note that the flows being deleted are IN USE by other
                  integrations. See above for the list
                </Callout>
              ) : null}
            </div>
          ),
          intent: Intent.DANGER,
          confirmButtonText: "Delete Flows",
          thirdButtonText: "Continue w/o Removing Flows",
          canEscapeKeyCancel: true
        });
        if (!deleteAssociatedNodeRedFlows) {
          setDeleting(false);
          return;
        }
      }

      await deleteRecordsHelper({
        records: [integration],
        noDeleteAlert: shouldPrompt
      });
      if (deleteAssociatedNodeRedFlows !== "thirdButtonClicked") {
        try {
          await deleteFlowsAndSubflows({
            tabsByIdToDelete: tabsAndConflictTabsByIdForInt,
            subflowIdsToRemove
          });
        } catch (e) {
          window.toastr.error(
            "Error deleting node-red flows. You'll need to manually delete the linked flows"
          );
          console.error(`e:`, e);
        }
      }

      refetchIntegrations && (await refetchIntegrations());
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error deleting integration.");
    }

    const newDeleting = {
      ...deleting
    };
    delete newDeleting[integration.id];
    setDeleting(newDeleting);
    setBundleRefetchCounter(bundleRefetchCounter + 1);
  };
}
