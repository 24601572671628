/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { createActions } from "redux-actions";
import notificationActions from "./notificationActions";

const identity = (payload: boolean) => payload;
const actionCreators = createActions({
  PLATFORM: {
    UI: {
      THEME: {
        TOGGLE: identity
      }
    },
    SEGMENT: {
      ACTIVE: {
        TOGGLE: identity
      }
    }
  }
}) as unknown as {
  platform: {
    ui: {
      theme: { toggle: string | (() => void) };
    };
    segment: {
      active: { toggle: string | (() => void) };
    };
  };
};

const allActions = {
  ...notificationActions,
  ...actionCreators
};

export default allActions;
