/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { Switch } from "@blueprintjs/core";
import { DataTable } from "@teselagen/ui";
import { EvolveConfig } from "../../../../../configs/config";
import cypressTags from "../../../../../configs/cypressTags.json";
import ExportTableAsCsvButton from "../../../../../../src-shared/ExportTableAsCsvButton";
import { get, orderBy, partition } from "lodash";

const EXPORTED_RESULTS_FILENAME = "evolve_exported_results";

const CSV_NAN_VALUE = "N/A";

// For the DataTable component, entity values accessible via paths
// that contain certain special characters fails. Particularly for brackets.
// TODO: wait for the issue to get fixed: https://github.com/tannerlinsley/react-table/issues/3094/
// In the meantime we can just replace those paths with parenthesis
function replaceSpecialCharsInObjectPaths(object) {
  let objStringified = JSON.stringify(object);
  objStringified = objStringified.replace(/\[/g, "(").replace(/\]/g, ")");
  return JSON.parse(objStringified);
}

class ModelResultsWidget extends Component {
  constructor(props) {
    super(props);
    const { modelType, dataSchema, evolveModelDatapoints } = props;

    const patchedDataSchema = dataSchema.map(obj =>
      replaceSpecialCharsInObjectPaths(obj)
    );
    const dataTableSchema = this.generateDataTableSchema(
      patchedDataSchema,
      modelType
    );
    const datapoints = evolveModelDatapoints.map(obj =>
      obj ? replaceSpecialCharsInObjectPaths(obj) : obj
    );

    this.state = {
      targetName: get(
        patchedDataSchema.filter(column => column.type === "target"),
        "0.name"
      ),
      modelType: modelType,
      dataTableSchema: dataTableSchema,
      datapoints: datapoints,
      onlyRecommendedCandidates: true,
      showTrainingSamples: false
    };
  }

  sortDatapointsByPriority = datapoints => {
    const batchedDatapoints = datapoints.filter(
      datapoint => datapoint.datapoint.in_batch
    );

    const notBatchedDatapoints = datapoints.filter(
      datapoint => !datapoint.datapoint.in_batch
    );

    const sortedBatchedDatapoints = batchedDatapoints.sort(
      (a, b) => Number(a.datapoint.priority) - Number(b.datapoint.priority)
    );
    const sortedDatapoints = [
      ...sortedBatchedDatapoints,
      ...notBatchedDatapoints
    ];

    return sortedDatapoints;
  };

  generateDataTableSchema = (dataSchema, modelType) => {
    const dataTableFields = [];
    if (modelType === EvolveConfig.constants.EVOLUTIVE_MODEL) {
      dataTableFields.push({
        path: "datapoint.priority",
        displayName: "Priority",
        width: 70,
        render: value => {
          if (value === null || value === undefined) {
            // Using the Icon Component generated issues when exporting the table as csv.
            // Every Cell is seen as [Object] [Object] in the spreadsheet.
            return CSV_NAN_VALUE; // <Icon key={index} icon={IconNames.MINUS} />;
          } else {
            return value + 1;
          }
        }
      });
    }

    if (modelType === EvolveConfig.constants.GENERATIVE_MODEL) {
      if (dataSchema.length === 1) dataSchema.push({ id: 2, name: "sigma" });
      const generativeModelDataTableFields =
        this.generateGenerativeModelDataTableFields(dataSchema);

      dataTableFields.push(...generativeModelDataTableFields);
    } else {
      const evolutiveAndPredictiveModelDataTableFields =
        this.generateEvolutiveAndPredictiveModelDataTableFields(dataSchema);

      dataTableFields.push(...evolutiveAndPredictiveModelDataTableFields);
    }

    return { model: "evolveModelDatapoints", fields: dataTableFields };
  };

  generateGenerativeModelDataTableFields = dataSchema => {
    const dataTableFields = [];
    dataSchema.reduce((accumulator, field, currentIndex) => {
      let dataSchemaField;
      if (field.name === "sigma") {
        dataSchemaField = {
          path: `datapoint.${field.name}`,
          displayName: "Diversity",
          render: (value, record) => {
            if (record.datapointType === "input") {
              // Using the Icon Component generated issues when exporting the table as csv.
              // Every Cell is seen as [Object] [Object] in the spreadsheet.
              return CSV_NAN_VALUE; // <Icon key={index} icon={IconNames.MINUS} />;
            } else {
              return value;
            }
          }
        };
      } else {
        dataSchemaField = {
          path: `datapoint.${field.name}`,
          displayName: field.name.charAt(0).toUpperCase() + field.name.slice(1)
        };
      }

      accumulator.push(dataSchemaField);
      if (currentIndex === 0) {
        accumulator[currentIndex + 1] = {
          displayName: "Length",
          render: (value, record) => {
            return record.datapoint.sequence.length;
          }
        };
      }
      return accumulator;
    }, dataTableFields);

    return dataTableFields;
  };

  generateEvolutiveAndPredictiveModelDataTableFields = dataSchema => {
    const dataTableFields = [];

    const targetName = get(
      dataSchema.filter(column => column.type === "target"),
      "0.name"
    );

    dataSchema.reduce((accumulator, field) => {
      const dataSchemaField = {
        path: `datapoint.${field.name}`,
        displayName: field.name
      };

      if (field.type !== "target") {
        accumulator.push(dataSchemaField);
      }
      return accumulator;
    }, dataTableFields);

    dataTableFields.push({
      path: `datapoint.${targetName}`,
      displayName: targetName,
      render: (value, record) => {
        if (
          record.datapoint[targetName] === undefined ||
          record.datapoint[targetName] === null
        ) {
          // Using the Icon Component generated issues when exporting the table as csv.
          // Every Cell is seen as [Object] [Object] in the spreadsheet.
          return CSV_NAN_VALUE; // <Icon key={index} icon={IconNames.MINUS} />;
        } else {
          return value;
        }
      }
    });

    dataTableFields.push({
      path: "datapoint.prediction",
      displayName: `Prediction`,
      width: 100,
      // displayName: `Prediction for ${targetField.name}`,
      render: (value, record) => {
        if (record.datapoint.prediction === undefined) {
          // Using the Icon Component generated issues when exporting the table as csv.
          // Every Cell is seen as [Object] [Object] in the spreadsheet.
          return CSV_NAN_VALUE; // <Icon key={index} icon={IconNames.MINUS} />;
        } else {
          return value;
        }
      }
    });

    if (this.props.modelType === EvolveConfig.constants.EVOLUTIVE_MODEL)
      dataTableFields.push({
        path: "datapoint.acq",
        displayName: `Acquisition`,
        width: 100,
        // displayName: `Prediction for ${targetField.name}`,
        render: (value, record) => {
          if (record.datapoint.acq === undefined) {
            // Using the Icon Component generated issues when exporting the table as csv.
            // Every Cell is seen as [Object] [Object] in the spreadsheet.
            return CSV_NAN_VALUE; // <Icon key={index} icon={IconNames.MINUS} />;
          } else {
            return value;
          }
        }
      });

    dataTableFields.push({
      path: `datapoint.${targetName}`,
      displayName: `Training Sample`,
      width: 75,
      // displayName: `Prediction for ${targetField.name}`,
      render: (_, record) => {
        if (get(record, `datapoint.${targetName}`) !== null) {
          return <span style={{ marginLeft: 15 }}>Yes</span>;
        } else {
          return <span style={{ marginLeft: 15 }}>No</span>;
        }
      }
    });

    return dataTableFields;
  };

  modelSubHeader = () => {
    switch (this.props.modelType) {
      case "predictive":
        return (
          <Switch
            id={cypressTags.SHOW_TRAINING_SAMPLES_SWITCH_ID}
            checked={this.state.showTrainingSamples}
            onChange={() =>
              this.setState({
                showTrainingSamples: !this.state.showTrainingSamples
              })
            }
            label="Show training samples"
          />
        );
      case "evolutive":
        return (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Switch
              style={{ marginRight: 20 }}
              id={cypressTags.ONLY_CANDIDATES_SWITCH_ID}
              checked={this.state.onlyRecommendedCandidates}
              onChange={() =>
                this.setState({
                  onlyRecommendedCandidates:
                    !this.state.onlyRecommendedCandidates,
                  showTrainingSamples: false
                })
              }
              label="Only recommended candidates"
            />
            <Switch
              id={cypressTags.SHOW_TRAINING_SAMPLES_SWITCH_ID}
              disabled={this.state.onlyRecommendedCandidates}
              checked={this.state.showTrainingSamples}
              onChange={() =>
                this.setState({
                  showTrainingSamples: !this.state.showTrainingSamples
                })
              }
              label="Show training samples"
            />
          </div>
        );
      default:
        return null;
    }
  };

  applyDatapointsFilters = datapoints => {
    const { modelType, selectedDatapointType } = this.props;
    let filteredDatapoints = [];
    if (selectedDatapointType !== "input") {
      filteredDatapoints = datapoints.filter(
        datapoint => datapoint.datapointType === "output"
      );
      if (modelType === EvolveConfig.constants.GENERATIVE_MODEL) {
        const sigmaDiversityMapper = sigmaValue => {
          if (sigmaValue === 0.01) return "Low";
          else if (sigmaValue === 0.1) return "Medium";
          else if (sigmaValue === 1) return "High";
        };
        filteredDatapoints = filteredDatapoints.map(_datapointObj => {
          return {
            ..._datapointObj,
            datapoint: {
              ..._datapointObj.datapoint,
              sigma: sigmaDiversityMapper(_datapointObj.datapoint.sigma)
            }
          };
        });
      } else {
        // This splits the datapoints into two groups. The first one
        // being the new samples explored by the model and the seconds one
        // corresponds to the training samples.
        // This is done so we can first sort the new samples based on prediction
        // And then concatenate the training samples also ordered by prediction.
        const [newDataPoints, trainingDataPoints] = partition(
          filteredDatapoints,
          datapoint => get(datapoint.datapoint, this.state.targetName) === null
        );
        filteredDatapoints = [
          ...orderBy(
            newDataPoints,
            datapoint => get(datapoint.datapoint, "prediction"),
            "desc"
          )
        ];
        if (modelType === EvolveConfig.constants.EVOLUTIVE_MODEL) {
          if (this.state.onlyRecommendedCandidates) {
            filteredDatapoints = filteredDatapoints.filter(
              datapoint => get(datapoint.datapoint, "priority") !== null
            );
          }
          filteredDatapoints =
            this.sortDatapointsByPriority(filteredDatapoints);
        }
        if (this.state.showTrainingSamples) {
          filteredDatapoints = [
            ...orderBy(
              trainingDataPoints,
              datapoint => get(datapoint.datapoint, "prediction"),
              "desc"
            ),
            ...filteredDatapoints
          ];
        }
      }
    } else {
      filteredDatapoints = orderBy(
        datapoints.filter(datapoint => datapoint.datapointType === "input"),
        datapoint => get(datapoint.datapoint, this.state.targetName),
        "desc"
      );
    }
    return filteredDatapoints;
  };

  render() {
    const { dataTableSchema, datapoints } = this.state;
    const { selectedDatapointType } = this.props;

    const filteredDatapoints = this.applyDatapointsFilters(datapoints);

    return (
      <div style={{ height: "95%" }}>
        <ExportTableAsCsvButton
          key="export"
          filename={EXPORTED_RESULTS_FILENAME}
          noId
          text="Export data"
          tableParams={{
            schema: dataTableSchema,
            entities: filteredDatapoints
          }}
          {...this.props}
        />
        <DataTable
          noHeader
          subHeader={selectedDatapointType !== "input" && this.modelSubHeader()}
          noSelect
          showCount
          formName="modelResultsDataTable"
          schema={dataTableSchema}
          // cellRenderer={cellRenderer}
          entities={filteredDatapoints}
        />
      </div>
    );
  }
}

export default ModelResultsWidget;
