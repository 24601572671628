/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

/* eslint react/jsx-no-bind: 0 */
import React from "react";
import { MenuItem } from "@blueprintjs/core";
import { startCase } from "lodash";
import Helmet from "react-helmet";
import { deleteRecordsHelper } from "../../../utils";
import "./style.css";
import modelNameToReadableName from "../../../../src-shared/utils/modelNameToReadableName";
import { showDialog } from "../../../../src-shared/GlobalDialog";
import UploadLocationSettingsDialog from "./UploadLocationSettingsDialog";
import AbstractLibrary from "../../../../src-shared/AbstractLibrary";
import { getModelNameFromFragment } from "@teselagen/apollo-methods";
import { compose } from "recompose";
import LibraryInspector from "../../../../src-shared/AbstractLibrary/LibraryInspector";
import libraryEnhancer from "../../../../src-shared/libraryEnhancer";

function LocationSettingsPanel(props) {
  const {
    libraryName,
    Dialog,
    data: { refetch },
    model,
    readOnly
  } = props;
  const readableName = modelNameToReadableName(model, {
    upperCase: true
  });

  const toggleEditModal = editRecord => {
    showDialog({
      ModalComponent: Dialog,
      modalProps: {
        refetch,
        model,
        dialogProps: {
          title: editRecord
            ? `Edit ${readableName}`
            : `Create New ${readableName}`
        },
        initialValues: editRecord
      }
    });
  };
  const extraTableParams = {
    contextMenu: readOnly
      ? undefined
      : ({ selectedRecords }) => {
          const record = selectedRecords[0];
          if (!record) return;

          const deleteRecords = () =>
            deleteRecordsHelper({
              records: selectedRecords,
              refetch
            });

          const editRecord = () => {
            toggleEditModal(record);
          };

          const menuItems = [];
          if (selectedRecords.length === 1) {
            menuItems.push(
              <MenuItem
                key="edit"
                icon="edit"
                onClick={editRecord}
                text="Edit"
              />
            );
          }
          menuItems.push(
            <MenuItem
              key="delete"
              icon="trash"
              onClick={deleteRecords}
              text="Delete"
            />
          );
          return menuItems;
        },
    hideSelectedCount: true,
    onDoubleClick: readOnly ? undefined : toggleEditModal
  };
  const upperModelName = startCase(libraryName);

  return (
    <div className="tg-card">
      <Helmet title={upperModelName} />
      <AbstractLibrary
        {...props}
        readOnly={readOnly}
        alwaysShowInspector
        ViewerComponent={ViewerComponent}
        compact
        onNewItemClick={() => toggleEditModal()}
        showUploadFunction={
          model !== "site"
            ? () => {
                showDialog({
                  ModalComponent: UploadLocationSettingsDialog,
                  modalProps: {
                    refetch,
                    model
                  }
                });
              }
            : null
        }
        extraTableParams={extraTableParams}
      />
    </div>
  );
}

const ViewerComponent = props => (
  <LibraryInspector
    renderExtraItemsTop={record => {
      return record.description;
    }}
    {...props}
  />
);

export default function ({ schema, fragment }) {
  const model = getModelNameFromFragment(fragment);
  return compose(
    libraryEnhancer({
      fragment,
      schema,
      isCodeModel: model !== "site",
      withSelectedEntities: true,
      noAddedBy: true
    })
  )(LocationSettingsPanel);
}
