/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useState } from "react";
import Helmet from "react-helmet";
import ApiKeyForm from "./ApiKeyForm";
import { Tooltip, Intent, Icon } from "@blueprintjs/core";
import { clearApiKey, getApiKey } from "../auth/utils";
import { get } from "lodash";
import HeaderWithHelper from "../HeaderWithHelper";

const API_KEY_LABEL = "API OTP";

const ProfilePanel = ({ currentUser }) => {
  const [apiKey, setApiKey] = useState(null);
  const [apiKeyExpiration, setApiKeyExpiration] = useState(
    currentUser?.userLogins[0]?.apiKeyExpiration
  );

  const onSubmit = async () => {
    try {
      const data = await getApiKey();
      setApiKey(data.apiKey);
      setApiKeyExpiration(data.apiKeyExpiration);
    } catch (error) {
      console.error(`error -> ${error}`);
      console.error("error:", error.response);
      const msg = get(error, "response.data.msg");
      window.toastr.error(msg || "Error creating apiKey");
    }
  };

  const _clearApiKey = async () => {
    /* remove apikey from row */
    try {
      await clearApiKey();
      window.toastr.success(`Cleared API OTP for ${currentUser.username}`);
      setApiKey(null);
      setApiKeyExpiration(null);
    } catch (error) {
      console.error(`error -> ${error}`);
      console.error("error:", error.response);
      const msg = get(error, "response.data.msg");
      window.toastr.error(msg || `Error clearing ${API_KEY_LABEL}`);
    }
  };

  return (
    <div>
      <div style={{ display: "inline-flex" }}>
        <Helmet>
          <title>One Time Password (OTP) Settings</title>
        </Helmet>
      </div>

      <div className="tg-card xtra-pad">
        <h3>
          API One Time Password (OTP)
          <Tooltip
            style={{ marginLeft: "1em" }}
            content={
              <div style={{ width: 325 }}>
                <p>
                  <strong>One Time Passwords (OTP)</strong> can be used as login
                  passwords for API access. They can be used by the{" "}
                  <strong>TeselaGen API</strong>,{" "}
                  <strong>TeselaGen Python Client</strong> or a direct HTTP
                  access.
                </p>
                <p>
                  This password last for 1 hour. TeselaGen's clients will just
                  require you to generate an OTP the first time you use them
                  (and after some time to renew your login or after you
                  explicitly logout).
                </p>
                <p>
                  If you are implementing HTTP requests to access the API, the
                  OTP allows you to create an access token that can be used as a
                  header value for some amount of time to authenticate API
                  requests. See more details at the API Documentation.
                </p>
              </div>
            }
          >
            <Icon
              icon="help"
              intent={Intent.PRIMARY}
              style={{ marginLeft: "0.6em", marginBottom: "0.125em" }}
            />
          </Tooltip>
        </h3>
        <div className="settings-footer-l">
          <ApiKeyForm
            {...{ currentUser }}
            header={
              <HeaderWithHelper
                helper={
                  <>
                    View the{" "}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="/client/api-docs/"
                    >
                      API Docs
                    </a>{" "}
                    for more information on how to use the API.
                  </>
                }
                header="Copy the API One Time Password (OTP) now, it will not be presented again."
              />
            }
            apiKeyLabel={API_KEY_LABEL}
            apiKey={apiKey}
            apiKeyExpiration={apiKeyExpiration}
            handleGenerateApiKey={onSubmit}
            handleClearApiKey={_clearApiKey}
          />
        </div>
      </div>
    </div>
  );
};

export default ProfilePanel;
