/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import QueryBuilder from "tg-client-query-builder";

type QueryData = {
  fragment: [string, string];
  schema: (string | { path: string; displayName: string })[];
  additionalFilter: object | ((params: any) => {});
  nameOverride?: string;
};

export const queryData: {
  [key: string]: QueryData;
} = {
  partList: {
    nameOverride: "Parts",
    fragment: ["part", "id name size sequence{id name}"],
    schema: [
      "name",
      "size",
      { path: "sequence.name", displayName: "Origin Sequence" }
    ],
    additionalFilter: {}
  },
  featureList: {
    nameOverride: "Features",
    fragment: ["sequenceFeature", "id name type sequence{id name}"],
    schema: [
      "name",
      "type",
      { path: "sequence.name", displayName: "Origin Sequence" }
    ],
    additionalFilter: {}
  },
  oligoList: {
    nameOverride: "Oligos",
    fragment: ["sequence", "id name size"],
    schema: ["name", "size"],
    additionalFilter: (_props: any, qb: QueryBuilder) => {
      qb.whereAll({
        sequenceTypeCode: "OLIGO"
      });
    }
  },
  featureGroup: {
    nameOverride: "Feature Groups",
    fragment: ["registeredAnnotationGroup", "id name description"],
    schema: ["name", "description"],
    additionalFilter: (_props: any, qb: QueryBuilder) => {
      qb.whereAll({
        registeredAnnotationTypeCode: "FEATURE"
      });
    }
  },
  partGroup: {
    nameOverride: "Part Groups",
    fragment: ["registeredAnnotationGroup", "id name description"],
    schema: ["name", "description"],
    additionalFilter: (_props: any, qb: QueryBuilder) => {
      qb.whereAll({
        registeredAnnotationTypeCode: "PART"
      });
    }
  },
  partsFromSequence: {
    nameOverride: "Sequences",
    fragment: ["sequence", "id name description size"],
    schema: ["name", "description", "size"],
    additionalFilter: (_props: any, qb: QueryBuilder) => {
      qb.whereAll({
        sequenceTypeCode: qb.contains("DNA"),
        "parts.name": qb.notNull()
      });
    }
  },
  featuresFromSequence: {
    nameOverride: "Sequences",
    fragment: ["sequence", "id name description size"],
    schema: ["name", "description", "size"],
    additionalFilter: (_props: any, qb: QueryBuilder) => {
      qb.whereAll({
        sequenceTypeCode: qb.contains("DNA"),
        "sequenceFeatures.name": qb.notNull()
      });
    }
  }
};
