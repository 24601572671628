/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

export const guideRNAFileTypesAndDescriptions = [
  {
    type: ["csv", "xlsx"],
    description: "Teselagen gRNA CSV Format",
    exampleFile: "gRNA_example.csv",
    extendedPropTypes: ["sequence"],
    validateAgainstSchema: {
      fields: [
        {
          path: "Name",
          isUnique: true,
          description: "Name of the sequence",
          example: "example sequence 1",
          isRequired: true
        },
        {
          path: "Spacer Sequence",
          description: "Spacer sequence",
          example: "acgcaugcuagcaugcuagc",
          isRequired: true
        },
        {
          path: "Scaffold Sequence",
          description: "Scaffold sequence",
          example: "ugaucgaucgaugcuagc",
          isRequired: true
        },
        {
          path: "Aliases",
          description: "Aliases for the sequence",
          example: "alias1, alias2"
        },
        {
          path: "Tags",
          description: "Tags for the sequence",
          example: "tag1, tag2"
        },
        {
          path: "Description",
          description: "Description of the sequence",
          example: "Short example to show off CSV import"
        }
      ]
    }
  }
];
