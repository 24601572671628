/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import PropTypes from "prop-types";
import { MenuItem } from "@blueprintjs/core";
import { deleteRecordsHelper } from "../../../../utils";

/**
 * For use inside of a `DataTable` context menu. This means that the
 * render function should return an array instead of an entire `Menu`.
 */
class LabelSettingsContextMenu extends React.Component {
  static propTypes = {
    /**
     * The type of objects in the data table.
     */
    model: PropTypes.string.isRequired,

    /**
     * The selected records of the data table.
     */
    selectedRecords: PropTypes.arrayOf(PropTypes.object).isRequired,

    /**
     * Refetch the items in the data table.
     */
    refetch: PropTypes.func.isRequired,

    /**
     * Function will be called with the signature `editRecord(selectedRecord)`
     * whenever the edit context menu option is clicked.
     */
    editRecord: PropTypes.func.isRequired
  };

  handleDelete = async () => {
    const { selectedRecords, refetch } = this.props;
    deleteRecordsHelper({
      records: selectedRecords,
      refetch
    });
  };

  handleEdit = () => {
    const { editRecord, selectedRecords } = this.props;
    return editRecord(selectedRecords[0]);
  };

  render() {
    const { selectedRecords } = this.props;

    if (!selectedRecords.length) return null;

    const menuItems = [
      <MenuItem
        key="delete"
        icon="trash"
        onClick={this.handleDelete}
        text="Delete"
      />
    ];

    if (selectedRecords.length === 1) {
      menuItems.unshift(
        <MenuItem
          key="edit"
          icon="edit"
          onClick={this.handleEdit}
          text="Edit"
        />
      );
    }

    return menuItems;
  }
}

export default LabelSettingsContextMenu;
