/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import createCachedSelector from "re-reselect"; // TODO: Change to import for vite

const clearCacheFnsHolder = [];
export default function tgCreateCachedSelector(...args) {
  const fnToApply = createCachedSelector(...args);
  return (...ins) => {
    const toRet = fnToApply(...ins);
    clearCacheFnsHolder.push(toRet.clearCache);
    return toRet;
  };
}

export const clearCacheFns = () => {
  clearCacheFnsHolder.forEach(fn => {
    fn();
  });
};
