/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { useEffect } from "react";
import { compose } from "recompose";
import { isEmpty, startCase } from "lodash";
import { MenuItem } from "@blueprintjs/core";
import { DataTable, showConfirmationDialog, SwitchField } from "@teselagen/ui";
import type { TableSchema } from "@teselagen/ui";
import { SimpleCircularOrLinearView } from "@teselagen/ove";
import modelNameToReadableName from "../../../../../src-shared/utils/modelNameToReadableName";
import stepFormValues from "../../../../../src-shared/stepFormValues";
import { showDialog } from "../../../../../src-shared/GlobalDialog";
import EditAnnotation from "../dialogs/EditAnnotationDialog";
import ExportTableAsCsvButton from "../../../../../src-shared/ExportTableAsCsvButton";
import { sequenceToVeInput } from "../../../../../../tg-iso-shared/src/sequence-import-utils/utils";
import { InjectedStepFormValuesProps } from "../../../../../src-shared/InjectedStepFormValuesProps";
import { InjectedStepFormProps } from "../../../../../src-shared/StepForm";
import { AnnotatedSequence, FormData, SequenceAnnotation } from "..";

type Feature = {
  id: string;
  start: number;
  end: number;
  name: string;
};

type InjectedProps = InjectedStepFormValuesProps<
  FormData,
  "targetSequences" | "sequenceAnnotations" | "annotationType"
> &
  InjectedStepFormProps<FormData>;

const annotationsTableSchema: TableSchema[] = [
  {
    path: "id",
    type: "string",
    displayName: "ID",
    description: "Unique identifier of the annotation",
    isHidden: true
  },
  {
    path: "name",
    type: "string",
    displayName: "Name",
    description: "Name, if available, of the matched reference sequence"
  },
  {
    path: "description",
    type: "string",
    displayName: "Description",
    description:
      "Description of the matched reference sequence from source database"
  },
  {
    path: "type",
    type: "string",
    render: value => startCase(value),
    displayName: "Type",
    description:
      "Feature type string associated with this annotation (only valid when creating Features)"
  },
  {
    path: "matchLength",
    type: "number",
    render: value => `${value} bp`,
    displayName: "Match length",
    description: "Size (bps) of the matched string (HSP) obtained by BLAST"
  },
  {
    path: "identity",
    type: "number",
    render: value => `${value.toFixed(1)} %` || "0",
    displayName: "Identity",
    description: "Percentage of matched base pairs within the match range"
  },
  {
    path: "mismatchCount",
    type: "number",
    render: value => value || "0",
    displayName: "Mismatch count",
    description: "Number of mismatches within the matching range"
  },
  {
    path: "referenceLength",
    type: "number",
    render: value => `${value} bp`,
    displayName: "Reference length",
    description:
      "Total size (bps) of the matched reference sequence (subject) in the source database"
  },
  {
    path: "referenceCoverage",
    type: "number",
    render: value => `${value.toFixed(1)} %` || "0",
    displayName: "Reference coverage",
    description:
      "Approximate percentage of the complete subject sequence covered by the current match. The max score (100%) is given to matches that: covers the complete subject sequence at the reference db and scores 100% identity"
  },
  {
    path: "sourceType",
    type: "string",
    render: value => startCase(value),
    displayName: "Source type",
    description: "Type of the reference database"
  },
  {
    path: "sourceDescription",
    type: "string",
    displayName: "Source description",
    description: "An additional description of the reference database"
  }
];

const ReviewAndSubmitStep = (props: InjectedProps) => {
  const {
    targetSequences,
    sequenceAnnotations,
    annotationType,
    stepFormProps: { change }
  } = props;

  const [annotatedSequences, setAnnotatedSequences] = React.useState<
    AnnotatedSequence[]
  >([]);
  const [selectedAnnotatedSequence, setSelectedAnnotatedSequence] =
    React.useState<AnnotatedSequence>();
  const [selectionLayer, setSelectionLayer] = React.useState<Feature | null>(
    null
  );
  const [showExistingAnnotations, setShowExistingAnnotations] =
    React.useState(true);

  useEffect(() => {
    function annotateSequences({
      sequences,
      annotations
    }: {
      sequences: FormData["targetSequences"];
      annotations: FormData["sequenceAnnotations"];
    }) {
      const annotatedSequences: AnnotatedSequence[] = [];
      for (const sequence of sequences) {
        const sequenceAnnotations = annotations.filter(
          annotation => annotation.sequenceId === sequence.id
        );

        annotatedSequences.push({
          ...sequence,
          ...(annotationType === "part"
            ? {
                parts: [
                  ...((showExistingAnnotations && sequence.parts) || []),
                  ...sequenceAnnotations
                ]
              }
            : {
                sequenceFeatures: [
                  ...((showExistingAnnotations && sequence.sequenceFeatures) ||
                    []),
                  ...sequenceAnnotations
                ]
              })
        });
      }
      return annotatedSequences;
    }

    if (targetSequences && sequenceAnnotations) {
      const annotatedSequences = annotateSequences({
        sequences: targetSequences,
        annotations: sequenceAnnotations
      });
      setAnnotatedSequences(annotatedSequences);
      // if no sequence is selected, select the first one
      setSelectedAnnotatedSequence(
        annotatedSequences.find(
          sequence => sequence.id === selectedAnnotatedSequence?.id
        ) || annotatedSequences[0]
      );
    }
  }, [
    targetSequences,
    sequenceAnnotations,
    annotationType,
    showExistingAnnotations,
    selectedAnnotatedSequence?.id
  ]);

  const removeAnnotations = async (records: SequenceAnnotation[]) => {
    const modelName = modelNameToReadableName(annotationType, {
      plural: records.length > 1
    });
    const confirm = await showConfirmationDialog({
      text: `Are you sure you want to discard seleted ${modelName} `,
      intent: "danger",
      icon: "trash",
      confirmButtonText: `Discard`
    });
    if (!confirm) return;
    const removeIds = records.map(record => record.id);
    const filteredAnnotations = sequenceAnnotations?.filter(
      annotation => !removeIds.includes(annotation.id)
    );

    // update sequenceAnnotations form value
    change("sequenceAnnotations", filteredAnnotations);
  };

  const editAnnotation = (annotation: SequenceAnnotation) => {
    change(
      "sequenceAnnotations",
      sequenceAnnotations.map(ann =>
        ann.id === annotation.id ? annotation : ann
      )
    );
  };

  const handleContextMenu = ({
    selectedRecords
  }: {
    selectedRecords: SequenceAnnotation[];
  }) => {
    const isSingleRecord = selectedRecords.length === 1;
    return (
      <React.Fragment>
        <MenuItem
          icon="trash"
          text="Delete"
          onClick={() => removeAnnotations(selectedRecords)}
        />
        {isSingleRecord && (
          <MenuItem
            icon="edit"
            text="Edit"
            onClick={() =>
              showDialog({
                ModalComponent: EditAnnotation,
                modalProps: {
                  annotation: selectedRecords[0],
                  editAnnotation
                }
              })
            }
          />
        )}
      </React.Fragment>
    );
  };

  const renderSequencesTable = (
    <DataTable
      withTitle
      noPadding={false}
      formName="targetSequences"
      tableName="Select target"
      isSimple
      isSingleSelect
      schema={[
        "name",
        "aliases",
        {
          path: "size",
          displayName: "Sequence Length",
          render: (value: AnnotatedSequence["size"]) => `${value} bp`
        },
        { path: "sequenceType.name", displayName: "Sequence Type" }
      ]}
      entities={annotatedSequences}
      onSingleRowSelect={setSelectedAnnotatedSequence}
      selectedIds={[selectedAnnotatedSequence?.id]}
    />
  );

  const renderAnnotationsTable = (
    <DataTable
      withDisplayOptions
      withTitle
      tableName="New Annotations"
      formName="resultAnnotations"
      withFilter
      withSearch={false}
      noFullscreenButton={true}
      hideSelectedCount={true}
      hidePageSizeWhenPossible={true}
      isInfinite
      contextMenu={handleContextMenu}
      onSingleRowSelect={setSelectionLayer}
      selectedIds={[selectionLayer?.id]}
      schema={annotationsTableSchema}
      entities={sequenceAnnotations?.filter(
        annotation => annotation.sequenceId === selectedAnnotatedSequence?.id
      )}
      additionalFooterButtons={
        !isEmpty(
          sequenceAnnotations?.filter(
            annotation =>
              annotation.sequenceId === selectedAnnotatedSequence?.id
          )
        ) && (
          <ExportTableAsCsvButton
            key="export-annotations"
            text="Export annotations"
            filename={`sequence_annotations_${selectedAnnotatedSequence?.name}`}
            schema={annotationsTableSchema}
            entities={sequenceAnnotations?.filter(
              annotation =>
                annotation.sequenceId === selectedAnnotatedSequence?.id
            )}
          />
        )
      }
    />
  );

  const renderSequencePreview = (
    <div className="column">
      <SwitchField
        name="showExistingAnnotations"
        label={"Show Existing Annotations"}
        defaultValue={showExistingAnnotations}
        checked={showExistingAnnotations}
        onChange={() => {
          setShowExistingAnnotations(!showExistingAnnotations);
        }}
      />
      <SimpleCircularOrLinearView
        withChoosePreviewType
        minimalPreviewTypeBtns
        selectionLayer={selectionLayer}
        selectionLayerUpdate={setSelectionLayer}
        withSelectionEnabled
        sequenceData={sequenceToVeInput(selectedAnnotatedSequence as any)}
      />
    </div>
  );

  return (
    <React.Fragment>
      <div className="tg-step-form-section column">
        <div className="tg-flex justify-space-between">
          <div style={{ width: 700 }}>
            {!isEmpty(annotatedSequences) && renderSequencesTable}
          </div>
          {!isEmpty(selectedAnnotatedSequence) && renderSequencePreview}
        </div>
        {!isEmpty(selectedAnnotatedSequence) && renderAnnotationsTable}
      </div>
    </React.Fragment>
  );
};

export default compose<any, any>(
  stepFormValues("targetSequences", "sequenceAnnotations", "annotationType")
)(ReviewAndSubmitStep);
