/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { molecularWeightDictionary } from "./molecularWeightDictionary";

export const calculateMolecularWeightAndExtinctionCoefficient = (
  sequence = ""
) => {
  let molecularWeight = 18.1524,
    numTyr = 0,
    numTrp = 0;
  for (const char of sequence.toUpperCase()) {
    molecularWeight += molecularWeightDictionary[char] || 0;
    if (char === "Y") numTyr++;
    if (char === "W") numTrp++;
  }
  return {
    molecularWeight,
    extinctionCoefficient: numTyr * 1490 + numTrp * 5500
  };
};
