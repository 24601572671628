/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";

export default gql`
  fragment userManagementFragment on user {
    id
    avatarFile {
      id
      path
    }
    userRoles {
      id
      appRoleCode
      appRole {
        code
        name
      }
    }
    avatarFileId
    firstName
    lastName
    email
    phoneNumber
    isDeactivated
    createdAt
  }
`;
