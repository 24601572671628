/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";

export const useLocalStorage = <T,>(
  storageKey: string,
  fallbackState: T
): [T, React.Dispatch<React.SetStateAction<T>>] => {
  const [value, setValue] = React.useState<T>(
    JSON.parse(localStorage.getItem(storageKey)!) ?? fallbackState
  );

  React.useEffect(() => {
    // For other falsy values, we want to keep them (false, "", 0) otherwise we cannot set fallbackState to thruthy values
    value === null || value === undefined
      ? localStorage.removeItem(storageKey)
      : localStorage.setItem(storageKey, JSON.stringify(value));
  }, [value, storageKey]);

  return [value, setValue];
};

export default useLocalStorage;
