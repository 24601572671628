/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

export const calculateSequenceTypeCode = (
  sequence,
  { polynucleotideMaterialTypeCode } = {}
) => {
  const polynucleotideMaterialTypeCodeToSequenceTypeCode = {
    LINEAR_DNA: "LINEAR_DNA",
    OLIGO: "OLIGO",
    PLASMID: "CIRCULAR_DNA",
    RNA: "RNA"
  };
  if (sequence.sequenceTypeCode) {
    return sequence.sequenceTypeCode;
  } else if (polynucleotideMaterialTypeCode) {
    return polynucleotideMaterialTypeCodeToSequenceTypeCode[
      polynucleotideMaterialTypeCode
    ];
  } else if (sequence.circular) {
    return "CIRCULAR_DNA";
  } else {
    return "LINEAR_DNA";
  }
};
