/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

/**
 * *** DO NOT UPDATE TOOL SCHEMA CODES ***
 * These will be inserted into the db and updating them will either crash the app or require a data migration.
 */

// all tool codes
// addReagentsTool
// aliquotRearrayTool
// assayPlanningTool
// assemblyReactionPlanning
// bacterialConjugation
// colonyPicking
// concatenateWorklists
// convertFileToDataTable
// createCustomWorklist
// createDataTableFromPlate
// createPlateMapTool
// createReactionMap
// createReagentList
// dehydration
// desaltTool
// executeReactionMapTool
// executeWorklist
// genericElutionTool
// idtOrderingTool
// importExperimentData
// inventoryCheck
// j5ConstructSelection
// j5Rework
// lysis
// microbialMaterialSelection
// microbialReactionPlanning
// microbialTransformation
// movePlates
// normalizationPlanning
// parseExperimentData
// parseExperimentData
// pcrPlanningAndInventoryCheck
// plasmidPrep
// plasmidPurificationPlanning
// platePrepTool
// plateReformatting
// plateRegistration
// plateRegistrationCSV
// pooling
// proteinPurification
// proteinPurificationPlanning
// runPCRV2
// sampleMultiplexing
// sampleQCTool
// sequenceAlignment
// sequenceAssociation
// sequencingQCInspectionTool
// sequencingQualityControlReportTool
// singlePlatePrep
// spinDown
// staticProtocol
// twistOrderingTool
// updateAliquotExtendedProperties
// updatePlate
// updateReagentReactionMap
// workflowStartTask
// worklistPlanning

import React from "react";
import { Link } from "react-router-dom";
import pluralize from "pluralize";
import { startCase, forEach } from "lodash";
// import makeLoadableComponent from "../../../src-shared/makeLoadableComponent";
import {
  itemTypeOptions,
  itemTypes
} from "./CreateOrEditPlateMapTool/constants";
import modelNameToReadableName from "../../../src-shared/utils/modelNameToReadableName";
import ConstructSelectionTool from "./ConstructSelectionTool";
import PlateRegistrationTool from "./PlateRegistrationTool";
import CSVPlateRegistrationTool from "./CSVPlateRegistrationTool";
// import sequenceAlignmentTool from "./sequenceAlignmentTool"
import MicrobialMaterialSelectionTool from "./MicrobialMaterialSelectionTool";
import LysisTool from "./LysisTool";
import ProteinPurification from "./ProteinPurification";
import ProteinPurificationPlanning from "./ProteinPurificationPlanning";
import PlasmidPurificationPlanning from "./PlasmidPurificationPlanning";
import SpinDownTool from "./SpinDownTool";
import Pooling from "./Pooling";
import SequenceAssociationTool from "./SequenceAssociationTool";
import SequenceAnnotationTool from "./SequenceAnnotationTool";
import ColonyPickingFeedback from "./ColonyPickingFeedback";
import RunPCRToolV2 from "./RunPCRToolV2";
import PlateReformatTool from "./PlateReformatTool";
import MicrobialTransformationTool from "./MicrobialTransformationTool";
import MicrobialReactionPlanningTool from "./MicrobialReactionPlanning";
import PlasmidPrepTool from "./PlasmidPrepTool";
import ExecuteWorklistTool from "./ExecuteWorklistTool";
import AliquotRearrayTool from "./AliquotRearrayTool";
import PlatePrepTool from "./PlatePrepTool";
import DesaltTool from "./DesaltTool";
import CreateOrEditPlateMapTool from "./CreateOrEditPlateMapTool";
import AddReagentsTool from "./AddReagentsTool";
import MoveTool from "./MoveTool";
import AssayPlanningTool from "./AssayPlanningTool";
import ExecuteReactionMapTool from "./ExecuteReactionMapTool";
import InventoryCheckTool from "./InventoryCheckTool";
import BacterialConjugation from "./BacterialConjugation";
import TuplingTool from "./TuplingTool";
import ConcatenateWorklists from "./ConcatenateWorklists";
import PcrPlanningAndInventoryCheck from "./PcrPlanningAndInventoryCheck";
import dnaAssemblyReactionPlanningTool from "./DNAAssemblyReactionPlanningTool";
import SequencingQualityControl from "./SequencingQualityControl";
import SampleQualityControl from "./SampleQualityControl";
import UpdatePlateTool from "./UpdatePlateTool";
import DehydrationTool from "./DehydrationTool";
import StartTaskTool from "./StartTaskTool";
import CreateReactionMapTool from "./CreateReactionMapTool";
import GenericElutionTool from "./GenericElutionTool";
import WorklistPlanningTool from "./WorklistPlanningTool";
import J5ReworkTool from "./J5ReworkTool";

// import SequencingQCInspectionTool from "./SequencingQCInspectionTool";
import {
  dataItemTypeCodeToModel,
  dataItemTypeMap,
  inventoryItemTypeCodeToModel,
  inventoryItemTypeMap
} from "../../../../tg-iso-shared/src/utils/inventoryUtils";
import CreateCustomWorklist from "./CreateCustomWorklist";
import TwistOrderingTool from "./TwistOrderingTool";
import CreateDataTableFromPlate from "./CreateDataTableFromPlateTool";
import UpdateAliquotExtendedProperties from "./UpdateAliquotExtendedProperties";
import IDTOrderingTool, { vendorFragment } from "./IDTOrderingTool";
import FileToDataGridTool from "../../../src-shared/Tools/FileToDataGridTool";
import DataGridToDataLake from "../../../src-shared/Tools/DataGridToDataLake";
import CreateReagentList from "./CreateReagentList";
import UpdateReagentReactionMap from "./UpdateReagentReactionMap";
import SinglePlatePrep from "./SinglePlatePrep";
import ReactionMapReworkTool from "./ReactionMapReworkTool";
import NormalizationTool from "./NormalizationTool";
import GenerateOligosForSynthonsTool from "./GenerateOligosForSynthonsTool";
import generateOligoForSynthonsFieldConstants from "./GenerateOligosForSynthonsTool/fieldConstants";
import {
  itemTypes as generateOligoForSynthonsItemTypes,
  reactionEntityOptions as generateOligosForSynthonsReactionEntityOptions
} from "./GenerateOligosForSynthonsTool/utils";
import customWorklistFieldConstants from "./CreateCustomWorklist/fieldConstants";
import worklistPlanningFieldConstants from "./WorklistPlanningTool/fieldConstants";
import ExportPlatesTool from "./ExportPlatesTool";
import ExternalImportToolGeneric from "./ExternalImportToolGeneric";
import createPlateMapFieldConstants from "./CreateOrEditPlateMapTool/fieldConstants";
import SampleStatusPropagationTool from "./SampleStatusPropagationTool";
import inventoryCheckFieldConstants from "./InventoryCheckTool/fieldConstants";
import { reactionEntityTypeOptionsInventoryCheck } from "./InventoryCheckTool/utils";
import { automateSelectionMethods } from "../AutomateSelectionField";
import DataAssociationTool from "../../../src-shared/Tools/DataAssociationTool";
import SampleScreeningTool from "../../../src-shared/Tools/SampleScreeningTool";
import UpdateMaterialExtendedProperties from "./UpdateMaterialExtendedProperties";
import { getReactionEntityTypeOptions } from "../../../src-shared/SelectReactionMapEntities";
import updateMaterialExtPropsfieldConstants from "./UpdateMaterialExtendedProperties/fieldConstants";
import idtOrderingFieldConstants from "./IDTOrderingTool/fieldConstants";
import { safeQuery } from "../../../src-shared/apolloMethods";
import { getIdtProductTypeOptions } from "./IDTOrderingTool/utils";
import { settingsTypes } from "../../../../tg-iso-lims/src/utils/workflowUtils";
import { inDevelopment } from "../../../src-shared/utils/inDevelopment";
import { GuideRNAScorePredictionTool } from "./GuideRNAScorePredictionTool";
import { CRISPRKnockinsDesignTool } from "./CRISPRKnockinsDesignTool";
import DesignFromDigestPartsTool from "./DesignFromDigestParts";
import DesignFromPartition from "./DesignFromPartition";
import { filterToolSchemasForLibrary } from "../../../src-shared/utils/toolUtils";

// simport.setSplitLoader(makeLoadableComponent);
/**
 * HOW TO MAKE A TOOL SCHEMA
 *
 * toolSchema: {
 *       // each tool has inputs and outputs
 *         // these are ioItems for workflows but will also get
 *         // used by the tools output and success page
 *       input: {
 *         ioItems: {
 *           j5Reports: {
 *             // if an item is marked as a list that means it can accept a variable number of inputs
 *             isList: true,
 *             label: "DNA Assembly Reports",
 *             dataItemTypeCode: dataItemTypeMap.j5Report
 *           }
 *         }
 *       },
 *       output: {
 *         ioItems: {
 *           selectedConstructsDataSet: {
 *             // outputs can also be marked as isList to signify that outputs are of variable length
 *             isList: true,
 *             label: "Selected Constructs Data Set",
 *             dataItemTypeCode: dataItemTypeMap.dataSet
 *             // can also add "isUpdate: true" here, that will tell the success page that these
 *             // items were updated not created
 *           },
 *           constructsList: {
 *             label: "Selected Constructs List",
 *             dataItemTypeCode: dataItemTypeMap.dataTable
 *           },
 *           assemblyPieceList: {
 *             label: "Assembly Piece List",
 *             dataItemTypeCode: dataItemTypeMap.dataTable
 *           }
 *         }
 *       }
 *     }
 */

/**
 * TOOL OUTPUT
 *
 * each tool needs to return an output that matches it's tool schema output:
 *
 * return return {
 *   leftPrimer: { // this key matches the key found in the toolSchema
 *     id: createdPlateMapGroups[0].id,
 *   },
 *   rightPrimer: {
 *     id: createdPlateMapGroups[1].id,
 *   },
 *   primaryTemplates: {
 *     id: createdPlateMapGroups[2].id,
 *   }
 * }
 *
 * if an output is a list then it should look like this:
 * updatedPlates: [{ id: 1}, { id: 2 }, { id: 3 }]
 */

//every tool needs to:
// 1. call onSubmit with id object like this:
// return {
//   leftPrimer: {
//     id: createdPlateMapGroups[0].id,
//   },
//   rightPrimer: {
//     id: createdPlateMapGroups[1].id,
//   },
//   primaryTemplates: {
//     id: createdPlateMapGroups[2].id,
//   }
// };

const blankSelectOption = [{ label: "", value: "" }];

const toolSchemas = [
  {
    code: "j5Rework",
    Component: J5ReworkTool,
    disabled: window.frontEndConfig.disabledToolkits.workflowManagement,
    toolSchema: {
      name: "DNA Assembly Rework",
      input: {
        ioItems: {
          j5Reports: {
            label: "DNA Assembly Reports",
            dataItemTypeCode: dataItemTypeMap.j5Report,
            isList: true
          },
          dataTables: {
            label: "Sample QC Data Tables",
            dataItemTypeCode: dataItemTypeMap.dataTable,
            isList: true
          }
        }
      },
      output: {
        ioItems: {
          j5Report: {
            label: "Assembly Report Subset",
            dataItemTypeCode: dataItemTypeMap.j5Report
          }
        }
      },
      suggestedNextTools: [
        "pcrPlanningAndInventoryCheck",
        "j5ConstructSelection"
      ]
    },
    title: "DNA Assembly Rework",
    description: (
      <span>
        This tool accepts assembly reports and lists of samples (from Sample
        Quality Control) and creates a smaller subset of the reports.
      </span>
    ),
    input: <span>DNA Assembly Report</span>,
    output: <span>DNA Assembly Report Subset</span>
  },
  {
    code: "reactionMapRework",
    Component: ReactionMapReworkTool,
    toolSchema: {
      name: "Reaction Map Rework",
      input: {
        ioItems: {
          reactionMaps: {
            label: "Reaction Maps",
            dataItemTypeCode: dataItemTypeMap.reactionMap,
            isList: true
          },
          dataTables: {
            label: "Sample QC Data Tables",
            dataItemTypeCode: dataItemTypeMap.dataTable,
            isList: true
          }
        }
      },
      output: {
        ioItems: {
          reactionMap: {
            label: "Reaction Map",
            dataItemTypeCode: dataItemTypeMap.reactionMap
          }
        }
      }
    },
    title: "Reaction Map Rework",
    disabled: window.frontEndConfig.disabledToolkits.workflowManagement,
    description: (
      <span>
        This tool accepts reaction maps and allows users to create child
        reaction maps with subsets of reactions from the selected inputs.
      </span>
    ),
    input: <span>Parent Reaction Maps</span>,
    output: <span>Child Reaction Map</span>
  },
  {
    code: "j5ConstructSelection",
    Component: ConstructSelectionTool,
    toolSchema: {
      input: {
        ioItems: {
          j5Reports: {
            isList: true,
            label: "DNA Assembly Reports",
            dataItemTypeCode: dataItemTypeMap.j5Report
          }
        }
      },
      output: {
        ioItems: {
          constructsDataSet: {
            label: "Constructs Data Set",
            dataItemTypeCode: dataItemTypeMap.dataSet
          },
          constructsList: {
            label: "Constructs List",
            dataItemTypeCode: dataItemTypeMap.dataTable
          },
          assemblyPieceList: {
            label: "Assembly Piece List",
            dataItemTypeCode: dataItemTypeMap.dataTable
          },
          assemblyPieceOrderList: {
            label: "Assembly Piece Order List",
            dataItemTypeCode: dataItemTypeMap.dataTable
          },
          assemblyReactionMap: {
            label: "Reaction Map",
            dataItemTypeCode: dataItemTypeMap.reactionMap
          }
        }
      },
      suggestedNextTools: [
        "assemblyReactionPlanning",
        "twistOrderingTool",
        "idtOrderingTool"
      ]
    },
    disabled: window.frontEndConfig.disabledToolkits.workflowManagement,
    title: "DNA Assembly Construct Selection",
    description: (
      <span>
        This tool allows you to select the DNA constructs you wish to build. You
        can choose constructs to build de novo from the DNA Assembly Report
        generated in the DESIGN module. You can also check to see what you have
        in stock, saving you the time and expense of remaking those pieces of
        DNA.
      </span>
    ),
    input: "DNA Assembly Report",
    output: "DNA Assembly Construct List and Assembly Piece List"
  },
  {
    code: "staticProtocol",
    workflowOnly: true,
    hasExtendedProperties: true,
    title: "Static Protocol",
    disabled: window.frontEndConfig.disabledToolkits.workflowManagement,
    description: (
      <span>
        This tool allows users to pre-configure arbitrary text-based protocol
        information in the context of a workflow definition. This can be used to
        include detailed protocol information, or create checkpoints for users
        who need to take certain actions within a workflow, actions that don't
        necessarily change or reconfigure the contents of tracked plates, tubes,
        aliquots, etc., but are nevertheless important to document during a
        complex workflow.
      </span>
    ),
    input: "Plates",
    output: "Plates",
    toolSchema: {
      input: {
        ioItems: {
          containerArrays: {
            isList: true,
            isOptional: true,
            label: "Plates",
            inventoryItemTypeCode: inventoryItemTypeMap.containerArray
          }
        }
      },
      output: {
        ioItems: {
          containerArrays: {
            isList: true,
            isUpdate: true,
            label: "Plates",
            inventoryItemTypeCode: inventoryItemTypeMap.containerArray
          }
        }
      },
      settings: {
        staticProtocolText: {
          label: "Static Protocol Text",
          canAdjustPresetSetting: false,
          workflowToolSettingDefinitionTypeCode: "MARKDOWN"
        }
      }
    }
  },
  {
    code: "workflowStartTask",
    Component: StartTaskTool,
    workflowOnly: true,
    hideTool: true,
    title: "Start Task",
    description: (
      <span>
        This tool allows you to select start items in a workflow to use in
        downstream tasks.
      </span>
    ),
    output: "Start Items",
    toolSchema: {}
  },
  {
    code: "pcrPlanningAndInventoryCheck",
    Component: PcrPlanningAndInventoryCheck,
    title: "PCR Planning and Inventory Check",
    disabled: window.frontEndConfig.disabledToolkits.workflowManagement,
    description: (
      <span>
        This tool uses the PCR Reaction Table output by the j5 protocol
        generator to generate plate maps for left primer, right primer, and
        template sequence plates. The tool allows you to manually assign the PCR
        reactions to locations in a PCR plate or use our algorithm to
        automatically assign the PCR reactions to locations in a PCR plate. The
        algorithm will assign locations in a way that optimizes the PCR
        reactions when using a zone gradient thermocycler.
      </span>
    ),
    input: "DNA Assembly Reports",
    output: (
      <span>
        One PCR Product Plate Map, Existing PCR Materials Lists, PCR Materials
        to Order Lists
      </span>
    ),
    toolSchema: {
      input: {
        ioItems: {
          j5Reports: {
            isList: true,
            label: "DNA Assembly Reports",
            dataItemTypeCode: dataItemTypeMap.j5Report
          },
          sourcePlateMapGroup: {
            label: "PCR Plate Map",
            dataItemTypeCode: dataItemTypeMap.plateMapGroup
          }
        }
      },
      output: {
        ioItems: {
          pcrPlanningDataSet: {
            label: "PCR Planning Data Set",
            dataItemTypeCode: dataItemTypeMap.dataSet
          },
          existingPrimerMaterialsList: {
            label: "List of Existing Primer PCR Materials",
            dataItemTypeCode: dataItemTypeMap.dataTable
          },
          existingTemplateMaterialsList: {
            label: "List of Existing Primary Template PCR Materials",
            dataItemTypeCode: dataItemTypeMap.dataTable
          },
          templateOrderList: {
            label: "List of Primary Templates to Order",
            dataItemTypeCode: dataItemTypeMap.dataTable
          },
          primerOrderList: {
            label: "List of Primers to Order",
            dataItemTypeCode: dataItemTypeMap.dataTable
          },
          plateMapGroup: {
            isUpdate: true,
            label: "PCR Plate Map",
            dataItemTypeCode: dataItemTypeMap.plateMapGroup
          }
        }
      },
      suggestedNextTools: [
        "aliquotRearrayTool",
        "twistOrderingTool",
        "idtOrderingTool"
      ]
    }
  },
  {
    code: "plateRegistration",
    Component: PlateRegistrationTool,
    disabled:
      window.frontEndConfig.disabledToolkits.materialsAndInventoryManagement,
    title: "Plate Registration (Plate Maps)",
    description: (
      <span>
        This tool allows users to register plates from plate maps created using
        the Create Plate Map tool. After selecting plate maps, and specifying a
        plate type, users have the option of setting plate-wide volumes or
        uploading a CSV of specific plate wells' volumes (download example file
        for formatting guidance).
      </span>
    ),
    input: "Plate Maps",
    output: "A registered plate for each selected plate map",
    toolSchema: {
      input: {
        ioItems: {
          plateMapGroups: {
            isList: true,
            label: "Plate Maps",
            dataItemTypeCode: dataItemTypeMap.plateMapGroup
          }
        }
      },
      output: {
        ioItems: {
          containerArrays: {
            isList: true,
            label: "Output Plates",
            inventoryItemTypeCode: inventoryItemTypeMap.containerArray
          }
        }
      }
    }
  },
  {
    code: "createCustomWorklist",
    Component: CreateCustomWorklist,
    disabled: window.frontEndConfig.disabledToolkits.workflowManagement,
    title: "Create Custom Worklist",
    description: (
      <span>This tool takes in a CSV file and outputs a worklist</span>
    ),
    input: "CSV",
    output: "Worklist",
    toolSchema: {
      input: {
        ioItems: {
          dataFiles: {
            isList: true,
            label: "Input Files",
            dataItemTypeCode: dataItemTypeMap.dataFile
          },
          sourcePlates: {
            isList: true,
            label: "Source Plates",
            inventoryItemTypeCode: inventoryItemTypeMap.containerArray
          },
          destinationPlates: {
            isList: true,
            label: "Destination Plates",
            inventoryItemTypeCode: inventoryItemTypeMap.containerArray
          },
          reactionMap: {
            label: "Reaction Map",
            dataItemTypeCode: dataItemTypeMap.reactionMap
          }
        }
      },
      output: {
        ioItems: {
          worklists: {
            isList: true,
            label: "Worklists",
            dataItemTypeCode: dataItemTypeMap.worklist
          },
          containerArrays: {
            isList: true,
            isUpdate: true,
            label: "Destination Plates",
            inventoryItemTypeCode: inventoryItemTypeMap.containerArray
          },
          aliquotContainers: {
            isList: true,
            isUpdate: true,
            label: "Destination Tubes",
            inventoryItemTypeCode: inventoryItemTypeMap.aliquotContainer
          }
        }
      },
      settings: {
        [customWorklistFieldConstants.universalTransferVolume]: {
          label: "Universal Transfer Volume",
          type: settingsTypes.numeric
        },
        [customWorklistFieldConstants.universalTransferVolumeUnitCode]: {
          label: "Universal Transfer Volume Unit",
          type: settingsTypes.select,
          options: "volumetricUnit"
        },
        [customWorklistFieldConstants.newDestination]: {
          label: "Create New Destination Plates",
          type: settingsTypes.checkbox
        },
        [customWorklistFieldConstants.platePrefix]: {
          label: "Plate Name Prefix",
          type: settingsTypes.text
        },
        integrationSetting: {
          label: "Integration",
          integrationTypeCode: "CUSTOM_WORKLIST",
          workflowToolSettingDefinitionTypeCode: "INTEGRATION"
        }
      }
    }
  },
  {
    code: "externalImportReactionMapTool",
    workflowOnly: true,
    Component: props => {
      return <ExternalImportToolGeneric {...props} subtype="REACTION_MAP" />;
    },
    title: "External Import Reaction Map Tool",
    disabled: window.frontEndConfig.disabledToolkits.labAutomation,
    description: (
      <span>
        This tool imports a reaction map (or multiple) from an external
        integration endpoint.
      </span>
    ),
    output: "Reaction Map(s)",
    toolSchema: {
      output: {
        ioItems: {
          reactionMaps: {
            isList: true,
            label: "Reaction Maps",
            isUpdate: true,
            successPageMessage: numberOfItems =>
              `${modelNameToReadableName("reactionMap", {
                plural: numberOfItems > 1
              })} Imported`,
            dataItemTypeCode: dataItemTypeMap.reactionMap
          }
        }
      },
      settings: {
        integrationSetting: {
          label: "Integration",
          integrationTypeCode: "IMPORT",
          additionalFilter: {
            subtype: "REACTION_MAP"
          },
          workflowToolSettingDefinitionTypeCode: "INTEGRATION"
        }
      }
    }
  },
  {
    code: "externalImportReactionDesignTool",
    workflowOnly: true,
    Component: props => {
      return <ExternalImportToolGeneric {...props} subtype="REACTION_DESIGN" />;
    },
    title: "External Import Reaction Design Tool",
    disabled: window.frontEndConfig.disabledToolkits.labAutomation,
    description: (
      <span>
        This tool imports a reaction design (or multiple) from an external
        integration endpoint.
      </span>
    ),
    output: "Reaction Design(s)",
    toolSchema: {
      output: {
        ioItems: {
          reactionDesigns: {
            isList: true,
            isUpdate: true,
            successPageMessage: numberOfItems =>
              `${modelNameToReadableName("reactionDesign", {
                plural: numberOfItems > 1
              })} Imported`,
            label: "Reaction Designs",
            dataItemTypeCode: dataItemTypeMap.reactionDesign
          }
        }
      },
      settings: {
        integrationSetting: {
          label: "Integration",
          integrationTypeCode: "IMPORT",
          additionalFilter: {
            subtype: "REACTION_DESIGN"
          },
          workflowToolSettingDefinitionTypeCode: "INTEGRATION"
        }
      }
    }
  },
  {
    code: "plateRegistrationCSV",
    Component: CSVPlateRegistrationTool,
    title: "Plate Registration (CSV)",
    disabled:
      window.frontEndConfig.disabledToolkits.materialsAndInventoryManagement,
    description: (
      <span>
        This tool allows users to upload a plate CSV (of any upload format
        accepted in the plate library) to create and register them.
      </span>
    ),
    input: "CSV File",
    output: "Registered Plates",
    toolSchema: {
      output: {
        ioItems: {
          containerArrays: {
            isList: true,
            label: "Output Plates",
            inventoryItemTypeCode: inventoryItemTypeMap.containerArray
          }
        }
      }
    }
  },
  // {
  //   code: "sequenceAlignment",
  //   Component:  sequenceAlignmentTool,
  //   title: "Sequence Alignment",
  //   description: `This tool allows users to upload sequence files in order to create and view alignments: \
  //   Multiple Sequence Alignment, Pairwise Alignment, Sanger Sequencing Alignment, or Next-Generation Sequencing Alignment.`,
  //   input: "Sequence Files",
  //   output: "Alignments",
  //   toolSchema: {
  //     output: {
  //       ioItems: {
  //         alignments: {
  //           // isList: true,
  //           label: "Alignment",
  //           dataItemTypeCode: dataItemTypeMap.alignment
  //         }
  //       }
  //     }
  //   }
  // },
  {
    code: "sequenceAnnotation",
    Component: SequenceAnnotationTool,
    title: "SeqAnnotate",
    description: (
      <span>
        "This tool allows you to add features or parts to your chosen target
        sequences. You can choose annotations from a reference database of
        common annotations, a list of your existing annotations, and/or your
        existing annotation groups."
      </span>
    ),
    input: "Sequence Files",
    output: "Annotated Sequences",
    toolSchema: {
      input: {
        ioItems: {
          sequences: {
            isList: true,
            label: "DNA Sequences to Annotate",
            inventoryItemTypeCode: inventoryItemTypeMap.sequence
          }
        }
      }
    }
  },
  {
    code: "microbialMaterialSelection",
    Component: MicrobialMaterialSelectionTool,
    deprecated: true,
    title: "Microbial Material Selection",
    description: (
      <span>
        This tool allows users to select one or more plates and data tables of
        microbial materials and outputs a combined data table of those microbial
        materials with any tags specified by the user.
      </span>
    ),
    input: "Plates and Data Tables of Microbial Materials",
    output: "Tagged Data Table of Microbial Materials",
    toolSchema: {
      input: {
        ioItems: {
          containerArrays: {
            isList: true,
            label: "Plates",
            inventoryItemTypeCode: inventoryItemTypeMap.containerArray
          },
          dataTables: {
            isList: true,
            label: "Data Tables",
            dataItemTypeCode: dataItemTypeMap.dataTable
          }
        }
      },
      output: {
        ioItems: {
          inventoryMicrobesList: {
            label: "List of Microbial Materials in Inventory",
            dataItemTypeCode: dataItemTypeMap.dataTable
          }
        }
      },
      suggestedNextTools: ["assayPlanningTool"]
    }
  },
  {
    code: "createReagentList",
    Component: CreateReagentList,
    title: "Create Reagent List",
    disabled: window.frontEndConfig.disabledToolkits.workflowManagement,
    description: (
      <span>
        This tool takes in a list of reagents and outputs a data table of the
        selected reagents.
      </span>
    ),
    input: "Reagents",
    output: "Table of Reagents",
    toolSchema: {
      input: {
        ioItems: {
          additiveMaterials: {
            isList: true,
            label: "Reagents",
            inventoryItemTypeCode: inventoryItemTypeMap.additiveMaterial
          }
        }
      },
      output: {
        ioItems: {
          dataTable: {
            label: "Reagent Table",
            dataItemTypeCode: dataItemTypeMap.dataTable
          }
        }
      },
      suggestedNextTools: ["updateReagentReactionMap"]
    }
  },
  {
    code: "updateReagentReactionMap",
    Component: UpdateReagentReactionMap,
    title: "Update Reaction Map With Reagents",
    disabled: window.frontEndConfig.disabledToolkits.workflowManagement,
    description: (
      <span>
        This tool takes in a reaction map and a list of reagents (data table),
        allowing the user to add reagents as inputs to the reactions before
        outputting an updated reaction map.
      </span>
    ),
    input: "Reaction Map, Data Tables, Reagents",
    output: "Reaction Map",
    toolSchema: {
      input: {
        ioItems: {
          reactionMap: {
            label: "Reaction Map",
            dataItemTypeCode: dataItemTypeMap.reactionMap
          },
          additiveMaterials: {
            isList: true,
            label: "Reagents",
            inventoryItemTypeCode: inventoryItemTypeMap.additiveMaterial
          },
          dataTables: {
            isList: true,
            label: "Table of Reagents",
            dataItemTypeCode: dataItemTypeMap.dataTable
          }
        }
      },
      output: {
        ioItems: {
          reactionMap: {
            isUpdate: true,
            label: "Reaction Map",
            dataItemTypeCode: dataItemTypeMap.reactionMap
          }
        }
      },
      suggestedNextTools: ["executeReactionMapTool", "worklistPlanning"]
    }
  },
  {
    code: "singlePlatePrep",
    Component: SinglePlatePrep,
    title: "Single Reagent Plate Prep",
    disabled: window.frontEndConfig.disabledToolkits.workflowManagement,
    description: (
      <span>
        This tool accepts one or more plates to prep with one reagent and
        generates a worklist.
      </span>
    ),
    input: "Plates, Prepping Reagent",
    output: "Worklist",
    toolSchema: {
      input: {
        ioItems: {
          containerArrays: {
            label: "Plates",
            isList: true,
            inventoryItemTypeCode: inventoryItemTypeMap.containerArray
          }
        }
      },
      output: {
        ioItems: {
          worklist: {
            label: "Worklist",
            dataItemTypeCode: dataItemTypeMap.worklist
          },
          containerArrays: {
            label: "Plates",
            isList: true,
            isUpdate: true,
            inventoryItemTypeCode: inventoryItemTypeMap.containerArray
          }
        }
      }
    }
  },
  {
    code: "lysis",
    Component: LysisTool,
    deprecated: true,
    title: "Lysis",
    disabled: window.frontEndConfig.disabledToolkits.workflowManagement,
    description: (
      <span>
        This tool takes in one or more plates of microbial materials as input.
        It validates the presence of lysate reagents in each well containing
        microbial material. The tool outputs the same input plates, however adds
        the "Lysate (raw)" extended property to microbial material aliquots. The
        user will also have the option of adding and removing additional
        extended properties at the aliquot and plate level.
      </span>
    ),
    input: "Plates of Microbial Materials",
    output: "Plates with Updated Extended Properties",
    toolSchema: {
      input: {
        ioItems: {
          containerArrays: {
            isList: true,
            label: "Plates",
            inventoryItemTypeCode: inventoryItemTypeMap.containerArray
          }
        }
      },
      output: {
        ioItems: {
          containerArrays: {
            isList: true,
            isUpdate: true,
            label: "Output Plates",
            inventoryItemTypeCode: inventoryItemTypeMap.containerArray
          }
        }
      }
    }
  },
  {
    code: "proteinPurification",
    Component: ProteinPurification,
    deprecated: true,
    title: "Protein Purification",
    disabled: window.frontEndConfig.disabledToolkits.workflowManagement,
    description: (
      <span>
        This tool takes one or more nickel column plates (attached to collection
        plates) of microbial materials and the protein purification reaction map
        as input and outputs an updated collection plate of protein materials.
      </span>
    ),
    input: "Plates of Microbial Materials",
    output: "Updated Column and Collection Plates",
    toolSchema: {
      suggestedNextTools: ["genericElutionTool"],
      input: {
        ioItems: {
          containerArrays: {
            label: "Plates",
            isList: true,
            inventoryItemTypeCode: inventoryItemTypeMap.containerArray
          },
          reactionMap: {
            label: "Purification Reaction Map",
            dataItemTypeCode: dataItemTypeMap.reactionMap
          }
        }
      },
      output: {
        ioItems: {
          collectionContainerArrays: {
            label: "Collection Plates",
            inventoryItemTypeCode: inventoryItemTypeMap.containerArray,
            isList: true,
            isUpdate: true
          },
          columnContainerArrays: {
            label: "Column Plates",
            inventoryItemTypeCode: inventoryItemTypeMap.containerArray,
            isList: true,
            isUpdate: true
          }
        }
      }
    }
  },
  {
    code: "proteinPurificationPlanning",
    Component: ProteinPurificationPlanning,
    title: "Protein Purification Planning",
    disabled: window.frontEndConfig.disabledToolkits.workflowManagement,
    description: (
      <span>
        This tool takes input plates, tubes or plate maps of DNA or Microbial
        materials, finds the associated protein materials and outputs a protein
        purification reaction map.
      </span>
    ),

    input: "Plates, Tubes or Plate Maps",
    output: "Protein Purification Reaction Map",
    toolSchema: {
      suggestedNextTools: ["executeReactionMapTool", "genericElutionTool"],
      input: {
        ioItems: {
          containerArrays: {
            label: "Plates",
            isList: true,
            inventoryItemTypeCode: inventoryItemTypeMap.containerArray
          },
          aliquotContainers: {
            label: "Tubes",
            isList: true,
            inventoryItemTypeCode: inventoryItemTypeMap.aliquotContainer
          },
          plateMapGroups: {
            label: "Plate Maps",
            isList: true,
            dataItemTypeCode: dataItemTypeMap.plateMapGroup
          }
        }
      },
      output: {
        ioItems: {
          reactionMap: {
            label: "Purification Reaction Map",
            dataItemTypeCode: dataItemTypeMap.reactionMap
          }
        }
      }
    }
  },
  {
    code: "convertFileToDataTable",
    title: "Convert File to Data Table",
    disabled: window.frontEndConfig.disabledToolkits.labAutomation,
    workflowOnly: true,
    description: (
      <span>
        This tool takes in a input file and sends it to an integration endpoint
        to be converted to a data table.
      </span>
    ),
    input: "Files",
    output: "Data Tables",
    toolSchema: {
      input: {
        ioItems: {
          containerArrays: {
            label: "Plates",
            isList: true,
            inventoryItemTypeCode: inventoryItemTypeMap.containerArray
          },
          dataFiles: {
            isList: true,
            label: "Input Files",
            dataItemTypeCode: dataItemTypeMap.dataFile
          }
        }
      },
      output: {
        ioItems: {
          dataTables: {
            isList: true,
            label: "Data Tables",
            dataItemTypeCode: dataItemTypeMap.dataTable
          }
        }
      },
      settings: {
        integrationSetting: {
          label: "Integration",
          integrationTypeCode: "FILE_TO_DATATABLE",
          workflowToolSettingDefinitionTypeCode: "INTEGRATION"
        }
      }
    }
  },
  {
    code: "plasmidPurificationPlanning",
    Component: PlasmidPurificationPlanning,
    title: "Plasmid Purification Planning",
    disabled: window.frontEndConfig.disabledToolkits.workflowManagement,
    description: (
      <span>
        This tool takes input plates, tubes or plate maps of microbial materials
        with plasmids and outputs a reaction map of those microbial materials to
        their respective plasmids.
      </span>
    ),

    input: "Plates, Tubes or Plate Maps",
    output: "Plasmid Purification Reaction Map",
    toolSchema: {
      suggestedNextTools: ["executeReactionMapTool", "genericElutionTool"],
      input: {
        ioItems: {
          containerArrays: {
            label: "Plates",
            isList: true,
            inventoryItemTypeCode: inventoryItemTypeMap.containerArray
          },
          aliquotContainers: {
            label: "Tubes",
            isList: true,
            inventoryItemTypeCode: inventoryItemTypeMap.aliquotContainer
          },
          plateMapGroups: {
            label: "Plate Maps",
            isList: true,
            dataItemTypeCode: dataItemTypeMap.plateMapGroup
          }
        }
      },
      output: {
        ioItems: {
          reactionMap: {
            label: "Plasmid Purification Reaction Map",
            dataItemTypeCode: dataItemTypeMap.reactionMap
          }
        }
      }
    }
  },
  {
    code: "spinDown",
    Component: SpinDownTool,
    deprecated: true,
    title: "Spin-Down",
    disabled: window.frontEndConfig.disabledToolkits.workflowManagement,
    description: (
      <span>
        This tool takes in one or more plates of microbial materials as input.
        It validates that each well contains a microbial material. The tool
        outputs the same input plates, however adds the "Pellet" extended
        property to microbial material aliquots and "Aspirated" extended
        property to plates if chosen to discard supernatant. The user will also
        have the option of adding and removing additional extended properties at
        the aliquot and plate level.
      </span>
    ),
    input: "Plates of Microbial Materials",
    output: "Plates with Added Extended Properties",
    toolSchema: {
      input: {
        ioItems: {
          containerArrays: {
            isList: true,
            label: "Plates",
            inventoryItemTypeCode: inventoryItemTypeMap.containerArray
          }
        }
      },
      output: {
        ioItems: {
          containerArrays: {
            isList: true,
            isUpdate: true,
            label: "Plates",
            inventoryItemTypeCode: inventoryItemTypeMap.containerArray
          }
        }
      }
    }
  },
  {
    code: "normalizationPlanning",
    Component: NormalizationTool,
    title: "Normalization Planning",
    disabled: window.frontEndConfig.disabledToolkits.workflowManagement,
    description: (
      <span>
        This tool helps you determine how much diluent (usually H2O) to add to a
        set of liquid samples in order to bring them all to the same target
        concentration. This is often useful when automating liquid handling so
        that the liquid handlers can work with similar volumes, often above a
        minimum pipetting threshold under which the liquid handler might lose
        accuracy. The tool takes as input a set of samples, their measured input
        concentrations and molecular weights. The user must also provide a
        desired output concentration. Samples may be held in multi-well plates,
        individual tubes or racks of tubes at the user's discretion.
      </span>
    ),
    input: "Plates or Racks",
    output: "Normalization Worklists",
    toolSchema: {
      input: {
        ioItems: {
          containerArrays: {
            isList: true,
            label: "Plates",
            inventoryItemTypeCode: inventoryItemTypeMap.containerArray
          }
        }
      },
      output: {
        ioItems: {
          worklists: {
            isList: true,
            label: "Normalization Worklists",
            dataItemTypeCode: dataItemTypeMap.worklist
          }
        }
      }
    }
  },
  {
    code: "dehydration",
    Component: DehydrationTool,
    disabled: window.frontEndConfig.disabledToolkits.workflowManagement,
    title: "Dehydration",
    description: <span>This tool dehydrates plates or racks.</span>,
    input: "Plates or Racks",
    output: "Updated Plates or Racks",
    toolSchema: {
      input: {
        ioItems: {
          containerArrays: {
            isList: true,
            label: "Plates",
            inventoryItemTypeCode: inventoryItemTypeMap.containerArray
          }
        }
      },
      output: {
        ioItems: {
          containerArrays: {
            isList: true,
            isUpdate: true,
            label: "Plates",
            inventoryItemTypeCode: inventoryItemTypeMap.containerArray
          }
        }
      }
    }
  },
  {
    code: "pooling",
    Component: Pooling,
    title: "Pooling",
    disabled: window.frontEndConfig.disabledToolkits.workflowManagement,
    description: (
      <span>
        This tool allows users to upload a CSV file or select a table that
        specifies how to pool existing sample in inventory together.
      </span>
    ),

    input: "CSV File or Table of specific template",
    output:
      "A pooling worklist transferring samples from input tubes and/or plates to destination tubes, racks or plates.",
    toolSchema: {
      input: {
        ioItems: {
          dataTable: {
            label: "Table",
            dataItemTypeCode: dataItemTypeMap.dataTable
          }
        }
      },
      output: {
        ioItems: {
          worklist: {
            label: "Pooling Worklist",
            dataItemTypeCode: dataItemTypeMap.worklist
          },
          containerArrays: {
            isList: true,
            label: "Output Plates",
            inventoryItemTypeCode: inventoryItemTypeMap.containerArray
          },
          aliquotContainers: {
            isList: true,
            label: "Output Tubes",
            inventoryItemTypeCode: inventoryItemTypeMap.aliquotContainer
          }
        }
      }
    }
  },
  {
    code: "sequenceAssociation",
    Component: SequenceAssociationTool,
    title: "DNA Sequence Association",
    disabled: window.frontEndConfig.disabledToolkits.workflowManagement,
    description: (
      <span>
        This tool allows users to update plates or tubes containing DNA with
        sequence information, via a CSV file or Data Table. The allowed sequence
        types are CIRCULAR_DNA, LINEAR_DNA, and OLIGO. The plates or tube racks
        specified in the CSV or data table must exist in inventory.
      </span>
    ),
    input: "CSV files or data tables of specific template",
    output: "Plates with aliquots updated with sequence information.",
    toolSchema: {
      input: {
        ioItems: {
          dataTable: {
            label: "Table",
            dataItemTypeCode: dataItemTypeMap.dataTable
          }
        }
      },
      output: {
        ioItems: {
          containerArrays: {
            isList: true,
            isUpdate: true,
            label: "Output Plates",
            inventoryItemTypeCode: inventoryItemTypeMap.containerArray
          },
          aliquotContainers: {
            isList: true,
            isUpdate: true,
            label: "Output Tubes",
            inventoryItemTypeCode: inventoryItemTypeMap.aliquotContainer
          }
        }
      }
    }
  },
  {
    code: "colonyPicking",
    Component: ColonyPickingFeedback,
    title: "Colony Picking Feedback",
    disabled: window.frontEndConfig.disabledToolkits.workflowManagement,
    description: (
      <span>
        This tool allows users to upload a CSV file or select a table that
        specifies how colonies are picked (usually generated by a picker robot)
        from source plates in inventory to destination plates. The source plates
        must exist in inventory. If the destination plate exists in inventory it
        must not contain any materials.
      </span>
    ),

    input: "CSV File or Table of specific template",
    output: "Plates of picked colonies.",
    toolSchema: {
      input: {
        ioItems: {
          dataTables: {
            label: "Table",
            dataItemTypeCode: dataItemTypeMap.dataTable,
            isList: true
          }
        }
      },
      output: {
        ioItems: {
          containerArrays: {
            isList: true,
            label: "Plates",
            inventoryItemTypeCode: inventoryItemTypeMap.containerArray
          }
        }
      }
    }
  },
  {
    code: "assemblyReactionPlanning",
    Component: dnaAssemblyReactionPlanningTool,
    title: "DNA Assembly Reaction Planning",
    disabled: window.frontEndConfig.disabledToolkits.workflowManagement,
    description: (
      <span>
        This tool takes in a list of constructs you want to make, as well as the
        plate containing the materials necessary to make those constructs. It
        outputs a worklist and reaction map specifying how those materials need
        to be combined to make the constructs and outputs an empty plate
        prepared to accept those constructs.
      </span>
    ),
    input: "Construct List and Source Plate",
    output: "Updated source plates and destination plates",
    toolSchema: {
      input: {
        ioItems: {
          constructLists: {
            isList: true,
            label: "Construct Lists",
            dataItemTypeCode: dataItemTypeMap.dataTable
          },
          containerArrays: {
            isList: true,
            label: "Source Plates",
            inventoryItemTypeCode: inventoryItemTypeMap.containerArray
          },
          plateMapGroup: {
            isOptional: true,
            label: "Plate Map",
            dataItemTypeCode: dataItemTypeMap.plateMapGroup
          }
        }
      },
      output: {
        ioItems: {
          worklist: {
            // isList: true,
            label: "Assembly Reaction Worklist",
            dataItemTypeCode: dataItemTypeMap.worklist
          },
          reactionMap: {
            // isList: true,
            label: "Assembly Reaction Map",
            dataItemTypeCode: dataItemTypeMap.reactionMap
          },
          containerArrays: {
            isList: true,
            label: "Assembly Reaction Plates",
            inventoryItemTypeCode: inventoryItemTypeMap.containerArray
          }
        }
      }
    }
  },
  {
    code: "runPCRV2",
    Component: RunPCRToolV2,
    title: "Run PCR",
    disabled: window.frontEndConfig.disabledToolkits.workflowManagement,
    description: (
      <span>
        This tool takes in plates (of templates, forward and reverse primers)
        and outputs a worklist and a reaction map specifying how those materials
        are used in the PCR reactions that generate assembly pieces that, in
        turn, will be used for DNA Assembly. The tool also outputs a destination
        PCR plate where the PCR reactions will take place.
      </span>
    ),
    input: "Plate Map, Input Plates, PCR Inventory List",
    output: "Worklist, Reaction Map, and PCR Plate",
    toolSchema: {
      input: {
        ioItems: {
          plateMapGroup: {
            label: "Input Plate Map",
            dataItemTypeCode: dataItemTypeMap.plateMapGroup
          },
          dataTables: {
            isList: true,
            label: "Inventory Material Lists",
            dataItemTypeCode: dataItemTypeMap.dataTable,
            isOptional: true
          },
          containerArrays: {
            isList: true,
            label: "Source Plates",
            inventoryItemTypeCode: inventoryItemTypeMap.containerArray
          }
        }
      },
      output: {
        ioItems: {
          reactionMap: {
            label: "PCR Reaction Map",
            dataItemTypeCode: dataItemTypeMap.reactionMap
          },
          containerArrays: {
            isList: true,
            label: "Plates",
            inventoryItemTypeCode: inventoryItemTypeMap.containerArray
          },
          worklist: {
            label: "PCR Worklist",
            dataItemTypeCode: dataItemTypeMap.worklist
          }
        }
      }
    }
  },
  {
    code: "plateReformatting",
    Component: PlateReformatTool,
    title: "Plate Reformatting",
    disabled: window.frontEndConfig.disabledToolkits.workflowManagement,
    description: (
      <span>
        <span className="italic bold">Replication</span> moves selected material
        from the source plates to the destination plates. <br />
        <span className="italic bold">Combination</span> moves selected material
        from lower density plates (e.g. 96 well) to higher density plates (e.g.
        384 well) following an assigned mapping. <br />
        <span className="italic bold">Breakdown</span> moves selected material
        from higher density plates (e.g. 384 well) to lower density plates (e.g.
        96 well) following an assigned mapping. <br />
        <span className="italic bold">Consolidation</span> takes any number of
        plates and combines their constituent aliquots onto one new plate.
      </span>
    ),
    input: "Source Plates",
    output: "Worklists, Destination Plates",
    toolSchema: {
      input: {
        ioItems: {
          containerArrays: {
            isList: true,
            label: "Input Plates",
            inventoryItemTypeCode: inventoryItemTypeMap.containerArray
          }
        }
      },
      output: {
        ioItems: {
          worklist: {
            label: "Plate Reformat Worklist",
            dataItemTypeCode: dataItemTypeMap.worklist
          },
          containerArrays: {
            isList: true,
            label: "Output Plates",
            inventoryItemTypeCode: inventoryItemTypeMap.containerArray
          },
          columnPlates: {
            isList: true,
            label: "Column Plates",
            inventoryItemTypeCode: inventoryItemTypeMap.containerArray
          }
        }
      }
    }
  },
  {
    code: "microbialReactionPlanning",
    Component: MicrobialReactionPlanningTool,
    title: "Microbial Reaction Planning",
    disabled: window.frontEndConfig.disabledToolkits.workflowManagement,
    description: (
      <span>
        This tool accepts plates, racks, or tubes of DNA or microbial materials
        and outputs transformation or conjugation reaction maps.
      </span>
    ),
    input: "Plates of DNA materials, Plates or lists of microbial materials",
    output: "Reaction Map",
    toolSchema: {
      suggestedNextTools: ["executeReactionMapTool", "worklistPlanning"],
      input: {
        ioItems: {
          containerArrays: {
            isList: true,
            label: "Plates",
            inventoryItemTypeCode: inventoryItemTypeMap.containerArray
          }
        }
      },
      output: {
        ioItems: {
          reactionMap: {
            label: "Reaction Map",
            dataItemTypeCode: dataItemTypeMap.reactionMap
          }
        }
      }
    }
  },
  {
    code: "microbialTransformation",
    Component: MicrobialTransformationTool,
    disabled: window.frontEndConfig.disabledToolkits.workflowManagement,
    title: "Microbial Transformation",
    description: (
      <span>
        This tool accepts plates, racks, or tubes of DNA materials. After
        selecting an existing microbial material, the tool will generate a
        worklist to combine DNA materials with selected microbial materials.
      </span>
    ),
    input: "Plates, racks or tubes containing DNA materials",
    output: "Transformation worklist, reaction map",
    toolSchema: {
      input: {
        ioItems: {
          containerArrays: {
            isList: true,
            label: "DNA Plates",
            inventoryItemTypeCode: inventoryItemTypeMap.containerArray
          },
          aliquotContainers: {
            isList: true,
            label: "DNA Tubes",
            inventoryItemTypeCode: inventoryItemTypeMap.aliquotContainer
          }
        }
      },
      output: {
        ioItems: {
          containerArrays: {
            isList: true,
            label: "Microbial Plates",
            inventoryItemTypeCode: inventoryItemTypeMap.containerArray
          },
          aliquotContainers: {
            isList: true,
            label: "Microbial Tubes",
            inventoryItemTypeCode: inventoryItemTypeMap.aliquotContainer
          },
          reactionMap: {
            label: "Microbial Transformation Reaction Map",
            dataItemTypeCode: dataItemTypeMap.reactionMap
          },
          worklist: {
            label: "Microbial Transformation Worklist",
            dataItemTypeCode: dataItemTypeMap.worklist
          }
        }
      }
    }
  },
  {
    code: "plasmidPrep",
    Component: PlasmidPrepTool,
    disabled: window.frontEndConfig.disabledToolkits.workflowManagement,
    title: "Plasmid Prep",
    description: (
      <span>
        This tool allows users to extract and purify plasmid DNA from
        transformed microbes. The user should also create an elution buffer from
        the Reagents library to apply to the destination plate.
      </span>
    ),
    input:
      "Plates or tubes of microbial materials containing plasmids. Note that materials in the input containers will be consumed entirely.",
    output:
      "Plates or tubes of plasmids extracted from input microbial materials",
    toolSchema: {
      input: {
        ioItems: {
          containerArrays: {
            isList: true,
            label: "Plates",
            inventoryItemTypeCode: inventoryItemTypeMap.containerArray
          },
          aliquotContainers: {
            isList: true,
            label: "Tubes",
            inventoryItemTypeCode: inventoryItemTypeMap.aliquotContainer
          }
        }
      },
      output: {
        ioItems: {
          containerArrays: {
            isList: true,
            label: "Plates",
            inventoryItemTypeCode: inventoryItemTypeMap.containerArray
          },
          aliquotContainers: {
            label: "Tubes",
            inventoryItemTypeCode: inventoryItemTypeMap.aliquotContainer
          }
        }
      }
    }
  },
  {
    code: "executeWorklist",
    Component: ExecuteWorklistTool,
    title: "Execute Worklist",
    disabled: window.frontEndConfig.disabledToolkits.labAutomation,
    description: (
      <span>
        This tool takes in one or more worklists with optional reaction maps to
        be executed in the lab. The tool validates that the source and
        destination materials both exist and have the appropriate
        volumes/capacity in the LIMS inventory. If the worklist is valid,
        executing the tool updates the effected plates in your lab's inventory.
      </span>
    ),
    input: "Worklists, Reaction Maps (optional)",
    output: "Updated source plates and destination plates",
    toolSchema: {
      input: {
        ioItems: {
          worklists: {
            isList: true,
            label: "Worklists",
            dataItemTypeCode: dataItemTypeMap.worklist
          },
          reactionMaps: {
            isList: true,
            isOptional: true,
            label: "Reaction Maps",
            dataItemTypeCode: dataItemTypeMap.reactionMap
          }
        }
      },
      output: {
        ioItems: {
          containerArrays: {
            isList: true,
            isUpdate: true,
            label: "Plates",
            inventoryItemTypeCode: inventoryItemTypeMap.containerArray
          },
          aliquotContainers: {
            isList: true,
            isUpdate: true,
            label: "Tubes",
            inventoryItemTypeCode: inventoryItemTypeMap.aliquotContainer
          }
        }
      }
    }
  },
  {
    code: "worklistPlanning",
    Component: WorklistPlanningTool,
    title: "Worklist Planning",
    disabled: window.frontEndConfig.disabledToolkits.labAutomation,
    description: (
      <span>
        This tool allows the user to select an input reaction map and one or
        more input plates and tubes. The reaction map will be used to plan a
        worklist that transfers input materials to wells on the destination
        plate where the reactions will take place. The tool also accepts
        inventory lists of samples (data tables).
      </span>
    ),
    input: "Reaction Map, source plates/tubes, data tables",
    output: "Worklist",
    toolSchema: {
      input: {
        ioItems: {
          containerArrays: {
            isList: true,
            label: "Plates",
            inventoryItemTypeCode: inventoryItemTypeMap.containerArray
          },
          aliquotContainers: {
            isList: true,
            label: "Tubes",
            inventoryItemTypeCode: inventoryItemTypeMap.aliquotContainer
          },
          reactionMaps: {
            label: "Reaction Maps",
            dataItemTypeCode: dataItemTypeMap.reactionMap,
            isList: true
          },
          plateMapGroup: {
            isOptional: true,
            label: "Plate Map",
            dataItemTypeCode: dataItemTypeMap.plateMapGroup
          },
          dataTables: {
            isList: true,
            label: "Inventory List(s)",
            dataItemTypeCode: dataItemTypeMap.dataTable
          }
        }
      },
      output: {
        ioItems: {
          worklist: {
            label: "Worklist",
            dataItemTypeCode: dataItemTypeMap.worklist
          }
        }
      },
      settings: {
        [worklistPlanningFieldConstants.destinationType]: {
          label: "New or Existing Destination Plates",
          type: settingsTypes.radio,
          options: ["New Plates", "Existing Plates"]
        },
        [worklistPlanningFieldConstants.plateBaseName]: {
          label: "New Destination Plate Base Name",
          type: settingsTypes.text
        },
        [worklistPlanningFieldConstants.enableMultipleReactions]: {
          label: "Enable Multiple Reactions per Well",
          type: settingsTypes.checkbox
        },
        [worklistPlanningFieldConstants.numberOfReactionsPerWell]: {
          label: "Number of Reactions per Well",
          type: settingsTypes.numeric
        },
        [worklistPlanningFieldConstants.transferTypeMaterial]: {
          label: "Transfer Type for Materials",
          type: settingsTypes.radio,
          options: ["volume", "mass"]
        },
        [worklistPlanningFieldConstants.applyUniversalTransfersMaterial]: {
          label: "Apply Universal Material Transfer",
          type: settingsTypes.checkbox
        },
        [worklistPlanningFieldConstants.universalTransferVolumeMaterial]: {
          label: "Universal Transfer Volume for Materials",
          type: settingsTypes.numeric
        },
        [worklistPlanningFieldConstants.universalTransferVolumetricUnitMaterial]:
          {
            label: "Universal Transfer Volume Unit for Materials",
            type: settingsTypes.select,
            options: "volumetricUnit"
          },
        [worklistPlanningFieldConstants.universalTransferMassMaterial]: {
          label: "Universal Transfer Mass for Materials",
          type: settingsTypes.numeric
        },
        [worklistPlanningFieldConstants.universalTransferMassUnitMaterial]: {
          label: "Universal Transfer Mass Unit for Materials",
          type: settingsTypes.select,
          options: "massUnit"
        },
        [worklistPlanningFieldConstants.transferTypeReagent]: {
          label: "Transfer Type for Reagents",
          type: settingsTypes.radio,
          options: ["volume", "mass"]
        },
        [worklistPlanningFieldConstants.applyUniversalTransfersReagent]: {
          label: "Apply Universal Reagent Transfer",
          type: settingsTypes.checkbox
        },
        [worklistPlanningFieldConstants.universalTransferVolumeReagent]: {
          label: "Universal Transfer Volume for Reagents",
          type: settingsTypes.numeric
        },
        [worklistPlanningFieldConstants.universalTransferVolumetricUnitReagent]:
          {
            label: "Universal Transfer Volume Unit for Reagents",
            type: settingsTypes.select,
            options: "volumetricUnit"
          },
        [worklistPlanningFieldConstants.universalTransferMassReagent]: {
          label: "Universal Transfer Mass for Reagents",
          type: settingsTypes.numeric
        },
        [worklistPlanningFieldConstants.universalTransferMassUnitReagent]: {
          label: "Universal Transfer Mass Unit for Reagents",
          type: settingsTypes.select,
          options: "massUnit"
        }
      }
    }
  },
  {
    code: "aliquotRearrayTool",
    Component: AliquotRearrayTool,
    title: "Aliquot Rearray",
    disabled: window.frontEndConfig.disabledToolkits.workflowManagement,
    description: (
      <span>
        This tool takes either (or both) a data table of inventory materials and
        plates as input and allows the user to generate worklists for
        transferring these aliquots onto new plates. The tool also accepts an
        optional plate map to validate input materials and determine the
        destination plate layout.
      </span>
    ),
    input: "Data Table and/or Plates",
    output: <span>Worklists for transfering inventory materials.</span>,
    toolSchema: {
      input: {
        ioItems: {
          dataTables: {
            isList: true,
            isOptional: true,
            label: "Inventory Material Lists",
            dataItemTypeCode: dataItemTypeMap.dataTable
          },
          containerArrays: {
            isList: true,
            isOptional: true,
            label: "Plates",
            inventoryItemTypeCode: inventoryItemTypeMap.containerArray
          },
          plateMapGroup: {
            isOptional: true,
            label: "Plate Map",
            dataItemTypeCode: dataItemTypeMap.plateMapGroup
          }
        }
      },
      output: {
        ioItems: {
          containerArrays: {
            isList: true,
            label: "Aliquot Rearray Output Plates",
            inventoryItemTypeCode: inventoryItemTypeMap.containerArray
          },
          worklist: {
            label: "Aliquot Rearray Worklist",
            dataItemTypeCode: dataItemTypeMap.worklist
          }
        }
      }
    }
  },
  {
    code: "platePrepTool",
    Component: PlatePrepTool,
    disabled: window.frontEndConfig.disabledToolkits.workflowManagement,
    title: "Plate Prep",
    description: (
      <span>
        This tool takes in any number of plates, tubes and reagent lots as input
        and allows the user to generate worklists to transfer their contents to
        new or existing plates.
      </span>
    ),
    input: "Tubes, Racks and/or Reagent Lots",
    output: (
      <span>
        One or more worklists for transferring reagents and/or materials to
        plates.
      </span>
    ),
    toolSchema: {
      input: {
        ioItems: {
          prepMaterialPlates: {
            isList: true,
            isOptional: true,
            label: "Source Plates",
            inventoryItemTypeCode: inventoryItemTypeMap.containerArray
          },
          prepLots: {
            isList: true,
            isOptional: true,
            label: "Source Lots",
            inventoryItemTypeCode: inventoryItemTypeMap.lot
          },
          prepTubes: {
            isList: true,
            isOptional: true,
            label: "Source Tubes",
            inventoryItemTypeCode: inventoryItemTypeMap.aliquotContainer
          },
          containerArrays: {
            isList: true,
            isOptional: true,
            label: "Destination Plates",
            inventoryItemTypeCode: inventoryItemTypeMap.containerArray
          }
        }
      },
      output: {
        ioItems: {
          worklists: {
            isList: true,
            label: "Plate Prep Worklists",
            dataItemTypeCode: dataItemTypeMap.worklist
          }
        }
      }
    }
  },
  {
    code: "desaltTool",
    Component: DesaltTool,
    deprecated: true,
    disabled: window.frontEndConfig.disabledToolkits.workflowManagement,
    title: "Desalt",
    description: (
      <span>
        The Desalt Tool takes in one or more column plates of protein materials
        and outputs an updated collection plate of the same protein materials.
        It also allows the user to add extended properties to the collection
        plate.`
      </span>
    ),
    input: "Column Plates",
    output: <span>One or more collection plates of protein materials.</span>,
    toolSchema: {
      input: {
        ioItems: {
          containerArrays: {
            isList: true,
            label: "Destination Plates",
            inventoryItemTypeCode: inventoryItemTypeMap.containerArray
          }
        }
      },
      output: {
        ioItems: {
          collectionContainerArrays: {
            isList: true,
            label: "Updated Collection Plates",
            inventoryItemTypeCode: inventoryItemTypeMap.containerArray
          },
          columnContainerArrays: {
            isList: true,
            label: "Updated Column Plates",
            inventoryItemTypeCode: inventoryItemTypeMap.containerArray
          }
        }
      }
    }
  },
  {
    code: "createReactionMap",
    Component: CreateReactionMapTool,
    title: "Create Reaction Map",
    disabled: window.frontEndConfig.disabledToolkits.workflowManagement,
    description: (
      <span>
        This tool allows a user to create a new reaction map. A reaction map is
        a description of how two or more materials combine to make a new
        material.
      </span>
    ),
    input: "Materials",
    output: "Reaction Map",
    toolSchema: {
      suggestedNextTools: ["executeReactionMapTool", "worklistPlanning"],
      input: {
        ioItems: {
          materials: {
            isList: true,
            label: "Materials",
            inventoryItemTypeCode: inventoryItemTypeMap.material
          }
        }
      },
      output: {
        ioItems: {
          reactionMap: {
            label: "Reaction Map",
            dataItemTypeCode: dataItemTypeMap.reactionMap
          }
        }
      }
    }
  },
  {
    code: "createPlateMapTool",
    // notWorkflow: true,
    Component: CreateOrEditPlateMapTool,
    title: "Create Plate Map",
    disabled: window.frontEndConfig.disabledToolkits.workflowManagement,
    description: (
      <span>
        This tool allows a user to create a new plate map. You can describe the
        plate map format, select the materials to be held in the plate map, and
        then distribute those materials across the plate map.
      </span>
    ),
    input: "List of Materials",
    output: "A plate map of pre-selected materials.",
    toolSchema: {
      input: {
        ioItems: itemTypes
          .filter(type => type !== "Inventory List")
          .reduce((acc, model) => {
            acc[pluralize(model)] = {
              isList: true,
              label: modelNameToReadableName(model, {
                upperCase: true,
                plural: true
              }),
              inventoryItemTypeCode: inventoryItemTypeMap[model],
              dataItemTypeCode: dataItemTypeMap[model]
            };
            return acc;
          }, {})
      },
      output: {
        ioItems: {
          plateMapGroup: {
            label: "Plate Map",
            dataItemTypeCode: dataItemTypeMap.plateMapGroup
          }
        }
      },
      suggestedNextTools: [
        "plateRegistration",
        "pcrPlanningAndInventoryCheck",
        "aliquotRearrayTool"
      ],
      settings: {
        [createPlateMapFieldConstants.itemType]: {
          label: "Item Type",
          type: settingsTypes.select,
          options: itemTypeOptions
        },
        [createPlateMapFieldConstants.selectAllReactionEntities]: {
          label: "Select All Reaction Map Entities",
          type: settingsTypes.checkbox
        },
        [createPlateMapFieldConstants.reactionEntityType]: {
          label: "Reaction Map Entity Type",
          type: settingsTypes.select,
          options: [
            {
              label: "",
              value: ""
            }
          ].concat(getReactionEntityTypeOptions())
        }
      }
    }
  },
  {
    code: "generateOligosForSynthonsTool",
    Component: GenerateOligosForSynthonsTool,
    title: "Generate Oligos for Synthons",
    disabled: window.frontEndConfig.disabledToolkits.labAutomation,
    description: (
      <span>
        This tool generates oligos to create synthons and outputs reaction maps.
        You can select materials from the material library or existing reaction
        maps, and then submit for creation of PCA and PCR reaction maps. The PCA
        reaction map describes pooling oligos to create synthons, and the PCR
        reaction map describes combining primers and synthons as PCR template to
        amplify the synthons. Powered by NinthBio's Homology Path.
      </span>
    ),
    input: "Materials",
    output: "Reaction Design, PCA Reaction Map, and PCR Reaction Map",
    toolSchema: {
      input: {
        ioItems: ["material", "reactionMap"].reduce((acc, model) => {
          acc[pluralize(model)] = {
            isList: true,
            label: modelNameToReadableName(model, {
              upperCase: true,
              plural: true
            }),
            inventoryItemTypeCode: inventoryItemTypeMap[model],
            dataItemTypeCode: dataItemTypeMap[model]
          };
          return acc;
        }, {})
      },
      output: {
        ioItems: {
          reactionDesign: {
            label: "Reaction Design",
            dataItemTypeCode: dataItemTypeMap.reactionDesign
          },
          pcaReactionMap: {
            label: "PCA Reaction Map",
            dataItemTypeCode: dataItemTypeMap.reactionMap
          },
          pcrReactionMap: {
            label: "PCR Reaction Map",
            dataItemTypeCode: dataItemTypeMap.reactionMap
          }
        }
      },
      settings: {
        [generateOligoForSynthonsFieldConstants.itemType]: {
          label: "Item Type",
          type: settingsTypes.select,
          options: generateOligoForSynthonsItemTypes.map(type => ({
            label: modelNameToReadableName(type, { upperCase: true }),
            value: type
          }))
        },
        [generateOligoForSynthonsFieldConstants.reactionEntityType]: {
          label: "Reaction Map Entity Type",
          type: settingsTypes.select,
          options: generateOligosForSynthonsReactionEntityOptions.map(
            option => ({
              label: option,
              value: option
            })
          )
        },
        [generateOligoForSynthonsFieldConstants.selectAllReactionEntities]: {
          label: "Select All Reaction Map Entities",
          type: settingsTypes.checkbox
        }
      },
      suggestedNextTools: ["worklistPlanning"]
    }
  },
  {
    code: "genericElutionTool",
    Component: GenericElutionTool,
    title: "Elution",
    disabled: window.frontEndConfig.disabledToolkits.workflowManagement,
    description: (
      <span>
        This tool accepts input column plates and a reaction map and outputs a
        worklist transferring contents to collection plates.
      </span>
    ),
    input: "Plates",
    output: "Worklist",
    toolSchema: {
      input: {
        ioItems: {
          containerArrays: {
            isList: true,
            label: "Column Plates",
            inventoryItemTypeCode: inventoryItemTypeMap.containerArray
          },
          reactionMap: {
            label: "Reaction Map",
            dataItemTypeCode: dataItemTypeMap.reactionMap
          }
        }
      },
      output: {
        ioItems: {
          worklist: {
            label: "Worklists",
            dataItemTypeCode: dataItemTypeMap.worklist
          }
        }
      }
    }
  },
  {
    code: "addReagentsTool",
    Component: AddReagentsTool,
    title: "Add Reagents",
    disabled: window.frontEndConfig.disabledToolkits.workflowManagement,
    description: (
      <span>
        This tool takes in plates and adds user-specified reagents across the
        plate wells.
      </span>
    ),
    input: "Plates",
    output: "Updated Plates",
    toolSchema: {
      input: {
        ioItems: {
          containerArrays: {
            isList: true,
            label: "Plates",
            inventoryItemTypeCode: inventoryItemTypeMap.containerArray
          }
        }
      },
      output: {
        ioItems: {
          containerArrays: {
            isUpdate: true,
            isList: true,
            label: "Plates",
            inventoryItemTypeCode: inventoryItemTypeMap.containerArray
          },
          dataTable: {
            label: "Add Reagents List",
            dataItemTypeCode: dataItemTypeMap.dataTable
          }
        }
      }
    }
  },
  {
    code: "movePlates",
    Component: MoveTool,
    title: "Move Plates",
    disabled: window.frontEndConfig.disabledToolkits.workflowManagement,
    description: (
      <span>This tool will assist in moving plates to a location</span>
    ),
    input: "Plates",
    output: "Moved Plates and a Placement List",
    toolSchema: {
      input: {
        ioItems: {
          containerArrays: {
            isList: true,
            label: "Plates",
            inventoryItemTypeCode: inventoryItemTypeMap.containerArray
          }
        }
      },
      output: {
        ioItems: {
          containerArrays: {
            isUpdate: true,
            isList: true,
            label: "Plates",
            inventoryItemTypeCode: inventoryItemTypeMap.containerArray
          },
          dataTable: {
            label: "Placement List",
            dataItemTypeCode: dataItemTypeMap.dataTable
          }
        }
      }
    }
  },
  {
    code: "exportPlatesTool",
    Component: ExportPlatesTool,
    title: "Export Plates",
    disabled: window.frontEndConfig.disabledToolkits.workflowManagement,
    description: <span>This tool will export plates in different formats</span>,
    input: "Plates",
    output: "Plates",
    toolSchema: {
      input: {
        ioItems: {
          containerArrays: {
            isList: true,
            label: "Plates",
            inventoryItemTypeCode: inventoryItemTypeMap.containerArray
          }
        }
      },
      output: {
        ioItems: {
          containerArrays: {
            isUpdate: true,
            successPageMessage: numberOfItems =>
              `${numberOfItems} ${
                numberOfItems > 1 ? "Plates" : "Plate"
              } Exported`,
            isList: true,
            label: "Plates",
            inventoryItemTypeCode: inventoryItemTypeMap.containerArray
          }
        }
      },
      settings: {
        integrationSetting: {
          label: "Integration",
          integrationTypeCode: "EXPORT_CUSTOM_PLATE",
          workflowToolSettingDefinitionTypeCode: "INTEGRATION"
        }
      }
    }
  },
  {
    code: "createDataTableFromPlate",
    Component: CreateDataTableFromPlate,
    title: "Create Data Table from Plate",
    disabled: window.frontEndConfig.disabledToolkits.workflowManagement,
    description: (
      <span>
        This tool takes in one or more plates as input and generates a data
        table (currently limited to sample QC).
      </span>
    ),
    input: "Plates",
    output: "Data Table",
    toolSchema: {
      input: {
        ioItems: {
          containerArrays: {
            isList: true,
            label: "Plates",
            inventoryItemTypeCode: inventoryItemTypeMap.containerArray
          }
        }
      },
      output: {
        ioItems: {
          dataTables: {
            label: "Data Tables",
            dataItemTypeCode: dataItemTypeMap.dataTable
          }
        }
      }
    }
  },
  {
    code: "updateAliquotExtendedProperties",
    Component: UpdateAliquotExtendedProperties,
    title: "Update Aliquot Extended Properties",
    disabled: window.frontEndConfig.disabledToolkits.workflowManagement,
    description: (
      <span>
        This tool takes in a selection of plates/tubes and updates aliquot
        extended properties
      </span>
    ),
    input: "Plates/Tubes",
    output: "Plates/Tubes",
    toolSchema: {
      input: {
        ioItems: {
          containerArrays: {
            isList: true,
            label: "Plates",
            inventoryItemTypeCode: inventoryItemTypeMap.containerArray
          },
          aliquotContainers: {
            isList: true,
            label: "Tubes",
            inventoryItemTypeCode: inventoryItemTypeMap.aliquotContainer
          }
        }
      },
      output: {
        ioItems: {
          containerArrays: {
            isList: true,
            isUpdate: true,
            label: "Plates",
            inventoryItemTypeCode: inventoryItemTypeMap.containerArray
          },
          aliquotContainers: {
            isUpdate: true,
            isList: true,
            label: "Tubes",
            inventoryItemTypeCode: inventoryItemTypeMap.aliquotContainer
          }
        }
      }
    }
  },
  {
    code: "updateMaterialExtendedProperties",
    Component: UpdateMaterialExtendedProperties,
    title: "Update Material Extended Properties",
    disabled: window.frontEndConfig.disabledToolkits.workflowManagement,
    description: (
      <span>
        This tool takes in a selection of materials to update extended
        properties
      </span>
    ),
    input: "Materials, Reaction Maps",
    output: "Materials",
    toolSchema: {
      input: {
        ioItems: {
          materials: {
            isList: true,
            label: "Materials",
            inventoryItemTypeCode: inventoryItemTypeMap.material
          },
          reactionMaps: {
            isList: true,
            label: "Reaction Maps",
            dataItemTypeCode: dataItemTypeMap.reactionMap
          }
        }
      },
      output: {
        ioItems: {
          materials: {
            isList: true,
            isUpdate: true,
            label: "Materials",
            inventoryItemTypeCode: inventoryItemTypeMap.material
          }
        }
      },
      settings: {
        [updateMaterialExtPropsfieldConstants.selectAllReactionEntities]: {
          label: "Select All Reaction Map Entities",
          type: settingsTypes.checkbox
        },
        [updateMaterialExtPropsfieldConstants.reactionEntityType]: {
          label: "Reaction Map Entity Type",
          type: settingsTypes.select,
          options: [
            {
              label: "",
              value: ""
            }
          ].concat(getReactionEntityTypeOptions({ onlyMaterials: true }))
        }
      }
    }
  },
  {
    code: "updatePlate",
    Component: UpdatePlateTool,
    title: "Update Plates",
    disabled:
      window.frontEndConfig.disabledToolkits.materialsAndInventoryManagement,
    description: (
      <span>
        This tool can be used to update aliquots containing materials on a plate
        for volume, concentration, or mass, by uploading a CSV file.
      </span>
    ),
    input: "Plates",
    output: "Updated Plates",
    toolSchema: {
      input: {
        ioItems: {
          containerArrays: {
            isList: true,
            label: "Plates",
            inventoryItemTypeCode: inventoryItemTypeMap.containerArray
          },
          dataTable: {
            label: "Data Table",
            dataItemTypeCode: dataItemTypeMap.dataTable
          }
        }
      },
      output: {
        ioItems: {
          containerArrays: {
            isUpdate: true,
            isList: true,
            label: "Plates",
            inventoryItemTypeCode: inventoryItemTypeMap.containerArray
          }
        }
      }
    }
  },
  {
    code: "assayPlanningTool",
    deprecated: true,
    Component: AssayPlanningTool,
    title: "Assay Planning",
    disabled: window.frontEndConfig.disabledToolkits.workflowManagement,
    description: (
      <span>
        This tool initiates the Assay Queue workflow. It takes in a data table
        of microbial material aliquots as input and the Assay Planning CSV and
        outputs a plate map that contains plate layer info (e.g. pH) and
        reaction condition groups.
      </span>
    ),
    input: "Data Tables",
    output: "Plate Map",
    toolSchema: {
      input: {
        ioItems: {
          dataTables: {
            isList: true,
            label: "Data Tables",
            dataItemTypeCode: dataItemTypeMap.dataTable
          }
        }
      },
      output: {
        ioItems: {
          plateMapGroup: {
            label: "Plate Map",
            dataItemTypeCode: dataItemTypeMap.plateMapGroup
          },
          reactionMap: {
            label: "Reaction Map",
            dataItemTypeCode: dataItemTypeMap.reactionMap
          }
        }
      }
    }
  },
  {
    code: "executeReactionMapTool",
    Component: ExecuteReactionMapTool,
    title: "Execute Reaction Map",
    disabled: window.frontEndConfig.disabledToolkits.workflowManagement,
    description: (
      <span>
        This tool accepts plates of pooled samples and reaction maps as inputs.
        It then uses each sample's material composition to link it to the
        corresponding output material.
      </span>
    ),
    input: "Plates of Pooled Samples",
    output: "Plates with Updated Samples",
    toolSchema: {
      input: {
        ioItems: {
          containerArrays: {
            isList: true,
            label: "Plates",
            inventoryItemTypeCode: inventoryItemTypeMap.containerArray
          },
          reactionMaps: {
            isList: true,
            label: "Reaction Maps",
            dataItemTypeCode: dataItemTypeMap.reactionMap
          }
        }
      },
      output: {
        ioItems: {
          containerArrays: {
            isList: true,
            isUpdate: true,
            label: "Plates",
            inventoryItemTypeCode: inventoryItemTypeMap.containerArray
          }
        }
      }
    }
  },
  {
    code: "inventoryCheck",
    Component: InventoryCheckTool,
    title: "Inventory Check",
    disabled:
      window.frontEndConfig.disabledToolkits.materialsAndInventoryManagement,
    description: (
      <span>
        This tool takes as input a plate map or reaction map and searches
        inventory for samples containing the materials linked to the selected
        input. It outputs the updated plates and a data table to track them.
      </span>
    ),
    input:
      "Plate Map, Reaction Map, Plates, Assembly Reports, Samples, Materials",
    output: "Plates, Plate List, Sample List",
    toolSchema: {
      input: {
        ioItems: {
          plateMapGroup: {
            label: "Plate Map",
            dataItemTypeCode: dataItemTypeMap.plateMapGroup
          },
          reactionMaps: {
            isList: true,
            label: "Reaction Map",
            dataItemTypeCode: dataItemTypeMap.reactionMap
          },
          j5Reports: {
            isList: true,
            label: "DNA Assembly Reports",
            dataItemTypeCode: dataItemTypeMap.j5Report
          },
          samples: {
            isList: true,
            label: "Samples",
            inventoryItemTypeCode: inventoryItemTypeMap.sample
          },
          materials: {
            isList: true,
            label: "Materials",
            inventoryItemTypeCode: inventoryItemTypeMap.material
          },
          containerArrays: {
            isList: true,
            label: "Plates",
            inventoryItemTypeCode: inventoryItemTypeMap.containerArray
          }
        }
      },
      output: {
        ioItems: {
          containerArrays: {
            isList: true,
            isUpdate: true,
            successPageMessage: numberOfItems =>
              `${numberOfItems} ${
                numberOfItems > 1 ? "Plates" : "Plate"
              } Selected`,
            label: "Plates",
            inventoryItemTypeCode: inventoryItemTypeMap.containerArray
          },
          aliquotContainers: {
            isList: true,
            isUpdate: true,
            successPageMessage: numberOfItems =>
              `${numberOfItems} ${
                numberOfItems > 1 ? "Tubes" : "Tube"
              } Selected`,
            label: "Tubes",
            inventoryItemTypeCode: inventoryItemTypeMap.aliquotContainer
          },
          plateList: {
            label: "Plate List",
            dataItemTypeCode: dataItemTypeMap.dataTable
          },
          sampleList: {
            label: "Sample List",
            dataItemTypeCode: dataItemTypeMap.dataTable
          }
        }
      },
      settings: {
        [inventoryCheckFieldConstants.reactionEntityType]: {
          label: "Reaction Entity Type",
          type: settingsTypes.select,
          options: blankSelectOption.concat(
            reactionEntityTypeOptionsInventoryCheck
          )
        },
        [inventoryCheckFieldConstants.hideDryAliquots]: {
          label: "Hide Dry Aliquots",
          type: settingsTypes.checkbox
        },
        [inventoryCheckFieldConstants.showInvalidSamples]: {
          label: "Show Invalid Samples",
          type: settingsTypes.checkbox
        },
        [inventoryCheckFieldConstants.automateSampleSelectionMethod]: {
          label: "Automate Sample Selection Method",
          type: settingsTypes.select,
          options: blankSelectOption.concat(
            automateSelectionMethods.map(method => ({
              label: startCase(method),
              value: method
            }))
          )
        }
      }
    }
  },
  {
    code: "bacterialConjugation",
    Component: BacterialConjugation,
    title: "Bacterial Conjugation",
    disabled: window.frontEndConfig.disabledToolkits.workflowManagement,
    description: (
      <span>
        This tool allows users to transfer DNA materials between two cells.
      </span>
    ),
    input: `Plates or tubes of donor cells (microbial materials containing plasmids) and recipient cells\
    (microbial materials containing none or some plasmids). Note that materials in the input containers\
    of the donor cells will be consumed entirely.`,
    output:
      "Plates or tubes of recipient cells with plasmids coming from donor cells.",
    toolSchema: {
      input: {
        ioItems: {
          containerArrays: {
            isList: true,
            label: "Input Plates / Racks",
            inventoryItemTypeCode: inventoryItemTypeMap.containerArray
          },
          aliquotContainers: {
            isList: true,
            label: "Input Tubes",
            inventoryItemTypeCode: inventoryItemTypeMap.aliquotContainer
          }
        }
      },
      output: {
        ioItems: {
          worklist: {
            label: "Bacterial Conjugation Worklist",
            dataItemTypeCode: dataItemTypeMap.worklist
          },
          reactionMap: {
            label: "Bacterial Conjugation Reaction Map",
            dataItemTypeCode: dataItemTypeMap.reactionMap
          }
        }
      }
    }
  },
  {
    code: "sequencingQualityControlReportTool",
    Component: SequencingQualityControl,
    title: "DNA Sequencing Quality Control",
    disabled: window.frontEndConfig.disabledToolkits.labAutomation,
    description: (
      <span>
        This tool allows users to generate a sequencing quality-control report
        by aligning sequencing data against reference sequences, computing
        coverage, and identifying variants in sequenced samples.
      </span>
    ),
    input: "Sequencing data (FASTQ files)",
    output:
      "A set of quality-control reports, each corresponding to a sequenced sample.",
    toolSchema: {
      output: {
        ioItems: {
          sequencingQualityControlReport: {
            isList: true,
            label: "DNA Sequencing Quality Control Reports",
            dataItemTypeCode: dataItemTypeMap.sequencingQualityControlReport
          }
        }
      }
    }
  },
  {
    code: "sampleQCTool",
    Component: SampleQualityControl,
    title: "Sample Quality Control",
    disabled: window.frontEndConfig.disabledToolkits.workflowManagement,
    description: (
      <span>
        This tool allows users select input plates from inventory and upload a
        CSV or excel file of sample statuses to update the plate with. Two data
        tables will be output by the tool, one listing valid sequences and one
        listing invalid sequences.
      </span>
    ),
    input: "Plates and Sample Status Data",
    output:
      "Two data tables, one listing valid samples and one listing invalid samples.",
    toolSchema: {
      suggestedNextTools: [
        "aliquotRearrayTool",
        "j5Rework",
        "reactionMapRework",
        "sampleStatusPropagation"
      ],
      input: {
        ioItems: {
          dataTables: {
            isList: true,
            label: "Input Sample QC Lists",
            dataItemTypeCode: dataItemTypeMap.dataTable
          },
          containerArrays: {
            isList: true,
            label: "Input Plates / Racks",
            inventoryItemTypeCode: inventoryItemTypeMap.containerArray
          }
        }
      },
      output: {
        ioItems: {
          containerArrays: {
            isList: true,
            isUpdate: true,
            label: "Input Plates",
            inventoryItemTypeCode: inventoryItemTypeMap.containerArray
          },
          validSampleList: {
            label: "Valid Sample List from QC",
            dataItemTypeCode: dataItemTypeMap.dataTable
          },
          invalidSampleList: {
            label: "Invalid Sample List from QC",
            dataItemTypeCode: dataItemTypeMap.dataTable
          }
        }
      }
    }
  },
  {
    code: "sampleStatusPropagation",
    Component: SampleStatusPropagationTool,
    title: "Sample Status Propagation",
    disabled: window.frontEndConfig.disabledToolkits.workflowManagement,
    description: (
      <span>
        This tool takes in sample QC lists and applies sample statuses to parent
        samples. The tool outputs new sample QC lists of those parent samples.
      </span>
    ),
    input: "Sample QC Data Tables",
    output: "This tool updates parent samples and doesn't have any outputs.",
    toolSchema: {
      suggestedNextTools: [
        "aliquotRearrayTool",
        "j5Rework",
        "reactionMapRework"
      ],
      input: {
        ioItems: {
          dataTables: {
            isList: true,
            label: "Input Sample QC Lists",
            dataItemTypeCode: dataItemTypeMap.dataTable
          }
        }
      }
    }
  },
  // {
  //   code: "sequencingQCInspectionTool",
  //   Component: SequencingQCInspectionTool,
  //   title: "Sequencing Quality Control Inspection",
  //   description: (
  //     <span>
  //       This tool allows users to inspect sequencing quality control reports and
  //       make decisions on whether to approve or reject the assembly.
  //     </span>
  //   ),
  //   input: "Sequencing Quality Control Reports",
  //   output: "Approved/rejected status update",
  //   toolSchema: {
  //     input: {
  //       ioItems: {
  //         sequencingQualityControlReport: {
  //           // isList: true,
  //           label: "Sequencing Quality Control Report",
  //           dataItemTypeCode: dataItemTypeMap.sequencingQualityControlReport
  //         }
  //       }
  //     }
  //     // output: { ioItems: {} }
  //   }
  // },
  {
    code: "sampleMultiplexing",
    Component: TuplingTool,
    title: "Sample Multiplexing",
    disabled: window.frontEndConfig.disabledToolkits.labAutomation,
    description: (
      <span>
        This tool is used previous to the DNA sequencing process. It performs
        Sample Multiplexing on the aliquots of selected plates. Without
        "barcoding" the DNA elements, it finds the best way of pooling these
        aliquots together in order to reduce sequencing costs as much as
        possible.
      </span>
    ),
    input: "Plates",
    output: "Pooling instructions",
    toolSchema: {
      input: {
        ioItems: {
          containerArrays: {
            isList: true,
            isOptional: false,
            label: "Plates",
            inventoryItemTypeCode: inventoryItemTypeMap.containerArray
          }
        }
      },
      output: {
        ioItems: {
          dataTable: {
            isList: false,
            isOptional: false,
            label: "Table (pooling tool)",
            dataItemTypeCode: dataItemTypeMap.dataTable
          }
        }
      }
    }
  },
  {
    code: "concatenateWorklists",
    Component: ConcatenateWorklists,
    title: "Concatenate Worklists",
    disabled: window.frontEndConfig.disabledToolkits.labAutomation,
    description: <span>This tool is used to concatenate worklists.</span>,
    input: "Worklists",
    output: "Worklist",
    toolSchema: {
      input: {
        ioItems: {
          worklists: {
            isList: true,
            label: "Worklists",
            dataItemTypeCode: dataItemTypeMap.worklist
          }
        }
      },
      output: {
        ioItems: {
          worklist: {
            isList: false,
            label: "Worklist",
            dataItemTypeCode: dataItemTypeMap.worklist
          }
        }
      }
    }
  },
  {
    code: "twistOrderingTool",
    Component: TwistOrderingTool,
    title: "Twist Ordering",
    description: (
      <span>
        This tool allows you to select sequences to order linear, double
        stranded DNA fragments from{" "}
        <a href="https://twistbioscience.com/">Twist</a> The tool scores
        selected sequences to ensure they meet Twist's build constraints, prices
        the sequences, and outputs an order form.
      </span>
    ),
    input: "DNA Sequences",
    output: "Twist Order Form",
    toolSchema: {
      input: {
        ioItems: {
          dataTables: {
            isList: true,
            label: "Data Table of DNA to be ordered",
            dataItemTypeCode: dataItemTypeMap.dataTable
          },
          reactionMaps: {
            isList: true,
            label: "Reaction map of DNA to be ordered",
            dataItemTypeCode: dataItemTypeMap.reactionMap
          },
          sequences: {
            isList: true,
            label: "DNA Sequences to Order",
            inventoryItemTypeCode: inventoryItemTypeMap.sequence
          },
          j5Reports: {
            isList: true,
            label: "DNA Assembly Reports",
            dataItemTypeCode: dataItemTypeMap.j5Report
          }
        }
      },
      output: {
        ioItems: {
          vendorOrder: {
            label: "DNA Order",
            dataItemTypeCode: dataItemTypeMap.vendorOrder
          }
        }
      }
    }
  },
  {
    code: "idtOrderingTool",
    Component: IDTOrderingTool,
    title: "IDT Ordering",
    description: (
      <span>
        This tool allows you to select sequences to order linear, double
        stranded DNA fragments from <a href="https://www.idtdna.com">IDT</a> The
        tool scores selected sequences to ensure they meet IDT's build
        constraints, prices the sequences, and outputs an order form.
      </span>
    ),
    input: "DNA Sequences",
    output: "IDT Order Form",
    toolSchema: {
      input: {
        ioItems: {
          dataTables: {
            isList: true,
            label: "Data Table of DNA to be ordered",
            dataItemTypeCode: dataItemTypeMap.dataTable
          },
          reactionMaps: {
            isList: true,
            label: "Reaction map of DNA to be ordered",
            dataItemTypeCode: dataItemTypeMap.reactionMap
          },
          sequences: {
            isList: true,
            label: "DNA Sequences to Order",
            inventoryItemTypeCode: inventoryItemTypeMap.sequence
          },
          j5Reports: {
            isList: true,
            label: "DNA Assembly Reports",
            dataItemTypeCode: dataItemTypeMap.j5Report
          }
        }
      },
      output: {
        ioItems: {
          vendorOrder: {
            label: "DNA Order",
            dataItemTypeCode: dataItemTypeMap.vendorOrder
          }
        }
      },
      settings: {
        [idtOrderingFieldConstants.productType]: {
          label: "Product Type",
          type: settingsTypes.select,
          loadOptions: async () => {
            try {
              const [vendor] = await safeQuery(vendorFragment, {
                variables: {
                  pageSize: 1,
                  filter: {
                    cid: "idt_cid"
                  }
                }
              });
              return getIdtProductTypeOptions(vendor);
            } catch (error) {
              console.error(`error:`, error);
              window.toastr.error("Error loading product type options");
            }
          }
        },
        [idtOrderingFieldConstants.skipScoring]: {
          label: "Skip Scoring",
          type: settingsTypes.checkbox
        }
      }
    }
  },
  // TEST: Data Import workflow tools.
  {
    code: "sampleScreening",
    Component: SampleScreeningTool,
    title: "Sample Screening",
    disabled: window.frontEndConfig.disabledToolkits.dataAnalysis,
    description: (
      <span>
        This tool allows users to screen samples for specific properties and
        output a list of samples that meet the criteria.
      </span>
    ),
    input: "Data Grid",
    output: "Data Table",
    toolSchema: {
      input: {
        ioItems: {
          dataGrids: {
            isList: true,
            label: "Data Grid",
            dataItemTypeCode: "DATA_GRID"
          }
        }
      },
      output: {
        ioItems: {
          dataTables: {
            isList: true,
            label: "Data Table",
            dataItemTypeCode: "DATA_TABLE"
          }
        }
      }
    }
  },
  {
    code: "dataAssociation",
    Component: DataAssociationTool,
    title: "Data Association",
    workflowOnly: false,
    description: (
      <span>Associates experimental data with inventory items.</span>
    ),
    disabled: window.frontEndConfig.disabledToolkits.dataAnalysis,
    input: "Plate",
    output: "Data Grid",
    toolSchema: {
      // TODO: The I/Os aren't yet 100% decided.
      input: {
        ioItems: {
          containerArrays: {
            isList: true,
            label: "Plates",
            inventoryItemTypeCode: inventoryItemTypeMap.containerArray
          }
        }
      },
      output: {
        ioItems: {
          assay: {
            isList: false,
            label: "Data Grid",
            dataItemTypeCode: dataItemTypeMap.dataGrid
          }
        }
      },
      suggestedNextTools: [
        "sampleScreening"
        // "importExperimentData",
      ]
    }
  },
  {
    code: "parseExperimentData",
    Component: FileToDataGridTool,
    title: "Parse Experiment Data",
    disabled: window.frontEndConfig.disabledToolkits.workflowManagement,
    // workflowOnly: true,
    description: (
      <span>
        Parses experimental data in the form of a CSV or XLSX file, and stores
        the data in BUILD as a Data Grid (or Grids). Parsers are customizable
        and are set up as Node-RED flows within the Integration Server. A
        default "Simple Excel Parser" is provided as a template and functional
        example. Once the data is stored as a Data Grid in BUILD, the{" "}
        <Link
          to={{
            pathname: `/tools`,
            hash: "#import-experiment-data-into-assay",
            search: "?nofilter=true"
          }}
        >
          Import Experiment Data into Assay
        </Link>{" "}
        tool can be run to save the data in the TEST module and to finish
        associating data with final data names and data types, resulting in an
        assay ready for analysis.
      </span>
    ),
    input: "Data File",
    output: "Data Grid(s)",
    toolSchema: {
      input: {
        ioItems: {
          dataFile: {
            isList: false,
            label: "Input File",
            dataItemTypeCode: dataItemTypeMap.dataFile
          }
        }
      },
      output: {
        ioItems: {
          dataGrids: {
            isList: true,
            label: "Data Grids",
            dataItemTypeCode: dataItemTypeMap.dataGrid
          }
        }
      },
      settings: {
        integrationSetting: {
          label: "Integration",
          integrationTypeCode: "FILE_TO_DATAGRID",
          workflowToolSettingDefinitionTypeCode: "INTEGRATION"
        }
      }
    }
  },
  {
    code: "importExperimentData",
    Component: DataGridToDataLake,
    title: "Import Experiment Data into Assay",
    disabled: window.frontEndConfig.disabledToolkits.dataAnalysis,
    description: (
      <span>
        This tool finishes the parsing of assay data begun with the{" "}
        <Link
          to={{
            pathname: `/tools`,
            hash: "#parse-experiment-data",
            search: "?nofilter=true"
          }}
        >
          Parse Experiment Data
        </Link>{" "}
        tool, by mapping the columns of a data grid with well-defined data names
        and data types that can be used for downstream analysis and machine
        learning in either the TEST module or the DISCOVER module. These precise
        data names and data types must be pre-configured as metadata in the TEST
        module.
      </span>
    ),
    input: "Data Grid",
    output: "Assay",
    toolSchema: {
      input: {
        ioItems: {
          dataGrids: {
            isList: true,
            // Right now the tool supports importing one dataGrid at a time
            // because it may sometimes be a long running task.
            // However we are setting 'inList: true' anyways to be able to connect the output
            // of the previous tool: 'code: "parseExperimentData"'.

            // TODO: Maybe refactor it so it can support submitting multiple import jobs at once.
            label: "Data Grid",
            dataItemTypeCode: dataItemTypeMap.dataGrid
          }
        }
      },
      output: {
        ioItems: {
          assay: {
            isList: false,
            label: "Assay",
            dataItemTypeCode: dataItemTypeMap.assay
          }
        }
      }
    }
  },
  {
    code: "gRNAScorePredictionTool",
    notWorkflow: true,
    Component: GuideRNAScorePredictionTool,
    title: "Guide RNA Score Prediction",
    description: (
      <span>
        This tool proposes gRNA sequences for a given target sequence, microbial
        material and cas enzyme. For each gRNA sequence given, it predicts the
        on target score, off target score, KO score, the binding site and a
        proposed TeselaGen ranking.
      </span>
    ),
    input: "Microbial Material, Target Sequence and Cas Enzyme",
    output: "gRNA Sequences and Scores",
    toolSchema: {
      input: {
        ioItems: {
          microbialMaterial: {
            isList: false,
            label: "Microbial Material",
            inventoryItemTypeCode: "MATERIAL"
          }
          // TODO: There are other inputs which are currently not defined in the dm.
        }
      }
    }
  },
  {
    code: "CRISPRKnockinDesign",
    Component: CRISPRKnockinsDesignTool,
    title: "CRISPR Knockin Design",
    description: (
      <span>
        This tool takes processed gRNA sequences for a given genomic region and
        cas enzyme, homology arms and a HDR donor content. It will create a
        repair template sequence and optionally an edited genomic region.
      </span>
    ),
    input:
      "Genomic Region, gRNA, Cas Enzyme, Homology Arms and HDR Donor sequence",
    output: "Repair template sequence, edited genomic region",
    toolSchema: {
      input: {
        ioItems: {
          microbialMaterial: {
            isList: false,
            label: "Microbial Material",
            inventoryItemTypeCode: "MATERIAL"
          },
          // Technically an RNA sequence, but in taskIoType, DNA_SEQUENCE maps to any Sequence
          guideRna: {
            isList: false,
            label: "Guide RNA",
            inventoryItemTypeCode: "DNA_SEQUENCE"
          }
        }
      },
      output: {
        ioItems: {
          crisprDesign: {
            isList: false,
            label: "CRISPR Design",
            dataItemTypeCode: "CRISPR_DESIGN"
          }
        }
      }
    }
  },

  {
    code: "partitionTool",
    Component: DesignFromPartition,
    title: "Create Design From Partition Tool",
    disabled: window.frontEndConfig.disabledToolkits.advancedMolecularBiology,
    description: <span>Creates a Design by Partitioning A DNA Sequence.</span>,
    input: "DNA Sequence",
    output: "Design",
    toolSchema: {
      input: {
        ioItems: {
          dnaSequence: {
            isList: false,
            label: "DNA Sequence",
            inventoryItemTypeCode: "DNA_SEQUENCE"
          }
        }
      },
      output: {
        ioItems: {
          design: {
            isList: false,
            label: "Design",
            dataItemTypeCode: "DESIGN"
          }
        }
      }
    }
  },
  {
    code: "digestDesignTool",
    Component: DesignFromDigestPartsTool,
    title: "Create Design From Digest Parts",
    description: (
      <span>
        Creates a Design from a list of Digest Parts. Parts detected as vector
        backbones as placed in the first column, subsequent parts are ordered
        into columns based on matching 5' to 3' overhangs.{" "}
      </span>
    ),
    input: "Digest Parts",
    output: "Design",
    toolSchema: {
      input: {
        ioItems: {
          dnaSequence: {
            isList: true,
            label: "DNA Part",
            dataItemTypeCode: "DNA_PART"
          }
        }
      },
      output: {
        ioItems: {
          design: {
            isList: false,
            label: "Design",
            dataItemTypeCode: "DESIGN"
          }
        }
      }
    }
  }

  // ,
  // {
  //   title: "DNA Assembly Reaction Planning",
  //   description: `This tool takes one or more primer plate mappings and allows the user to
  //   create either a custom order form, or an order form in the shape of one
  //   of the predefined vendors. First select the primer plate mappings, then
  //   define the order form schema and mapping, and finally download the order
  //   form as a csv or excel file.`
  // },
  // {
  //   title: "Liquid Handling",
  //   description: `This tool takes one or more primer plate mappings and allows the user to
  //   create either a custom order form, or an order form in the shape of one
  //   of the predefined vendors. First select the primer plate mappings, then
  //   define the order form schema and mapping, and finally download the order
  //   form as a csv or excel file.`
  // },
  // {
  //   title: "Colony Picking",
  //   description: `This tool takes one or more primer plate mappings and allows the user to
  //   create either a custom order form, or an order form in the shape of one
  //   of the predefined vendors. First select the primer plate mappings, then
  //   define the order form schema and mapping, and finally download the order
  //   form as a csv or excel file.`
  // },
  // {
  //   title: "DNA Extraction",
  //   description: `This tool takes one or more primer plate mappings and allows the user to
  //   create either a custom order form, or an order form in the shape of one
  //   of the predefined vendors. First select the primer plate mappings, then
  //   define the order form schema and mapping, and finally download the order
  //   form as a csv or excel file.`
  // },
  // {
  //   title: "Transformation",
  //   description: `This tool takes one or more primer plate mappings and allows the user to
  //   create either a custom order form, or an order form in the shape of one
  //   of the predefined vendors. First select the primer plate mappings, then
  //   define the order form schema and mapping, and finally download the order
  //   form as a csv or excel file.`
  // },
  // {
  //   title: "Sequence Validation",
  //   description: `This tool takes one or more primer plate mappings and allows the user to
  //   create either a custom order form, or an order form in the shape of one
  //   of the predefined vendors. First select the primer plate mappings, then
  //   define the order form schema and mapping, and finally download the order
  //   form as a csv or excel file.`
  // },
  // {
  //   title: "Incubation",
  //   description: `This tool takes one or more primer plate mappings and allows the user to\
  //   create either a custom order form, or an order form in the shape of one\
  //   of the predefined vendors. First select the primer plate mappings, then\
  //   define the order form schema and mapping, and finally download the order\
  //   form as a csv or excel file.`
  // }
];

// the name property of the toolschema is used in workflows. just copy the title for it to
// keep it consistent.
toolSchemas.forEach(schema => {
  if (schema.toolSchema) {
    schema.toolSchema.name = schema.title;
  }
  if (inDevelopment) {
    const inputIoItems = schema.toolSchema?.input?.ioItems;
    const outputIoItems = schema.toolSchema?.output?.ioItems;
    if (inputIoItems) {
      validateIoItems(inputIoItems, schema);
    }
    if (outputIoItems) {
      validateIoItems(outputIoItems, schema);
    }
  }
});

function validateIoItems(ioItems, schema) {
  forEach(ioItems, (ioItem, key) => {
    if (
      ioItem.inventoryItemTypeCode &&
      !inventoryItemTypeCodeToModel[ioItem.inventoryItemTypeCode]
    ) {
      throw new Error(
        `Invalid inventoryItemTypeCode ${ioItem.inventoryItemTypeCode} for ioItem ${key} in tool ${schema.code}`
      );
    } else if (
      ioItem.dataItemTypeCode &&
      !dataItemTypeCodeToModel[ioItem.dataItemTypeCode]
    ) {
      throw new Error(
        `Invalid dataItemTypeCode ${ioItem.dataItemTypeCode} for ioItem ${key} in tool ${schema.code}`
      );
    } else if (!ioItem.inventoryItemTypeCode && !ioItem.dataItemTypeCode) {
      console.error(`ioItem:`, ioItem);
      throw new Error(
        `ioItem ${key} (label ${ioItem.label}) in tool ${schema.code} does not have a valid inventoryItemTypeCode or dataItemTypeCode`
      );
    }
  });
}

// sort the tools by disabled first then by title
export default toolSchemas.sort((a, b) => {
  if (a.disabled && !b.disabled) {
    return 1;
  } else if (!a.disabled && b.disabled) {
    return -1;
  }
  return a.title.localeCompare(b.title);
});

const keyedFullToolSchemas = toolSchemas.reduce((acc, schema) => {
  acc[schema.code] = schema;
  return acc;
}, {});
const keyedToolSchemas = toolSchemas.reduce((acc, schema) => {
  acc[schema.code] = schema.toolSchema;
  return acc;
}, {});

const toolSchemasForLibrary = filterToolSchemasForLibrary(toolSchemas);

export { keyedFullToolSchemas, keyedToolSchemas, toolSchemasForLibrary };
