/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { useContext } from "react";
import { InjectedFormProps, reduxForm } from "redux-form";
import { ReactSelectField } from "@teselagen/ui";
import { Button, Callout } from "@blueprintjs/core";
import { safeUpsert } from "../apolloMethods";
import CurrentUserContext from "../context/CurrentUserContext";

type ValuesType = {
  defaultProjectId: string;
};

const DefaultUserProjectPanel = ({
  handleSubmit,
  submitting
}: InjectedFormProps<ValuesType>) => {
  const { currentUser } = useContext(CurrentUserContext);
  return (
    <div>
      <div className="tg-card ">
        <h3>Default Project</h3>
        <Callout style={{ marginBottom: 20 }} intent="primary">
          This is the project that will be selected by default when you log in.
          It will not override the last selected project if one is found.
        </Callout>
        <div style={{ maxWidth: 250 }}>
          <ReactSelectField
            label="Default Project"
            name="defaultProjectId"
            isRequired
            defaultValue={currentUser!.defaultProjectId}
            options={[
              {
                label: "None",
                value: "none"
              }
            ].concat(
              currentUser!.projectRoles.map(pr => ({
                label: pr.project.name,
                value: pr.project.id
              }))
            )}
          />
        </div>
        <div>
          <Button
            intent="primary"
            onClick={handleSubmit(async (values: ValuesType) => {
              try {
                await safeUpsert("user", {
                  id: currentUser!.id,
                  defaultProjectId:
                    values.defaultProjectId === "none"
                      ? null
                      : values.defaultProjectId
                });
                window.toastr.success("Default project updated");
              } catch (error) {
                console.error(`error:`, error);
                window.toastr.error("Error updating default project");
              }
            })}
            loading={submitting}
            text="Update Default Project"
          />
        </div>
      </div>
    </div>
  );
};

export default reduxForm<ValuesType>({
  form: "defaultUserProject"
})(DefaultUserProjectPanel);
