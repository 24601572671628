/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";

export type CurrentUserType = {
  username: String;
  id: string;
  projectRoles: { project: { name: string; id: string } }[];
  labRoles: [];
  email: string;
  defaultProjectId: string;
};

const CurrentUserContext = React.createContext<{
  currentUser?: CurrentUserType;
  refetchCurrentUser?: () => void;
}>({});

export default CurrentUserContext;
