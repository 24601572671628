/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { Callout } from "@blueprintjs/core";
import React from "react";
const adminMessage = "You are an Admin";

function AdminCallout({ children }) {
  return (
    <Callout style={{ marginBottom: 15 }} intent="primary" title={adminMessage}>
      {children}
    </Callout>
  );
}

export default AdminCallout;
