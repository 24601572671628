/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { keyBy } from "lodash";
import urlJoin from "url-join";
import axios from "axios";
import { isoContext } from "@teselagen/utils";

export async function installIntegrationBundle(
  {
    nodeRedFileUrl,
    integrationHookFileUrl,
    urlPrefix,
    id,
    //do these need to come into play?
    name,
    headers,
    nodeRedEditorUrl = "/node-red-editor",
    nodeRedFile,
    integrationHook
  },
  ctx = isoContext
) {
  //fetch the integration and the flow

  try {
    if (!nodeRedFile) {
      nodeRedFile = await axios.get(
        urlJoin(urlPrefix, "integrationBundles", nodeRedFileUrl),
        {
          headers
        }
      );
      nodeRedFile = nodeRedFile.data;
    }
    if (!integrationHook) {
      integrationHook = await axios.get(
        urlJoin(urlPrefix, "integrationBundles", integrationHookFileUrl),
        { headers }
      );
      integrationHook = integrationHook.data;
    }
    const [integration] = await ctx.safeUpsert("integration", {
      ...integrationHook,
      cid: id,
      name
    });

    // // Authorization: `Bearer ${window.localStorage.getItem(
    // //   "authToken-teselagen"
    // // )}`,
    await axios({
      url: urlJoin(nodeRedEditorUrl, "/flow"),
      method: "POST",
      headers: {
        ...headers,
        "Content-type": "application/json"
      },
      data: {
        label: nodeRedFile[0].label,
        nodes: nodeRedFile.slice(1)
      }
    });
    return integration.id;
  } catch (error) {
    console.error(`error installing integration ${id}:`, error);
  }
}

export async function checkIfBundlesHaveBeenInstalled(ids, ctx = isoContext) {
  //check based on the id if the integration has been installed
  const integrations = await ctx.safeQuery(["integration", "id cid"], {
    variables: {
      filter: {
        cid: ids
      }
    }
  });
  const allCids = keyBy(integrations, "cid");
  const toInstallIdsMap = {};
  ids.forEach(id => {
    toInstallIdsMap[id] = !allCids[id];
  });
  return toInstallIdsMap;
}
