/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { Button, Icon } from "@blueprintjs/core";
import React, { useState } from "react";
import Helmet from "react-helmet";
import { TgSelect } from "@teselagen/ui";

import { getApiKey } from "../auth/utils";
import appGlobals from "../appGlobals";

const ApiLoginPanel = () => {
  const [apiToken, setApiToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const [apiTokenExpiration, setApiTokenExpiration] = useState("1y");
  return (
    <div>
      <Helmet>
        <title>API Login (DEVELOPMENT ONLY)</title>
      </Helmet>

      <div className="tg-card xtra-pad">
        <h3>Login to the API (Generates an API token) (DEVELOPMENT ONLY)</h3>
        <div className="settings-footer-l">
          {/* ask how long should the token last for:  */}
          <div
            style={{
              display: "inline-flex",
              alignItems: "center",
              marginBottom: "1em"
            }}
          >
            <label>Token Expiration:</label>
            <TgSelect
              value={apiTokenExpiration}
              onChange={v => setApiTokenExpiration(v)}
              options={[
                { value: "1y", label: "1 year" },
                { value: "1m", label: "1 month" },
                { value: "1w", label: "1 week" },
                { value: "1d", label: "1 day" },
                { value: "1h", label: "1 hour" }
              ]}
            ></TgSelect>
          </div>
          <div>
            <Button
              intent="success"
              icon="key"
              loading={loading}
              onClick={async () => {
                // first generate a OTP
                setLoading(true);
                const data = await getApiKey();
                const resp = await window.tgApi({
                  method: "PUT",
                  url: `/public/auth`,
                  data: {
                    email: appGlobals.currentUser.email,
                    username: appGlobals.currentUser.username,
                    password: data.apiKey,
                    expiresIn: apiTokenExpiration
                  }
                });
                if (resp.data?.token) {
                  setApiToken(resp.data.token);
                }
                setLoading(false);
              }}
            >
              Generate New Token
            </Button>
          </div>
          <div>
            {apiToken && (
              <div>
                <h4
                  style={{
                    marginTop: "1em",
                    display: "inline-flex",
                    alignItems: "center"
                  }}
                >
                  <Icon icon="tick-circle" intent="primary" /> &nbsp; Token:{" "}
                </h4>
                <div
                  style={{
                    wordWrap: "break-word"
                  }}
                >
                  {apiToken}
                </div>

                <h4
                  style={{
                    marginTop: "1em",
                    display: "inline-flex",
                    alignItems: "center"
                  }}
                >
                  <Icon icon="manual" intent="primary" /> &nbsp; Instructions:{" "}
                </h4>
                <div>
                  Copy the token now, it will not be presented again. You can
                  generate a new one. Remember to store it.
                </div>
                <div>Use this token as a header on API calls like so:</div>
                <br></br>
                <br></br>
                <code>"x-tg-api-token": token</code>
                <br></br>
                <br></br>
                <div>
                  Or use it to login to the API docs page via the Authorize
                  button at the top.
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApiLoginPanel;
