/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { DataTable } from "@teselagen/ui";
import { get } from "lodash";

import DescriptionComponent from "./DescriptionComponent";

const schema = {
  model: "defaultPositions",
  fields: [
    {
      displayName: "Name",
      path: "name"
    },
    {
      displayName: "Label",
      path: "label"
    },
    {
      displayName: "Index",
      path: "index"
    }
  ]
};

class DescriptionWithDefaultPositions extends Component {
  renderDefaultPositions = () => {
    const { record, model } = this.props;
    const defaultPositions = get(
      record,
      `${model.replace("Type", "")}DefaultPositions`,
      []
    );
    if (!defaultPositions.length) return;
    return (
      <div>
        <div className="location-setting-description">Default Positions</div>
        <DataTable
          formName="defaultPositions"
          entities={defaultPositions}
          schema={schema}
          isSimple
          compact
        />
      </div>
    );
  };

  render() {
    const { record, model } = this.props;
    const extraFieldFilter = {
      [`${model.replace("Type", "")}DefaultPositions`]: true,
      isPositional: true,
      equipmentItems: true,
      containers: true
    };
    return (
      <div>
        <DescriptionComponent
          record={record}
          extraFieldFilter={extraFieldFilter}
        />
        {this.renderDefaultPositions()}
      </div>
    );
  }
}

export default DescriptionWithDefaultPositions;
