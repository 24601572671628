/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { Enum } from "../utils/generalUtils";

export const ReportStatusEnum = Enum({
  SAVING: "Saving to database",
  ERROR: "Error",
  COMPLETED: "Completed",
  COMPLETED_WITH_WARNINGS: "Completed with warnings"
});

/**
 * Given a j5 report object, check if it has any errors.
 * @param {Object} j5Report Must have the `j5LogMessages` array attached to it.
 */
export const j5ReportHasErrors = j5Report =>
  j5Report.status === "errored" ||
  j5Report.j5LogMessages.some(m => m.j5LogMessageTypeCode === "ERROR");

/**
 * Determines the status of a j5 report.
 *
 * This function evaluates the status of a given j5 report object based on its `status` property
 * and the contents of its `j5LogMessages` array. It returns an appropriate status from the
 * `ReportStatus` enumeration which can be one of: SAVING, ERROR, COMPLETED, or COMPLETED_WITH_WARNINGS.
 *
 * @param {Object} params - The parameters object.
 * @param {Object} params.j5Report - The j5 report object to evaluate.
 *        This object must contain a `status` property and a `j5LogMessages` array.
 * @returns {string} The status of the j5 report as a string. The value is one of the properties
 *          of the `ReportStatus` enum, namely: 'Saving to database', 'Error', 'Completed', or
 *          'Completed with warnings'.
 *
 * @example
 * // Example usage
 * const report = {
 *   status: 'saving',
 *   j5LogMessages: [{ j5LogMessageTypeCode: 'WARNING', message: 'Sample message' }]
 * };
 * const status = getReportStatus({ j5Report: report });
 * console.log(status); // Outputs: "Saving to database"
 */
export function getReportStatus({ j5Report }) {
  if (!j5Report || typeof j5Report !== "object") {
    throw new Error(`Invalid j5Report object, received ${j5Report}`);
  }

  const { status, j5LogMessages } = j5Report;

  if (typeof status !== "string" && status !== null) {
    throw new Error('j5Report object is missing a valid "status" property');
  }

  if (status === "saving") {
    return ReportStatusEnum.SAVING;
  }

  if (!Array.isArray(j5LogMessages)) {
    throw new Error('j5Report object is missing a valid "j5LogMessages" array');
  }

  if (j5ReportHasErrors(j5Report)) {
    return ReportStatusEnum.ERROR;
  } else if (!j5LogMessages.length) {
    return ReportStatusEnum.COMPLETED;
  } else {
    return ReportStatusEnum.COMPLETED_WITH_WARNINGS;
  }
}
