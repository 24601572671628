/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose } from "redux";
import { DataTable } from "@teselagen/ui";
import { Button, Classes, Intent } from "@blueprintjs/core";
import PropTypes from "prop-types";
import { startCase } from "lodash";
import Helmet from "react-helmet";
import modelNameToLink from "../../../../../src-shared/utils/modelNameToLink";

import LabelSettingsContextMenu from "./LabelSettingsContextMenu";
import { pushHelper } from "../../../../../src-shared/utils/pushHelper";

/**
 * Generic component to prevent code duplication in the label settings panel.
 *
 * Renders a card with data table showing all items of that type and a button
 * to create new items. Users can edit and delete items.
 */
class LabelPanel extends React.Component {
  static propTypes = {
    /**
     * The name of the models that we intend to display.
     */
    model: PropTypes.string.isRequired,

    /**
     * A standard @teselagen/ui `tableParams` object.
     */
    tableParams: PropTypes.object.isRequired,

    /**
     * A React component comprising the dialog for creating/editing new items.
     * The component should be wrapped with `withDialog`. The dialog will be
     * rendered with the props: `hideModal`, `refetch`, `initialValues`.
     */
    Dialog: PropTypes.func,

    /**
     * Refetch the items in the data table.
     */
    refetch: PropTypes.func.isRequired,

    /**
     * Function that will get called on the selected record when generating
     * the initial values for the dialog.
     */
    getDialogInitialValues: PropTypes.func
  };

  static defaultProps = {
    getDialogInitialValues: selectedRecord => selectedRecord
  };

  state = {
    isDialogOpen: false,
    selectedRecord: null
  };

  selectRecord = record => {
    this.setState({
      selectedRecord: record
    });
  };

  editRecord = record => {
    const { model, Dialog, history } = this.props;
    if (Dialog) {
      this.setState({
        selectedRecord: record,
        isDialogOpen: true
      });
    } else {
      history.push(modelNameToLink(model, record.id));
    }
  };

  toggleModal = () =>
    this.setState(({ isDialogOpen }) => ({
      isDialogOpen: !isDialogOpen,
      selectedRecord: null
    }));

  handleContextMenu = ({ selectedRecords }) => {
    const { model, refetch } = this.props;

    return (
      <LabelSettingsContextMenu
        model={model}
        refetch={refetch}
        selectedRecords={selectedRecords}
        editRecord={this.editRecord}
      />
    );
  };

  renderDataTable() {
    const { tableParams, model, Dialog, readOnly } = this.props;
    return (
      <DataTable
        {...tableParams}
        tableName={startCase(model)}
        hideSelectedCount
        onSingleRowSelect={this.selectRecord}
        onDeselect={this.selectRecord}
        contextMenu={!readOnly && this.handleContextMenu}
        onDoubleClick={!readOnly && this.editRecord}
      >
        {!readOnly && (
          <Button
            className={Classes.MINIMAL}
            intent={Intent.SUCCESS}
            text={"Create New " + startCase(model)}
            onClick={
              Dialog
                ? this.toggleModal
                : e => pushHelper(e, modelNameToLink(model) + "/new")
            }
            icon="add"
          />
        )}
      </DataTable>
    );
  }

  getDialogTitle() {
    return `${this.state.selectedRecord ? "Edit" : "Create New"} ${startCase(
      this.props.model
    )}`;
  }

  /**
   * Nothing will get rendered if the dialog isn't open.
   */
  renderDialog() {
    const { Dialog: DialogComp, refetch, getDialogInitialValues } = this.props;
    if (!DialogComp) return;
    const { isDialogOpen, selectedRecord } = this.state;
    if (!isDialogOpen) return null;

    const dialogTitle = this.getDialogTitle();

    return (
      <DialogComp
        noTarget
        dialogProps={{
          isOpen: isDialogOpen,
          title: dialogTitle
        }}
        hideModal={this.toggleModal}
        refetch={refetch}
        initialValues={getDialogInitialValues(selectedRecord)}
      />
    );
  }

  render() {
    const { model } = this.props;
    const upperModel = startCase(model);
    return (
      <div className="tg-card">
        <Helmet title={upperModel} />
        {this.renderDialog()}
        {this.renderDataTable()}
      </div>
    );
  }
}

export default compose()(LabelPanel);
