/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
// Copyright (C) 2018 TeselaGen Biotechnology, Inc.

import { Button } from "@blueprintjs/core";
import classNames from "classnames";
import { isEmpty } from "lodash";
import React, { useMemo } from "react";
import StepForm from "../../components/StepForm";
import { EvolveConfig } from "../../configs/config";
import createModelStepFormStyles from "./createModelStepFormStyles.module.css";
import ConfigurationStep from "./Steps/configurationStep";
import SelectData from "./Steps/selectDataStep";

const TAG = "[CreateGenerationModelForm]:";
const {
  TRAIN_PREDICTIVE_MODEL_ROUTE,
  TRAIN_EVOLUTIVE_MODEL_ROUTE,
  TRAIN_GENERATIVE_MODEL_ROUTE,
  TRAIN_MODEL_ENDPOINT,
  DESCRIPTOR_COLUMN_TYPE,
  UNASSIGNED_VALUE,
  TARGET_COLUMN_TYPE,
  AA_SEQUENCE_VALUE,
  PREDICTIVE_MODEL,
  EVOLUTIVE_MODEL,
  GENERATIVE_MODEL
} = EvolveConfig.constants;

const validateSubmission = formValues => {
  const dataSchema = formValues.modelDataColumnSchema;
  const dataSchemaWithNoUnassignedColumns =
    formValues.modelDataColumnSchema.filter(
      column => column.type !== EvolveConfig.constants.UNASSIGNED_COLUMN_TYPE
    );
  // The following lines of code will perform validation on the form values.
  // First. Check that there's only ONE target column.
  let targetCounter = 0;
  dataSchema.forEach(column => {
    if (column.type === TARGET_COLUMN_TYPE) targetCounter += 1;
  });
  if (targetCounter === 0)
    return {
      validationPass: false,
      errorMsg: "A target column needs to be selected."
    };
  else if (targetCounter > 1)
    return {
      validationPass: false,
      errorMsg: "Only a single target column needs to be selected."
    };

  // Second. Check that there are descriptor columns.
  let descriptorCounter = 0;
  dataSchema.forEach(column => {
    if (column.type === DESCRIPTOR_COLUMN_TYPE) descriptorCounter += 1;
  });
  if (descriptorCounter === 0)
    return {
      validationPass: false,
      errorMsg: "At least one descriptor column needs to be selected."
    };

  // Third. Checks that all assigned columns have their value type selected.
  let unassignedValueTypeCounter = 0;
  dataSchemaWithNoUnassignedColumns.forEach(column => {
    if (column.value_type === UNASSIGNED_VALUE) unassignedValueTypeCounter += 1;
  });
  if (unassignedValueTypeCounter !== 0)
    return {
      validationPass: false,
      errorMsg:
        "Assigned (descriptor/target) columns need their value type defined."
    };
  return { validationPass: true, errorMsg: "" };
};

const onSubmit = async formValues => {
  // console.log("formvalues", formValues);
  const { validationPass, errorMsg } = validateSubmission(formValues);
  if (validationPass) {
    const name = formValues.modelName;
    const description = formValues.modelDescription;
    // const taskInput = { job: TRAIN_MODEL_JOB, kwargs: {} };
    // This removes the unassigned columns from the modelData.
    const dataSchema = formValues.modelDataColumnSchema.filter(
      column => column.type !== EvolveConfig.constants.UNASSIGNED_COLUMN_TYPE
    );
    formValues.modelData.forEach(row => {
      Object.keys(row)
        .filter(key => !dataSchema.map(column => column.name).includes(key))
        .forEach(key => {
          delete row[key];
        });
    });

    const dataInput = formValues.modelData;

    // This configuration object is for the EVOLVE MVP. Eventually some user defined configurations can be included here (s.a. which algorithm to use for training).
    const configs = formValues.modelConfiguration;

    const createModelData = {
      // taskInput, This is moved to evolve-lib
      dataInput,
      dataSchema,
      modelType: formValues.modelType,
      configs,
      name,
      description,
      // These are pre-trained predictive and/or generative model IDs used to initialize the new created model.
      predictiveModelId: formValues.predictiveModelId,
      generativeModelId: formValues.generativeModelId
    };
    try {
      await window.serverApi.request({
        method: "post",
        url: TRAIN_MODEL_ENDPOINT,
        withCredentials: true,
        data: createModelData
      });
      return {};
    } catch (error) {
      console.error(error);
      alert("Something went wrong. Please try again.");
    }
  } else {
    alert(errorMsg);
  }
};

const formatDataForGenerationsSubmission = data => {
  if (isEmpty(data)) {
    const errorMessage = "Data can't be empty.";
    console.error(TAG, errorMessage);
    throw new Error(errorMessage);
  }

  const name = data.modelName;

  const dataWithSequenceKey = [];

  // This only keeps valid sequences
  if (data.filterInvalidSequences) {
    data.modelData.forEach((row, idx) => {
      if (!data.filteredSequences.includes(idx.toString()))
        dataWithSequenceKey.push({
          sequence: row[data.aminoAcidSequencesColumnName]
        });
    });
  } else {
    data.modelData.forEach(row => {
      dataWithSequenceKey.push({
        sequence: row[data.aminoAcidSequencesColumnName]
      });
    });
  }

  const description =
    data.modelDescription &&
    data.modelDescription !==
      EvolveConfig.constants.GENERATIONS_ADVANCED_MODE_SECRET
      ? data.modelDescription
      : "";
  const formattedData = {
    // taskInput: taskInput, // This is moved to evolve-lib
    dataInput: dataWithSequenceKey,
    dataSchema: [
      {
        id: 0,
        name: "sequence",
        type: TARGET_COLUMN_TYPE,
        value_type: AA_SEQUENCE_VALUE
      }
    ],
    modelType: GENERATIVE_MODEL,
    // There's the frontEndConfig.evolveHardwareOverride var, which is useful for PR instance where we might wanna test GANs on CPUs.
    // window.frontEndConfig.evolveHardwareOverride
    configs:
      data.modelConfiguration || EvolveConfig.modelConfigs[GENERATIVE_MODEL],
    name: name,
    description: description,
    // These are pre-trained predictive and/or generative model IDs used to initialize the new created model.
    generativeModelId: data.generativeModelId
  };

  return formattedData;
};

const onGenerationsSubmit = async formValues => {
  if (
    !isEmpty(formValues.globalIssues) ||
    !isEmpty(formValues.sequencesIssues)
  ) {
    const errorMessage = "You can't submit a new model with validation issues.";
    console.error(TAG, errorMessage);
    throw new Error(errorMessage);
  }

  const formattedData = formatDataForGenerationsSubmission(formValues);

  try {
    await window.serverApi.request({
      method: "post",
      url: TRAIN_MODEL_ENDPOINT,
      withCredentials: true,
      data: formattedData
    });
    return {};
  } catch (error) {
    console.error(error);
    alert("Something went wrong. Please try again.");
  }
};

const CreateModelStepForm = ({ history }) => {
  const modelType = useMemo(() => {
    if (history.location.pathname.includes(TRAIN_PREDICTIVE_MODEL_ROUTE))
      return PREDICTIVE_MODEL;
    else if (history.location.pathname.includes(TRAIN_EVOLUTIVE_MODEL_ROUTE))
      return EVOLUTIVE_MODEL;
    else if (history.location.pathname.includes(TRAIN_GENERATIVE_MODEL_ROUTE))
      return GENERATIVE_MODEL;
  }, [history.location.pathname]);

  const modelConfiguration = useMemo(() => {
    if (history.location.pathname.includes(TRAIN_PREDICTIVE_MODEL_ROUTE))
      return EvolveConfig.modelConfigs[PREDICTIVE_MODEL];
    else if (history.location.pathname.includes(TRAIN_EVOLUTIVE_MODEL_ROUTE))
      return EvolveConfig.modelConfigs[EVOLUTIVE_MODEL];
    else if (history.location.pathname.includes(TRAIN_GENERATIVE_MODEL_ROUTE))
      return EvolveConfig.modelConfigs[GENERATIVE_MODEL];
  }, [history.location.pathname]);

  return (
    <div className="view-container">
      <div
        className={classNames(
          createModelStepFormStyles.margins,
          createModelStepFormStyles.paddings,
          createModelStepFormStyles.widths
        )}
      >
        <StepForm
          toolSchema={{
            title: `Train ${modelType} model`,
            code: `${modelType}ModelStepForm`, // this is the form name
            toolSchema: {
              name: `${modelType} model`,
              input: { ioItems: {} },
              output: { ioItems: {} }
            }
          }}
          steps={[
            {
              title: "Select your data",
              Component: SelectData,
              withCustomFooter: true
            },
            {
              title:
                modelType === GENERATIVE_MODEL
                  ? "Choose your amino acid sequences"
                  : "Choose your target and descriptors",
              Component: ConfigurationStep,
              withCustomFooter: true
            }
          ]}
          onSubmit={
            modelType !== GENERATIVE_MODEL ? onSubmit : onGenerationsSubmit
          }
          initialValues={{
            modelType,
            modelConfiguration,
            dataModule: "DESIGN_MODULE"
          }}
          finishButton={
            <Button
              text={`Go to ${modelType} models`}
              onClick={() => {
                if (modelType === PREDICTIVE_MODEL) {
                  history.push(
                    EvolveConfig.constants.VIEW_ALL_PREDICTIVE_MODELS_ROUTE
                  );
                } else if (modelType === EVOLUTIVE_MODEL)
                  history.push(
                    EvolveConfig.constants.VIEW_ALL_EVOLUTIVE_MODELS_ROUTE
                  );
                else if (modelType === GENERATIVE_MODEL)
                  history.push(
                    EvolveConfig.constants.VIEW_ALL_GENERATIVE_MODEL_ROUTE
                  );
                else history.push(EvolveConfig.constants.EVOLVE_APP_ROUTE);
              }}
            />
          }
        />
      </div>
    </div>
  );
};

export default CreateModelStepForm;
