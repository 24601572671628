/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { compose } from "recompose";
import { InjectedFormProps, reduxForm } from "redux-form";
import { startCase } from "lodash";
import classNames from "classnames";
import { Classes } from "@blueprintjs/core";
import { DialogFooter, InputField, wrapDialog } from "@teselagen/ui";
import type { InjectedWrapDialogProps } from "@teselagen/ui";
import { SequenceAnnotation } from "..";

type DialogForm = {
  name: string;
  description: string;
};

type InjectedProps = InjectedFormProps<DialogForm> &
  InjectedWrapDialogProps & {
    annotation: SequenceAnnotation;
    editAnnotation: (sources: SequenceAnnotation) => void;
  };

const EditAnnotationDialog = (props: InjectedProps) => {
  const { hideModal, handleSubmit, annotation, editAnnotation } = props;
  const { name, description } = annotation;

  const saveData = (values: DialogForm) => {
    const { name, description } = values;
    const newAnnotation = { ...annotation, name, description };
    editAnnotation(newAnnotation);
    hideModal();
  };

  return (
    <React.Fragment>
      <div
        className={classNames(Classes.DIALOG_BODY, "tg-flex column auto")}
        data-test={`tgDialog_${startCase(name)}`}
      >
        <InputField
          data-test="name"
          name="name"
          label="Name"
          defaultValue={name}
        />
        <InputField
          data-test="description"
          name="description"
          label="Description"
          defaultValue={description}
        />
      </div>
      <DialogFooter
        onClick={handleSubmit(saveData)}
        hideModal={hideModal}
        disabled={false}
        text="Save"
      />
    </React.Fragment>
  );
};

export default compose<any, any>(
  reduxForm({ form: "annotation" }),
  wrapDialog({
    title: "Edit Annotation"
  })
)(EditAnnotationDialog);
