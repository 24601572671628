/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
// Evolve private views
import CreateModelStepForm from "../views/CreateModelStepForm";
import AllModelsView from "../views/AllModelsView";
import ModelDetailView from "../views/ModelDetailView";

//Evolve Config file
import { EvolveConfig } from "../configs/config";

const {
  TRAIN_PREDICTIVE_MODEL_ROUTE,
  TRAIN_EVOLUTIVE_MODEL_ROUTE,
  TRAIN_GENERATIVE_MODEL_ROUTE,
  VIEW_ALL_PREDICTIVE_MODELS_ROUTE,
  VIEW_ALL_EVOLUTIVE_MODELS_ROUTE,
  VIEW_ALL_GENERATIVE_MODEL_ROUTE,
  MODEL_DETAIL_VIEW_ROUTE,
  PREDICTIVE_MODEL_DETAIL_VIEW_ROUTE,
  EVOLUTIVE_MODEL_DETAIL_VIEW_ROUTE,
  GENERATIVE_MODEL_DETAIL_VIEW_ROUTE
} = EvolveConfig.constants;

const generateRoutes = () => {
  const privateRoutes = {};

  if (EvolveConfig.evolveViews.models) {
    privateRoutes[TRAIN_PREDICTIVE_MODEL_ROUTE] = CreateModelStepForm;
    privateRoutes[TRAIN_EVOLUTIVE_MODEL_ROUTE] = CreateModelStepForm;
    privateRoutes[TRAIN_GENERATIVE_MODEL_ROUTE] = CreateModelStepForm;
    privateRoutes[VIEW_ALL_PREDICTIVE_MODELS_ROUTE] = AllModelsView;
    privateRoutes[VIEW_ALL_EVOLUTIVE_MODELS_ROUTE] = AllModelsView;
    privateRoutes[VIEW_ALL_GENERATIVE_MODEL_ROUTE] = AllModelsView;
    privateRoutes[MODEL_DETAIL_VIEW_ROUTE] = ModelDetailView;
    privateRoutes[PREDICTIVE_MODEL_DETAIL_VIEW_ROUTE] = ModelDetailView;
    privateRoutes[EVOLUTIVE_MODEL_DETAIL_VIEW_ROUTE] = ModelDetailView;
    privateRoutes[GENERATIVE_MODEL_DETAIL_VIEW_ROUTE] = ModelDetailView;
  }
  return privateRoutes;
};

export const privateRoutes = generateRoutes();
export const appRoutes = {
  privateRoutes
};
