/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
// Copyright (C) 2019 TeselaGen Biotechnology, Inc.

import React from "react";
import { reduxForm } from "redux-form";
import { compose } from "redux";
import { NumericInputField } from "@teselagen/ui";

import { upsertAppSetting } from "../../../../src-shared/utils/appSettings";
import { Button } from "@blueprintjs/core";

function ChangeAutoAnnotateMinimumForm({
  submitting,
  handleSubmit,
  pristine,
  onFinish
}) {
  const onSubmit = async ({
    minAutoAnnotatedFeatureLength,
    minAutoAnnotatedPartLength
    // minAutoAnnotatedOligoLength
  }) => {
    await upsertAppSetting({
      code: "MIN_AUTO_ANNOTATED_FEATURE_LENGTH",
      // key: "minAutoAnnotatedFeatureLength",
      value: Number(minAutoAnnotatedFeatureLength)
    });
    await upsertAppSetting({
      code: "MIN_AUTO_ANNOTATED_PART_LENGTH",
      // key: "minAutoAnnotatedPartLength",
      value: Number(minAutoAnnotatedPartLength)
    });
    // await upsertAppSetting({
    //   code: "MIN_AUTO_ANNOTATED_OLIGO_LENGTH",
    //   // key: "minAutoAnnotatedOligoLength",
    //   value: Number(minAutoAnnotatedOligoLength)
    // });
    onFinish && onFinish();
    window.toastr.success("Successfully updated configuration.");
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <NumericInputField
        label="Minimum Registered Feature Length"
        name="minAutoAnnotatedFeatureLength"
        min={1}
        isRequired
        defaultValue={
          window.frontEndConfig.appSettings.MIN_AUTO_ANNOTATED_FEATURE_LENGTH
        }
      />
      <NumericInputField
        label="Minimum Registered Part Length"
        name="minAutoAnnotatedPartLength"
        min={1}
        isRequired
        defaultValue={
          window.frontEndConfig.appSettings.MIN_AUTO_ANNOTATED_PART_LENGTH
        }
      />
      {/* <NumericInputField
        label="Minimum Registered Oligo Length"
        name="minAutoAnnotatedOligoLength"
        min={1}
        isRequired
        defaultValue={
          window.frontEndConfig.appSettings.MIN_AUTO_ANNOTATED_OLIGO_LENGTH
        }
      /> */}
      <div className="settings-footer">
        <Button
          loading={submitting}
          disabled={pristine}
          type="submit"
          text="Update Minimum Lengths"
          intent="primary"
        />
      </div>
    </form>
  );
}

export default compose(
  reduxForm({
    form: "ChangeAutoAnnotateMinimumForm", // a unique name for this form
    enableReinitialize: true
  })
)(ChangeAutoAnnotateMinimumForm);
