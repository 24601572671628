/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import shortid from "shortid";
import { get, omit, uniqBy } from "lodash";
import handleUpdateMutations from "./handleUpdates";
import { handleNestedRecords, upsertAddIds } from "./utils";
import { isoContext } from "@teselagen/utils";

export const DESIGN_PART = async (
  { recordsToImport, upsertHandlers, ...rest },
  ctx = isoContext
) => {
  const { safeUpsert, deleteWithQuery } = ctx;

  /**
   * This function updates the FAS of an element.
   * It will first delete any FAS records linked to the elementId before creating the fas record,
   * since each element can only be linked to one FAS. (This is a bug in the DM because elements to FAS are one-to-many!!)
   */
  async function updateElementsFAS(elementsFas) {
    await deleteWithQuery("fas", {
      elementId: elementsFas.map(fas => fas.elementId)
    });
    await safeUpsert("fas", elementsFas);
  }

  // Here we will store the Forced Assembly Strategy of each element
  // so we can then updated them in the database
  let elementsFas = [];

  // First process the design elements' parts.
  // Update them accordingly or even create new ones.
  const recordsToContinueUpserting = await handleNestedRecords(
    recordsToImport,
    "dnaPart",
    async parts => {
      await upsertHandlers.DNA_PART(
        {
          ...rest,
          model: "part",
          recordsToImport: parts.map(p => omit(p, "partSequence")),
          upsertHandlers
        },
        ctx
      );
    }
  );

  //update the dna elements
  const newRecords = await handleUpdateMutations(
    {
      recordsToImport: recordsToContinueUpserting,
      convertUserFacingToDbModel: (r, { isOldRecord }) => {
        if (!r.id) r.cid = shortid();
        r["partId"] = get(r, "dnaPart.id");
        const elementFas = get(r, "forcedAssemblyStrategy");
        if (!isOldRecord) {
          if (elementFas) {
            elementsFas.push({
              cid: shortid(),
              name: elementFas,
              elementId: r.id,
              designId: r.design.id,
              reactionId: r.reactionId
            });
          }
        }
        delete r.forcedAssemblyStrategy;
        delete r.reactionId;
        delete r.dnaPart;
        delete r.design;
        return r;
      },
      model: "element"
    },
    ctx
  );

  // create new parts
  await upsertAddIds(
    {
      recordsToCreate: newRecords,
      recordsToImport,
      modelOrFragment: "element"
    },
    ctx
  );

  elementsFas = uniqBy(
    elementsFas,
    elementFas => elementFas.name && elementFas.elementId
  );
  await updateElementsFAS(elementsFas);
};
