/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import store from "../../redux/store";
import { activateSegmentUtils } from "../activateSegmentUtils";

export const SegmentEvents = {
  OpenEntry: "notebook-entry-opened",
  NewEntry: "notebook-entry-created",
  PasswordReset: "password-reset",
  PasswordResetRequested: "password-reset-requested",
  LoggedIn: "logged-in",
  LoggedOut: "logged-out",
  UsedAnOldDesignFileType: "used-an-old-design-file-type",
  ArchivedEntry: "notebook-entry-archived",
  UnarchivedEntry: "notebook-entry-unarchived",
  DeletedEntry: "notebook-entry-deleted",
  UniversalSearchStarted: "universal-search-started",
  UniversalSearchCompleted: "universal-search-completed",
  UniversalSearchResultSelected: "universal-search-result-selected",
  OpenELN: "open-eln"
} as const;

type SegmentEventPropertiesMap = {
  /** These events need some user identification */
  [SegmentEvents.PasswordReset]: { email: string };
  [SegmentEvents.PasswordResetRequested]: { email: string };
  [SegmentEvents.LoggedIn]: { email: string };
  [SegmentEvents.LoggedOut]: { email: string };
  [SegmentEvents.UsedAnOldDesignFileType]: { email: string };
  /** Don't pass any user data to other events, its embedded in each event */
  [SegmentEvents.OpenEntry]: { entryId: string };
  [SegmentEvents.NewEntry]: { entryId: string };
  [SegmentEvents.ArchivedEntry]: { entryId: string };
  [SegmentEvents.UnarchivedEntry]: { entryId: string };
  [SegmentEvents.DeletedEntry]: { entryId: string };
  [SegmentEvents.UniversalSearchStarted]: {
    searchTerm: string;
    searchTermTags: string[];
    universalSearchId: string;
    source: "ELN" | "GENERAL_UNIVERSAL_SEARCH";
  };
  [SegmentEvents.UniversalSearchCompleted]: {
    universalSearchId: string;
    resultsCount: number;
  };
  [SegmentEvents.UniversalSearchResultSelected]: {
    universalSearchId: string;
    selectedEntityId: string;
    selectedEntityTypename: string;
  };
  [SegmentEvents.OpenELN]: { source: "landing-page" };
};

export const triggerSegmentEvent = <T extends keyof SegmentEventPropertiesMap>(
  event: T,
  properties: SegmentEventPropertiesMap[T],
  options?: Object,
  callback?: Function
) => {
  try {
    const state = store.getState();
    // activateSegmentUtils = true && active = true => true
    // activateSegmentUtils = true && active = false => false
    // activateSegmentUtils = false => true
    if (
      window.frontEndConfig.segmentAnalyticsKey &&
      ((activateSegmentUtils && state?.platform?.segment?.active) ||
        !activateSegmentUtils)
    ) {
      window.analytics.track(event, properties, options, callback);
    }
  } catch (error) {
    console.error("Error setting user for analytics");
    console.error(error);
  }
};
