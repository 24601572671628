/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
import queryString from "query-string";
import StepForm from "../../../../src-shared/StepForm";
import SelectTargetsStep from "./Steps/SelectTargetsStep";
import SelectReferencesStep from "./Steps/SelectReferences";
import ReviewAndSubmitStep from "./Steps/ReviewAndSubmitStep";
import { safeUpsert } from "../../../../src-shared/apolloMethods";
import { pluralIfNeeded } from "../../../utils";
import {
  TargetSequenceFragment,
  PartsFragment,
  FeaturesFragment
} from "./fragments.gql.generated";

export type SourceDatabase = {
  id: string;
  name?: string;
  type: string;
  sequence?: { name: string };
  size?: number;
  sourceType?: string;
};

export type SourceRecord = {
  sourceType: string;
  sources: SourceDatabase[];
};

export type SequenceAnnotation = {
  id: string;
  sequenceId: string;
  start: number;
  end: number;
  name: string;
  description?: string;
  type?: string | { value: string; label: string; userCreated: boolean };
  matchLength: number;
  identity: number;
  mismatchCount: number;
  referenceLength: number;
  referenceCoverage: number;
  sourceType?: string;
  sourceDescription?: string;
  strand: number;
};

/** An annotated sequence can either have the parts annotated or the sequenceFeatures */
export type AnnotatedSequence =
  | (Omit<TargetSequenceFragment, "parts" | "sequenceFeatures"> & {
      parts?: SequenceAnnotation[] | PartsFragment[];
    })
  | (Omit<TargetSequenceFragment, "parts" | "sequenceFeatures"> & {
      sequenceFeatures?: SequenceAnnotation[] | FeaturesFragment[];
    });

export type FormData = {
  targetSequences: TargetSequenceFragment[];
  sequenceAnnotations: SequenceAnnotation[];
  annotationType: keyof typeof annotationTypes;
  featureType?: string | { value: string; label: string; userCreated: boolean };
  sources: SourceDatabase[];
};

type Props = {
  toolIntegrationProps: any;
  isToolIntegrated: boolean;
  toolSchema: any;
  initialValues: any;
};

const annotationTypes = {
  sequenceFeature: "Features",
  part: "Parts"
};

const SuccessPageInnerContent = (sequenceIds: string[]) => {
  const modelName = pluralIfNeeded("sequence", sequenceIds);
  const isPlural = sequenceIds.length > 1;
  const sequencesLink = `/sequences?filters=id__inList__${sequenceIds.join("%3B")}`;
  const sequenceLink = `/sequences/${sequenceIds}`;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
      }}
    >
      <h4>{`Annotated ${sequenceIds.length} ${modelName} correctly.`}</h4>
      <h5>
        <Link to={isPlural ? sequencesLink : sequenceLink}>
          Go to annotated {modelName}
        </Link>
      </h5>
    </div>
  );
};

const SequenceAnnotationTool = ({
  toolIntegrationProps,
  isToolIntegrated,
  toolSchema,
  initialValues
}: Props) => {
  const { sequenceIds, annotationType } = queryString.parse(
    window.location?.search
  );

  const onSubmit = async (values: FormData) => {
    try {
      const { sequenceAnnotations, annotationType } = values;
      if (isEmpty(sequenceAnnotations))
        throw new Error("No new annotations found"); // should never happen but in case it does throw an error

      const sequenceIds: string[] = [];
      const annotations = sequenceAnnotations.map(annotation => ({
        name: annotation.name,
        type: annotation.type,
        start: annotation.start,
        end: annotation.end,
        strand: annotation.strand,
        sequenceId: annotation.sequenceId
      }));

      await safeUpsert(annotationType, annotations);
      annotations.forEach(
        annotation =>
          !sequenceIds.includes(annotation.sequenceId) &&
          sequenceIds.push(annotation.sequenceId)
      );

      return sequenceIds;
    } catch (error) {
      console.error(`Error:`, error);
      return window.toastr.error(error.message || "Error saving annotations");
    }
  };

  const steps = [
    {
      title: "Select target sequences",
      Component: SelectTargetsStep,
      props: {
        annotationTypes,
        annotationType,
        sequenceIds
      },
      withCustomFooter: true
    },
    {
      title: "Select Annotation Sources",
      Component: SelectReferencesStep,
      withCustomFooter: true
    },
    {
      title: "Review and submit",
      Component: ReviewAndSubmitStep
    }
  ];

  return (
    <StepForm
      toolIntegrationProps={toolIntegrationProps}
      enableReinitialize={isToolIntegrated}
      steps={steps}
      toolSchema={toolSchema}
      onSubmit={onSubmit}
      initialValues={initialValues}
      successPageInnerContent={SuccessPageInnerContent}
    />
  );
};

export default SequenceAnnotationTool;
