/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { cloneDeep } from "lodash";
import { forEach } from "lodash";
import { map, filter, flatMap, isObject, isArray } from "lodash";
import shortid from "shortid";

export async function getFlows() {
  return window.serverApi.request(
    `${window.frontEndConfig.nodeRedEditorUrl}/flows`
  );
}

export async function deleteFlowsAndSubflows({
  tabsByIdToDelete = {},
  optionalFlowData,
  subflowIdsToRemove = {}
}) {
  let allFlows;
  if (optionalFlowData) {
    allFlows = optionalFlowData;
  } else {
    ({ data: allFlows } = await getFlows());
  }

  allFlows = filter(allFlows, n => {
    if (subflowIdsToRemove[n.id] || subflowIdsToRemove[n.z]) {
      //filter out any subflows that have been deleted
      return false;
    } else if (tabsByIdToDelete[n.id] || tabsByIdToDelete[n.z]) {
      // filter out any tabs that have been deleted
      return false;
    } else {
      return true;
    }
  });
  //finally make the new request
  await window.serverApi.request(
    `${window.frontEndConfig.nodeRedEditorUrl}/flows`,
    {
      method: "POST",
      data: generateIdsForNodes(allFlows)
    }
  );
}

export async function deleteFlowsManual(flowIds = []) {
  for (const id of flowIds) {
    await window.serverApi.request(
      `${window.frontEndConfig.nodeRedEditorUrl}/flow/${id}`,
      {
        method: "DELETE"
      }
    );
  }
}

export function getHttpInsByUrlAndTabsAndSubflowsById(_nodeRedFlowsData) {
  const nodeRedFlowsData = cloneDeep(_nodeRedFlowsData);
  const httpInsByUrl = {};
  const tabsById = {};
  const subflowsById = {};
  const tabIdsToSubflowIds = {};

  nodeRedFlowsData.forEach(n => {
    //loop through all the node-red nodes, gathering all the httpIns and all the tabs
    if (n.type === "http in") {
      if (n.url.startsWith("/")) {
        //normalize the url for the http-in
        n.url = n.url.slice(1);
      }
      if (httpInsByUrl[n.url]) {
        //if there exists more than 1 http-in node with the same url, add a hasConflict prop to the node and push the node into a conflictNodes array
        httpInsByUrl[n.url].hasConflict = true;
        if (!httpInsByUrl[n.url].conflictNodes) {
          httpInsByUrl[n.url].conflictNodes = [];
        }
        httpInsByUrl[n.url].conflictNodes.push(n);
      } else {
        httpInsByUrl[n.url] = n;
      }
    } else if (n.type === "tab") {
      tabsById[n.id] = n;
    } else if (n.type === "subflow") {
      subflowsById[n.id] = n;
    } else if (n.type && n.type.includes("subflow:")) {
      if (n.z) {
        if (!tabIdsToSubflowIds[n.z]) {
          tabIdsToSubflowIds[n.z] = {};
        }
        tabIdsToSubflowIds[n.z][n.type.replace("subflow:", "")] = true;
      } else {
        console.error(
          `Error 19h29182he9 something weird with the linkage of subflows to tabs is happening`
        );
      }
    }
  });
  linkNestedSubflows({ tabIdsToSubflowIds });
  return {
    httpInsByUrl,
    tabsById,
    tabIdsToSubflowIds,
    subflowsById
  };
}

export function getIntegrationIdToNodeRedTabMap({
  tabsById,
  integrations,
  httpInsByUrl,
  subflowsById,
  tabIdsToSubflowIds
}) {
  const integrationToNodeRedTabMap = {};
  integrationToNodeRedTabMap.__tabsById = tabsById;
  integrations.forEach((int = {}) => {
    integrationToNodeRedTabMap[int.id] = {
      integration: int,
      tabsByIdForInt: {},
      subflowsByIdForInt: {},
      httpInsByUrlForInt: {},
      conflicts: [],
      conflictTabsByIdForInt: {}
    };
    map(int.integrationEndpoints, ({ url } = {}) => {
      if (url && url.startsWith("node-red://")) {
        const urlEnd = url.replace("node-red://", "");
        const httpIn = httpInsByUrl[urlEnd];
        if (httpIn) {
          const tabid = httpIn.z;
          integrationToNodeRedTabMap[int.id].tabsByIdForInt[tabid] =
            tabsById[tabid];
          if (
            tabIdsToSubflowIds[tabid] &&
            Object.keys(tabIdsToSubflowIds[tabid]).length
          ) {
            Object.keys(tabIdsToSubflowIds[tabid]).forEach(subflowId => {
              integrationToNodeRedTabMap[int.id].subflowsByIdForInt[subflowId] =
                subflowsById[subflowId];
            });
          }

          integrationToNodeRedTabMap[int.id].httpInsByUrlForInt[urlEnd] =
            httpIn;
          if (httpIn.hasConflict) {
            integrationToNodeRedTabMap[int.id].conflicts.push(
              ...httpIn.conflictNodes
            );
            map(httpIn.conflictNodes, n => {
              integrationToNodeRedTabMap[int.id].conflictTabsByIdForInt[n.z] =
                tabsById[n.z];
            });
          }
        }
      }
    });
  });
  return integrationToNodeRedTabMap;
}

export async function getNodeRedInfoForIntegrationsById(integrations) {
  const res = await getFlows();
  const { httpInsByUrl, tabsById, subflowsById, tabIdsToSubflowIds } =
    getHttpInsByUrlAndTabsAndSubflowsById(res.data);
  const intIdToFlow = getIntegrationIdToNodeRedTabMap({
    tabsById,
    subflowsById,
    tabIdsToSubflowIds,
    httpInsByUrl,
    integrations
  });
  if (!intIdToFlow) return;
  intIdToFlow.__allNodes = res.data;
  return intIdToFlow;
}

export async function getNodeRedInfoForSingleIntegration(integration) {
  const intIdToFlow = await getNodeRedInfoForIntegrationsById([integration]);
  if (!intIdToFlow) return {};

  const toRet = intIdToFlow[integration.id];
  if (!toRet) return {};

  toRet.__allNodes = intIdToFlow.__allNodes;

  return toRet;
  /* { //RESPONSE LOOKS LIKE:
    integration: int,
    tabsByIdForInt: {},
    subflowsByIdForInt: {},
    httpInsByUrlForInt: {},
    conflicts: [],
    __allNodes
  } */
}

export function getNodesAssociatedWithTab(nodes, tabId) {
  const toRet = [];
  nodes.forEach(node => {
    if (tabId === node.id || tabId === node.z) {
      toRet.push(node);
    }
  });
  return toRet;
}

// function processNodeRedFile({ nodeRedFile, uniqueIdentifier }) {
//   return generateIdsForNodes(cloneDeep(nodeRedFile)).map(n => {
//     if (n.type === "http in") {
//       n.url = `/${uniqueIdentifier}${n.url}`;
//     }
//     return n;
//   });
// }
export function getTabsAndSubflowsFromNodes(nodes = []) {
  const tabs = {};
  const subflows = {};
  nodes.forEach(n => {
    if (n.type === "tab") {
      tabs[n.id] = [n];
    }
    if (n.type === "subflow") {
      subflows[n.id] = [n];
      // subflows[n.id] = { ...n, nodes: [] };
    }
  });
  nodes.forEach(n => {
    if (n.type !== "tab" && n.z) {
      const tabNodes = tabs[n.z];
      const subflowNodes = subflows[n.z];
      if (tabNodes) {
        tabNodes.push(n);
      } else if (subflowNodes) {
        subflowNodes.push(n);
      } else {
        console.warn(`We really shouldn't be seeing this`, n);
      }
    }
  });
  return { tabs, subflows };
}

export function generateIdsForNodes(nodes, idMap = {}) {
  const innerNodes = flatMap(nodes, n => {
    if (n.id) {
      let newId;
      if (idMap[n.id]) {
        newId = idMap[n.id];
      } else {
        newId = shortid();
        idMap[n.id] = newId;
      }
      n.id = newId;
    }
    if (n.type && n.type.startsWith("subflow:")) {
      const oldId = n.type.replace("subflow:", "");
      let newId;
      if (idMap[oldId]) {
        newId = idMap[oldId];
      } else {
        newId = shortid();
        idMap[oldId] = newId;
      }
      n.type = `subflow:${newId}`;
    }
    if (n.z) {
      let newId;
      if (idMap[n.z]) {
        newId = idMap[n.z];
      } else {
        newId = shortid();
        idMap[n.z] = newId;
      }
      n.z = newId;
    }
    if (n.in) {
      n.in = generateIdsForNodes(n.in, idMap);
    }
    if (n.out) {
      n.out = generateIdsForNodes(n.out, idMap);
    }
    if (n.wires) {
      n.wires = n.wires.map(wire => {
        const innerFn = wireOrWireId => {
          if (typeof wireOrWireId === "string") {
            let newId;
            if (idMap[wireOrWireId]) {
              newId = idMap[wireOrWireId];
            } else {
              newId = shortid();
              idMap[wireOrWireId] = newId;
            }
            return newId;
          } else if (isObject(wireOrWireId)) {
            let newId;
            if (idMap[wireOrWireId.id]) {
              newId = idMap[wireOrWireId.id];
            } else {
              newId = shortid();
              idMap[wireOrWireId.id] = newId;
            }
            return {
              ...wireOrWireId,
              id: newId
            };
          }
        };
        if (isArray(wire)) {
          return wire.map(innerFn);
        } else if (isObject(wire)) {
          return innerFn(wire);
        } else {
          console.error(
            `Error 9182h91p8h: We shouldn't be here. Please contact a developer near you`
          );
          return wire;
        }
      });
    }

    return n;
  });
  return innerNodes;
}

function linkNestedSubflows({ tabIdsToSubflowIds }) {
  let repeat;

  forEach(tabIdsToSubflowIds, (subflowMap, tabId) => {
    forEach(subflowMap, (val, subflowId) => {
      if (!val) return;
      if (tabIdsToSubflowIds[subflowId]) {
        const lengthBefore = map(tabIdsToSubflowIds[tabId]).length;
        tabIdsToSubflowIds[tabId] = {
          ...tabIdsToSubflowIds[tabId],
          ...tabIdsToSubflowIds[subflowId]
        };
        const lengthAfter = map(tabIdsToSubflowIds[tabId]).length;
        if (lengthBefore !== lengthAfter) {
          repeat = true;
        }
      }
    });
  });

  if (repeat) {
    linkNestedSubflows({ tabIdsToSubflowIds });
  }
}
