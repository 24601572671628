/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { getCircularMatchIndex } from "./getCircularMatchIndex";

export const addRotatedByPropertyToSequence = (
  sequences,
  seqMatchesKeyedByHash
) => {
  return sequences.map(seq => {
    const original = seqMatchesKeyedByHash[seq.hash];
    const matchIndex = getCircularMatchIndex(seq, original);
    if (matchIndex > -1) {
      return { ...seq, rotatedBy: matchIndex };
    }
    return seq;
  });
};
