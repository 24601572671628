/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { compose } from "recompose";
import {
  InputField,
  TextareaField,
  DialogFooter,
  wrapDialog
} from "@teselagen/ui";
import { Classes } from "@blueprintjs/core";
import { reduxForm } from "redux-form";
import { noop } from "lodash";

import AsyncValidateFieldSpinner from "../../../../src-shared/AsyncValidateFieldSpinner";
import { withProps } from "recompose";
import { asyncValidateName } from "../../../../src-shared/utils/formUtils";

import { safeUpsert } from "../../../../src-shared/apolloMethods";
import modelNameToReadableName from "../../../../src-shared/utils/modelNameToReadableName";

const fragment = ["targetOrganismClass", "id name description"];

class CreateOrganismClassDialog extends Component {
  onSubmit = async values => {
    const { hideModal, refetch = noop } = this.props;
    const { id: updatedId, name, description } = values;
    try {
      await safeUpsert(fragment, { id: updatedId, name, description });
      await refetch();
      hideModal();
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error creating organism group.");
    }
  };

  render() {
    const { hideModal, submitting, asyncValidating, handleSubmit } = this.props;
    return (
      <React.Fragment>
        <div className={Classes.DIALOG_BODY}>
          <InputField
            name="name"
            isRequired
            label="Name"
            rightElement={
              <AsyncValidateFieldSpinner validating={asyncValidating} />
            }
          />
          <TextareaField name="description" label="Description" />
        </div>
        <DialogFooter
          hideModal={hideModal}
          submitting={submitting}
          onClick={handleSubmit(this.onSubmit)}
        />
      </React.Fragment>
    );
  }
}

const CreateOrganismClassWithDialog = compose(
  wrapDialog(({ initialValues = {} }) => {
    return {
      title: `${initialValues.id ? "Edit" : "Create"} ${modelNameToReadableName(
        "targetOrganismClass",
        {
          upperCase: true
        }
      )}`
    };
  }),
  withProps(() => ({ model: "targetOrganismClass" })),
  reduxForm({
    form: "createOrganismClass",
    ...asyncValidateName
  })
)(CreateOrganismClassDialog);

export default CreateOrganismClassWithDialog;
