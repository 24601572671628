/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { Helmet } from "react-helmet";
import modelNameToReadableName from "../../../src-shared/utils/modelNameToReadableName";
import { DataTable } from "@teselagen/ui";
import unitGlobals from "../../../../tg-iso-lims/src/unitGlobals";
import pluralize from "pluralize";
import { startCase } from "lodash";

function UnitPanel({ unitType }) {
  const units = unitGlobals[pluralize(unitType)];
  const baseKey = unitGlobals.unitNameToBaseKey[unitType];
  const entities = Object.values(units).sort((a, b) => b[baseKey] - a[baseKey]);
  const title = modelNameToReadableName(unitType, {
    upperCase: true,
    plural: true
  });
  return (
    <div className="tg-card">
      <Helmet title={title} />
      <DataTable
        tableName={title}
        withTitle
        isSimple
        withSearch
        schema={[
          {
            displayName: "Name",
            path: "code"
          },
          {
            displayName: startCase(baseKey),
            path: baseKey
          }
        ]}
        entities={entities}
      />
    </div>
  );
}

export default UnitPanel;
