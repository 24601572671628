/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { reduxForm } from "redux-form";
import { Classes } from "@blueprintjs/core";
import { InputField, DialogFooter } from "@teselagen/ui";
import { asyncValidateName } from "../../../../src-shared/utils/formUtils";

function SiteLocationDialog({ handleSubmit, hideModal }) {
  return (
    <form onSubmit={handleSubmit}>
      <div className={Classes.DIALOG_BODY}>
        <InputField name="name" label="Name" isRequired />
        <InputField name="address" label="Address Line 1" />
        <InputField
          name="address2"
          label="Address Line 2"
          secondaryLabel="(optional)"
        />
        <InputField name="city" label="City, State" />
      </div>
      <DialogFooter hideModal={hideModal} />
    </form>
  );
}

export default reduxForm({
  form: "siteLocationEdit",
  ...asyncValidateName
})(SiteLocationDialog);
