/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { getSequence } from "../../utils/getSequence";

export const getCircularMatchIndex = (seq1, seq2) => {
  const sequenceString1 = typeof seq1 === "string" ? seq1 : getSequence(seq1);
  if (typeof sequenceString1 !== "string") {
    throw new Error(
      `Could not find sequence string for sequence: ${JSON.stringify(seq1)}`
    );
  }

  // We need to double the sequence string of the sequence we are comparing against,
  // because if seq1 is just a rotated version of seq2, we need seq2 to repeat itself
  // because the 'indexOf' function needs to find the whole match in a "linear fashion"
  let sequenceString2 = typeof seq2 === "string" ? seq2 : getSequence(seq2);
  sequenceString2 += sequenceString2;

  if (typeof sequenceString2 !== "string") {
    throw new Error(
      `Could not find sequence string for sequence: ${JSON.stringify(seq2)}`
    );
  }

  const matchIndex = sequenceString2
    .toLowerCase()
    .indexOf(sequenceString1.toLowerCase());

  return matchIndex;
};
