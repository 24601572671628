/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { flatMap, omit } from "lodash";
import shortid from "shortid";
import { transformSubtypes } from "../integrationTypeSettingsMap/transformSubtypes";

export function createExampleNodeRedFlowForIntegration({
  endpoints,
  name,
  uniqueIdentifier,
  ...rest
}) {
  const tabId = shortid();
  const tabNode = {
    id: tabId,
    type: "tab",
    label: `${uniqueIdentifier} - ${name}`,
    disabled: false,
    info: ""
  };
  const endpointNodes = Object.entries(endpoints).map(([key, val], index) => {
    return createFlowForEndpoint({
      ...val,
      ...rest,
      uniqueIdentifier,
      tabId,
      level: index,
      urlEnd: key
    });
  });
  return flatMap([tabNode, ...endpointNodes]);
}

function createFlowForEndpoint({
  level,
  uniqueIdentifier,
  method,
  tabId,
  name,
  urlEnd,
  subtype,
  exampleRequest,
  exampleResponse
}) {
  const y = (level + 1) * 150;
  const httpInId = shortid();
  const fnId = shortid();
  const httpOutId = shortid();
  const toRet = [
    {
      id: httpInId,
      type: "http in",
      z: tabId,
      name: name,
      url: `/${uniqueIdentifier}/${urlEnd.toLowerCase()}`,
      method: (method || "get").toLowerCase(),
      upload: false,
      swaggerDoc: "",
      x: 120,
      y,
      wires: [[fnId]]
    },
    {
      id: fnId,
      type: "function",
      z: tabId,
      name: "Helper Fn",
      func: `
${prettifyReqRes(exampleRequest, subtype, { isReq: true })}

${prettifyReqRes(exampleResponse, subtype, { isReq: false })}
      `,

      outputs: 1,
      noerr: 0,
      initialize: "",
      finalize: "",
      x: 320,
      y,
      wires: [[httpOutId]]
    },
    {
      id: httpOutId,
      type: "http response",
      z: tabId,
      name: "Response",
      statusCode: "",
      headers: {},
      x: 520,
      y,
      wires: []
    }
  ];
  return toRet;
}

function prettifyReqRes(reqRes, subtype, { isReq }) {
  let inner;
  if (!reqRes) {
    if (isReq) {
      inner = `// No Payload`;
    } else {
      inner = `// No payload expected on the response`;
    }
  } else if (reqRes.__tgMultipleExamples__) {
    inner = `(Multiple Types)
    `;
    reqRes.__tgMultipleExamples__.forEach((n, i) => {
      inner += `

// OPTION ${i + 1} - ${n.__tgTabTitle__}
${
  isReq
    ? ""
    : `
${i > 0 ? "/** " : ""}
const newPayload = `
}${JSON.stringify(
        transformSubtypes(omit(n, "__tgTabTitle__"), subtype, true),
        null,
        4
      )} ${i > 0 && !isReq ? "**/ " : ""}`;
    });
  } else {
    inner = `
${isReq ? "" : `const newPayload = `}${JSON.stringify(
      transformSubtypes(reqRes, subtype, true),
      null,
      4
    )}`;
  }

  if (isReq) {
    return `//Example Payload Structure:
/**
 ${inner}
**/`;
  } else {
    return `
// Example Return Structure ${inner}
msg.payload = newPayload;
return msg;
`;
  }
}
