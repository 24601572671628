/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { Button, HTMLSelect, Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { isEmpty, some, isEqual, size } from "lodash";
import React from "react";
import {
  DataTable,
  // SelectField,
  SwitchField,
  withHotkeys
} from "@teselagen/ui";
import HeaderWithHelper from "../../components/HeaderWithHelper";
import { EvolveConfig } from "../../configs/config";
import cypressTags from "../../configs/cypressTags.json";
import createModelStepFormStyles from "./createModelStepFormStyles.module.css";

const {
  UNASSIGNED_COLUMN_TYPE,
  DESCRIPTOR_COLUMN_TYPE,
  TARGET_COLUMN_TYPE,
  CATEGORIC_VALUE,
  NUMERIC_VALUE,
  AA_SEQUENCE_VALUE,
  SMILES_VALUES,
  UNASSIGNED_VALUE,
  MIN_ALLOWED_AA_SEQ_LENGTH,
  MAX_ALLOWED_AA_SEQ_LENGTH,
  VALID_AMINOACIDS_TOKENS
} = EvolveConfig.constants;

const CONSOLE_TAG = "[ConfigurationStep]:";

const TARGET_VALUE_TYPES = [
  {
    value: UNASSIGNED_VALUE,
    label: "unassigned"
  },
  {
    value: NUMERIC_VALUE,
    label: "numerical"
  },
  {
    value: CATEGORIC_VALUE,
    label: "categorical"
  }
];

const getValueOptions = columnType => {
  switch (columnType) {
    case "Descriptors":
      return [
        {
          value: UNASSIGNED_VALUE,
          label: "unassigned"
        },
        {
          value: NUMERIC_VALUE,
          label: "numerical"
        },
        {
          value: CATEGORIC_VALUE,
          label: "categorical"
        },
        JSON.parse(localStorage.getItem("aaSequenceDescriptors")) && {
          value: AA_SEQUENCE_VALUE,
          label: "amino acid sequence"
        },
        JSON.parse(localStorage.getItem("smilesDescriptors")) && {
          value: SMILES_VALUES,
          label: "smiles"
        }
      ].filter(option => option);
    case "Targets":
      return TARGET_VALUE_TYPES;
    default:
      return [];
  }
};

class SelectDescriptorsAndTargets extends React.PureComponent {
  constructor(props) {
    super(props);
    this.HotkeyEnabler = withHotkeys({
      leftHotkey: {
        allowInInput: true,
        global: true,
        label: "Update Column Right",
        combo: "right",
        onKeyDown: () => this.updateColumnList("right")
      },
      rightHotkey: {
        allowInInput: true,
        global: true,
        label: "Update Column Left",
        combo: "left",
        onKeyDown: () => this.updateColumnList("left")
      }
    });
  }
  state = {
    validationMap: {},
    currentColumnNameValueTypeChange: ""
  };

  componentDidUpdate(prevProps) {
    if (
      !isEqual(
        this.props.modelDataColumnSchema,
        prevProps.modelDataColumnSchema
      )
    ) {
      this.valueTypeValidation();
    }
  }

  valueTypeValidation = () => {
    const {
      stepFormProps: { change },
      modelDataColumnSchema,
      modelData,
      setIsValid
    } = this.props;
    let validationObject = { validationPass: true, message: "" };

    // const assignedColumns = modelDataColumnSchema.filter(
    //   column => column.type !== EvolveConfig.constants.UNASSIGNED_COLUMN_TYPE
    // );
    const column_name = this.state.currentColumnNameValueTypeChange;
    const columnUnderValidation = modelDataColumnSchema.filter(
      column => column.name === column_name
    );
    let cellUnderEvaluation;

    // TODO: this validation loops over all columns and rows everytime modelDataColumnSchema changes. This needs some efficiency modifications.
    columnUnderValidation.forEach(column => {
      for (let index = 0; index < modelData.length; index++) {
        // Update the cell under evaluation. This cell corresponds to the current (row, column) pair of the data.
        cellUnderEvaluation = modelData[index][column.name];
        // Validate value types on descriptor columns.
        if (column.value_type === NUMERIC_VALUE) {
          try {
            // If the current column is defined as numeric AND the current cell is not numeric or parseable to a numeric value, then a value type warning will arise.
            if (Number.isNaN(Number(cellUnderEvaluation))) {
              validationObject = {
                validationPass: false,
                message: `${column.type} column ${column.name} has non-${column.value_type} values! Please fix to continue.`
              };
              break;
            } else {
              validationObject = {
                validationPass: true
              };
            }
          } catch (error) {
            // If the current column is defined as numeric AND if parsing the current cell to a Number fails, then a value type warning will arise.
            // console.log('Error while parsing value to Number: ', error);
            validationObject = {
              validationPass: false,
              message: `${column.type} column ${column.name} has non-${column.value_type} values! Please fix to continue.`
            };
          }
          // Checking if a column contains all categoric values is TRICKY.
          // The algorithm here proposed and implemented is that if there are both strings and numeric values in the column, a warning will arise claiming that there is a non-categoric value.
        } else if (column.value_type === CATEGORIC_VALUE) {
          try {
            if (!Number.isNaN(Number(cellUnderEvaluation))) {
              validationObject = {
                validationPass: true,
                message: `${column.type} column ${column.name} has non-${column.value_type} values!`
              };
              break;
            } else {
              validationObject = {
                validationPass: true
              };
            }

            // else if (typeof cellUnderEvaluation === 'string') categoricDescriptorsValidation.stringCounter += 1
          } catch (error) {
            // If the current column is defined as categoric AND if parsing the current cell to a Number fails, then a value type warning will arise.
            // This is because parsing string values to number, is an operation that shoudn't rise an error but converts the string to a NaN value.
            // console.log('Error while parsing value to Number: ', error);
            validationObject = {
              validationPass: false,
              message: `${column.type} column ${column.name} has non-${column.value_type} values! Please fix to continue. (categoric values cannot be numeric)`
            };
          }
        } else if (column.value_type === AA_SEQUENCE_VALUE) {
          try {
            // Just make sure we are going to deal with a string variable.
            const sequenceString =
              cellUnderEvaluation && String(cellUnderEvaluation);
            let potentialInvalidAACharacter = "";
            // If the current column is defined as numeric AND the current cell is not numeric or parseable to a numeric value, then a value type warning will arise.
            if (
              sequenceString.length < MIN_ALLOWED_AA_SEQ_LENGTH ||
              sequenceString.length > MAX_ALLOWED_AA_SEQ_LENGTH
            ) {
              validationObject = {
                validationPass: false,
                message: `${column.type} column ${
                  column.name
                } has a sequence in row:${index + 1} of length=${
                  sequenceString.length
                }, which is outside the allowed sequence lengths between [10,50] Amino Acid characters! Please fix to continue.`
              };
              break;
            } else if (
              some(sequenceString.split(""), aaToken => {
                if (!VALID_AMINOACIDS_TOKENS.includes(aaToken.toUpperCase())) {
                  potentialInvalidAACharacter = aaToken;
                  return true;
                } else {
                  return false;
                }
              })
            ) {
              validationObject = {
                validationPass: false,
                message: `${column.type} column ${
                  column.name
                } has a sequence in row:${
                  index + 1
                } with a non-supported Amino Acid character '${potentialInvalidAACharacter}' (only IUPAC 20 amino acids characters are supported)! Please fix to continue.`
              };
              break;
            } else {
              validationObject = {
                validationPass: true
              };
            }
          } catch (error) {
            validationObject = {
              validationPass: false,
              message: `${column.type} column ${column.name} has invalid values, make sure they are sequences of lengths between 10 and 50 IUPAC 20 amino acid letters! Please fix to continue.`
            };
            console.error(error);
          }
        }
      }
    });
    const _validationMap = {
      ...this.state.validationMap,
      [column_name]: validationObject
    };
    const columnTypesNotValid = Object.keys(_validationMap).some(
      key => !_validationMap[key].validationPass
    );
    const completedDescriptors = modelDataColumnSchema.filter(
      column => column.type === DESCRIPTOR_COLUMN_TYPE && column.value_type
    );
    const completedTargets = modelDataColumnSchema.filter(
      column => column.type === TARGET_COLUMN_TYPE && column.value_type
    );
    setIsValid(
      !columnTypesNotValid &&
        size(completedDescriptors) &&
        size(completedTargets)
    );
    this.setState({
      validationMap: _validationMap
    });
    change("validationObject", validationObject);
  };

  updateColumnValueType = (column_record, new_value_type) => {
    const {
      stepFormProps: { change },
      modelDataColumnSchema
    } = this.props;
    const new_modelDataColumnSchema = modelDataColumnSchema.map(column => {
      if (column.name === column_record.name) {
        return { ...column, value_type: new_value_type };
      } else {
        return { ...column };
      }
    });
    // This unselects every data table entity currently selected.
    this.props.changeFormValue(
      "unassignedTable",
      "reduxFormSelectedEntityIdMap",
      {}
    );
    this.props.changeFormValue(
      "descriptorTable",
      "reduxFormSelectedEntityIdMap",
      {}
    );
    this.props.changeFormValue(
      "targetTable",
      "reduxFormSelectedEntityIdMap",
      {}
    );
    change("selectedColumns", []); // this clear the columns selected by the user in  the UI once the selections change type.
    change("modelDataColumnSchema", new_modelDataColumnSchema);
    this.setState({ currentColumnNameValueTypeChange: column_record.name });
  };

  dualListSchema = () => {
    const schema = {
      fields: [
        { path: "name", displayName: "Column Name" },
        { path: "value_type", displayName: "Type of values" }
      ]
    };
    return schema;
  };

  unassignedListSchema = () => {
    const schema = {
      fields: [{ path: "name", displayName: "Column Name" }]
    };
    return schema;
  };

  // This corresponds to the schema of the data table used to show the samples to be tupled using the upload zip file for the "stand alone mode" of the Tupling Tool.
  uploaded_samples_schema = () => {
    const { modelDataColumnSchema = [] } = this.props;

    const assignedColumnsSchema = modelDataColumnSchema.filter(
      column =>
        column.value_type !== UNASSIGNED_VALUE &&
        column.type !== UNASSIGNED_COLUMN_TYPE
    );

    const schema = {
      fields: assignedColumnsSchema.map(column => ({
        path: column.name,
        displayName: column.name
      }))
    };
    return schema;
  };

  updateColumnList = direction => {
    const {
      stepFormProps: { change },
      selectedColumns,
      modelDataColumnSchema
    } = this.props;
    if (selectedColumns) {
      const newSelectedColumns = selectedColumns.map(selectedColumn => {
        if (direction === "left") {
          if (selectedColumn.type === UNASSIGNED_COLUMN_TYPE)
            return {
              ...selectedColumn,
              type: DESCRIPTOR_COLUMN_TYPE,
              value_type: UNASSIGNED_VALUE
            };
          else if (selectedColumn.type === TARGET_COLUMN_TYPE) {
            return {
              ...selectedColumn,
              type: UNASSIGNED_COLUMN_TYPE,
              value_type: UNASSIGNED_VALUE
            };
          } else {
            return selectedColumn;
          }
        } else if (direction === "right") {
          if (selectedColumn.type === UNASSIGNED_COLUMN_TYPE)
            return {
              ...selectedColumn,
              type: TARGET_COLUMN_TYPE,
              value_type: UNASSIGNED_VALUE
            };
          else if (selectedColumn.type === DESCRIPTOR_COLUMN_TYPE) {
            return {
              ...selectedColumn,
              type: UNASSIGNED_COLUMN_TYPE,
              value_type: UNASSIGNED_VALUE
            };
          } else {
            return selectedColumn;
          }
        } else {
          throw new Error(
            `${CONSOLE_TAG} Direction error on updateColumnList.`
          );
        }
      });

      const new_modelDataColumnSchema = modelDataColumnSchema.map(column => {
        const newColumn = newSelectedColumns.reduce(
          (accumulator, selectedColumn) => {
            if (column.name === selectedColumn.name)
              accumulator = {
                ...column,
                type: selectedColumn.type,
                value_type:
                  selectedColumn.type === UNASSIGNED_COLUMN_TYPE
                    ? UNASSIGNED_VALUE
                    : selectedColumn.value_type
              };
            return accumulator;
          },
          {}
        );
        if (isEmpty(newColumn)) {
          return column;
        } else {
          return newColumn;
        }
      });

      change("modelDataColumnSchema", new_modelDataColumnSchema); // this updates the modelDataColumn object with the new column types chosen.
      change("selectedColumns", []); // this clear the columns selected by the user in  the UI once the selections change type.
      // This re-initializes each of the datatables components entitie selection maps.
      this.props.changeFormValue(
        "unassignedTable",
        "reduxFormSelectedEntityIdMap",
        {}
      );
      this.props.changeFormValue(
        "descriptorTable",
        "reduxFormSelectedEntityIdMap",
        {}
      );
      this.props.changeFormValue(
        "targetTable",
        "reduxFormSelectedEntityIdMap",
        {}
      );
    }
  };

  hasAssignedColumns = modelDataColumnSchema => {
    return some(
      modelDataColumnSchema,
      column =>
        column.value_type !== UNASSIGNED_VALUE &&
        column.type !== UNASSIGNED_COLUMN_TYPE
    );
  };

  /**
   *  This table shows the results of the file uploaded on the "Select Data" section of Step 1 of the Tupling Tool.
   */
  renderTableWithData = entities => {
    const schema = this.uploaded_samples_schema();
    const schemaFieldPaths = schema["fields"].map(schema => schema.path);

    // Thhis cellRenderer is sued because for some reason
    // the DataTable component sometimes fails to recognize path's
    // with special characters.
    const cellRenderer = {};
    schemaFieldPaths.forEach(path => {
      cellRenderer[path] = (_, record) => {
        return <span>{record[path]}</span>;
      };
    });

    return (
      <div id={cypressTags.STEP_2_TABLE_WITH_DATA_ID}>
        <DataTable
          isSimple
          formName="configurationStepDataTable"
          maxHeight={300}
          noSelect
          entities={entities}
          cellRenderer={cellRenderer}
          schema={this.uploaded_samples_schema()}
        />
      </div>
    );
  };

  renderColumnsTable = (
    title,
    entities,
    schema,
    stepFormProps,
    formName,
    maxHeight,
    maxWidth
  ) => {
    return (
      <DataTable
        className={createModelStepFormStyles.column_map_table}
        withSearch={false}
        noFullscreenButton={true}
        hideSelectedCount={true}
        hidePageSizeWhenPossible={true}
        withCheckboxes={false}
        topLeftItems={<h3>{title}</h3>}
        formName={formName}
        maxHeight={maxHeight}
        maxWidth={maxWidth}
        schema={schema}
        cellRenderer={this.cellRenderer(title)}
        entities={entities}
        onSingleRowSelect={e => stepFormProps.change("selectedColumns", [e])}
        onMultiRowSelect={e => stepFormProps.change("selectedColumns", e)}
      />
    );
  };

  renderValidationValueTypeComponent = validationMap => {
    return Object.keys(validationMap).map(key => {
      const componentContent = { message: validationMap[key].message };
      if (!validationMap[key].validationPass) {
        componentContent.className = createModelStepFormStyles.errorMessage;
        componentContent.icon = IconNames.ERROR;
      } else if (
        validationMap[key].validationPass &&
        validationMap[key].message
      ) {
        componentContent.className = createModelStepFormStyles.warningMessage;
        componentContent.icon = IconNames.WARNING_SIGN;
      }
      return (
        <span
          key={key}
          id={cypressTags.STEP_2_DATA_VALIDATION_WARNING_ID}
          className={componentContent.className}
        >
          {" "}
          <Icon
            iconSize={Icon.SIZE_STANDARD}
            icon={componentContent.icon}
          />{" "}
          {componentContent.message}{" "}
        </span>
      );
    });
  };

  renderSwitchShowTableWithData = (
    showTableWithData,
    stepFormProps,
    hasAssignedColumns
  ) => {
    return (
      <SwitchField
        // disabled={!hasAssignedColumns}
        // defaultValue={showTableWithData && hasAssignedColumns}
        value={showTableWithData && hasAssignedColumns}
        label="Show Data"
        onFieldSubmit={() => {
          stepFormProps.change(
            "showTableWithData",
            !this.props.showTableWithData && hasAssignedColumns
          );
        }}
        name="switchData"
        id={cypressTags.SHOW_TABLE_WITH_DATA_TOGGLE_BUTTON_ID}
      />
    );
  };

  renderMoveButtons = (direction, position) => {
    let _id = "";
    if (direction === "left") {
      if (position === "left") {
        _id = cypressTags.MOVE_COLUMN_LEFT_LEFT_BUTTON_ID;
      } else {
        _id = cypressTags.MOVE_COLUMN_LEFT_RIGHT_BUTTON_ID;
      }
    } else {
      if (position === "left") {
        _id = cypressTags.MOVE_COLUMN_RIGHT_LEFT_BUTTON_ID;
      } else {
        _id = cypressTags.MOVE_COLUMN_RIGHT_RIGHT_BUTTON_ID;
      }
    }
    return (
      <Button
        id={_id}
        onClick={() => this.updateColumnList(direction)}
        minimal
        tabIndex="-1"
        className={createModelStepFormStyles.moveItemButton}
        icon={IconNames[`DOUBLE_CHEVRON_${direction.toUpperCase()}`]}
      />
    );
  };

  cellRenderer = columnType => {
    return {
      value_type: (_, record) => {
        return (
          <span>
            <HTMLSelect
              className={createModelStepFormStyles.typeSelector}
              options={getValueOptions(columnType)}
              name={record.name}
              // onFieldSubmit={e => this.updateColumnValueType(record, e)}
              onChange={e => this.updateColumnValueType(record, e.target.value)}
              onClick={e => e.stopPropagation()}
              value={record.value_type}
            />
          </span>
        );
      },
      name: (_, record) => {
        return <span>{record.name}</span>;
      }
    };
  };

  render() {
    const {
      stepFormProps,
      modelData,
      showTableWithData,
      modelDataColumnSchema
    } = this.props;

    const {
      descriptorList = modelDataColumnSchema.filter(
        column => column.type === DESCRIPTOR_COLUMN_TYPE
      ),
      unassignedList = modelDataColumnSchema.filter(
        column => column.type === UNASSIGNED_COLUMN_TYPE
      ),
      targetList = modelDataColumnSchema.filter(
        column => column.type === TARGET_COLUMN_TYPE
      )
    } = this.props;

    const moveLeftLeftButton = this.renderMoveButtons("left", "left");
    const moveLeftRightButton = this.renderMoveButtons("right", "left");
    const moveRightLeftButton = this.renderMoveButtons("left", "right");
    const moveRightRightButton = this.renderMoveButtons("right", "right");

    const validationValueTypeComponent =
      this.renderValidationValueTypeComponent(this.state.validationMap);

    const switchShowTableWithData = this.renderSwitchShowTableWithData(
      showTableWithData,
      stepFormProps,
      this.hasAssignedColumns(modelDataColumnSchema)
    );

    const HotkeyEnabler = this.HotkeyEnabler;

    return (
      <div className="tg-step-form-section column">
        <HotkeyEnabler />
        <div className="tg-flex justify-space-between">
          <HeaderWithHelper
            header="Configuration"
            helper="Tell us what you would like us to predict by classifying columns as Descriptors or Targets.
                  Three tables are shown below with the column names of your data.
                  Please categorize your unassigned ‘Column Names’ by moving rows to either the ‘Descriptors’ (inputs) table on the left or the ‘Targets’ (outputs) table on the right."
          />
        </div>
        <div
          className={`tg-step-form-section row ${createModelStepFormStyles.column_map_section}`}
        >
          {this.renderColumnsTable(
            "Descriptors",
            descriptorList,
            this.dualListSchema(),
            stepFormProps,
            "descriptorTable",
            300,
            300
          )}
          {moveLeftLeftButton}
          {moveLeftRightButton}
          {this.renderColumnsTable(
            "Unassigned",
            unassignedList,
            this.unassignedListSchema(),
            stepFormProps,
            "unassignedTable",
            300,
            300
          )}
          {moveRightLeftButton}
          {moveRightRightButton}
          {this.renderColumnsTable(
            "Targets",
            targetList,
            this.dualListSchema(),
            stepFormProps,
            "targetTable",
            300,
            300
          )}
        </div>
        {validationValueTypeComponent}
        {this.hasAssignedColumns(modelDataColumnSchema) &&
          switchShowTableWithData}
        {showTableWithData && this.hasAssignedColumns(modelDataColumnSchema) ? (
          this.renderTableWithData(modelData)
        ) : (
          <div />
        )}
      </div>
    );
  }
}

export default SelectDescriptorsAndTargets;
