/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useEffect, useMemo, useState } from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { camelCase, times, chunk } from "lodash";
import shortid from "shortid";
import { reduxForm } from "redux-form";
import { tgFormValues } from "@teselagen/ui";
import classNames from "classnames";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import mustache from "mustache";

import { withRouter } from "react-router-dom";
import { Classes, Button } from "@blueprintjs/core";
import {
  DialogFooter,
  DataTable,
  withSelectedEntities,
  CheckboxField,
  InputField,
  wrapDialog,
  withSelectTableRecords
} from "@teselagen/ui";

import { showAlreadyPlacedWarning } from "../../../utils";
import { assignAliquotContainerPosition } from "../../../utils/plateUtils";

import modelNameToReadableName from "../../../../src-shared/utils/modelNameToReadableName";

import UniversalPlateNameTemplateField from "../../LimsTools/PlateReformatTool/UniversalPlateNameTemplateField";
import withQuery from "../../../../src-shared/withQuery";
import gql from "graphql-tag";

import {
  safeUpsert,
  safeQuery,
  safeDelete
} from "../../../../src-shared/apolloMethods";
import { addBarcodesToRecords } from "../../../../../tg-iso-lims/src/utils/barcodeUtils";

dayjs.extend(localizedFormat);

const schema = {
  model: "placmentLocation", // (not really)
  fields: [
    {
      displayName: "Path",
      path: "path",
      type: "string"
    },
    {
      displayName: "Capacity",
      path: "capacity",
      type: "string",
      render: (capacity, record) =>
        capacity === -1
          ? "∞"
          : `${capacity - record.assignedPositions.length} / ${capacity}`
    }
  ]
};
const formName = "placeAccordingToStrategyForm";

const PlaceAccordingToStrategyDialog = props => {
  const {
    availableLocations,
    placementInformation,
    selectTableRecords,
    newRackType,
    placeAccordingToStrategyFormSelectedEntities = [], // Comes from withSelectedEntities
    itemIds,
    numberOfRacksToGenerate,
    placementStrategy,
    type,
    hideModal,
    history,
    refetch,
    placementCb,
    change,
    generateBarcodes,
    submitting,
    handleSubmit
  } = props;

  const [page, setPage] = useState(1);

  const entries = useMemo(() => {
    return availableLocations.map(al => {
      /**
       * Same as the `availableLocations` prop, but the `id` now takes the form
       * of `{positionTypeCode}:{id}`. This ensures that the ids are unique.
       */
      const entry = {
        ...al,
        id: `${al.positionTypeCode}:${al.id}`
      };
      return entry;
    });
  }, [availableLocations]);

  const isCreatingRacks = useMemo(() => {
    return !!numberOfRacksToGenerate;
  }, [numberOfRacksToGenerate]);

  useEffect(() => {
    const selectedIds = placementInformation.map(
      pi => `${pi.positionTypeCode}:${pi.locationId}`
    );
    const recordsToSelect = [];
    entries.forEach(entry => {
      if (selectedIds.includes(entry.id)) {
        recordsToSelect.push(entry);
      }
    });
    selectTableRecords(recordsToSelect);
  }, [entries, placementInformation, selectTableRecords]);

  const placeItems = maybeItemIds => {
    // if this is on submit we might be removing items that are already placed
    // otherwise show all of them
    let itemQueue = [...(maybeItemIds || itemIds)];
    const locationQueue = [...placeAccordingToStrategyFormSelectedEntities];
    const placementInformation = [];
    const numItemsInLocation = {};
    let racksToCreate;

    if (isCreatingRacks) {
      itemQueue = [];
      racksToCreate = times(numberOfRacksToGenerate, i => {
        const rackCid = shortid();
        itemQueue.push(rackCid);
        return {
          cid: rackCid,
          name: `Box ${i + 1}`,
          containerArrayTypeId: newRackType
            ? newRackType.id
            : placementStrategy.destinationContainerArrayType.id
        };
      });
    }

    const numToPlace = itemQueue.length;
    const prepPlacementLocation = location => {
      if (numItemsInLocation[location.id] === undefined) {
        numItemsInLocation[location.id] = location.assignedPositions.length;
      }
    };

    const getUnplacedInfo = numUnplaced => ({
      canPlace: false,
      numUnplaced,
      numToPlace
    });

    while (itemQueue.length) {
      // If we still have items to place but no place to put them,
      // then we must exit early.
      if (!locationQueue.length) {
        return getUnplacedInfo(itemQueue.length);
      }

      const itemId = itemQueue.shift();
      let location = locationQueue[0];
      if (location) {
        prepPlacementLocation(location);
      }

      if (
        location.capacity !== -1 &&
        numItemsInLocation[location.id] >= location.capacity
      ) {
        locationQueue.shift();
        if (!locationQueue.length) {
          // unplaced should include the itemId we already shifted
          return getUnplacedInfo(itemQueue.length + 1);
        }
        location = locationQueue[0];
        prepPlacementLocation(location);
      }

      numItemsInLocation[location.id]++;

      placementInformation.push({
        itemId,
        locationId: location.id.split(":")[1],
        locationType: camelCase(location.positionTypeCode.toLowerCase())
      });
    }

    return {
      canPlace: true,
      numToPlace,
      racksToCreate,
      placementInformation
    };
  };

  const onSubmit = async values => {
    try {
      const { generateBarcodes, boxes = [] } = values;

      if (isCreatingRacks && page === 1) {
        return setPage(prev => prev + 1);
      }
      const items = await safeQuery(
        [type, "id name assignedPosition { id } placementQueueId"],
        {
          variables: {
            filter: {
              id: itemIds
            }
          }
        }
      );

      await showAlreadyPlacedWarning(items);
      if (!items.length) return hideModal();

      const { placementInformation, canPlace, racksToCreate } = placeItems(
        items.map(item => item.id)
      );

      if (!canPlace) {
        return window.toastr.error(
          `Unable to place ${
            type === "containerArray" ? "plates" : "tubes"
          } because the selected locations had insufficient capacity to hold the ${
            type === "containerArray" ? "plates" : "tubes"
          }.`
        );
      }

      let itemTypeToUse = type;
      let createdRacks = [];

      if (racksToCreate) {
        racksToCreate.forEach((rack, i) => {
          rack.name = boxes[i].name;
          if (!generateBarcodes) {
            rack.barcode = {
              barcodeString: boxes[i].barcode
            };
          }
        });
        createdRacks = await safeUpsert(
          ["containerArray", "id cid"],
          racksToCreate
        );
        if (generateBarcodes) {
          await addBarcodesToRecords(createdRacks);
        }
        itemTypeToUse = "containerArray";
        const cidToId = {};
        createdRacks.forEach(r => {
          cidToId[r.cid] = r.id;
        });
        placementInformation.forEach(info => {
          info.itemId = cidToId[info.itemId];
        });
      }

      const {
        data: { success, err, dataTableId }
      } = await window.serverApi.request({
        method: "POST",
        url: "/createPlacement",
        data: {
          createDataTable:
            !!placementCb || (!createdRacks.length && itemIds.length > 1),
          dataTableName: `${placementStrategy.name} ${
            type === "containerArray" ? "Plate" : "Tube"
          } Placement`,
          itemType: itemTypeToUse,
          placementInformation
        }
      });

      if (!success) {
        await safeDelete(
          "containerArray",
          createdRacks.map(r => r.id)
        );
        throw new Error(err.message || err);
      }
      if (createdRacks.length) {
        const tubeIds = itemIds;
        const containerArrayType =
          newRackType || placementStrategy.destinationContainerArrayType;
        const containerFormat = containerArrayType.containerFormat;
        const tubeUpdates = [];
        chunk(tubeIds, containerFormat.quadrantSize).forEach(
          (chunkOfTubeIds, i) => {
            const tubes = chunkOfTubeIds.map(id => ({
              id,
              containerArrayId: createdRacks[i].id
            }));
            tubeUpdates.push(
              ...assignAliquotContainerPosition(tubes, containerFormat)
            );
          }
        );
        await safeUpsert("aliquotContainer", tubeUpdates);
      }
      // remove from the placement queue if it is in one
      await safeUpsert(
        type,
        items
          .filter(item => item.placementQueueId)
          .map(item => ({ id: item.id, placementQueueId: null }))
      );
      if (dataTableId && !placementCb) {
        history.push(`/data-tables/${dataTableId}`);
      } else if (refetch) {
        await refetch();
      }
      let shouldHideModal = true;
      if (placementCb) {
        const { cancelHide } =
          (await placementCb({
            dataTableId,
            success: true
          })) || {};
        shouldHideModal = !cancelHide;
      }
      if (shouldHideModal) {
        hideModal();
      }
    } catch (e) {
      console.error(e);
      window.toastr.error("Error placing items.");
    }
  };

  const renderTable = () => {
    return (
      <DataTable
        schema={schema}
        entities={entries}
        formName={formName}
        change={change}
        isSingleSelect={itemIds.length === 1}
        withCheckboxes={itemIds.length > 1}
        noPadding
        // isSingleSelect
        destroyOnUnmount={false}
        doNotShowEmptyRows
      />
    );
  };
  const updateGeneratedBoxNames = template => {
    if (template) {
      const current_date = dayjs().format("l");
      times(numberOfRacksToGenerate, index => {
        const val = mustache.render(template, {
          incrementing_number: index + 1,
          incrementing_letter: String.fromCharCode(97 + index).toUpperCase(),
          current_date
        });
        change(`boxes.${index}.name`, val);
      });
    }
  };

  const renderBarcodeForm = () => {
    return (
      <div className="contain-dialog-contents">
        <h6>Enter Names and Barcodes for New Racks or Boxes </h6>
        <CheckboxField
          name="generateBarcodes"
          label="Generate Barcodes for new Racks or Boxes"
          defaultValue
        />
        <UniversalPlateNameTemplateField
          onFieldSubmit={updateGeneratedBoxNames}
          label="Box/Rack Name Template"
          tooltipInfo="This can be used to help fill out the names below."
          templateVariables={[
            "incrementing_number",
            "incrementing_letter",
            "current_date"
          ]}
        />
        <hr className="tg-section-break" />
        {times(numberOfRacksToGenerate, i => {
          return (
            <React.Fragment key={i}>
              <InputField
                name={`boxes.${i}.name`}
                label={`Box/Rack ${i + 1} Name`}
                isRequired
              />
              {!generateBarcodes && (
                <InputField
                  name={`boxes.${i}.barcode`}
                  label="Barcode"
                  isRequired
                />
              )}
              {i !== numberOfRacksToGenerate - 1 && (
                <hr className="tg-section-break" />
              )}
            </React.Fragment>
          );
        })}
      </div>
    );
  };

  const { numUnplaced, canPlace, numToPlace } = placeItems();
  let message;
  const plural = itemIds.length > 1;
  const itemName = modelNameToReadableName(type, {
    plural
  });
  let readableName = itemName;
  if (isCreatingRacks) {
    readableName = numToPlace > 1 ? "racks/boxes" : "rack/box";
  }
  if (isCreatingRacks) {
    if (plural) {
      message =
        `The ${itemIds.length} ${itemName} will be placed into ${numToPlace} new ${readableName} ` +
        `in the selected location${
          numToPlace > 1 ? "s" : ""
        }. A data table will be generated providing information about the placements.`;
    } else {
      message = `The ${itemName} will be placed into a new rack/box in the selected location.`;
    }
  } else {
    if (plural) {
      message =
        `The ${itemName} will be placed in the selected locations. A data table will be ` +
        `generated providing information about the placements.`;
    } else {
      message = `The ${itemName} will be placed in the selected location.`;
    }
  }

  if (numUnplaced > 0) {
    if (numToPlace === 1) {
      message += `\n\nPlease choose a location for the ${readableName}.`;
    } else {
      message += `\n\nPlease choose locations for all the ${readableName}. Of the ${numToPlace} to place ${numUnplaced} `;
      if (numUnplaced > 1) {
        message += "still need locations.";
      } else {
        message += "still needs a location.";
      }
    }
  } else {
    if (numToPlace === 1) {
      message += `\n\nThe ${readableName} is ready to be placed.`;
    } else {
      message += `\n\nLocations chosen for all ${readableName}.`;
    }
  }

  return (
    <React.Fragment>
      <div className={classNames(Classes.DIALOG_BODY, "compact-lib-dialog")}>
        <div style={{ marginBottom: 15 }} className="preserve-newline">
          {message}
        </div>
        {page === 1 && renderTable()}
        {page === 2 && renderBarcodeForm()}
      </div>
      <DialogFooter
        submitting={submitting}
        hideModal={hideModal}
        disabled={!canPlace}
        text={page === 2 || !isCreatingRacks ? "Submit" : "Next"}
        additionalButtons={
          page === 2 && <Button text="Back" onClick={() => setPage(1)} />
        }
        onClick={handleSubmit(onSubmit)}
      />
    </React.Fragment>
  );
};
PlaceAccordingToStrategyDialog.PropTypes = {
  /**
   * The id of the strategy we are placing according to.
   */
  placementStrategyId: PropTypes.string.isRequired,

  /**
   * Are we placing tubes or plates?
   */
  type: PropTypes.oneOf(["containerArray", "aliquotContainer"]).isRequired,

  /**
   * The ids of the plates or tubes we are placing.
   */
  itemIds: PropTypes.arrayOf(PropTypes.string).isRequired,

  /**
   * Information about which locations are part of the placement strategy
   * and have space available to put items. We assume we have enough space
   * to place all of the items.
   */
  availableLocations: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Locations with lower order get filled first. Should be the same as
       * the array index.
       */
      order: PropTypes.number.isRequired,

      /**
       * Information about whether this is a location, equipment, equipmentPostion, etc.
       */
      positionTypeCode: PropTypes.string.isRequired,

      /**
       * The id of the location.
       */
      id: PropTypes.string.isRequired,

      /**
       * The name of the location.
       */
      name: PropTypes.string.isRequired,

      /**
       * capacity for location.
       */
      capacity: PropTypes.number.isRequired
    })
  ).isRequired,

  /**
   * Information used to construct the original selected items. The
   * objects in the array will have additional information, but we can ignore
   * them for now.
   */
  placementInformation: PropTypes.arrayOf(
    PropTypes.shape({
      locationId: PropTypes.string.isRequired,
      positionTypeCode: PropTypes.string.isRequired
    })
  )
};

const placeAccordingToStrategyPlacementStrategyFragment = gql`
  fragment placeAccordingToStrategyPlacementStrategyFragment on placementStrategy {
    id
    name
    isDestinationContainerArray
    generateBoxes
    destinationContainerArrayType {
      id
      name
      containerFormat {
        code
        rowCount
        columnCount
        quadrantSize
        is2DLabeled
      }
    }
  }
`;

export default compose(
  reduxForm({
    form: formName
    // validate
  }),
  tgFormValues("generateBarcodes"),
  wrapDialog({
    getDialogProps: props => {
      const readableName = modelNameToReadableName(props.type, {
        upperCase: true,
        plural: true
      });
      return {
        title: `Place ${readableName} According to Strategy`,
        style: { width: 850 }
      };
    }
  }),
  withQuery(placeAccordingToStrategyPlacementStrategyFragment, {
    showLoading: true,
    inDialog: true,
    options: props => {
      return {
        variables: {
          id: props.placementStrategyId
        }
      };
    }
  }),
  withRouter,
  withSelectedEntities(formName),
  withSelectTableRecords(formName)
)(PlaceAccordingToStrategyDialog);
