/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { compose } from "redux";
import { SubmissionError, reduxForm } from "redux-form";
import { tgFormValues } from "@teselagen/ui";
import { noop } from "lodash";
import { Classes } from "@blueprintjs/core";
import {
  SelectField,
  InputField,
  BlueprintError,
  DialogFooter
} from "@teselagen/ui";
import isEmail from "validator/lib/isEmail";
import "./add-lab-member-style.css";
import { safeUpsert, safeQuery } from "../apolloMethods";
import { wrapDialog } from "@teselagen/ui";

// TODO remove this component (which is here to support the in-app add
// user dialog) and add a way to import the inner form from the platform
// AuthPage component instead.
class AddLabMember extends Component {
  onSubmit = async data => {
    const {
      hideModal = noop,
      labId,
      isSingleLabMode,
      refetch
      // currentUser
    } = this.props;

    // finish the on submit

    const { labRole, email } = data;

    try {
      const usersWithEmail = await safeQuery(
        ["user", "id email labRoles { id }"],
        {
          variables: {
            filter: {
              email
            }
          }
        }
      );
      if (usersWithEmail.length < 1) {
        throw new SubmissionError({
          _error: "A user with that email does not exist."
        });
      } else if (usersWithEmail.length > 1) {
        throw new SubmissionError({
          _error: "Multiple users share this email! Please contact support!"
        });
      }

      const existingUser = usersWithEmail[0];

      if (
        existingUser.labRoles &&
        existingUser.labRoles.length > 0 &&
        isSingleLabMode
      ) {
        throw new SubmissionError({
          _error:
            "The user associated with this email already belongs to a lab. A user may only belong to one lab in single lab mode."
        });
      }

      await safeUpsert("labRole", {
        roleCode: labRole,
        userId: existingUser.id,
        labId
      });

      // await safeUpsert("user", {
      //   id: existingUser.id,
      //   defaultLabId: labId
      // });

      refetch();
      hideModal();
    } catch (err) {
      if (err instanceof SubmissionError) {
        throw err;
      } else {
        console.error("err:", err);
        let error = err.message;
        try {
          error = JSON.parse(err.request.responseText).error;
        } catch (e) {
          error = e;
        }
        const errorMsg = "Error adding user to lab: ";
        throw new SubmissionError({
          _error: errorMsg + error
        });
      }
    }
  };

  render() {
    const { valid, hideModal, handleSubmit, error, submitting } = this.props;

    const tooltipProps = {
      className: "full-width-tooltip",
      position: "left"
    };
    return (
      <React.Fragment>
        <div className={Classes.DIALOG_BODY}>
          <InputField
            name="email"
            label="Email"
            type="email"
            isRequired
            tooltipError
            tooltipProps={tooltipProps}
          />
          <SelectField
            name="labRole"
            label="Lab Role"
            isRequired
            // type="email"
            defaultValue="MEMBER"
            options={[
              { label: "Member", value: "MEMBER" },
              { label: "Admin", value: "ADMIN" }
            ]}
            tooltipError
            tooltipProps={tooltipProps}
          />
          {error && <BlueprintError error={error} />}
        </div>
        <DialogFooter
          hideModal={hideModal}
          loading={submitting}
          disabled={!valid}
          onClick={handleSubmit(this.onSubmit)}
        />
      </React.Fragment>
    );
  }
}

const validate = values => {
  const errors = {};
  if (!errors.email && values.email && !isEmail(values.email)) {
    errors.email = "Invalid email address";
  }
  return errors;
};

const AddLabMemberForm = compose(
  reduxForm({
    form: "addLabMemberForm",
    enableReinitialize: true,
    asyncBlurFields: ["email"],
    validate
  }),
  tgFormValues("email", "labOptionForUser")
)(AddLabMember);

export default AddLabMemberForm;
export const AddLabMemberDialog = wrapDialog({ title: "Add Lab Member" })(
  AddLabMemberForm
);
