/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import initApp from "../src-shared/initApp";
import modalComponents from "./modalComponents";

import modalComponentsDesign from "../src-design/modalComponents";

import "./components/Library/initModelToLibrary";
// needs to be above App because it is changing modelNameToLink
import "./app.css";
import mainStore from "../src-shared/Store/main";
import { mobXModalComponents } from "../src-test";
import Helper from "../src-shared/Helper";
import Alert from "../src-shared/Alert";

if (module.hot) {
  module.hot.accept();
}

initApp({
  mobXStore: mainStore,
  modalComponents: { ...modalComponents, ...modalComponentsDesign },
  mobXModalComponents,
  Helper,
  Alert
});
