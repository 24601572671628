/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { get, remove, set, pullAll, groupBy, pick } from "lodash";
import pluralize from "pluralize";
import { stringify } from "qs";
import { showProgressToast, showConfirmationDialog } from "@teselagen/ui";
import { unparse } from "papaparse";
import QueryBuilder from "tg-client-query-builder";
import {
  query,
  safeUpsert,
  safeQuery,
  safeDelete
} from "../../src-shared/apolloMethods";
import { parseHash } from "./generalUtils";
import { download } from "../../src-shared/utils/downloadTest";
import modelNameToReadableName from "../../src-shared/utils/modelNameToReadableName";
import gql from "graphql-tag";

import stripFields from "../../src-shared/utils/stripFields";
import { handleZipFiles } from "../../../tg-iso-shared/src/utils/fileUtils";

function addIndicesToPositions(defaultPositions) {
  return defaultPositions.map((pos, i) => ({
    ...pos,
    index: i
  }));
}

/** SortableListField adds this 'tempKey' to each position to correctly handle re-renders */
function removeTempKeyFromPositions(positions) {
  return positions.map(pos => {
    const { tempKey, ...rest } = pos;
    return rest;
  });
}

export async function prepareEditingPositions({
  values,
  initialValues,
  key,
  model,
  isCode
}) {
  let newEquipPos = values.isPositional ? get(values, pluralize(key), []) : [];
  const oldEquipPos = get(initialValues, pluralize(key), []);
  const posToDelete = oldEquipPos.reduce((acc, pos) => {
    const keep = newEquipPos.some(nPos => nPos.id === pos.id);
    if (!keep) acc.push(pos.id);
    return acc;
  }, []);
  newEquipPos = addIndicesToPositions(newEquipPos);
  let posToAdd = remove(newEquipPos, pos => !pos.id);
  posToAdd = removeTempKeyFromPositions(posToAdd);

  if (posToDelete.length) {
    await safeDelete(key, posToDelete);
  }
  if (posToAdd.length) {
    await safeUpsert(
      key,
      posToAdd.map(pos => ({
        ...pos,
        label: pos.label || null,
        [`${model}${isCode ? "Code" : "Id"}`]: values[isCode ? "code" : "id"]
      }))
    );
  }
  if (newEquipPos.length) {
    await safeUpsert(key, stripFields(newEquipPos, ["__typename"]));
  }

  delete values[pluralize(key)];
  return values;
}

export function prepareCreatingPositions({ values, key }) {
  values[pluralize(key)] = addIndicesToPositions(
    get(values, pluralize(key), [])
  );

  values[pluralize(key)] = removeTempKeyFromPositions(
    get(values, pluralize(key), [])
  );

  return values;
}

export function prepareInitialValuesForPositions({ initialValues, key }) {
  const defPositions = get(initialValues, pluralize(key));
  let newInitialValues = initialValues;

  if (defPositions) {
    newInitialValues = { ...initialValues };
    set(
      newInitialValues,
      pluralize(key),
      [...defPositions].sort((a, b) => a.index - b.index)
    );
  }
  return newInitialValues;
}

const containerFields = `
  id
  path
  equipmentId
  equipmentPositionId
  locationId
`;

export async function getPositionRecordByIdAndType(partialRecord) {
  const { __typename, id } = partialRecord;
  const queryOptions = {
    variables: {
      id
    }
  };
  if (__typename === "equipmentItem" || __typename === "equipmentPosition") {
    return partialRecord;
  } else if (__typename === "container") {
    return await query(["container", containerFields], queryOptions);
  } else if (__typename === "containerPosition") {
    return await query(
      ["containerPosition", `id container { ${containerFields} }`],
      queryOptions
    );
  }
}

export const resetAssignedPositionFields = {
  containerId: null,
  containerPositionId: null,
  equipmentId: null,
  equipmentPositionId: null,
  locationId: null
};

export function getPositionItemFromLocation(location, match) {
  const { equipmentPositionId, path } = parseHash(location);
  const isEquipment = location.pathname.startsWith("/equipment");
  const equipmentId = match.params.id;
  let __typename,
    itemId,
    containerId,
    pathToItem = "";
  if (!isEquipment && !path) {
    __typename = "container";
    itemId = equipmentId;
  } else if (!path && !equipmentPositionId) {
    __typename = "equipment";
    itemId = equipmentId;
  } else if (!path && equipmentPositionId) {
    __typename = "equipmentPosition";
    itemId = equipmentPositionId;
  } else {
    // go off path
    const last = path.slice(path.lastIndexOf("/") + 1);
    pathToItem = path.slice(0, path.lastIndexOf("/"));
    const [pathContainerId, containerPositionId] = last.split("-");
    __typename = containerPositionId ? "containerPosition" : "container";
    itemId = containerPositionId || pathContainerId;
    if (containerPositionId) containerId = pathContainerId;
  }
  const position = {
    id: itemId,
    ...(containerId && { containerId }),
    __typename
  };
  if (
    isEquipment &&
    (__typename === "containerPosition" || __typename === "container")
  ) {
    if (equipmentPositionId) {
      position.equipmentPositionId = equipmentPositionId;
    } else if (equipmentId) {
      position.equipmentId = equipmentId;
    }
    position.path = pathToItem;
  }
  return position;
}

export function getBasicHashForContainer(container, { path } = {}) {
  if (container) {
    const { equipmentId, equipmentPositionId } = container;
    const containerPath = (container.path || "") + `/${container.id}`;

    return stringify({
      ...(equipmentId && { equipmentId }),
      ...(equipmentPositionId && { equipmentPositionId }),
      path: path || containerPath
    });
  }
}

export function getHashForContainer(location, container) {
  const position = parseHash(location);
  const needsEquipmentId =
    !position.equipmentId && get(container, "equipmentId");
  const needsEquipmentPositionId =
    !position.equipmentPositionId && get(container, "equipmentPositionId");
  const needsPath = !position.path;
  if (needsEquipmentId || needsEquipmentPositionId || needsPath) {
    return getBasicHashForContainer(container, position);
  }
}

export function getPathOfAssignedPosition(ap) {
  return (
    get(ap, "containerArray.containerArrayPathView.fullPath") ||
    get(ap, "aliquotContainer.aliquotContainerPathView.fullPath") ||
    ""
  );
}

export async function getContentsCsv(equipmentIds) {
  const clearProgressToast = showProgressToast(`Loading equipment...`);
  try {
    const equipmentPositions = await safeQuery(["equipmentPosition", "id"], {
      variables: {
        filter: {
          equipmentId: equipmentIds
        }
      }
    });
    const equipmentPositionIds = equipmentPositions.map(ep => ep.id);
    const assignedPositionFields = `
      id
      containerArray {
        id
        name
        barcode {
          id
          barcodeString
        }
        containerArrayPathView {
          id
          fullPath
        }
      }
      aliquotContainer {
        id
        name
        barcode {
          id
          barcodeString
        }
        aliquotContainerPathView {
          id
          fullPath
        }
      }
    `;

    const assignedPositionWhereFilters = [
      {
        equipmentId: equipmentIds
      },
      {
        "container.equipmentId": equipmentIds
      },
      {
        "containerPosition.container.equipmentId": equipmentIds
      }
    ];
    if (equipmentPositionIds.length) {
      assignedPositionWhereFilters.push(
        {
          equipmentPositionId: equipmentPositionIds
        },
        {
          "container.equipmentPositionId": equipmentPositionIds
        },
        {
          "containerPosition.container.equipmentPositionId":
            equipmentPositionIds
        }
      );
    }
    const qb = new QueryBuilder("assignedPosition");
    const assignedPositionFilter = qb
      .whereAny(...assignedPositionWhereFilters)
      .toJSON();
    const assignedPositions = await safeQuery(
      ["assignedPosition", assignedPositionFields],
      {
        variables: {
          filter: assignedPositionFilter
        }
      }
    );
    if (assignedPositions.length) {
      const csvFields = ["Path", "Name", "Barcode"];
      let csvRows = [];
      assignedPositions.forEach(assignedPosition => {
        const fullPath = getPathOfAssignedPosition(assignedPosition);
        const { name, barcode } =
          assignedPosition.containerArray ||
          assignedPosition.aliquotContainer ||
          {};
        if (!fullPath) {
          // this is a broken assigned position
          return;
        }

        const row = [fullPath, name, barcode && barcode.barcodeString];
        csvRows.push(row);
      });
      // sort by paths
      csvRows = csvRows.sort((a, b) => a[0].localeCompare(b[0]));
      const csvString = unparse({
        fields: csvFields,
        data: csvRows
      });
      download(csvString, "equipment.csv", "text/plain");
    } else {
      // no assignedPositions means nothing in equipment
      clearProgressToast();
      return window.toastr.warning("No items found.");
    }
  } catch (error) {
    window.toastr.error("Error loading contents.");
    console.error("error:", error);
  }
  clearProgressToast();
}

const exportEquipmentEquipmentFragment = gql`
  fragment exportEquipmentEquipmentFragment on equipmentItem {
    id
    name
    equipmentTypeCode
    barcode {
      id
      barcodeString
    }
    extendedStringValueViews {
      id
      value
      type
      extendedProperty {
        id
        name
      }
    }
    hasContents
    make
    model
    manufacturer
    description
    serialNumber
    serviceContactInfo
    serviceContractExpiration
    warrantyExpiration
  }
`;

const exportEquipmentContainerFragment = gql`
  fragment exportEquipmentContainerFragment on container {
    id
    name
    barcode {
      id
      barcodeString
    }
    label
    path
    equipmentId
    containerTypeCode
    containerPositions {
      id
      name
      index
      label
    }
  }
`;

/**
 * This will export equipment into json files.
 * Because we have removed the support of equipment positions this function will not include them.
 * If we ever add equipment positions back into the app this will need to updated
 * @param {*} equipmentIds
 */
export async function exportEquipment(equipmentIds) {
  const clearProgressToast = showProgressToast(`Exporting equipment...`);
  try {
    const equipmentItems = await safeQuery(exportEquipmentEquipmentFragment, {
      variables: {
        filter: {
          id: equipmentIds
        }
      }
    });
    const containers = await safeQuery(exportEquipmentContainerFragment, {
      variables: {
        filter: {
          equipmentId: equipmentIds
        }
      }
    });

    // now we need to make the json that is accepted by the equipment upload dialog
    const groupedContainers = groupBy(containers, "equipmentId");

    const cleanContainer = parentContainer => {
      return {
        name: parentContainer.name,
        label: parentContainer.label,
        barcode: parentContainer.barcode?.barcodeString,
        type: parentContainer.containerTypeCode,
        containerPositions: [...parentContainer.containerPositions]
          .sort((a, b) => a.index - b.index)
          .map(pos => {
            return {
              name: pos.name,
              label: pos.label
            };
          }),
        containers: containers.reduce((acc, childContainer) => {
          if (
            childContainer.path ===
            (parentContainer.path || "") + `/${parentContainer.id}`
          ) {
            acc.push(cleanContainer(childContainer));
          }
          return acc;
        }, [])
      };
    };

    const equipmentNameMap = {};
    const filesToZip = [];
    for (const equipmentItem of equipmentItems) {
      const finalEquipmentObject = {
        ...pick(equipmentItem, [
          "name",
          "description",
          "make",
          "model",
          "manufacturer",
          "serialNumber",
          "serviceContactInfo",
          "serviceContractExpiration",
          "warrantyExpiration"
        ]),
        type: equipmentItem.equipmentTypeCode,
        shownInPlacementDialogs: equipmentItem.hasContents,
        barcode: equipmentItem.barcode?.barcodeString,
        extendedProperties: equipmentItem.extendedStringValueViews.map(v => {
          return {
            name: v.extendedProperty.name,
            value: v.value
          };
        })
      };
      const containers = groupedContainers[equipmentItem.id];

      if (containers) {
        const rootContainers = containers.filter(c => !c.path);
        finalEquipmentObject.containers = rootContainers.map(cleanContainer);
      }
      const name = finalEquipmentObject.name || "equipment";
      if (!equipmentNameMap[name]) {
        equipmentNameMap[name] = 0;
      }
      const filename =
        equipmentNameMap[name] === 0
          ? name
          : name + ` ${equipmentNameMap[name]}`;
      equipmentNameMap[name]++;
      filesToZip.push({
        name: filename + ".json",
        data: JSON.stringify(finalEquipmentObject, null, 2)
      });
    }
    if (window.Cypress) {
      window.Cypress.cypressTestFile = filesToZip[0].data;
      window.toastr.success("file downloaded");
    } else {
      download(await handleZipFiles(filesToZip), "Equipment.zip");
    }
  } catch (error) {
    window.toastr.error("Error exporting equipment.");
    console.error("error:", error);
  }
  clearProgressToast();
}

/**
 * Shows a warning for items that have already been placed in a location.
 *
 * Will remove the already placed items if the user chooses to not move them.
 * @param {array} items
 */
export const showAlreadyPlacedWarning = async items => {
  const itemsAlreadyPlaced = items.filter(item => item.assignedPosition);
  if (itemsAlreadyPlaced.length) {
    let text;
    if (itemsAlreadyPlaced.length === 1) {
      const name =
        itemsAlreadyPlaced[0].name ||
        `This ${modelNameToReadableName(items[0].__typename, {
          upperCase: true
        })}`;
      text = `${name} has already been placed in a location. Would you like to move it?`;
    } else {
      text = `These items have been previously placed in locations: ${itemsAlreadyPlaced
        .map(item => item.name)
        .join(", ")}. \n
      Would you like to move them?`;
    }
    const moveItemsAlreadyPlaced = await showConfirmationDialog({
      text,
      cancelButtonText: "No",
      confirmButtonText: "Yes"
    });
    if (!moveItemsAlreadyPlaced) {
      pullAll(items, itemsAlreadyPlaced);
    }
  }
  return items;
};
