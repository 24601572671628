/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { FormGroup, Intent, Switch } from "@blueprintjs/core";
import React from "react";
import Helmet from "react-helmet";
import useLocalStorage from "../../src-shared/hooks/useLocalStorage";
import MetaData from "../views/MetaData";

export const TestSettings = () => {
  const [advancedMode, setAdvancedMode] = useLocalStorage(
    "advancedMode",
    localStorage.getItem("advancedMode")
  );
  const [experimentalMode, setExperimentalMode] = useLocalStorage(
    "experimentalMode",
    localStorage.getItem("experimentalMode")
  );
  const [advancedMetadata, setAdvancedMetadata] = useLocalStorage(
    "advancedMetadata",
    localStorage.getItem("advancedMetadata")
  );

  return (
    <React.Fragment>
      <div className="tg-card">
        <Helmet title="Experiment Settings" />
        <h3 className="settings-form-header">Experiment Settings</h3>
        <div className="settings-body">
          <FormGroup
            helperText="Enable and disable extra Test features"
            intent={Intent.PRIMARY}
            label="App Mode"
            labelFor="text-input"
            labelInfo="(optional)"
          >
            <Switch
              id="advancedMode"
              label="Enable advanced features"
              onChange={() => setAdvancedMode(!advancedMode)}
              checked={advancedMode}
            />
            <Switch
              id="experimentalMode"
              label="Enable experimental features"
              onChange={() => setExperimentalMode(!experimentalMode)}
              checked={experimentalMode}
            />
            <Switch
              id="advancedMetadata"
              label="Show advanced meta data"
              onChange={() => setAdvancedMetadata(!advancedMetadata)}
              checked={advancedMetadata}
            />
          </FormGroup>
        </div>
      </div>
      <MetaData />
    </React.Fragment>
  );
};
