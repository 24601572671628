/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import downloadjsFile from "downloadjs";

export function download(data, fileName, type) {
  if (window.Cypress) {
    window.Cypress.cypressTestFile = data;
    window.Cypress.cypressTestFileName = fileName;
    window.Cypress.cypressTestFileType = type;
    window.toastr.success(
      "file downloaded, use catchDownload((file,contents)=>{}) to assert on it"
    );
  }
  downloadjsFile(new Blob([data]), fileName, type);
}
