/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { startCase } from "lodash";
import LocationSettingsDialog from "../../../containers/LocationSettingsDialog";
import SiteLocationDialog from "../../Dialogs/SiteLocationDialog";
import EquipmentTypeDialog from "../../Dialogs/EquipmentTypeDialog";
import LocationTypeDialog from "../../Dialogs/LocationTypeDialog";
import ContainerTypeDialog from "../../Dialogs/ContainerTypeDialog";
import {
  prepareEditingPositions,
  prepareCreatingPositions,
  prepareInitialValuesForPositions
} from "../../../utils";
import modelNameToReadableName from "../../../../src-shared/utils/modelNameToReadableName";
import DescriptionWithDefaultPositions from "./DescriptionWithDefaultPositions";
import DefaultDescriptionComponent from "./DescriptionComponent";
import gql from "graphql-tag";
import LocationSettingsPanel from "./LocationSettingsPanel";
const codeModels = {
  locationType: true,
  equipmentType: true,
  containerType: true
};

function prepareEditDefaultPositions({ model, ...rest }) {
  const key = `${model.replace("Type", "")}DefaultPosition`;
  return prepareEditingPositions({ key, model, ...rest });
}

function prepareCreateDefaultPositions({ model, ...rest }) {
  const key = `${model.replace("Type", "")}DefaultPosition`;
  return prepareCreatingPositions({ key, model, ...rest });
}

function prepareInitialValuesForDefaultPositions({ model, ...rest }) {
  const key = `${model.replace("Type", "")}DefaultPosition`;
  return prepareInitialValuesForPositions({ key, model, ...rest });
}

const siteSettingsFragment = gql`
  fragment siteSettingsFragment on site {
    id
    name
    city
    address
    address2
    postalCode
    locations(pageSize: 1) {
      id
    }
  }
`;

const locationTypeSettingsFragment = gql`
  fragment locationTypeSettingsFragment on locationType {
    code
    name
    description
    locations {
      id
    }
  }
`;

const equipmentTypeSettingsFragment = gql`
  fragment equipmentTypeSettingsFragment on equipmentType {
    code
    name
    description
    isPositional
    equipmentItems(pageSize: 1) {
      id
    }
    equipmentDefaultPositions {
      id
      name
      label
      index
    }
  }
`;

const containerTypeSettingsFragment = gql`
  fragment containerTypeSettingsFragment on containerType {
    code
    name
    description
    isPositional
    containers(pageSize: 1) {
      id
    }
    containerDefaultPositions {
      id
      name
      label
      index
    }
  }
`;

const locationSettings = {
  site: {
    model: "site",
    fragment: siteSettingsFragment,
    dialog: SiteLocationDialog
  },
  locationType: {
    model: "locationType",
    fragment: locationTypeSettingsFragment,
    dialog: LocationTypeDialog
  },
  equipmentType: {
    model: "equipmentType",
    fragment: equipmentTypeSettingsFragment,
    DescriptionComponent: DescriptionWithDefaultPositions,
    dialog: EquipmentTypeDialog,
    wrapDialogOptions: {
      style: { width: 600 }
    },
    dialogOptions: {
      prepareInitialValues: prepareInitialValuesForDefaultPositions,
      prepareEditInput: prepareEditDefaultPositions,
      prepareCreateInput: prepareCreateDefaultPositions
    }
  },
  containerType: {
    model: "containerType",
    fragment: containerTypeSettingsFragment,
    DescriptionComponent: DescriptionWithDefaultPositions,
    dialog: ContainerTypeDialog,
    wrapDialogOptions: {
      style: { width: 600 }
    },
    dialogOptions: {
      prepareInitialValues: prepareInitialValuesForDefaultPositions,
      prepareEditInput: prepareEditDefaultPositions,
      prepareCreateInput: prepareCreateDefaultPositions
    }
  }
};

export default ({ model, ...rest }) => {
  const {
    fragment,
    dialog,
    dialogOptions,
    wrapDialogOptions,
    DescriptionComponent,
    additionalFields = []
  } = locationSettings[model];
  const schema = {
    model,
    fields: [
      {
        path: "name",
        type: "string",
        displayName: startCase(model)
      },
      ...additionalFields
    ]
  };
  const WrappedPanel = LocationSettingsPanel({
    schema,
    fragment
  });
  const Dialog = LocationSettingsDialog({
    model,
    Dialog: dialog,
    dialogOptions,
    wrapDialogOptions,
    codeModels
  });

  return (
    <WrappedPanel
      {...{
        ...rest,
        DescriptionComponent:
          DescriptionComponent || DefaultDescriptionComponent,
        libraryName: modelNameToReadableName(model),
        model,
        Dialog
      }}
    />
  );
};
