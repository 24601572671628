/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

export const partsFragment = gql`
  fragment partsFragment on part {
    id
    name
    strand
    start
    end
    size
  }
`;

export const featuresFragment = gql`
  fragment featuresFragment on sequenceFeature {
    id
    name
    strand
    start
    end
  }
`;

export const targetSequenceFragment = gql`
  fragment targetSequenceFragment on sequence {
    id
    name
    circular
    size
    fullSequence
    sequenceType {
      code
      name
    }
    parts {
      ...partsFragment
    }
    sequenceFeatures {
      ...featuresFragment
    }
  }
  ${partsFragment}
  ${featuresFragment}
`;

export const registratedAnnotationGroupFragment = gql`
  fragment registratedAnnotationGroupFragment on registeredAnnotationGroup {
    id
    name
    registeredAnnotationTypeCode
  }
`;
