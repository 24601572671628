/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose } from "recompose";
import { reduxForm } from "redux-form";
import { Classes } from "@blueprintjs/core";
import {
  DialogFooter,
  FileUploadField,
  BlueprintError,
  wrapDialog
} from "@teselagen/ui";
import { v1 as uuid } from "uuid";
import { throwFormError } from "../../../src-shared/utils/formUtils";
import { safeUpsert } from "../../../src-shared/apolloMethods";
import { allowedCsvFileTypes } from "../../../../tg-iso-shared/src/utils/fileUtils";
import { getCaseInsensitiveKeyedItems } from "../../../../tg-iso-shared/src/utils/caseInsensitiveFilter";
import { getDownloadTemplateFileHelpers } from "../../../src-shared/components/DownloadTemplateFileButton";

const UploadGeneraAndSpecies = ({
  hideModal,
  refetch,
  handleSubmit,
  submitting,
  error
}) => {
  const onSubmit = async values => {
    try {
      const { parsedData } = values.generaCsv[0];
      const genusNames = parsedData.map(row => row.genusName);
      if (!genusNames.length) return;
      const keyedExistingGenus = await getCaseInsensitiveKeyedItems(
        [
          "genus",
          /* GraphQL */ `
            {
              id
              name
              species {
                id
                name
              }
            }
          `
        ],
        "name",
        genusNames
      );

      const genusNameToId = {};
      const newSpecies = [];
      const newGenus = [];
      parsedData.forEach(row => {
        const existingGenus =
          keyedExistingGenus[row.genusName.trim().toLowerCase()];
        if (existingGenus) {
          const hasSpecies = existingGenus.species.some(
            s =>
              (s.name || "").trim().toLowerCase() ===
              row.speciesName.trim().toLowerCase()
          );
          if (!hasSpecies) {
            newSpecies.push({
              genusId: existingGenus.id,
              name: row.speciesName,
              abbreviatedName: row.speciesAbbrev,
              description: row.speciesDescription
            });
          }
        } else {
          if (!genusNameToId[row.genusName]) {
            const id = uuid();
            newGenus.push({
              id,
              name: row.genusName,
              abbreviation: row.genusAbbrev,
              description: row.genusDescription
            });
            genusNameToId[row.genusName] = id;
          }
          const genusId = genusNameToId[row.genusName];
          newSpecies.push({
            genusId,
            name: row.speciesName,
            abbreviatedName: row.speciesAbbrev,
            description: row.speciesDescription
          });
        }
      });
      await safeUpsert("genus", newGenus, { forceCreate: true });
      await safeUpsert("specie", newSpecies);
      await refetch();
      hideModal();
    } catch (error) {
      console.error("error:", error);
      throwFormError(error.message || `Error uploading genus and species.`);
    }
  };

  return (
    <>
      <div className={Classes.DIALOG_BODY}>
        <FileUploadField
          isRequired
          fileLimit={1}
          accept={getDownloadTemplateFileHelpers({
            type: allowedCsvFileTypes,
            fileName: "genusAndSpecies",
            validateAgainstSchema: {
              fields: [
                {
                  path: "speciesName",
                  isRequired: true,
                  example: "coli"
                },
                {
                  path: "speciesAbbrev",
                  displayName: "Species Abbreviated Name",
                  example: "E. coli"
                },
                {
                  path: "speciesDescription",
                  example:
                    "Rod-shaped, gram-negative bacterium commonly found in the lower intestine of warm-blooded organisms."
                },
                {
                  path: "genusName",
                  isRequired: true,
                  example: "Escherichia"
                },
                {
                  path: "genusAbbrev",
                  displayName: "Genus Abbreviation",
                  example: "E."
                },
                {
                  path: "genusDescription",
                  example:
                    "A genus of gram-negative, facultatively anaerobic, rod-shaped bacteria."
                }
              ]
            }
          })}
          name="generaCsv"
        />
        <BlueprintError error={error} />
      </div>
      <DialogFooter
        submitting={submitting}
        hideModal={hideModal}
        onClick={handleSubmit(onSubmit)}
      />
    </>
  );
};

export default compose(
  wrapDialog(() => {
    return {
      title: "Upload Genera and Species"
    };
  }),
  reduxForm({
    form: "uploadGeneraAndSpeciesDialog"
  })
)(UploadGeneraAndSpecies);
