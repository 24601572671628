/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import pluralize from "pluralize";
import { DataTable, CollapsibleCard } from "@teselagen/ui";
import { pick, get, round } from "lodash";
import AbstractRecord from "../AbstractRecord";

import modelNameToLink from "../utils/modelNameToLink";
import TgSequenceEditor, { seqEdEnhancer } from "../TgSequenceEditor";

import MicrobialMaterialCard from "./MicrobialMaterialCard";
import { tagColumnWithRenderNested } from "../utils/tagColumn";
import { showDialog } from "../GlobalDialog";
import { molecularWeightRender } from "../utils/unitUtils";
import GenomeCard from "./GenomeCard";
import { getEnclosingFeatures } from "../utils/sequenceUtils";
import UpdateSequenceDialog from "../components/Dialogs/UpdateSequenceDialog";
import OligoBindingSitesCard from "./OligoBindingSitesCard";
import { annotationSizeStartEndColumns } from "../utils/libraryColumns";
import { flatMap } from "lodash";
import { linkSequencesToMaterials } from "../../src-build/utils";
import { safeQuery } from "../apolloMethods";
import { associatedDesigns } from "../PartRecordView";
// import SequenceBPS from "./SequenceBPS";

function MaterialLink({ sequence, fragment, rnaOrDna }) {
  const [linking, setLinking] = useState(false);
  if (linking) {
    return <span>Linking...</span>;
  }
  return sequence.polynucleotideMaterial ? (
    <Link
      key="dnaMaterialLink"
      to={modelNameToLink(sequence.polynucleotideMaterial)}
    >
      {sequence.polynucleotideMaterial.name}
    </Link>
  ) : (
    <a
      onClick={async () => {
        setLinking(true);
        try {
          await linkSequencesToMaterials({
            records: [sequence],
            isRNA: sequence.sequenceTypeCode === "RNA"
          });
          await safeQuery(fragment, { variables: { id: sequence.id } });
        } catch (error) {
          console.error(`error:`, error);
        }
        setLinking(false);
      }}
    >
      Create {rnaOrDna} Material
    </a>
  );
}

class DNASequenceRecordView extends Component {
  state = {};

  renderLinkOrNotFound({ sequence, path, route, render }) {
    const routeToUse = route ? route : pluralize(path);
    const item = get(sequence, path);
    return item ? (
      <Link to={`/${routeToUse}/${item.id}`}>
        {render ? render(item) : item.name}
      </Link>
    ) : (
      "Not Found"
    );
  }

  updateShowFunction = () => {
    const { sequence, refetchSequence: refetch } = this.props;
    showDialog({
      ModalComponent: UpdateSequenceDialog,
      modalProps: {
        refetch,
        initialValues: {
          ...pick(sequence, ["id", "name", "description", "sequenceTypeCode"]),
          inductionMethodIds:
            sequence.plasmidInductionMethodPlasmids &&
            sequence.plasmidInductionMethodPlasmids.map(
              pim => pim.inductionMethod.id
            )
        },
        plasmidInductionMethods: sequence.plasmidInductionMethodPlasmids
      }
    });
  };

  onCdsDoubleClick = record => {
    this.props.history.push(`/dna-sequences/${record.codingDnaSequence.id}`);
  };

  onPlasmidDoubleClick = record => {
    this.props.history.push(`/dna-sequences/${record.sequence.id}`);
  };

  onProteinDoubleClick = record => {
    this.props.history.push(
      `/functional-protein-units/${record.functionalProteinUnit.id}`
    );
  };

  onSeqFeatDoubleClick = record => {
    this.props.history.push(`/sequence-features/${record.id}`);
  };

  onMicrobialMaterialDblClick = record => {
    this.props.history.push(`/microbial-materials/${record.id}`);
  };

  render() {
    const { sequence, fragment, location, readOnly, disableBpEditing } =
      this.props;

    if (
      sequence?.sequenceTypeCode === "RNA" &&
      sequence?.rnaType?.name === "gRNA"
    ) {
      return (
        <Redirect
          to={{
            ...location,
            pathname: `/guide-rna-sequences/${sequence.id}`
          }}
        />
      );
    }
    const codingDnaSequences = (
      <CollapsibleCard title="Coding Sequences" key="CDS">
        <DataTable
          entities={sequence.sequenceCodingSequences}
          isSimple
          schema={CDSSchema}
          formName="sequenceCodingSequenceForm"
          onDoubleClick={this.onCdsDoubleClick}
        />
      </CollapsibleCard>
    );
    const plasmidsCard = (
      <CollapsibleCard key="plasmids" title="Source Sequences">
        <DataTable
          entities={sequence.codingDnaSequenceSequenceCodingSequences}
          isSimple
          schema={plasmidSchema}
          formName="plasmidForm"
          onDoubleClick={this.onPlasmidDoubleClick}
        />
      </CollapsibleCard>
    );
    const functionalProteinUnits = (
      <CollapsibleCard key="fpus" title="Relevant Functional Protein Units">
        <DataTable
          entities={
            sequence.polynucleotideMaterial
              ?.polynucleotideMaterialMaterialFpus || []
          }
          isSimple
          schema={FPUSchema}
          formName="materialFpuForm"
          onDoubleClick={this.onProteinDoubleClick}
        />
      </CollapsibleCard>
    );
    const sequenceFeaturesCard = (
      <CollapsibleCard
        key="sequenceFeatures"
        title={`Sequence Features (${sequence.sequenceFeatures.length})`}
      >
        <DataTable
          entities={sequence.sequenceFeatures.map(ann => ({
            ...ann,
            sequence
          }))}
          isSimple
          schema={seqFeatureSchema}
          formName="seqFeatForm"
          onDoubleClick={this.onSeqFeatDoubleClick}
        />
      </CollapsibleCard>
    );

    const partsCard = (
      <CollapsibleCard
        key="parts"
        title={`Sequence Parts (${sequence.parts.length})`}
      >
        <DataTable
          entities={sequence.parts.map(ann => ({
            ...ann,
            sequence
          }))}
          isSimple
          schema={partSchema}
          formName="partForm"
        />
      </CollapsibleCard>
    );
    const additionalCards = [];
    if (sequence.sequenceCodingSequences.length) {
      additionalCards.push(codingDnaSequences);
    }
    if (sequence.codingDnaSequenceSequenceCodingSequences.length) {
      additionalCards.push(plasmidsCard);
    }
    if (
      sequence.polynucleotideMaterial?.polynucleotideMaterialMaterialFpus
        ?.length
    ) {
      additionalCards.push(functionalProteinUnits);
    }
    if (sequence.sequenceFeatures.length) {
      additionalCards.push(sequenceFeaturesCard);
    }
    if (sequence.parts.length) {
      additionalCards.push(partsCard);
    }
    if (sequence.sequenceTypeCode === "GENOMIC_REGION") {
      additionalCards.push(
        <GenomeCard key="genomes" sequenceId={sequence.id} />
      );
    }

    if (
      sequence.polynucleotideMaterial
        ?.polynucleotideMaterialMicrobialMaterialPlasmids?.length
    ) {
      additionalCards.push(
        <MicrobialMaterialCard
          key="microbialMaterials"
          sequenceId={sequence.id}
        />
      );
    }
    if (sequence.sequenceTypeCode === "OLIGO") {
      additionalCards.push(
        <OligoBindingSitesCard
          key="oligoBindingSites"
          sequenceId={sequence.id}
        />
      );
    }

    const rnaOrDna = sequence.sequenceTypeCode === "RNA" ? "RNA" : "DNA";
    const sequenceInfo = [
      ["Name", sequence.name],
      ["Size", sequence.size],
      ["Sequence Type", get(sequence, "sequenceType.name")],
      [
        "Read Only",
        readOnly
          ? typeof readOnly === "string"
            ? "Yes - " + readOnly
            : "Yes"
          : "No"
      ],
      [
        "Base Pair Editing Disabled",
        disableBpEditing ? "Yes - " + disableBpEditing : "No"
      ],
      ["Molecular Weight", molecularWeightRender(sequence.molecularWeight)],
      sequence.description && ["Description", sequence.description],
      sequence.sequenceTypeCode === "RNA" &&
        sequence.rnaType && [
          "RNA Type",
          sequence.rnaType && sequence.rnaType.name
        ],
      sequence.plasmidInductionMethodPlasmids &&
        sequence.plasmidInductionMethodPlasmids.length > 0 && [
          "Induction Methods:",
          sequence.plasmidInductionMethodPlasmids
            .map(pim => pim.inductionMethod.name)
            .join(", ")
        ]
    ];

    sequenceInfo.push([
      rnaOrDna + " Material",
      <MaterialLink
        key="link"
        {...{
          sequence,
          fragment,
          rnaOrDna
        }}
      />
    ]);

    if (sequence.aminoAcidSequence?.name) {
      sequenceInfo.push([
        "Amino Acid Sequence",
        this.renderLinkOrNotFound({
          sequence,
          path: "aminoAcidSequence",
          route: "amino-acid-sequences"
        })
      ]);
    }
    sequenceInfo.push([
      associatedDesigns("parts.").displayName,
      associatedDesigns("parts.").render(null, sequence)
    ]);
    sequenceInfo.push([
      associatedJ5ReportsToSeq.displayName,
      associatedJ5ReportsToSeq.render(null, sequence)
    ]);

    return (
      <AbstractRecord
        {...this.props}
        recordInfo={sequenceInfo}
        recordName="sequence"
        updateShowFunction={this.updateShowFunction}
        additionalCards={additionalCards}
      >
        <TgSequenceEditor
          {...this.props}
          onPreviewModeFullscreenClose={() => {
            this.props.refetchRecord();
            window.refetchSequenceRecordBPS?.();
          }}
        />
      </AbstractRecord>
    );
  }
}

export default seqEdEnhancer(DNASequenceRecordView);

const CDSSchema = [
  {
    displayName: "Fragment Id",
    path: "codingDnaSequence.name"
  },
  {
    displayName: "Size",
    path: "codingDnaSequence.size",
    render: record => record + " bp"
  },
  tagColumnWithRenderNested("codingDnaSequence")
];

const FPUSchema = [
  {
    displayName: "Name",
    path: "functionalProteinUnit.name"
  },
  {
    displayName: "Extinction Coefficient",
    path: "functionalProteinUnit.extinctionCoefficient"
  },
  {
    displayName: "Molecular Weight",
    path: "functionalProteinUnit.molecularWeight",
    render: value => {
      return value ? round(value, 2) : "";
    }
  },
  tagColumnWithRenderNested("functionalProteinUnit")
];

const plasmidSchema = [
  {
    displayName: "Sequence Name",
    path: "sequence.name"
  },
  {
    displayName: "EOU Description",
    path: "eouDescription"
  },
  {
    displayName: "RBS Strength",
    path: "ribosomeBindingStrength"
  },
  tagColumnWithRenderNested("sequence")
];

const seqFeatureSchema = [
  {
    displayName: "Name",
    path: "name"
  },
  {
    displayName: "Enclosing Features",
    render: (v, record) => {
      const enclosingFeatures = getEnclosingFeatures(record, record.sequence);
      return enclosingFeatures.map(f => f.name).join(", ");
    }
  },
  {
    displayName: "Type",
    path: "type"
  },
  ...annotationSizeStartEndColumns
];

const partSchema = [
  {
    displayName: "Name",
    path: "name"
  },
  ...annotationSizeStartEndColumns
];

export const associatedJ5ReportsToSeq = {
  path: "sequenceJ5ItemViews.j5Report.name",
  type: "string",
  displayName: "Linked Assembly Reports",
  render: (_, seq) => {
    return (
      <>
        {flatMap(seq.sequenceJ5ItemViews, ({ j5Report }) => {
          if (!j5Report?.id) return [];
          return [
            ", ",
            <span key={j5Report.id}>
              <Link to={`/assembly-reports/${j5Report.id}`}>
                {j5Report.name}
              </Link>
            </span>
          ];
        }).slice(1)}
      </>
    );
  }
};
