/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { Callout, Switch } from "@blueprintjs/core";
import { useDispatch, useSelector } from "react-redux";
import actions from "../redux-shared/actions";
import { AnyAction } from "redux";

export const ActivateSegmentPanel = () => {
  const dispatch = useDispatch();
  const isActive = useSelector(
    (state: { platform: { segment: { active: boolean } } }) =>
      state.platform.segment.active
  );
  return (
    <div className="tg-card ">
      <h3>Activate Segment</h3>
      <Callout style={{ marginBottom: 20 }} intent="primary">
        This only for testing purposes. It will not be available in production.
        You can activate segment tracking by setting this flag to true.
      </Callout>
      <Switch
        style={{ marginLeft: 30 }}
        label="Activate Segment"
        onChange={() => {
          dispatch(
            (actions.platform.segment.active.toggle as () => AnyAction)()
          );
        }}
        checked={isActive}
      />
    </div>
  );
};
