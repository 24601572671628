/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { isoContext } from "@teselagen/utils";
import { adjustRangeToRotation } from "@teselagen/range-utils";
import { isSimpleDesignJson, getValidAssemblyMethods } from "./importUtils";
import caseInsensitiveFilter from "../../tg-iso-shared/src/utils/caseInsensitiveFilter";
import { keyBy, snakeCase, forEach, toString } from "lodash";
import * as uuid from "uuid";
import forcedAssemblyStrategies from "../constants/forcedAssemblyStrategies";
import defaultJ5OutputNamingTemplateMap from "../constants/defaultJ5OutputNamingTemplateMap";
import {
  getDefaultParamsAsCustomJ5ParamName,
  getParamsAsCustomJ5ParamName
} from "../../tg-iso-shared/redux/sagas/submitDesignForAssembly/createParameters";
import { getParamsForRestrictionEnzyme } from "../../tg-iso-shared/utils/enzymeUtils";
import restrictionEnzymeFragment from "../../tg-iso-shared/src/fragments/restrictionEnzymeFragment";
import uploadDnaSequences from "../../tg-iso-shared/src/sequence-import-utils/uploadDnaSequences";
import {
  addRotatedByPropertyToSequence,
  calculateSequenceTypeCode,
  computeSequenceHash
} from "../../tg-iso-shared/src/sequence-import-utils/utils";

export default async function importDesignSimpleJson({
  json,
  ctx = isoContext,
  allowDuplicates = false,
  promptForDuplicates = true
}) {
  if (!isSimpleDesignJson(json)) {
    throw new Error("This is not simple JSON design.");
  }
  const { safeUpsert, safeQuery } = ctx;

  const {
    name,
    eugene_rules, //allow import of eugene rules
    description,
    columns,
    layout_type,
    circular,
    restrictionEnzymeName,
    customJ5Parameter
  } = json;

  let { sequences } = json;

  if (!columns?.length) {
    throw new Error("No columns on design");
  }

  const [assemblyMethod] = await getValidAssemblyMethods(json, {
    safeQuery
  });

  const isCircular = circular !== false;

  let restrictionEnzyme = null;

  const allPassedInPartsById = {};
  const partIdTransform = {};
  if (sequences) {
    forEach(sequences, ({ parts }) => {
      forEach(parts, part => {
        allPassedInPartsById[part.id] = part;
      });
    });

    sequences.forEach(s => {
      delete s.id;
      s.sequenceTypeCode = calculateSequenceTypeCode(s);
      s.hash = computeSequenceHash(s.sequence, s.sequenceTypeCode);
    });
    const { allSeqIds } = await uploadDnaSequences(
      {
        allowDuplicates,
        promptForDuplicates,
        noImportCollection: true,
        sequenceJsons: sequences
      },
      ctx
    );

    const dbSequences = await safeQuery(
      [
        "sequence",
        "id hash parts { id name start end strand } sequenceFragments { id index fragment }"
      ],
      {
        variables: {
          filter: {
            id: allSeqIds
          }
        }
      }
    );

    const dbSequencesKeyedByHash = keyBy(dbSequences, "hash");

    sequences = addRotatedByPropertyToSequence(
      sequences,
      dbSequencesKeyedByHash
    );
    sequences.forEach(s => {
      const dbSeq = dbSequencesKeyedByHash[s.hash];
      if (!dbSeq) {
        throw new Error(
          `Could not find sequence with hash ${s.hash} in the database.`
        );
      }
      s.parts.forEach(p => {
        const partToCompare = adjustRangeToRotation(
          p,
          // make negative
          -s.rotatedBy,
          s.size
        );
        const dbPart = dbSeq.parts.find(
          dbPart =>
            dbPart.start === partToCompare.start &&
            dbPart.end === partToCompare.end &&
            dbPart.strand === partToCompare.strand
        );
        if (!dbPart) {
          throw new Error(
            `Could not find part ${p.name} in sequence with hash ${s.hash}.`
          );
        }
        partIdTransform[p.id] = dbPart.id;
      });
    });
  }
  if (restrictionEnzymeName) {
    [restrictionEnzyme] = await safeQuery(restrictionEnzymeFragment, {
      variables: {
        filter: caseInsensitiveFilter("restrictionEnzyme", "name", [
          restrictionEnzymeName
        ])
      }
    });
    if (!restrictionEnzyme) {
      throw new Error(
        `No restriction enzyme found with name ${restrictionEnzymeName}.`
      );
    }
  }

  const allPartNames = [];
  const allPartIds = [];
  const allFasNames = [];
  let keyedPartsById = {};
  // We need to collect all the part names and ids to check for duplicates
  columns.forEach(col => {
    col.parts?.forEach(part => {
      if (part.isEmpty) {
        //do nothing
      } else if (part.id) {
        let foundPart = false;
        if (sequences) {
          const p = allPassedInPartsById[part.id]; //get the part as it was passed in
          if (partIdTransform[part.id]) {
            //find the newly created part with the same key
            p.id = partIdTransform[part.id]; //set the id to the newly created part's id
            part.id = p.id;
            foundPart = true;
            keyedPartsById[p.id] = p;
          }
        }
        !foundPart && allPartIds.push(part.id);
      } else if (part.name) {
        allPartNames.push(part.name);
      } else {
        throw new Error(
          `Column ${col.name} listed a part which didn't specify an id or name.`
        );
      }
      if (part.forced_assembly_strategy) {
        allFasNames.push(part.forced_assembly_strategy);
      }
    });
  });

  let keyedPartsByName = {};
  if (allPartNames.length) {
    const partsByNameInDb = await safeQuery(["part", "id name"], {
      variables: {
        filter: caseInsensitiveFilter("part", "name", allPartNames, {
          additionalFilter: {
            "sequence.isInLibrary": true
          }
        })
      }
    });
    keyedPartsByName = keyBy(partsByNameInDb, p => p.name.toLowerCase());
  }
  if (allPartIds.length) {
    const partsByIdInDb = await safeQuery(["part", "id name"], {
      variables: {
        filter: {
          id: allPartIds
        }
      }
    });
    keyedPartsById = {
      ...keyedPartsById,
      ...keyBy(partsByIdInDb, "id")
    };
  }

  const designId = uuid.v4();
  const designObject = {
    id: designId,
    name: name || "Untitled Design",
    ...(description && { description }),
    type: "grand-design",
    layoutType: layout_type?.toLowerCase() === "list" ? "list" : "combinatorial"
  };
  const icons = await safeQuery(["icon", "id name"]);

  const normalizeName = name => snakeCase(name).toUpperCase();

  const keyedIcons = keyBy(icons, icon => normalizeName(icon.name));

  const rootCardId = uuid.v4();

  const reactionId = uuid.v4();

  const bins = [];
  const seenBinIndices = [];
  const fas = [];
  const partIdToElementId = {};
  let numRows = 0;
  columns
    .sort((a, b) => a.index - b.index)
    .forEach((col, i) => {
      let iconId;
      if (col.icon) {
        const hasIcon = keyedIcons[normalizeName(col.icon)];
        if (hasIcon) {
          iconId = hasIcon.id;
        } else {
          iconId = keyedIcons["USER_DEFINED"].id;
        }
      }
      const elements = [];
      const seenIndices = [];
      col.parts
        ?.sort((a, b) => a.index - b.index)
        .forEach((part, i) => {
          let existingPart;
          if (part.isEmpty) {
            part.name = "EMPTY";
            existingPart = part;
          } else if (part.id) {
            existingPart = keyedPartsById[part.id];
            if (!existingPart) {
              throw new Error(`Could not find part with id ${part.id}`);
            }
          } else if (part.name) {
            existingPart = keyedPartsByName[part.name.toLowerCase()];
            if (!existingPart) {
              throw new Error(`Could not find part with name ${part.name}`);
            }
          } else {
            throw new Error(
              `No part name or id specified in column ${col.name}`
            );
          }

          const elIndex = part.index || i;
          if (seenIndices.includes(elIndex)) {
            throw new Error(`Duplicate index ${elIndex} for part ${part.name}`);
          } else if (elIndex < 0) {
            throw new Error(`Invalid negative index for part ${part.name}`);
          }
          seenIndices.push(elIndex);
          const element = {
            id: uuid.v4(),
            isEmpty: part.isEmpty,
            name: existingPart.name,
            partId: existingPart.id,
            index: elIndex,
            designId
          };
          if (!part.isEmpty) partIdToElementId[existingPart.id] = element.id;
          if (part.forced_assembly_strategy) {
            const validFas = forcedAssemblyStrategies.find(
              fas =>
                normalizeName(fas) ===
                normalizeName(part.forced_assembly_strategy)
            );

            if (!validFas) {
              throw new Error(
                `Invalid forced_assembly_strategy ${part.forced_assembly_strategy} passed for part ${part.name}`
              );
            }
            fas.push({
              elementId: element.id,
              designId,
              name: validFas,
              reactionId
            });
          }
          if (elIndex + 1 > numRows) {
            numRows = elIndex + 1;
          }
          elements.push(element);
        });
      const colIndex = col.index || i;
      if (seenBinIndices.includes(colIndex)) {
        throw new Error(`Duplicate index ${colIndex} for column ${col.name}`);
      } else if (colIndex < 0) {
        throw new Error(`Invalid negative index for column ${col.name}`);
      } else if (colIndex !== i) {
        throw new Error(`Column ${col.name} index is not incremental.`);
      }
      seenBinIndices.push(colIndex);
      const bin = {
        id: uuid.v4(),
        designId,
        name: toString(col.name),
        direction: col.direction === "forward" || !col.direction,
        iconId,
        elements
      };

      bins.push(bin);
    });

  const cardIds = bins.map(() => uuid.v4());
  const rootCard = {
    id: rootCardId,
    designId,
    circular: isCircular,
    isRoot: true,
    binCards: bins.map((bin, i) => {
      return {
        bin,
        designId,
        index: i
      };
    }),
    outputReaction: {
      id: reactionId,
      designId,
      name: assemblyMethod.name,
      assemblyMethodId: assemblyMethod.id,
      restrictionEnzymeId:
        restrictionEnzyme !== null ? restrictionEnzyme.id : null,
      customJ5Parameter: {
        isLocalToThisDesignId: designId,
        ...getDefaultParamsAsCustomJ5ParamName(),
        ...customJ5Parameter,
        ...(restrictionEnzyme && {
          ...getParamsAsCustomJ5ParamName(
            getParamsForRestrictionEnzyme(restrictionEnzyme)
          )
        })
      },
      reactionJ5OutputNamingTemplates: Object.keys(
        defaultJ5OutputNamingTemplateMap
      ).map(outputTarget => ({
        designId,
        j5OutputNamingTemplate: {
          designId,
          outputTarget,
          ...defaultJ5OutputNamingTemplateMap[outputTarget]
        }
      })),
      cards: cardIds.map((cardId, i) => ({
        designId,
        inputIndex: i,
        circular: isCircular,
        id: cardId,
        name: "",
        dsf: columns[i].dsf ? columns[i].dsf : false
      }))
    }
  };

  designObject.numRows = numRows;
  const [newDesign] = await safeUpsert("design", designObject, {
    forceCreate: true
  });

  await safeUpsert("card", rootCard, { forceCreate: true });
  await safeUpsert(
    "binCard",
    cardIds.map((cardId, i) => ({
      designId,
      index: i,
      binId: bins[i].id,
      cardId
    })),
    { forceCreate: true }
  );
  await safeUpsert("fas", fas, { forceCreate: true });
  await safeUpsert(
    "junction",
    bins.map((bin, i) => ({
      designId,
      junctionTypeCode: "SCARLESS",
      isPhantom: false,
      reactionId: reactionId,
      fivePrimeCardId: cardIds[i],
      fivePrimeCardEndBinId: bin.id,
      fivePrimeCardInteriorBinId: bin.id,
      threePrimeCardId: cardIds[i],
      threePrimeCardStartBinId: bin.id,
      threePrimeCardInteriorBinId: bin.id
    })),
    { forceCreate: true }
  );
  if (eugene_rules) {
    await safeUpsert(
      "eugeneRule",
      eugene_rules.map(
        ({
          name,
          operand1,
          operand2,
          negation_operator,
          compositional_operator
        }) => {
          const id1 = partIdTransform[operand1] || operand1;
          const elementId1 = partIdToElementId[id1];
          const erId = uuid.v4();
          const toRet = {
            id: erId,
            name,
            reactionId: reactionId,
            designId,
            negationOperator: !!negation_operator,
            compositionalOperator: compositional_operator,
            operand1EugeneRuleElements: [
              {
                designId,
                eugeneRule1Id: erId,
                elementId: elementId1
              }
            ]
          };
          if (compositional_operator === "MORETHAN") {
            toRet.operand2Number = operand2;
          } else {
            const id2 = partIdTransform[operand2] || operand2;
            const elementId2 = partIdToElementId[id2];
            toRet.operand2EugeneRuleElements = [
              {
                designId,
                eugeneRule2Id: erId,
                elementId: elementId2
              }
            ];
          }
          return toRet;
        }
      ),
      { forceCreate: true }
    );
  }
  return { designId: newDesign.id };
}
