/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { getSequenceGenbank } from "./getSequenceGenbank";
import { getSequenceFasta } from "./getSequenceFasta";
import { getSequenceJsonString } from "./getSequenceJsonString";
import { sequenceToVeInput } from "../sequenceToVeInput";

export const getSequenceInFileType = (sequence, type, options = {}) => {
  const fn =
    type === "genbank"
      ? getSequenceGenbank
      : type === "fasta"
        ? getSequenceFasta
        : type === "json"
          ? options.keepJsonObject
            ? sequenceToVeInput
            : getSequenceJsonString
          : null;
  if (fn) return fn(sequence, options);
};
