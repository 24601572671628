/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { compose } from "recompose";
import Helmet from "react-helmet";
import { withTableParams, DataTable } from "@teselagen/ui";
import withQuery from "../../../src-shared/withQuery";

import { MenuItem, Button, Intent, Classes } from "@blueprintjs/core";
import { pick } from "lodash";
import AbstractLibrary from "../../../src-shared/AbstractLibrary";
import { deleteRecordsHelper } from "../../utils";
import { dateModifiedColumn } from "../../../src-shared/utils/libraryColumns";
import { showDialog } from "../../../src-shared/GlobalDialog";
import gql from "graphql-tag";
import UploadGenusAndSpeciesDialog from "./UploadGenusAndSpeciesDialog";

const schema = {
  model: "genus",
  fields: [
    { path: "name", type: "string", displayName: "Name" },
    { path: "description", type: "string", displayName: "Description" },
    { path: "abbreviation", type: "string", displayName: "Abbreviation" },
    dateModifiedColumn
  ]
};

class GenusPanel extends Component {
  openEditWindow = record => {
    showDialog({
      modalType: "CREATE_NEW_GENUS",
      modalProps: {
        refetch: this.refetch,
        initialValues: record
      }
    });
  };

  refetch = async () => {
    const {
      selectedEntities = [],
      refetchGenera,
      selectTableRecords
    } = this.props;
    await refetchGenera();
    if (selectedEntities.length) {
      selectTableRecords([]);
      selectTableRecords([selectedEntities[0].id]);
    }
  };

  createOrEditSpecies = record => {
    const { selectedEntities = [], readOnly } = this.props;
    if (readOnly) return;
    const selectedGenus = selectedEntities[0];
    showDialog({
      modalType: "CREATE_NEW_SPECIES",
      modalProps: {
        refetch: this.refetch,
        selectedGenus,
        ...(record && {
          initialValues: {
            ...pick(record, ["id", "name", "description", "abbreviatedName"])
          }
        })
      }
    });
  };

  contextMenu = ({ selectedRecords }) => {
    if (this.props.readOnly) return;
    const menuItems = [];
    const deleteRecords = () =>
      deleteRecordsHelper({
        records: selectedRecords,
        refetch: this.refetch
      });

    const deleteMenuItem = (
      <MenuItem
        key="delete"
        icon="trash"
        onClick={deleteRecords}
        text="Delete"
      />
    );
    if (selectedRecords.length === 1) {
      const record = selectedRecords[0];
      const recordType = selectedRecords[0].__typename;
      menuItems.push(
        <MenuItem
          key="edit"
          icon="edit"
          onClick={() =>
            recordType === "specie"
              ? this.createOrEditSpecies(record)
              : this.openEditWindow(record)
          }
          text="Edit"
        />
      );
    }
    menuItems.push(deleteMenuItem);
    return menuItems;
  };

  onUpload = () => {
    const {
      selectedEntities = [],
      refetchGenera,
      selectTableRecords
    } = this.props;

    showDialog({
      ModalComponent: UploadGenusAndSpeciesDialog,
      modalProps: {
        refetch: async () => {
          await refetchGenera();
          if (selectedEntities.length) {
            selectTableRecords([]);
            selectTableRecords([selectedEntities[0].id]);
          }
        }
      }
    });
  };

  render() {
    const { selectedEntities = [], readOnly } = this.props;
    const selectedGenus = selectedEntities[0];
    const selectedGenusSpecies = selectedGenus && selectedEntities[0].species;
    return (
      <div className="tg-card">
        <Helmet title="Genera" />
        <AbstractLibrary
          {...this.props}
          readOnly={readOnly}
          contextMenu={this.contextMenu}
          onDoubleClick={this.openEditWindow}
          libraryName="genus"
          onNewItemClick={this.openEditWindow}
          showUploadFunction={this.onUpload}
        />
        <i
          className="tg-flex justify-space-around"
          style={{ marginBottom: 20 }}
        >
          Select a genus to display its associated species.
        </i>
        {selectedGenus && <hr className="tg-section-break" />}
        {selectedGenus && (
          <DataTable
            tableName="Species"
            formName="selectedGenusSpeciesTable"
            entities={selectedGenusSpecies}
            contextMenu={this.contextMenu}
            onDoubleClick={this.createOrEditSpecies}
            schema={["abbreviatedName", "name", "description"]}
          >
            {!readOnly && (
              <Button
                intent={Intent.SUCCESS}
                className={Classes.MINIMAL}
                text="Create Species"
                icon="add"
                onClick={() => this.createOrEditSpecies()}
              />
            )}
          </DataTable>
        )}
      </div>
    );
  }
}

const fragment = gql`
  fragment genusFragment on genus {
    id
    name
    description
    abbreviation
    species {
      id
      name
      description
      abbreviatedName
    }
    createdAt
    updatedAt
    user {
      id
      username
    }
  }
`;

export default compose(
  withTableParams({
    urlConnected: false,
    schema,
    withSelectedEntities: true,
    formName: "genusLibrary",
    withDisplayOptions: true,
    defaults: {
      pageSize: 10
    }
  }),
  withQuery(fragment, {
    isPlural: true
  })
)(GenusPanel);
