/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
// import isURL from "validator/lib/isURL";
export const validateUrlAllowEmpty = val => {
  return validateUrl(val, { allowEmpty: true });
};

export const validateUrl = (val, { allowEmpty } = {}) => {
  if (!val && allowEmpty) return;
  if (!val) return "This Field is Required";
  if (val.startsWith("node-red://")) return;
  if (
    !isURL(val || "", { host_whitelist: ["localhost"] /* allow localhost */ })
  ) {
    return "This must be a valid URL";
  }
};

function isURL(str) {
  const urlRegex =
    "^(?!mailto:)(?:(?:http|node-red|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$";
  const url = new RegExp(urlRegex, "i");
  return str.length < 2083 && url.test(str);
}
