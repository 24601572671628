/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useContext } from "react";
import Helmet from "react-helmet";
import ChangePasswordForm from "./ChangePasswordForm";
import ChangeEmailForm from "./ChangeEmailForm";
import CurrentUserContext from "../context/CurrentUserContext";

const AccountPanel = () => {
  const { currentUser } = useContext(CurrentUserContext);
  return (
    <div>
      <Helmet title="Account Settings" />
      <div className="tg-card xtra-pad">
        <h3>Change Password</h3>
        <ChangePasswordForm />
        <h3>Change Email</h3>
        <ChangeEmailForm currentUser={currentUser!} />
      </div>
    </div>
  );
};

export default AccountPanel;
