/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useCallback, useMemo, useState } from "react";
import { compose } from "redux";
import { get, some } from "lodash";
import { parseCsvFile } from "../../../../../tg-iso-shared/src/utils/fileUtils";
import {
  DataTable,
  FileUploadField,
  Loading,
  BlueprintError,
  RadioGroupField
} from "@teselagen/ui";
import {
  testAssayFragment,
  designTags // This is more efficient and can be used when no tag options are used. But we can't assure that a-priori.
  // designTagOptions
} from "../selectDataFragments";
import { Button, Icon, Intent } from "@blueprintjs/core";
import HeaderWithHelper from "../../../components/HeaderWithHelper";
import { EvolveConfig } from "../../../configs/config";
import TestConfig from "../../../../src-test/configs/config.json";
import cypressTags from "../../../configs/cypressTags.json";
import createModelStepFormStyles from "../createModelStepFormStyles.module.css";
import GenericSelect from "../../../../src-shared/GenericSelect";
import { tagColumnWithRender } from "../../../../src-shared/utils/tagColumn";
import { getActiveLabId } from "@teselagen/auth-utils";
import client from "../../../../src-shared/apolloClient";
import Tag from "../../../../src-shared/Tag";
import stepFormValues from "../../../../src-shared/stepFormValues";

import {
  collectAllExtendedValues,
  displayPropertyValue
} from "../../../../src-shared/utils/extendedPropertyUtils";
import queryBuilder from "tg-client-query-builder";
import { getDownloadTemplateFileHelpers } from "../../../../src-shared/components/DownloadTemplateFileButton";

// import withQuery from "../../../../../src-shared/withQuery";
// import queryBuilder from "tg-client-query-builder";

const SELECT_DATA_HELPER_TITLE_GENERATIONS = "Select Amino Acid Sequence Data";
const SELECT_DATA_HELPER_SUBTITLE_GENERATIONS =
  "Select your data either from a Tag (retrieves all sequences linked to a tag) or from an experiment Assay.";

const SELECT_DATA_HELPER_TITLE = "Select Data";
const SELECT_DATA_HELPER_SUBTITLE = "Select your data from an Assay.";

const {
  EVOLUTIVE_MODEL,
  GENERATIVE_MODEL,
  PREDICTIVE_MODEL,
  UNASSIGNED_COLUMN_TYPE
} = EvolveConfig.constants;

// const SELECT_DATA_HELPER_TITLE_GENERATIONS = "Select Amino Acid Sequence Data";
// const SELECT_DATA_HELPER_SUBTITLE_GENERATIONS =
//   "Select your amino acid sequences either from the DESIGN Module or from a TEST Module assay.";

const tableHeaders = [
  "ID",
  "Descriptor 1",
  "Descriptor 2",
  "Descriptor 3",
  "Target 1",
  "Target 2"
];
const requiredHeaders = ["ID", "Descriptor 1", "Target 1"];

const generationsTableHeaders = [
  "Amino Acid Sequence",
  "Descriptor 1",
  "Descriptor 2"
];

const generationsRequiredHeaders = ["Amino Acid Sequence"];

const SELECT_DATA_TITLE = "Select Data";
const TEMPLATE_FILE_NAME = "evolve_upload_template";
const DESIGN_MODULE = "DESIGN_MODULE";
const TEST_MODULE = "TEST_MODULE";

const renderDataValidationComponent = validationObject => {
  return (
    <div>
      <p />
      <br />
      <span
        id={cypressTags.STEP_1_DATA_VALIDATION_WARNING_ID}
        style={{ fontSize: "16px", color: "red" }}
      >
        {" "}
        <Icon iconSize={20} icon="warning-sign" /> {validationObject.errorMsg}{" "}
      </span>
    </div>
  );
};

const taggedAminoAcidSequences = (props, qb) => {
  // Hard to support both alternatives (querying for tagged items tagged with tagOptions and/or tags) using a GenericSelect Component.
  // return { "tag.taggedItems.aminoAcidSequenceId": qb.notNull() };
  return {
    "taggedItems.aminoAcidSequenceId": qb.notNull(),
    // Sometimes 'Tags' are created in common lab and used to tag non-common lab items. So we need to make a lab filter explicit.
    labId: getActiveLabId()
  }; // This is the proper filter to be used if the 'designTags' fragment is used instead of the 'designTagOptions'.
};

const renderAATags = {
  displayName: "Tags",
  render: (val, record) => {
    return <Tag {...record} />;
  }
};

const SelectSamplesComponent = ({
  stepFormProps: { change },
  modelDataColumnSchema = [],
  modelType,
  dataModule,
  dataFromFileUpload,
  Footer,
  footerProps,
  modelData = [],
  validationObject = { validationPass: true }
}) => {
  const [loading, setLoading] = useState(false);

  const isDesignDataModule = useMemo(() => {
    return (
      modelType !== EVOLUTIVE_MODEL &&
      dataModule === DESIGN_MODULE &&
      !dataFromFileUpload
    );
  }, [dataFromFileUpload, dataModule, modelType]);

  const dataValidation = useCallback(
    data => {
      let validationObject = { validationPass: true, errorMsg: "" };
      if (data && data.length) {
        const columnNames = Object.keys(data[0]);
        const missingValues = [];
        columnNames.forEach(column => {
          data.forEach(row => {
            if (row[column] === "") missingValues.push({ cell: [column, row] });
          });
        });

        if (missingValues.length > 0)
          validationObject = {
            validationPass: false,
            errorMsg:
              "There are missing values in your data! Please fix this before continuing."
          };
      }
      change("validationObject", validationObject);
    },
    [change]
  );

  // This functions parses the file that a user can upload. This file may be a single csv file or a zip file containing a csv and genbank files.
  const readTrainModelUpload = useCallback(
    async files => {
      const csvFile = files[0];
      if (!csvFile) return false;
      try {
        const csv = await parseCsvFile(csvFile);
        const columns = Object.keys(csv.data[0]).map((column, idx) => ({
          id: idx,
          name: column,
          value_type: "",
          type: UNASSIGNED_COLUMN_TYPE
        }));
        dataValidation(csv.data);
        change("isStandAlone", true);
        change("modelData", csv.data);
        change("modelDataColumnSchema", columns);
        change("dataFromFileUpload", true);
        return true;
      } catch (error) {
        console.error("error:", error);
        return window.toastr.error("Error parsing CSV file.");
      }
    },
    [change, dataValidation]
  );

  const onSelectAssay = useCallback(
    result => {
      const {
        id: assayId,
        importFileSets: [importFileSet]
      } = result;
      change("isStandAlone", true);
      setLoading(true);
      const queryParams = new URLSearchParams({
        importFileSetId: importFileSet.id,
        pageNumber: 1,
        pageSize: 400,
        tabular: true
      }).toString();
      return window.serverApi
        .get(
          TestConfig.endpoints["getAssayResults"].replace(":assayId", assayId) +
            "?" +
            queryParams
        )
        .then(output => {
          const rows = output.data.results;
          dataValidation(rows);
          const columns = Object.keys(rows[0]).map((column, idx) => ({
            id: idx,
            name: column,
            value_type: "",
            type: UNASSIGNED_COLUMN_TYPE
          }));
          change("modelDataColumnSchema", columns);
          change("modelData", rows);
          setLoading(false);
        })
        .catch(error => {
          console.error("error", error);
          setLoading(false);
        });
    },
    [change, dataValidation]
  );

  const onSelectAminoAcids = useCallback(
    tagRecords => {
      change("isStandAlone", true);
      setLoading(true);
      // Sometimes 'Tags' are created in common lab and used to tag non-common lab items.
      // Here's a double check to filter amino acid sequences by the currently active lab id.
      const labId = getActiveLabId();

      const column = [
        {
          id: 0,
          name: "Name",
          value_type: "",
          type: UNASSIGNED_COLUMN_TYPE
        },
        {
          id: 1,
          name: "Sequence",
          value_type: "",
          type: UNASSIGNED_COLUMN_TYPE
        },
        {
          id: 2,
          name: "Size",
          value_type: "",
          type: UNASSIGNED_COLUMN_TYPE
        }
      ];

      const aminoAcidRecords = tagRecords.reduce((aminoAcids, tagRecord) => {
        tagRecord.taggedItems
          .filter(taggedItem => taggedItem.aminoAcidSequence)
          .forEach(taggedItem => {
            const _currentAmino = taggedItem.aminoAcidSequence;
            if (
              !some(
                aminoAcids,
                aminoAcid => aminoAcid.id === _currentAmino.id
              ) &&
              _currentAmino.labId === labId
            ) {
              aminoAcids.push(_currentAmino);
            }
          });
        return aminoAcids;
      }, []);

      const addedPropIds = [];
      aminoAcidRecords.forEach(amino => {
        collectAllExtendedValues(amino).forEach(v => {
          const prop = v.extendedProperty;
          if (!addedPropIds.includes(prop.id)) {
            column.push({
              id: addedPropIds.length + 3,
              name: prop.name,
              // Auto-value_type selection not yet supported.
              value_type: "",
              type: UNASSIGNED_COLUMN_TYPE
            });
            addedPropIds.push(prop.id);
          }
        });
      });

      const rows = aminoAcidRecords.map(result => ({
        Name: result.name,
        Sequence: result.proteinSequence,
        Size: result.size.toString(),
        taggedItems: result.taggedItems,
        ...Object.assign(
          {},
          ...collectAllExtendedValues(result).map(val => ({
            [val.extendedProperty.name]: displayPropertyValue(val)
          }))
        )
      }));

      dataValidation(rows);

      change("modelDataColumnSchema", column);
      change("modelData", rows);
      setLoading(false);
    },
    [change, dataValidation]
  );

  // Function that clears the current object "tuplingData" stepform value.
  const clearModelData = useCallback(() => {
    change("modelData", []);
    change("trainModelUploadData", null);
    change("isStandAlone", false);
    change("createModelSchemaTable", null);
    change("modelDataColumnSchema", []);
  }, [change]);

  // This corresponds to the schema of the data table used to show the samples to be tupled using the upload zip file for the "stand alone mode" of the Tupling Tool.
  const uploaded_samples_schema = useMemo(() => {
    const schema = {
      fields: modelDataColumnSchema.map(column => ({
        path: column.name,
        displayName: column.name,
        render: (_, record) => get(record, column.name)
      }))
    };
    if (isDesignDataModule) {
      schema.fields.push(tagColumnWithRender);
    }
    return schema;
  }, [isDesignDataModule, modelDataColumnSchema]);

  const cellRenderer = useMemo(() => {
    const cellRendererObject = {};
    modelDataColumnSchema.forEach(column => {
      cellRendererObject[column.name] = (_, record) => {
        return record[column.name] !== "" ? (
          <span>{record[column.name]}</span>
        ) : (
          <Icon icon="warning-sign" />
        );
      };
    });
    return cellRendererObject;
  }, [modelDataColumnSchema]);

  const renderClearButton = useCallback(
    () => (
      <div className={createModelStepFormStyles.clearTableButton}>
        <Button
          onClick={clearModelData}
          id={cypressTags.CLEAR_DATA_BUTTON_ID}
          intent="danger"
          minimal
          text="Select other data"
        />
      </div>
    ),
    [clearModelData]
  );

  const renderTable = useCallback(
    entities => (
      <>
        <div id={cypressTags.MODEL_DATA_TABLE_ID}>
          <DataTable
            isSimple
            formName="selectedModelData"
            noSelect
            schema={uploaded_samples_schema}
            cellRenderer={cellRenderer}
            entities={entities}
          />
        </div>
        {entities.error && (
          <BlueprintError error="Please fix errors in Tupling Tool input data." />
        )}
      </>
    ),
    [cellRenderer, uploaded_samples_schema]
  );

  const renderUploadSection = useCallback(
    () => (
      <div>
        <div className="tg-flex align-center">
          {/* This div section contains the UPLOAD CSV option */}
          <div>
            <div id={cypressTags.UPLOAD_CSV_BUTTON_ID}>
              <FileUploadField
                fileLimit={1}
                accept={getDownloadTemplateFileHelpers({
                  type: ".csv",
                  fileName: TEMPLATE_FILE_NAME,
                  headers:
                    modelType === GENERATIVE_MODEL
                      ? generationsTableHeaders
                      : tableHeaders,
                  requiredHeaders:
                    modelType === GENERATIVE_MODEL
                      ? generationsRequiredHeaders
                      : requiredHeaders
                })}
                name="trainModelUploadData"
                innerText="Click or drag to upload CSV"
                readBeforeUpload
                beforeUpload={readTrainModelUpload}
              />
            </div>
          </div>
        </div>
      </div>
    ),
    [modelType, readTrainModelUpload]
  );

  const renderTestDataSection = useCallback(() => {
    const qb = new queryBuilder("assay");
    const filter = qb.whereAll({
      "importFileSets.importFileSetStepCode": qb.inList([
        "FINISHED",
        "FINISHED-DISCARDED"
      ])
    });
    return (
      <div className="tg-flex align-center">
        <div
          id={cypressTags.SELECT_TEST_DATA_BUTTON_ID}
          className={createModelStepFormStyles.select_data_button}
        >
          <GenericSelect
            name="createModelSchemaTable" //the field name within the redux form Field
            buttonProps={{
              text: SELECT_DATA_TITLE,
              loading: undefined //loadingDataTable
            }}
            dialogProps={{
              title: isDesignDataModule
                ? "Select Tags for amino acid sequences"
                : "Select Assays"
            }}
            dialogFooterProps={{ text: SELECT_DATA_TITLE }}
            isMultiSelect={isDesignDataModule}
            onSelect={isDesignDataModule ? onSelectAminoAcids : onSelectAssay}
            schema={isDesignDataModule ? ["id", renderAATags] : ["id", "name"]}
            queryOptions={{
              client,
              variables: {
                filter: isDesignDataModule ? undefined : filter
              }
            }}
            fragment={
              isDesignDataModule
                ? designTags // designTagOptions --> Hard to support tag options with a GenericSelect.
                : testAssayFragment
            }
            tableParamOptions={{
              additionalFilter: isDesignDataModule && taggedAminoAcidSequences
            }}
          />
        </div>
      </div>
    );
  }, [isDesignDataModule, onSelectAminoAcids, onSelectAssay]);

  const shouldRender = !modelData.length && !loading;
  const shouldRenderTable =
    !!modelData.length && !loading && modelDataColumnSchema;

  return (
    <>
      {!shouldRenderTable && (
        <div className="tg-step-form-section column">
          <div className="tg-flex justify-space-between">
            <div
              className={createModelStepFormStyles.select_data_left_container}
            >
              <HeaderWithHelper
                header={
                  modelType === GENERATIVE_MODEL
                    ? SELECT_DATA_HELPER_TITLE_GENERATIONS
                    : SELECT_DATA_HELPER_TITLE
                }
                helper={
                  modelType !== EVOLUTIVE_MODEL
                    ? SELECT_DATA_HELPER_SUBTITLE_GENERATIONS
                    : SELECT_DATA_HELPER_SUBTITLE
                }
              />
              {modelType === PREDICTIVE_MODEL && (
                <RadioGroupField
                  name="dataModule"
                  label="Select from"
                  defaultValue={DESIGN_MODULE}
                  options={[
                    { label: "Sequence Tag", value: DESIGN_MODULE },
                    { label: "Assay", value: TEST_MODULE }
                  ]}
                  inline
                />
              )}
            </div>
            {shouldRender && renderTestDataSection()}
          </div>
          {loading && <Loading bounce />}
        </div>
      )}
      {!shouldRenderTable && (
        <div className="tg-step-form-section column">
          <div className="tg-flex justify-space-between">
            <HeaderWithHelper
              header="Select Data"
              helper="Upload your own CSV file."
            />
            {shouldRender && renderUploadSection()}
          </div>
        </div>
      )}
      {shouldRenderTable && (
        <div className="tg-step-form-section column">
          <div className="tg-flex justify-space-between column">
            <HeaderWithHelper
              header="Selected data"
              helper="If this looks ok, please click 'Next', otherwise click 'Select other data'."
            />
            {renderClearButton()}
            {renderTable(modelData)}{" "}
            {!validationObject.validationPass &&
              renderDataValidationComponent(validationObject)}
          </div>
        </div>
      )}

      <Footer
        {...footerProps}
        nextButton={
          <Button
            id={cypressTags.STEP_1_NEXT_BUTTON_ID}
            type="next"
            intent={Intent.PRIMARY}
            text="Next"
            disabled={!modelData.length || !validationObject.validationPass}
          />
        }
      />
    </>
  );
};

export default compose(
  // Currently, Importing AA sequences from DESIGN tagged with both option and non-option tags is not supported.
  // AA sequences tagged by option tags will only consider the TAG name and not filter by its tag options.

  // // Needed to see if there are any AA seqs tagged with option tags.
  // withQuery(designTagOptions, {
  //   isPlural: true,
  //   options: () => {
  //     return {
  //       client: clients["design"]
  //     };
  //   }
  // }),
  // // Needed to see if there are any AA seqs tagged with non-option tags.
  // withQuery(designTags, {
  //   isPlural: true,
  //   options: () => {
  //     return {
  //       client: clients["design"]
  //     };
  //   }
  // }),
  stepFormValues(
    "modelData",
    "isStandAlone",
    "modelDataColumnSchema",
    "modelType",
    "dataModule",
    "dataFromFileUpload",
    "validationObject",
    "loadingAssayResults"
  )
)(SelectSamplesComponent);
