/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { compose } from "redux";
import { times, get } from "lodash";
import {
  RadioGroupField,
  NumericInputField,
  ReactSelectField,
  SelectField,
  InputField,
  CheckboxField
} from "@teselagen/ui";

import mustache from "mustache";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import HeaderWithHelper from "../../../../src-shared/HeaderWithHelper";
import stepFormValues from "../../../../src-shared/stepFormValues";
import GenericSelect from "../../../../src-shared/GenericSelect";
import { is2x2Mapping, canPlateFormatTile } from "../utils";

import UniversalTransfer from "./SharedFieldComponents/UniversalTransfer";
import UniversalPlateNameTemplateField from "./UniversalPlateNameTemplateField";
import { dateModifiedColumn } from "../../../../src-shared/utils/libraryColumns";
import { Tooltip } from "@blueprintjs/core";
import platePreviewColumn from "../../../utils/platePreviewColumn";
import {
  arrayToItemValuedOptions,
  getContainerFormatOptions
} from "../../../../src-shared/utils/formUtils";
import { everyOtherTooltip, multiChannelTooltip } from "./constants";
import unitGlobals from "../../../../../tg-iso-lims/src/unitGlobals";
import { breakdownPatterns } from "./utils";

dayjs.extend(localizedFormat);

class Breakdown extends Component {
  is2x2 = (sourcePlate, universalDestinationFormat) => {
    const { replicateFormats = {} } = this.props;
    const sourcePlateFormat = sourcePlate.containerArrayType.containerFormat;
    return is2x2Mapping(
      universalDestinationFormat || replicateFormats["id" + sourcePlate.id],
      sourcePlateFormat
    );
  };

  getContainerFormatOptions = sourcePlate => {
    const { containerFormats = [] } = this.props;
    const sourcePlateFormat = sourcePlate.containerArrayType.containerFormat;
    return getContainerFormatOptions(
      containerFormats.filter(containerFormat => {
        return canPlateFormatTile(containerFormat, sourcePlateFormat);
      })
    );
  };

  getBreakdownPatternOptions = (sourcePlate, all2x2) => {
    return breakdownPatterns(all2x2, sourcePlate && this.is2x2(sourcePlate));
  };

  getPlateTypeOptions = sourcePlateId => {
    const {
      containerArrayTypes = [],
      replicateFormats,
      applyUniversalBreakdownPattern,
      universalDestinationFormat
    } = this.props;
    let replicateContainerFormat;
    if (applyUniversalBreakdownPattern) {
      replicateContainerFormat = universalDestinationFormat;
    } else {
      replicateContainerFormat = replicateFormats["id" + sourcePlateId];
    }
    if (!replicateContainerFormat) {
      return [];
    }
    const filteredContainerArrayTypes = containerArrayTypes.filter(
      containerArrayType =>
        containerArrayType.containerFormatCode === replicateContainerFormat.code
    );
    return arrayToItemValuedOptions(filteredContainerArrayTypes);
  };

  allSourcePlatesAreSameFormat = () => {
    const { sourcePlates = [] } = this.props;
    const formatCode = get(
      sourcePlates,
      "[0].containerArrayType.containerFormat.code"
    );
    return sourcePlates.every(
      sourcePlate =>
        get(sourcePlate, "containerArrayType.containerFormat.code") ===
        formatCode
    );
  };

  updateReplicatePlateNames = ({ template, replicateFormat }) => {
    const {
      sourcePlates = [],
      universalDestinationFormat,
      stepFormProps: { change }
    } = this.props;

    const format = replicateFormat || universalDestinationFormat;

    if (template && format) {
      const current_date = dayjs().format("l");
      sourcePlates.forEach(plate => {
        times(
          get(plate, "containerArrayType.containerFormat.quadrantSize") /
            format.quadrantSize,
          index => {
            const val = mustache.render(template, {
              source_plate_name: plate.name,
              incrementing_number: index + 1,
              incrementing_letter: String.fromCharCode(
                97 + (index % 26)
              ).toUpperCase(),
              current_date
            });
            change(`replicatePlateInfo.id${plate.id}.${index}.name`, val);
          }
        );
      });
    }
  };

  onUniversalPlateNameTemplateSubmit = template => {
    this.updateReplicatePlateNames({
      template
    });
  };

  onUniversalReplicateFormatSubmit = replicateFormat => {
    const { universalPlateNameTemplate } = this.props;
    if (universalPlateNameTemplate) {
      this.updateReplicatePlateNames({
        template: universalPlateNameTemplate,
        replicateFormat
      });
    }
  };

  renderUniversalBreakdownSettings = () => {
    const {
      applyUniversalBreakdownPattern,
      universalMultichannel,
      sourcePlates = [],
      universalDestinationFormat,
      universalNewOrExistingDestinationPlates
    } = this.props;
    if (!applyUniversalBreakdownPattern) return null;

    const all2x2 = sourcePlates.every(sourcePlate =>
      this.is2x2(sourcePlate, universalDestinationFormat)
    );

    return (
      <div className="tg-flex justify-space-between">
        <div>
          <RadioGroupField
            name="universalNewOrExistingDestinationPlates"
            disabled={!universalDestinationFormat}
            options={[
              { label: "Create new Plates", value: "NEW" },
              {
                label: "Breakdown onto Existing Plates",
                value: "EXISTING"
              }
            ]}
            defaultValue="EXISTING"
          />

          {universalNewOrExistingDestinationPlates === "NEW" && (
            <React.Fragment>
              <UniversalPlateNameTemplateField
                name="universalPlateNameTemplate"
                tooltipInfo="This can be used to pre-populate the replicate plate names."
                onFieldSubmit={this.onUniversalPlateNameTemplateSubmit}
                defaultValue="{{{source_plate_name}}} Breakdown {{{incrementing_number}}}"
                templateVariables={[
                  "source_plate_name",
                  "incrementing_number",
                  "incrementing_letter",
                  "current_date"
                ]}
              />
              <ReactSelectField
                name="universalDestinationPlateType"
                isRequired
                label="Destination Plate Type"
                options={this.getPlateTypeOptions()}
                enableReinitialize
                defaultValue={
                  this.getPlateTypeOptions()[0] &&
                  this.getPlateTypeOptions()[0].value
                }
              />
            </React.Fragment>
          )}
        </div>

        <div>
          <ReactSelectField
            name="universalDestinationFormat"
            isRequired
            label="Destination Plate Format"
            options={this.getContainerFormatOptions(sourcePlates[0])}
            onFieldSubmit={this.onUniversalReplicateFormatSubmit}
            style={{ minWidth: 250 }}
          />
          <ReactSelectField
            name="universalBreakdownPattern"
            isRequired
            label="Breakdown Pattern"
            disabled={!universalDestinationFormat}
            options={this.getBreakdownPatternOptions(null, all2x2)}
            style={{ minWidth: 250 }}
          />
          <CheckboxField
            name="universalEveryOtherRow"
            label="Every other row"
            tooltipInfo={everyOtherTooltip("breakdown", "row")}
            defaultValue
          />
          <CheckboxField
            name="universalEveryOtherColumn"
            label="Every other column"
            tooltipInfo={everyOtherTooltip("breakdown", "column")}
            defaultValue
          />
          <CheckboxField
            name="universalMultichannel"
            label="Multi-Channel"
            tooltipInfo={multiChannelTooltip}
          />
          {universalMultichannel && (
            <RadioGroupField
              name="universalChannelOrientation"
              inline
              label="Channel Orientation"
              defaultValue="Vertical"
              options={["Vertical", "Horizontal"]}
            />
          )}
        </div>
      </div>
    );
  };

  render() {
    const {
      toolSchema,
      sourcePlates = [],
      replicateFormats = {},
      universalTransfers,
      newOrExistingReplicates,
      generateBarcodes = {},
      multiChannel = {},
      applyUniversalBreakdownPattern,
      universalNewOrExistingDestinationPlates,
      universalDestinationFormat
    } = this.props;

    return (
      <React.Fragment>
        <UniversalTransfer toolSchema={toolSchema} />
        <div className="tg-step-form-section column">
          <div className="tg-flex justify-space-between">
            <HeaderWithHelper
              header="Universal Breakdown Pattern"
              helper="Specify a universal breakdown pattern which will be used for all source plates. This option is only available when all source plates are the same format."
            />
            <div className="tg-flex column align-flex-end">
              <CheckboxField
                disabled={!this.allSourcePlatesAreSameFormat()}
                name="applyUniversalBreakdownPattern"
                label="Apply Universal Breakdown Pattern"
              />
            </div>
          </div>
          {this.renderUniversalBreakdownSettings()}
        </div>
        <div className="tg-step-form-section column">
          <HeaderWithHelper
            header="Breakdown Plate Mapping"
            helper="For each input plate, select a destination plate
              format. Next, choose whether you would like to create new
              destination plates or breakdown onto existing plates. If you have
              not entered a universal transfer volume, specify a transfer
              volume for each input plate. Finally, specify a Breakdown
              Pattern, this will determine the order in which materials
              are transferred to destination plates."
            width="100%"
          />
          {sourcePlates.map(plate => {
            let breakdownOptions;
            if (!applyUniversalBreakdownPattern) {
              breakdownOptions = (
                <React.Fragment>
                  <ReactSelectField
                    name={`replicateFormats.id${plate.id}`}
                    label="Destination Format"
                    options={this.getContainerFormatOptions(plate)}
                    style={{ minWidth: 250 }}
                  />
                  <ReactSelectField
                    name={`breakdownPatterns.id${plate.id}`}
                    label="Breakdown Pattern"
                    options={this.getBreakdownPatternOptions(plate)}
                    disabled={!replicateFormats["id" + plate.id]}
                    style={{ minWidth: 250 }}
                  />
                  <CheckboxField
                    name={`everyOtherRow.id${plate.id}`}
                    label="Every other row"
                    tooltipInfo={everyOtherTooltip("breakdown", "row")}
                    defaultValue
                  />
                  <CheckboxField
                    name={`everyOtherColumn.id${plate.id}`}
                    label="Every other column"
                    tooltipInfo={everyOtherTooltip("breakdown", "column")}
                    defaultValue
                  />
                  <CheckboxField
                    name={`multiChannel.id${plate.id}`}
                    label="Multi-Channel"
                    tooltipInfo={multiChannelTooltip}
                  />
                  {multiChannel["id" + plate.id] && (
                    <RadioGroupField
                      name={`channelOrientation.id${plate.id}`}
                      inline
                      label="Channel Orientation"
                      defaultValue="Vertical"
                      options={["Vertical", "Horizontal"]}
                    />
                  )}
                </React.Fragment>
              );
            }
            let newOrExistingValue;
            if (applyUniversalBreakdownPattern) {
              newOrExistingValue = universalNewOrExistingDestinationPlates;
            } else {
              newOrExistingValue =
                newOrExistingReplicates &&
                newOrExistingReplicates["id" + plate.id];
            }
            let replicateFormat;
            if (applyUniversalBreakdownPattern) {
              replicateFormat = universalDestinationFormat;
            } else {
              replicateFormat = replicateFormats["id" + plate.id];
            }
            const numberOfDestPlates =
              replicateFormat &&
              get(plate, "containerArrayType.containerFormat.quadrantSize") /
                replicateFormat.quadrantSize;

            return (
              <div
                key={plate.id}
                className="tg-flex column"
                style={{ minHeight: 300 }}
              >
                <hr className="tg-section-break" />
                <div className="tg-flex column">
                  <h6>{plate.name}</h6>
                  <div className="tg-flex justify-space-between">
                    <div className="tg-flex column">
                      {!applyUniversalBreakdownPattern && (
                        <RadioGroupField
                          name={`newOrExistingReplicates.id${plate.id}`}
                          disabled={!replicateFormat}
                          options={[
                            { label: "Create new Plates", value: "NEW" },
                            {
                              label: "Replicate onto Existing Plates",
                              value: "EXISTING"
                            }
                          ]}
                          defaultValue="EXISTING"
                        />
                      )}
                      {newOrExistingValue === "NEW" && (
                        <React.Fragment>
                          <CheckboxField
                            name={`generateBarcodes.id${plate.id}`}
                            label="Generate Barcodes For Destination Plates"
                            defaultValue
                          />
                          <CheckboxField
                            name={`createEmptyPlates.id${plate.id}`}
                            label={
                              <Tooltip content="Will create plates that do not receive any transfers.">
                                Create Empty Plates
                              </Tooltip>
                            }
                          />
                        </React.Fragment>
                      )}
                    </div>
                    <div className="tg-flex column">
                      {breakdownOptions}
                      {!universalTransfers && (
                        <div className="input-with-unit-select">
                          <NumericInputField
                            label="Transfer Volume"
                            name={`transferVolumes.id${plate.id}`}
                            min={0}
                          />
                          <SelectField
                            name={`transferVolumeUnitCodes.id${plate.id}`}
                            defaultValue="uL"
                            label="none"
                            className="tg-unit-select"
                            options={unitGlobals.getOptionsForSelect(
                              "volumetricUnit"
                            )}
                            disabled={universalTransfers}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  {newOrExistingValue === "EXISTING" && (
                    <GenericSelect
                      {...{
                        name: `destinationPlates.id${plate.id}`,
                        schema: ["name", dateModifiedColumn],
                        isMultiSelect: true,
                        mustSelect: numberOfDestPlates,
                        isRequired: true,
                        getButtonText: v =>
                          v?.length
                            ? "Change Selected Plates"
                            : "Replicate onto Existing Plates",
                        buttonProps: {
                          disabled: !replicateFormat
                        },
                        fragment: ["containerArray", "id name updatedAt"],
                        additionalDataFragment: [
                          "containerArray",
                          "id name containerArrayType { containerFormat { code quadrantSize } id name } updatedAt"
                        ],
                        tableParamOptions: {
                          additionalFilter: {
                            "containerArrayType.containerFormatCode":
                              replicateFormat ? replicateFormat.code : null
                          }
                        },
                        postSelectDTProps: {
                          formName: `selectedDestinationPlates.${plate.id}`,
                          isSingleSelect: true,
                          schema: platePreviewSchema
                        }
                      }}
                    />
                  )}
                  {newOrExistingValue === "NEW" && replicateFormat && (
                    <React.Fragment>
                      <div className="tg-flex column">
                        {times(numberOfDestPlates, index => {
                          return (
                            <div
                              key={index}
                              className="tg-flex column"
                              style={{ width: "50%" }}
                            >
                              <div style={{ flex: 1 }}>
                                <InputField
                                  name={`replicatePlateInfo.id${plate.id}.${index}.name`}
                                  label="Replicate Plate Name"
                                  isRequired
                                  defaultValue={`${
                                    plate.name
                                  } Replicate ${index + 1}`}
                                />
                                {!generateBarcodes["id" + plate.id] && (
                                  <InputField
                                    name={`replicatePlateInfo.id${plate.id}.${index}.barcode`}
                                    label="Replicate Plate Barcode"
                                  />
                                )}
                                {!applyUniversalBreakdownPattern && (
                                  <ReactSelectField
                                    name={`replicatePlateInfo.id${plate.id}.${index}.plateType`}
                                    label="Replicate Plate Type"
                                    isRequired
                                    options={this.getPlateTypeOptions(plate.id)}
                                    enableReinitialize
                                    defaultValue={
                                      this.getPlateTypeOptions(plate.id)[0] &&
                                      this.getPlateTypeOptions(plate.id)[0]
                                        .value
                                    }
                                  />
                                )}
                              </div>
                              <div className="tg-flex-separator" />
                              <hr className="tg-section-break" />
                            </div>
                          );
                        })}
                      </div>
                    </React.Fragment>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </React.Fragment>
    );
  }
}

export default compose(
  stepFormValues(
    "sourcePlates",
    "replicateFormats",
    "universalTransfers",
    "applyUniversalBreakdownPattern",
    "universalMultichannel",
    "newOrExistingReplicates",
    "generateBarcodes",
    "multiChannel",
    "universalDestinationFormat",
    "universalNewOrExistingDestinationPlates",
    "universalPlateNameTemplate"
  )
)(Breakdown);

const platePreviewSchema = [
  platePreviewColumn(),
  "name",
  {
    path: "containerArrayType.name",
    displayName: "Plate Type",
    dateModifiedColumn
  }
];
