/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { isEmpty } from "lodash";
import { EvolveConfig } from "../../../configs/config";
const LOG_TAG = "[CsvGenerationInputValidation]";

//#region CONSTANTS
const {
  GENERATIVE_MODEL_MIN_ALLOWED_SEQUENCES,
  GENERATIVE_MODEL_MAX_ALLOWED_SEQUENCES,
  MIN_ALLOWED_AA_SEQ_LENGTH,
  MAX_ALLOWED_AA_SEQ_LENGTH,
  VALID_AMINOACIDS_TOKENS
} = EvolveConfig.constants;

const VALIDATION_ISSUES_TYPES = {
  minSeqNumRequirementNotMet: {
    type: "minSeqNumRequirementNotMet",
    message: "Minimum number of sequences not met."
  },
  maxSeqNumRequirementExceeded: {
    type: "maxSeqNumRequirementExceeded",
    message: "Maximum number of sequences exceeded."
  },
  minSeqLengthRequirementNotMet: {
    type: "minSeqLengthRequirementNotMet",
    message: "Minimum sequence length requirement not met."
  },
  maxSeqLengthRequirementExceeded: {
    type: "maxSeqLengthRequirementExceeded",
    message: "Maximum sequence length exceeded."
  },
  sequenceCharactersInvalid: {
    type: "sequenceCharactersInvalid",
    message: "Sequence provided has invalid characters."
  }
};
//#endregion

/**
 * Validates a string against an array or string representing a list of allowed characters.
 * It will return an object with the validation result.
 *
 * @param {String | String[]} validCharacters the valid characters that the given string will be validated against.
 * @param {String} stringToValidate the string to validate against the list of valid characters.
 *
 * @returns an object with the following structure {
 *      isValidSequence: Boolean,
 *      invalidCharacters: Array<{character: String, index: Integer}>
 *     }
 *
 * @throws an error if the validCharacters param is undefined or with a length === 0.
 *
 */
function isAValidSequence(validCharacters, stringToValidate) {
  if (validCharacters === undefined || validCharacters.length === 0) {
    throw new Error(
      `${LOG_TAG}: argument @validCharacters in isAValidSequence() can't be null.`
    );
  }

  let validCharactersUpperCased;

  // validCharacters supports both String and String[].
  if (Array.isArray(validCharacters)) {
    validCharactersUpperCased = validCharacters.map(character =>
      character.toUpperCase()
    );
  } else {
    validCharactersUpperCased = validCharacters.toUpperCase();
  }

  const stringToValidateUpperCased = stringToValidate.toUpperCase();

  const validationObject = {
    invalidCharacters: []
  };

  for (let i = 0; i < stringToValidateUpperCased.length; i++) {
    const character = stringToValidateUpperCased.charAt(i);
    if (!validCharactersUpperCased.includes(character)) {
      const invalidCharObject = {
        character: character,
        position: i
      };
      validationObject.invalidCharacters.push(invalidCharObject);
    }
  }

  validationObject.isValidSequence = isEmpty(
    validationObject.invalidCharacters
  );

  return validationObject;
}

/**
 * Check if the number of sequences is between a rage of the minimum number required and the
 * maximum number allowed.
 *
 * @param {String[]} sequencesToValidate an array with the sequences string that will be used to calculate
 *                                            the number of sequences to validate.
 * @param {Number} minNumOfSeqsRequired the minimum number of sequences required.
 * @param {Number} maxNumOfSeqsAllowed the max number of sequences allowed.
 *
 * @returns if everything is fine returns an empty object, if there's an issue it will return an object with the
 *          following structure {
 *              type: String,
 *              message: String,
 *              validationRule: String | Number
 *          }
 *
 */
function checkAllowedNumberOfSeq(
  sequencesToValidate,
  minNumOfSeqsRequired,
  maxNumOfSeqsAllowed
) {
  const numOfSequences = sequencesToValidate.length;

  if (!numOfSequences || numOfSequences < minNumOfSeqsRequired) {
    return {
      type: VALIDATION_ISSUES_TYPES.minSeqNumRequirementNotMet.type,
      message: VALIDATION_ISSUES_TYPES.minSeqNumRequirementNotMet.message,
      validationRule: minNumOfSeqsRequired,
      value: numOfSequences
    };
  } else if (numOfSequences > maxNumOfSeqsAllowed) {
    return {
      type: VALIDATION_ISSUES_TYPES.maxSeqNumRequirementExceeded.type,
      message: VALIDATION_ISSUES_TYPES.maxSeqNumRequirementExceeded.message,
      validationRule: maxNumOfSeqsAllowed,
      value: numOfSequences
    };
  }

  return {};
}

/**
 * Check the sequences lenght to be in the allowed range and that the characters are valid.
 *
 * @param {String[]} sequencesToValidate an array of sequences to be validated.
 * @param {Number} minSeqLength the minimum sequence length required.
 * @param {Number} maxSeqLength the maximum sequence length required.
 * @param {String | String[]} validCharacters the valid characters that the each sequences will be validated against.
 *
 * @returns an empty array if everything is ok, if there are any issues it will return an array of objects with the
 *          following structure {
 *            type: String,
 *            message: String,
 *            sequence: String,
 *            value: Any,
 *            position: Number,
 *            validationRule: String | Number
 *          }
 *
 *
 * @throw a "Sequences to validate lenght can't be < 0." error if the list of sequences length is < 0.
 */
function checkSequencesLenghtsAndValidity(
  sequencesToValidate,
  minSeqLength,
  maxSeqLength,
  validCharacters
) {
  const issues = [];

  if (sequencesToValidate.length < 0) {
    throw new Error("Sequences to validate lenght can't be < 0.");
  } else {
    sequencesToValidate.forEach((sequence, index) => {
      if (sequence.length < minSeqLength) {
        issues.push({
          type: VALIDATION_ISSUES_TYPES.minSeqLengthRequirementNotMet.type,
          message:
            VALIDATION_ISSUES_TYPES.minSeqLengthRequirementNotMet.message,
          sequence: sequence,
          value: sequence.length,
          position: index,
          validationRule: minSeqLength
        });
      } else if (sequence.length > maxSeqLength) {
        issues.push({
          type: VALIDATION_ISSUES_TYPES.maxSeqLengthRequirementExceeded.type,
          message:
            VALIDATION_ISSUES_TYPES.maxSeqLengthRequirementExceeded.message,
          sequence: sequence,
          value: sequence.length,
          position: index,
          validationRule: maxSeqLength
        });
      }

      const sequenceValidityResult = isAValidSequence(
        validCharacters,
        sequence
      );

      if (!sequenceValidityResult.isValidSequence) {
        issues.push({
          type: VALIDATION_ISSUES_TYPES.sequenceCharactersInvalid.type,
          message: VALIDATION_ISSUES_TYPES.sequenceCharactersInvalid.message,
          sequence: sequence,
          value: sequenceValidityResult,
          position: index,
          validationRule: validCharacters
        });
      }
    });

    return issues;
  }
}

function validateAndCapitalizeSequences(
  sequencesToValidate,
  validationParameters = {}
) {
  const {
    validCharacters = VALID_AMINOACIDS_TOKENS,
    minSeqLength = MIN_ALLOWED_AA_SEQ_LENGTH,
    maxSeqLength = MAX_ALLOWED_AA_SEQ_LENGTH,

    minNumOfSeqsRequired = GENERATIVE_MODEL_MIN_ALLOWED_SEQUENCES,
    maxNumOfSeqsAllowed = GENERATIVE_MODEL_MAX_ALLOWED_SEQUENCES
  } = validationParameters;

  // This validationObject gets updated along the validation process.
  const validationObject = { isValid: true, issues: [] };

  let allowedNumOfSeqsIssue = {};
  let allowedSeqLengthAndValidityIssues = [];

  allowedNumOfSeqsIssue = checkAllowedNumberOfSeq(
    sequencesToValidate,
    minNumOfSeqsRequired,
    maxNumOfSeqsAllowed
  );

  if (!isEmpty(allowedNumOfSeqsIssue)) {
    validationObject.issues.push(allowedNumOfSeqsIssue);
  }

  allowedSeqLengthAndValidityIssues = checkSequencesLenghtsAndValidity(
    sequencesToValidate,
    minSeqLength,
    maxSeqLength,
    validCharacters
  );

  validationObject.issues = [
    ...validationObject.issues,
    ...allowedSeqLengthAndValidityIssues
  ];

  validationObject.isValid = isEmpty(validationObject.issues);

  return validationObject;
}

export {
  isAValidSequence,
  checkAllowedNumberOfSeq,
  checkSequencesLenghtsAndValidity,
  validateAndCapitalizeSequences,
  VALIDATION_ISSUES_TYPES
};
