/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import {
  Button,
  Collapse,
  Divider,
  Intent,
  Menu,
  MenuDivider,
  MenuItem
} from "@blueprintjs/core";
import { size } from "lodash";
import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import { DataTable } from "@teselagen/ui";
import { metaDataStore } from "./store";
import "./style.css";
import config from "../../../src-test/configs/config.json";
import { withRouter } from "react-router-dom";

class MetaData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      advancedMetadata: window.localStorage.getItem("advancedMetadata"),
      selectedTab: "mappingPreset",
      store: metaDataStore.create({}),
      metadataWithView: ["mappingPreset"],
      metadataWithAddButton: [
        "assaySubjectClass",
        "computationalMethod",
        "computedValueType",
        "descriptorType",
        "externalSourceSystem",
        "measurementTarget",
        "measurementType",
        "referenceDimension",
        "unit",
        "unitDimension",
        "unitScale",
        "columnMap",
        "mappingPreset"
      ]
    };
  }

  UNSAFE_componentWillMount() {
    this.state.store.getFromRoute(this.props.match);
  }

  renderContent(item) {
    const {
      store: { select }
    } = this.state;
    if (item.content.isMulti) {
      return (
        <React.Fragment key={item.key}>
          <MenuItem
            icon={item.content.isOpen ? "caret-down" : "caret-right"}
            text={item.content.label}
            onClick={() => item.content.open()}
          />
          <Collapse isOpen={item.content.isOpen}>
            {size(item.content.content) &&
              item.content.content
                .filter(item => !item.isHidden)
                .map((subItem, index) => (
                  <MenuItem
                    key={index}
                    icon="dot"
                    text={subItem.label}
                    onClick={() => select(subItem)}
                  />
                ))}
          </Collapse>
          <Divider />
        </React.Fragment>
      );
    } else {
      return (
        <MenuItem
          icon="dot"
          key={item.key}
          text={item.content.label}
          onClick={() => select(item.content.content)}
        />
      );
    }
  }

  renderContextMenu(row) {
    const {
      mainStore: { alert, modal }
    } = this.props;
    const { store } = this.state;
    const {
      fetch,
      selection: {
        schema: { model }
      }
    } = store;
    const menuItems = [];
    menuItems.push(
      <MenuItem
        key="edit"
        icon="edit"
        onClick={() => {
          const [{ __typename: type, mappingPresetColumnMaps: dataMaps }] =
            row.selectedRecords;
          if (type === "columnMap" && dataMaps.length) {
            window.toastr.error(
              "Cannot update Column Mappers used by Data Mappers"
            );
          } else if (row.selectedRecords.length > 1) {
            window.toastr.error("Records can only be edited one at a time.");
          } else {
            modal.openModal(
              {
                open: true,
                type: "ADD_META_DATA",
                title: `Edit ${store.selection.label}`
              },
              {
                ...store.selection,
                refetch: store.fetch,
                row: row.selectedRecords
              }
            );
          }
        }}
        text="Edit"
      />
    );
    menuItems.push(
      <MenuItem
        key="delete"
        icon="trash"
        intent={Intent.DANGER}
        onClick={() => {
          alert.newAlert({
            content: "Are you sure you want to remove the elements selected?",
            cancelButtonText: "Cancel",
            confirmButtonText: "Delete",
            icon: "trash",
            intent: Intent.DANGER,
            onCancel: () => alert.close(),
            onConfirm: () => {
              const [{ __typename: type, mappingPresetColumnMaps: dataMaps }] =
                row.selectedRecords;
              if (type === "columnMap" && dataMaps.length) {
                window.toastr.error(
                  "Cannot delete Column Mappers used by Data Mappers"
                );
              } else {
                this.remove(model, row.selectedRecords, fetch);
              }
              alert.close();
            }
          });
        }}
        text="Delete"
      />
    );
    if (
      row.selectedRecords.length === 1 &&
      this.state.metadataWithView.includes(row.selectedRecords[0].__typename)
    ) {
      menuItems.push(
        <MenuItem
          key="view"
          icon="eye-open"
          onClick={() => {
            this.renderViewDialog(row.selectedRecords[0]);
          }}
          text="View"
        />
      );
    }
    return menuItems;
  }

  remove(model, records, refetch) {
    records.forEach(element => {
      const requestUrl = config.endpoints["deleteMetadata"]
        .replace(":metaDataType", model)
        .replace(":metaDataId", element.id);
      window.serverApi
        .delete(requestUrl)
        .catch(error => {
          window.toastr.error(`Error: ${error.message}`);
        })
        .then(response => {
          if (response) {
            if (response.data?.status === "failed") {
              window.toastr.error(
                `Error: ${element.name} cannot be deleted because assay data depends on it`
              );
            } else {
              refetch();
            }
          }
        });
    });
  }

  renderViewDialog(record) {
    if (!this.state.metadataWithView.includes(record.__typename)) {
      return null;
    }
    const {
      mainStore: { modal }
    } = this.props;
    modal.openModal(
      {
        open: true,
        type: "VIEW_META_DATA",
        title: record.name,
        text: "Ok",
        noCancel: true
      },
      { record: record }
    );
  }

  renderTable() {
    const {
      mainStore: { modal }
    } = this.props;
    const { store } = this.state;
    const model = store.selection.schema?.model;
    if (size(store.selection)) {
      return (
        <DataTable
          formName={store.selection.label}
          schema={store.selection.schema}
          entities={store.entities}
          isLoading={store.loading}
          tableName={store.selection.label}
          onRemove={true}
          contextMenu={store.allowCUD && (row => this.renderContextMenu(row))}
          onDoubleClick={record => {
            this.renderViewDialog(record);
          }}
          defaults={{
            pageSize: store.currentPageSize
          }}
          controlled_page={store.currentPage}
          controlled_total={store.totalResults}
          controlled_setPage={pageNumber => {
            store.fetch({ pageNumber });
          }}
          controlled_setPageSize={pageSize => {
            store.fetch({ pageSize });
          }}
        >
          {store.allowCUD &&
            this.state.metadataWithAddButton.includes(model) && (
              <Button
                minimal
                text={`New ${store.selection.label}`}
                intent={Intent.SUCCESS}
                icon="add"
                onClick={() =>
                  modal.openModal(
                    {
                      open: true,
                      type: "ADD_META_DATA",
                      title: `New ${store.selection.label}`
                    },
                    { ...store.selection, refetch: store.fetch }
                  )
                }
              />
            )}
        </DataTable>
      );
    }
  }

  render() {
    const { store } = this.state;
    return (
      <div className="view-container">
        <div className="meta-data-container">
          <div className="meta-data-menu">
            <Menu style={{ height: "78%" }}>
              <div style={{ height: "97%" }}>
                <MenuDivider title="Global definitions settings" />
                {store.getValues &&
                  store.getValues.map(item => this.renderContent(item))}
                <MenuDivider />
              </div>
            </Menu>
          </div>
          <div className="meta-data-card">{this.renderTable()}</div>
        </div>
      </div>
    );
  }
}

export default withRouter(inject("mainStore")(observer(MetaData)));
