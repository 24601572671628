/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

export function flashTab(newTitle: string, interval?: number) {
  const originalTitle = document.title;
  let isFlashing = false;

  function flashTitle(newTitle: string, interval = 1000) {
    if (!isFlashing) {
      isFlashing = true;
      const timer = setInterval(() => {
        document.title =
          document.title === originalTitle ? newTitle : originalTitle;
      }, interval);

      // Stop flashing after a certain time or when the user focuses on the tab
      window.addEventListener("focus", () => {
        clearInterval(timer);
        document.title = originalTitle;
        isFlashing = false;
      });
    }
  }

  // if the page is NOT currently focused, flash the title
  if (!document.hasFocus()) {
    flashTitle(newTitle, interval);
  }
}
