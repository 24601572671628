/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";

export default gql`
  fragment labelFormatFragment on labelFormat {
    id
    name
    description
    fileExt

    isContainerArray

    startTemplate
    endTemplate
    labelTemplate
    labelFormatLabelMedias {
      id
      labelMedia {
        id
        name
      }
    }

    labelTemplatePrompts {
      id
      name
      description
      path
    }
  }
`;
