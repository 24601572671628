/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

const actions = {
  openNotifications: (payload: boolean) => {
    return {
      type: "NOTIFICATIONS_OPEN",
      payload
    };
  },
  updateUnreadCount: (payload: number) => {
    return {
      type: "NOTIFICATIONS_UPDATE_UNREAD_COUNT",
      payload
    };
  },
  updateActiveCount: (payload: number) => {
    return {
      type: "NOTIFICATIONS_UPDATE_ACTIVE_COUNT",
      payload
    };
  },
  closeNotifications: (payload: boolean) => {
    return {
      type: "NOTIFICATIONS_CLOSE",
      payload
    };
  },
  addNotification: (payload: { notifications: {}; forceOpen: boolean }) => {
    return {
      type: "NOTIFICATIONS_ADD_NOTIFICATION",
      payload
    };
  },
  updateNotification: (payload: { notifications: {}; forceOpen: boolean }) => {
    return {
      type: "NOTIFICATIONS_UPDATE_NOTIFICATION",
      payload
    };
  },
  tickTimers: (payload: {
    id: string;
    timestamp: number;
    addedTimestamp: number;
    endTimestamp: number;
  }) => {
    return {
      type: "NOTIFICATIONS_TICK_TIMERS",
      payload
    };
  },
  setAllTicks: (payload: { notifications: object }) => {
    return {
      type: "NOTIFICATIONS_SET_ALL_TICKS",
      payload
    };
  },
  clear: (payload: {}) => {
    return {
      type: "NOTIFICATIONS_CLEAR",
      payload
    };
  },
  NOTIFICATIONS_TOGGLE: (payload: boolean) => {
    return {
      type: "NOTIFICATIONS_TOGGLE",
      payload
    };
  }
};

export default actions;
