/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { compose } from "recompose";
import { reduxForm } from "redux-form";
import { Classes } from "@blueprintjs/core";
import {
  DialogFooter,
  FileUploadField,
  BlueprintError,
  wrapDialog
} from "@teselagen/ui";

import modelNameToReadableName from "../../../src-shared/utils/modelNameToReadableName";
import { throwFormError } from "../../../src-shared/utils/formUtils";
import { safeUpsert } from "../../../src-shared/apolloMethods";

import { allowedCsvFileTypes } from "../../../../tg-iso-shared/src/utils/fileUtils";

import { getCaseInsensitiveKeyedItems } from "../../../../tg-iso-shared/src/utils/caseInsensitiveFilter";
import { getDownloadTemplateFileHelpers } from "../../../src-shared/components/DownloadTemplateFileButton";
import { validateNameCsvTableUniqueAsync } from "./validateNameCsvTableUniqueAsync";

class UploadItemWithNameAndDescription extends Component {
  onSubmit = async values => {
    const { hideModal, refetch, model } = this.props;
    const readableName = modelNameToReadableName(model, { plural: true });
    try {
      const { parsedData } = values.itemsCsvFile[0];
      const namesToCheck = [];
      parsedData.forEach((row, index) => {
        const { name } = row;
        if (!name || !name.trim()) {
          throw new Error(`Row ${index + 1} did not provide a name.`);
        }
        namesToCheck.push(name.trim());
      });

      if (!namesToCheck.length) return;
      const keyedExisting = await getCaseInsensitiveKeyedItems(
        model,
        "name",
        namesToCheck
      );

      const newItems = [];
      let hadDuplicate = false;
      parsedData.forEach(row => {
        const { name, description } = row;
        if (keyedExisting[name.toLowerCase()]) {
          hadDuplicate = true;
          return;
        }
        keyedExisting[name.toLowerCase()] = true;
        const newItem = {
          name,
          description
        };
        newItems.push(newItem);
      });
      await safeUpsert(model, newItems);
      await refetch();
      if (hadDuplicate) {
        window.toastr.warning("Duplicates were skipped.");
      }
      hideModal();
    } catch (error) {
      console.error("error:", error);
      throwFormError(error.message || `Error uploading ${readableName}.`);
    }
  };

  render() {
    const { hideModal, handleSubmit, submitting, model, error } = this.props;
    return (
      <React.Fragment>
        <div className={Classes.DIALOG_BODY}>
          <FileUploadField
            isRequired
            fileLimit={1}
            accept={getDownloadTemplateFileHelpers({
              type: allowedCsvFileTypes,
              fileName: modelNameToReadableName(model, { plural: true }),
              validateAgainstSchema: {
                ...validateNameCsvTableUniqueAsync({ model }),
                fields: [
                  {
                    path: "name",
                    isUnique: true,
                    isRequired: true
                  },
                  {
                    path: "description"
                  }
                ]
              }
            })}
            name="itemsCsvFile"
          />
          <BlueprintError error={error} />
        </div>
        <DialogFooter
          submitting={submitting}
          hideModal={hideModal}
          onClick={handleSubmit(this.onSubmit)}
        />
      </React.Fragment>
    );
  }
}

export default compose(
  wrapDialog(props => {
    return {
      title: `Upload ${modelNameToReadableName(props.model, {
        upperCase: true,
        plural: true
      })}`
    };
  }),
  reduxForm({
    form: "uploadSimpleItemsWithNameAndDescription"
  })
)(UploadItemWithNameAndDescription);
