/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useCallback, useEffect, useState } from "react";
import {
  DataTable,
  Loading,
  ReactSelectField,
  withSelectTableRecords
} from "@teselagen/ui";
import { get, identity, noop, isEmpty } from "lodash";
import { Button, Classes, Colors, Icon, Tooltip } from "@blueprintjs/core";
import classNames from "classnames";
import { Link } from "react-router-dom";
import PlateMapPlate from "../PlateMapPlate";
import { getPlateTableSchema } from "../Record/ContainerArrayRecordView/utils";
import { ignoreBatchHeaders } from "../../utils";
import {
  getActiveLocationsForQuadrant,
  getAliquotMaterialString,
  getAliquotContainerAdditiveString
} from "../../utils/plateUtils";
import * as pmSchemas from "./plateMapSchemas";
import modelNameToLink from "../../../src-shared/utils/modelNameToLink";
import { volumeColumn } from "../../../src-shared/utils/libraryColumns";
import { useDispatch, useSelector } from "react-redux";
import { compose } from "recompose";
import containerArrayPlatePreviewFragment from "../../graphql/fragments/containerArrayPlatePreviewFragment";
import plateMapGroupPreviewFragment from "../../graphql/fragments/plateMapGroupPreviewFragment";
import withQuery from "../../../src-shared/withQuery";

import "./style.css";
import { reduxForm } from "redux-form";
import { tgFormValues } from "@teselagen/ui";
import { branch } from "recompose";
import { popoverOverflowModifiers } from "../../../src-shared/utils/generalUtils";
import actions from "../../../src-shared/redux/actions";
import { getAliquotContainerLocation } from "../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";
import {
  generateContainerArray,
  sortAliquotContainers
} from "../../../../tg-iso-lims/src/utils/plateUtils";

type PlateLayer = {
  __typename: "plateLayer";
  id: string;
  plateLayerDefinition: {
    __typename: "plateLayerDefinition";
    id: string;
    name: string;
  };
  containerArrayId: string | null;
  plateZones: {
    __typename: "plateZone";
    id: string;
    plateZoneDefinition: {
      __typename: "plateZoneDefinition";
      id: string;
      name: string;
      color: string;
    };
    plateZoneWells: {
      __typename: "plateZoneWell";
      aliquotContainerId: string | null;
      plateMapItemId: string;
      id: string;
    }[];
  }[];
};

type AliquotContainer = {
  __typename: "aliquotContainer";
  rowPosition: number;
  columnPosition: number;
  tooltip?: string;
  id?: string;
  color?: string;
  additives?: any[];
  containerArray?: ContainerArray;
  volume?: number;
  volumetricUnitCode?: string;
  barcode: {
    barcodeString: string;
  };
  aliquot?: {
    __typename: "aliquot";
    id: string;
    [key: string]: any; // Other aliquot props
  };
};

type ContainerFormat = {
  code: string;
  rowCount: number;
  columnCount: number;
  is2DLabeled: boolean;
  name: string;
  __typename: "containerFormat";
};

type ContainerArrayType = {
  __typename: "containerArrayType";
  name: string;
  id: string;
  isPlate?: boolean;
  isColumn?: boolean;
  containerFormat: ContainerFormat;
};
type ContainerArray = {
  id: string;
  name: string;
  __typename: "containerArray";
  containerArrayType?: ContainerArrayType;
  aliquotContainers: AliquotContainer[];
};

type PlateMapItem = {
  id: string;
  name: string;
  __typename: "plateMapItem";
  rowPosition: number;
  columnPosition: number;
  inventoryItem: any; // contains information on what is on each plate
  volume?: number;
  volumetricUnitCode?: string;
  barcode: string;
  location: string;
};

type PlateMap = {
  id: string;
  name: string;
  __typename: "plateMap";
  plateMapItems: PlateMapItem[];
  plateLayers: PlateLayer[];
  type: string;
  temperatureZones: number[];
  temperatureZoneOrientation: string;
};

type PlateMapGroup = {
  id: string;
  name: string;
  __typename: "plateMapGroup";
  plateMaps: PlateMap[];
  containerFormat: ContainerFormat;
  containerFormatCode: string;
};

const getSchema = (
  plateMapItems: PlateMapItem[] | AliquotContainer[],
  {
    containerArray,
    withSampleStatus
  }: { containerArray: ContainerArray; withSampleStatus: boolean }
) => {
  const schemaMap = {
    ...pmSchemas,
    plateMapItem: pmSchemas.sequence
  };

  if (containerArray) {
    // @ts-ignore
    return getPlateTableSchema({
      containerArray,
      withSampleStatus,
      simpleSchema: true
    });
  }

  let dataType: string | undefined;
  let needsVolumeColumn = false;
  plateMapItems.some(plateMapItem => {
    if (get(plateMapItem, "inventoryItem")) {
      dataType =
        get(plateMapItem, "inventoryItem.material.__typename") ||
        get(plateMapItem, "inventoryItem.sample.__typename") ||
        get(plateMapItem, "inventoryItem.aliquot.__typename") ||
        get(plateMapItem, "inventoryItem.additiveMaterial.__typename") ||
        get(plateMapItem, "inventoryItem.lot.__typename") ||
        get(plateMapItem, "inventoryItem.aliquotContainer.__typename");
      needsVolumeColumn = !!plateMapItem.volume;
    } else if (get(plateMapItem, "j5Item")) {
      dataType =
        get(plateMapItem, "j5Item.j5PcrReaction.__typename") ||
        get(plateMapItem, "j5Item.j5InputSequence.__typename") ||
        get(plateMapItem, "j5Item.j5DirectSynthesis.__typename") ||
        get(plateMapItem, "j5Item.j5Oligo.__typename");
      // @ts-ignore
    } else if (get(plateMapItem, "__typename") === "sequence") {
      dataType = "pcrPlanningInputSequence";
      // @ts-ignore
    } else if (get(plateMapItem, "__typename") === "j5Oligo") {
      dataType = get(plateMapItem, "__typename");
    } else if (get(plateMapItem, "pcrProductSequence")) {
      dataType = "pcrProductSequence";
    }
    return !!dataType;
  });
  // @ts-ignore
  let schema = schemaMap[dataType || "plateMapItem"];
  if (needsVolumeColumn) {
    schema = [...schema, volumeColumn];
  }
  return schema;
};

const tableCellRenderer = {
  column: (column: number) => column + 1
};

type TableRecord = PlateMapItem | AliquotContainer;

type Props = {
  selectedTubeBarcode: string;
  selectedAliquotContainerId: string;
  selectTableRecords: (records: TableRecord[]) => void;
  // Either plateMapGroup or containerArray is required
  plateMapGroup?: PlateMapGroup;
  containerArray?: ContainerArray;
  noEntityTransform?: boolean;
  wellErrors?: { [location: string]: string[] | string };
  wellWarnings?: { [location: string]: string[] | string };
  tableSchema: any[] | Function;
  withSampleStatus: boolean;

  // Check onwards for optional
  noPadding: boolean;
  title: string;
  nameAsTitle?: boolean;
  nameAsLink?: boolean;
  noTitle?: boolean;
  wrapPlateMapPlate?: (
    plateMapPlate: React.JSX.Element,
    options: { plateMap?: PlateMap }
  ) => React.JSX.Element;
  tableOnly?: boolean;
  plateOnly?: boolean;
  withDragSelect?: boolean;
  onWellsSelected?: (locations: string[], e: React.MouseEvent) => void;
  onTableRowDoubleClick?: (record: any) => void;
  aliquotContainerDoubleClick?: (aliquotContainer: AliquotContainer) => void;
  getContextMenuItems?: (
    options: { selectedRecords: AliquotContainer[] },
    location: string
  ) => React.JSX.Element[];
  aliquotContextMenu?: (
    aliquotContainer: AliquotContainer
  ) => React.JSX.Element;
  onAliquotContainerSelected?: (
    record: AliquotContainer,
    location: string
  ) => void;
  withQuadrantFilter?: boolean;
  quadrantFilter?: string; // Injected by form
  breakdownPattern?: string;
  withDisplayOptions?: boolean;
  stacked?: boolean;
};

const PlateMapView = ({
  selectedTubeBarcode,
  selectedAliquotContainerId,
  plateMapGroup,
  containerArray,
  noPadding,
  title: _title,
  nameAsTitle,
  nameAsLink,
  noTitle,
  wrapPlateMapPlate = identity,
  tableOnly,
  plateOnly,
  withDragSelect,
  onWellsSelected,
  onTableRowDoubleClick,
  aliquotContainerDoubleClick,
  selectTableRecords,
  getContextMenuItems,
  aliquotContextMenu,
  onAliquotContainerSelected: _onAliquotContainerSelected = noop,
  withQuadrantFilter,
  quadrantFilter,
  breakdownPattern = "Z",
  withDisplayOptions,
  stacked,
  wellErrors = {},
  wellWarnings = {},
  noEntityTransform,
  tableSchema,
  withSampleStatus
}: Props) => {
  const selectedAliquotContainerLocations = useSelector(
    state =>
      (state as any).ui.records.containerArray
        .selectedAliquotContainerLocations || []
  ) as string[];
  const dispatch = useDispatch();

  const setSelectedAliquotContainerLocation = useCallback(
    (arg0: { location: string | null }) => {
      dispatch(
        actions.ui.records.containerArray.setSelectedAliquotContainerLocation(
          arg0
        )
      );
    },
    [dispatch]
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState<number | null>(null);

  const prevPage = () => {
    setCurrentPage(prev => prev - 1);
  };
  const nextPage = () => {
    setCurrentPage(prev => prev + 1);
  };

  const renderPagingTool = () => {
    if (!totalPages) return null;
    return (
      <div className="tg-flex align-center">
        <Button
          disabled={currentPage === 1}
          onClick={prevPage}
          className={Classes.MINIMAL}
          icon="arrow-left"
        />
        Plate Map {currentPage} of {totalPages}
        <Button
          disabled={currentPage === totalPages}
          onClick={nextPage}
          className={Classes.MINIMAL}
          icon="arrow-right"
        />
      </div>
    );
  };

  const onSingleRowSelect = useCallback(
    (record: AliquotContainer | PlateMapItem) => {
      const location: string =
        (record as any).location || getAliquotContainerLocation(record);
      if (!selectedAliquotContainerLocations.includes(location)) {
        setSelectedAliquotContainerLocation({
          location
        });
      }
    },
    [selectedAliquotContainerLocations, setSelectedAliquotContainerLocation]
  );

  const getEntities = useCallback(() => {
    let plateMapItems: AliquotContainer[] | PlateMapItem[] = [];
    if (containerArray) {
      plateMapItems = containerArray.aliquotContainers!;
      const containerArrayType = get(containerArray, "containerArrayType");
      if (containerArrayType && containerArrayType.isPlate) {
        plateMapItems = plateMapItems.filter(ac => {
          return ac.aliquot || (ac.additives && ac.additives.length);
        });
      }
      plateMapItems = plateMapItems.map(ac => {
        if (!ac.containerArray) {
          return {
            ...ac,
            containerArray: {
              id: containerArray.id,
              containerArrayType
            } as ContainerArray
          };
        }
        return ac;
      });
    } else if (plateMapGroup) {
      const plateMap = get(
        plateMapGroup!.plateMaps,
        `[${currentPage - 1}]`,
        undefined
      );
      plateMapItems = plateMap?.plateMapItems || [];
    } else {
      throw new Error("Either plateMapGroup or containerArray is required");
    }

    plateMapItems = sortAliquotContainers(plateMapItems);

    return noEntityTransform
      ? plateMapItems.filter(plateMapItem => (plateMapItem as any).aliquot)
      : plateMapItems.map(plateMapItem => {
          return {
            location: getAliquotContainerLocation(plateMapItem),
            ...plateMapItem
          };
        });
  }, [containerArray, currentPage, noEntityTransform, plateMapGroup]);

  const getErrorMsgForWell = (
    ac: AliquotContainer | PlateMapItem,
    addBullets?: boolean
  ) => {
    const location = getAliquotContainerLocation(ac, {
      force2D: true
    });
    let errorForLocation = wellErrors[location] || wellWarnings[location];
    if (errorForLocation) {
      if (!Array.isArray(errorForLocation)) {
        errorForLocation = [errorForLocation];
      }
      if (addBullets) {
        errorForLocation = errorForLocation.map(e => ` - ${e}`);
      }
      errorForLocation = errorForLocation.join("\n");
    }
    return { msg: errorForLocation, isError: !!wellErrors[location] };
  };

  const getFullSchema = (
    plateMapItems: PlateMapItem[] | AliquotContainer[]
  ) => {
    let schema = tableSchema;

    if (typeof schema === "function") {
      schema = schema(plateMapItems) as any[];
    } else if (!schema) {
      schema = getSchema(plateMapItems, {
        containerArray: containerArray!,
        withSampleStatus: withSampleStatus
      });
    }

    if (!isEmpty(wellErrors) || !isEmpty(wellWarnings)) {
      schema = [
        {
          type: "action",
          width: 35,
          render: (_: any, record: AliquotContainer | PlateMapItem) => {
            const { msg } = getErrorMsgForWell(record);
            if (msg) {
              return (
                // @ts-ignore
                <Tooltip
                  position="bottom-left"
                  popoverClassName="preserve-newline"
                  content={msg}
                  modifiers={popoverOverflowModifiers}
                >
                  <Icon
                    intent={!isEmpty(wellErrors) ? "danger" : "warning"}
                    style={{ marginRight: 10 }}
                    icon="warning-sign"
                  />
                </Tooltip>
              );
            }
            return null;
          }
        },
        ...(schema as any[])
      ];
    }

    return schema;
  };

  useEffect(() => {
    const newPlateMaps = get(plateMapGroup, "plateMaps", []);
    setTotalPages(newPlateMaps?.length || null);
    if (currentPage > newPlateMaps.length) {
      setCurrentPage(1);
    }

    let matchingTube: PlateMapItem | AliquotContainer | undefined;
    if (selectedTubeBarcode) {
      const tubes = getEntities() as AliquotContainer[];
      matchingTube = tubes.find(
        e => e.barcode && e.barcode.barcodeString === selectedTubeBarcode
      );
    } else if (selectedAliquotContainerId) {
      const tubes = getEntities();
      matchingTube = tubes.find(e => e.id === selectedAliquotContainerId);
    }
    if (matchingTube) {
      selectTableRecords([matchingTube]);
      onSingleRowSelect(matchingTube);
    }
  }, [
    currentPage,
    getEntities,
    onSingleRowSelect,
    plateMapGroup,
    selectTableRecords,
    selectedAliquotContainerId,
    selectedTubeBarcode
  ]);

  useEffect(() => {
    return () => {
      setSelectedAliquotContainerLocation({ location: null });
    };
  }, [setSelectedAliquotContainerLocation]);

  if (!plateMapGroup && !containerArray) {
    return <Loading inDialog />;
  }
  let containerArrayType: ContainerArrayType | undefined,
    plateMapItems: PlateMapItem[] | AliquotContainer[] | undefined,
    temperatureZones: number[] | undefined,
    temperatureZoneOrientation: string | undefined,
    containerFormat: ContainerFormat | undefined,
    plateMap: PlateMap | undefined,
    plateLayers: PlateLayer[] | undefined;

  if (containerArray) {
    containerArrayType = get(containerArray, "containerArrayType");
    containerFormat = get(containerArray, "containerArrayType.containerFormat");

    plateMapItems = containerArray.aliquotContainers;
    if (containerArrayType && containerArrayType.isPlate) {
      plateMapItems = plateMapItems.filter(ac => {
        return ac.aliquot || ac.additives;
      });
    }
    plateMapItems = plateMapItems.map(ac => {
      const cleanedAc = { ...ac };
      if (!ac.containerArray) {
        cleanedAc.containerArray = {
          id: containerArray.id,
          containerArrayType
        } as ContainerArray;
      }
      const sampleName = get(ac, "aliquot.sample.name");
      const materialNames = getAliquotMaterialString(ac.aliquot);
      const additiveString = getAliquotContainerAdditiveString(ac);
      let tooltipTitle = materialNames || sampleName || additiveString;

      if (tooltipTitle) {
        if (tooltipTitle.length > 50) {
          tooltipTitle = tooltipTitle.slice(0, 50) + "...";
        }
        cleanedAc.tooltip = tooltipTitle + "\n";
      }

      const { msg, isError } = getErrorMsgForWell(ac, true);
      if (msg) {
        if (cleanedAc.tooltip) {
          cleanedAc.tooltip += "\n";
        } else {
          cleanedAc.tooltip = "";
        }
        cleanedAc.tooltip += msg;
        cleanedAc.color = isError ? Colors.RED2 : Colors.GOLD2;
      }

      return cleanedAc;
    });
  } else {
    const safePlateMapGroup = plateMapGroup as PlateMapGroup;

    containerArrayType = get(plateMapGroup, "containerArrayType");
    containerFormat =
      get(plateMapGroup, "containerArrayType.containerFormat") ||
      get(plateMapGroup, "containerFormat");
    plateMap = get(
      safePlateMapGroup.plateMaps,
      `[${currentPage - 1}]`,
      {}
    ) as PlateMap;
    plateLayers = plateMap.plateLayers;
    plateMapItems = plateMap.plateMapItems || [];
    temperatureZones =
      plateMap.temperatureZones || get(plateMap, "metadata.temperatureZones");
    temperatureZoneOrientation =
      plateMap.temperatureZoneOrientation ||
      get(plateMap, "metadata.temperatureZoneOrientation");
  }

  let entities = getEntities();
  const wells = generateContainerArray(
    plateMapItems,
    containerFormat
  ) as AliquotContainer[];

  const schema = getFullSchema(plateMapItems);
  let containerFormatSpecs: ContainerFormat | undefined;
  if (!containerArrayType) {
    containerFormatSpecs = containerFormat;
  } else {
    containerFormatSpecs = containerArrayType.containerFormat;
  }
  let size;
  if ((containerFormatSpecs as ContainerFormat).rowCount >= 16) {
    size = 600;
  }

  // can only have quadrant filter if the container format is divisible by 4
  let shouldHaveQuadrantFilter = false;
  if (
    withQuadrantFilter &&
    containerFormatSpecs &&
    (containerFormatSpecs.rowCount * containerFormatSpecs.columnCount) % 4 === 0
  ) {
    shouldHaveQuadrantFilter = true;
  }

  const onAliquotContainerSelected = (
    record: AliquotContainer,
    location: string
  ) => {
    _onAliquotContainerSelected(record, location);
    const match = entities.find(r => r.id === record.id);
    if (match) {
      selectTableRecords([match]);
    }
  };

  let isWellDisabled;
  if (shouldHaveQuadrantFilter && quadrantFilter) {
    const activeLocationsArray: string[] = getActiveLocationsForQuadrant({
      containerFormat: containerFormatSpecs,
      aliquotContainers: wells,
      breakdownPattern,
      quadrant: Number(quadrantFilter)
    });
    isWellDisabled = (_ac: AliquotContainer, location: string) => {
      return !activeLocationsArray.includes(location);
    };
    entities = (entities as PlateMapItem[]).filter(e =>
      activeLocationsArray.includes(e.location)
    );
  }
  const plateMapPlate = (
    <div className={classNames({ "col-lg-6": !stacked })}>
      <PlateMapPlate
        isWellDisabled={isWellDisabled}
        selectedAliquotContainerLocations={selectedAliquotContainerLocations}
        setSelectedAliquotContainerLocation={
          setSelectedAliquotContainerLocation
        }
        aliquotContainerDoubleClick={aliquotContainerDoubleClick}
        withDragSelect={withDragSelect}
        onWellsSelected={onWellsSelected}
        onAliquotContainerSelected={onAliquotContainerSelected}
        getContextMenuItems={getContextMenuItems}
        aliquotContextMenu={aliquotContextMenu}
        containerArrayType={containerArrayType}
        containerFormatSpecs={containerFormatSpecs}
        containerFormat={containerFormat}
        plateLayers={plateLayers}
        size={size}
        temperatureZones={temperatureZones}
        temperatureZoneOrientation={temperatureZoneOrientation}
        aliquotContainers={wells}
      />
    </div>
  );

  let title: string | React.JSX.Element = _title;
  if (!title && nameAsTitle) {
    title = containerArray ? containerArray.name : plateMap!.name;
  }
  if (nameAsLink) {
    const item = (containerArray as ContainerArray) || (plateMap as PlateMap);
    title = <Link to={modelNameToLink(item.__typename, item.id)}>{title}</Link>;
  }
  const titleToUse = !noTitle && (plateMap?.name || title);

  const plateView = wrapPlateMapPlate(plateMapPlate, { plateMap });
  const tableView = (
    <div
      className={classNames({
        "col-lg-6": !stacked
      })}
    >
      <DataTable
        orderByFirstColumn
        formName="plateMapViewTable"
        maxHeight={350}
        className="plate-map-view-table"
        entities={entities}
        schema={schema}
        withDisplayOptions={withDisplayOptions}
        isInfinite
        noHeader
        noFooter={!withDisplayOptions}
        onDoubleClick={onTableRowDoubleClick}
        noPadding={noPadding}
        withSearch={false}
        cellRenderer={tableCellRenderer}
        onSingleRowSelect={onSingleRowSelect}
        contextMenu={getContextMenuItems}
      />
    </div>
  );

  const pagingTool = renderPagingTool();
  return (
    <div style={{ overflow: "auto" }}>
      {(pagingTool || titleToUse) && (
        <div
          className="plate-map-view-header"
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: noPadding ? undefined : 10
          }}
        >
          {titleToUse ? <h3>{titleToUse}</h3> : <div />}
          {pagingTool}
        </div>
      )}
      {shouldHaveQuadrantFilter && (
        <div style={{ maxWidth: 250 }}>
          <ReactSelectField
            label="Filter Quadrants"
            name="quadrantFilter"
            options={[
              { label: "One", value: "0" },
              { label: "Two", value: "1" },
              { label: "Three", value: "2" },
              { label: "Four", value: "3" }
            ]}
          />
        </div>
      )}
      <div
        className={classNames("plate-map-view-container", {
          container: !stacked,
          noPadding
        })}
      >
        <div
          className={classNames({
            row: !stacked
          })}
        >
          {!tableOnly && plateView}
          {!plateOnly && tableView}
        </div>
      </div>
    </div>
  );
};

export default compose<any, Props>(
  withQuery(plateMapGroupPreviewFragment, {
    skip: props => !props.plateMapGroupId,
    options: props => {
      return {
        ...ignoreBatchHeaders,
        ...props.queryOptions,
        variables: {
          id: props.plateMapGroupId,
          ...(props.queryOptions && props.queryOptions.variables)
        }
      };
    },
    showLoading: true,
    inDialog: true
  }),
  withQuery(containerArrayPlatePreviewFragment, {
    skip: (props: { containerArrayId?: string }) => !props.containerArrayId,
    options: props => {
      return {
        ...ignoreBatchHeaders,
        ...props.queryOptions,
        variables: {
          id: props.containerArrayId,
          ...(props.queryOptions && props.queryOptions.variables)
        }
      };
    },
    showLoading: true,
    inDialog: true
  }),
  withSelectTableRecords("plateMapViewTable"),
  branch(
    (props: { withQuadrantFilter: boolean }) => props.withQuadrantFilter,
    compose(
      reduxForm({
        form: "plateMapViewForm"
      }),
      tgFormValues("quadrantFilter")
    )
  )
)(PlateMapView);
