/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { SHA256, enc } from "crypto-js";

// Should only be called on circular sequence!
// Booth's algorithm:
// https://en.wikipedia.org/wiki/Lexicographically_minimal_string_rotation#Booth's_Algorithm
function leastRotation(sequence) {
  const seq_length = sequence.length;
  const f = Array(2 * seq_length).fill(-1); //Failure function
  let k = 0;
  for (let j = 1; j < 2 * seq_length; j++) {
    let i = f[j - k - 1];
    const sj = sequence[j % seq_length];
    while (i !== -1 && sj !== sequence[(k + i + 1) % seq_length]) {
      if (sj < sequence[(k + i + 1) % seq_length]) {
        k = j - i - 1;
      }
      i = f[i];
    }

    if (i === -1 && sj !== sequence[(k + i + 1) % seq_length]) {
      if (sj < sequence[k % seq_length]) {
        // k + i + 1 = k
        k = j;
      }
      f[j - k] = -1;
    } else {
      f[j - k] = i + 1;
    }
  }
  return sequence.substring(k) + sequence.substring(0, k);
}

function hashPrefix(sequenceTypeCode) {
  /*

  Please refer to 'hash_sequence.sql.js' file for the true prefix map.
  sequence Type code  | hashPrefix
  ----------------+----------
  AA                 | ALS
  CDS                | NLD
  CIRCULAR_DNA       | NCD
  LINEAR_DNA         | NLD
  RNA                | NLS
  OLIGO              | NLS
  GENOME             | NLD
  GENOMIC_REGION     | NLD
  ALIGNMENT_SEQ      | NLD
  */
  const prefix = {
    AA: "ALS",
    CDS: "NLD",
    CIRCULAR_DNA: "NCD",
    LINEAR_DNA: "NLD",
    RNA: "NLS",
    OLIGO: "NLS",
    GENOME: "NLD",
    GENOMIC_REGION: "NLD",
    ALIGNMENT_SEQ: "NLD",
    REGISTERED_ANN: "H2P"
  };
  return prefix[sequenceTypeCode] || "H2P";
}

export function computeSequenceHash(
  sequence,
  sequenceTypeCode = "",
  joinChar = ""
) {
  if (!sequenceTypeCode) {
    throw new Error("sequenceTypeCode is required to compute sequence hash.");
  }
  if (!sequence) return;
  let seq = sequence;
  if (Array.isArray(sequence)) {
    seq = sequence.join(joinChar);
  }
  seq = seq.toLowerCase();
  const prefix = hashPrefix(sequenceTypeCode);
  if (prefix.charAt(1) === "C") seq = leastRotation(seq); // Circular Sequence
  return prefix + enc.Hex.stringify(SHA256(seq));
}
