/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useContext } from "react";
import ChangeProfileForm from "./ChangeProfileForm";
import ChangeAvatar from "./ChangeAvatar";
import CurrentUserContext from "../context/CurrentUserContext";

const ProfilePanel = () => {
  const { currentUser, refetchCurrentUser } = useContext(CurrentUserContext);
  return (
    <div className="tg-card xtra-pad">
      <h3>Change Avatar</h3>
      <ChangeAvatar />
      <h3>Profile</h3>
      <div style={{ marginBottom: 10 }}>Email: {currentUser!.email}</div>
      <ChangeProfileForm
        currentUser={currentUser}
        refetchCurrentUser={refetchCurrentUser}
      />
    </div>
  );
};

export default ProfilePanel;
