/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useContext } from "react";
import { Intent, Button } from "@blueprintjs/core";
import { InputField } from "@teselagen/ui";
import { Link } from "react-router-dom";
import { pick, get } from "lodash";
import { InjectedFormProps, reduxForm } from "redux-form";
import { auth } from "../auth/utils";
import PasswordWithStrength from "../auth/components/PasswordWithStrength";
import CurrentUserContext from "../context/CurrentUserContext";
import { useSelector } from "react-redux";

type Props = {};
type ValuesType = {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
};

type State = {
  form?: { changePasswordForm?: { values?: { newPassword: string } } };
};

const ChangePasswordForm = ({
  handleSubmit,
  submitting,
  reset
}: Props & InjectedFormProps<ValuesType, Props>) => {
  const enteredPassword = useSelector(
    (state: State) => state.form?.changePasswordForm?.values?.newPassword
  );
  const { currentUser } = useContext(CurrentUserContext);
  const onSubmit = async (values: ValuesType) => {
    try {
      await auth.changePassword(
        pick(values, ["oldPassword", "newPassword"]),
        currentUser!.id
      );
      reset();
      window.toastr.success("Password changed");
    } catch (error) {
      console.error("error:", error.response);
      const msg = get(error, "response.data.msg");
      window.toastr.error(msg || "Error changing password");
    }
  };

  const nonEmptyPass = !!(enteredPassword && enteredPassword.length);

  return (
    <form>
      <InputField
        label="Current password"
        name="oldPassword"
        isRequired
        type="password"
        leftIcon="lock"
        tooltipError
        tooltipProps={{
          position: "left"
        }}
      />
      <PasswordWithStrength
        label="New password"
        name="newPassword"
        isRequired
        showStrength={nonEmptyPass}
        email={currentUser!.email}
        leftIcon="lock"
        tooltipError
        tooltipProps={{
          position: "left"
        }}
      />
      <InputField
        label="Confirm new password"
        name="confirmNewPassword"
        isRequired
        type="password"
        leftIcon="lock"
        tooltipError
        tooltipProps={{
          position: "left"
        }}
      />
      <div className="settings-footer">
        {window.frontEndConfig.recoverPassword && (
          <Link to="/password-reset">I forgot my password</Link>
        )}
        <Button
          intent={Intent.PRIMARY}
          onClick={handleSubmit(onSubmit)}
          loading={submitting}
          text="Update Password"
        />
      </div>
    </form>
  );
};

const validate = (values: ValuesType) => {
  const errors: { confirmNewPassword?: string } = {};

  if (values.confirmNewPassword) {
    if (values.confirmNewPassword !== values.newPassword) {
      errors.confirmNewPassword =
        "Password confirmation does not match password";
    }
  }

  return errors;
};

const formConnectedChangePasswordForm = reduxForm<ValuesType>({
  enableReinitialize: true,
  form: "changePasswordForm",
  validate
})(ChangePasswordForm);

export default formConnectedChangePasswordForm;
