/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

export function getParsedSequenceMatch(
  parsedSequences = [],
  sequenceName = ""
) {
  const lowerSeqName = sequenceName.toLowerCase();
  const nm = parsedSequences.sequenceNameMap;
  const fm = parsedSequences.sequenceFileMap;
  const byName = nm && nm[lowerSeqName] && nm[lowerSeqName][0];
  const byFile = fm && fm[lowerSeqName] && fm[lowerSeqName][0];
  return byName || byFile;
}
