/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { forEach, get, kebabCase } from "lodash";
import modelNameToReadableName from "../../../tg-iso-shared/src/utils/modelNameToReadableName";
import pluralize from "pluralize";
import { extendedPropertyModels } from "../../../tg-iso-shared/constants";
import ProfilePanel from "../PersonalSettings/ProfilePanel";
import RestrictionEnzymeManagementPanel from "../RestrictionEnzymeManagementPanel";
import AnnotationManagementPanel from "../AnnotationManagementPanel";
import FeatureTypeOverrides from "../FeatureTypeOverrides";
import SingleLabPanel from "../PersonalSettings/SingleLabPanel";
import DefaultUserProjectPanel from "../PersonalSettings/DefaultUserProjectPanel";
import NotificationSettings from "../NotificationUI/NotificationSettings";
import ChangeLabLogo from "../ChangeLabLogo";
import LabManagementPanel from "../LabManagementPanel";
import CodonMapLibraryContainer from "../../src-design/containers/CodonMapLibraryContainer";
import RNATypePanel from "../RNATypePanel";
import CustomHelpDocsManagementPanel from "../CustomHelpDocsManagementPanel";
import DefaultValuesManagementPanel from "../DefaultValuesManagementPanel";
import IntegrationsManagementPanel from "../IntegrationsManagementPanel";
import ServiceAccountsPanel from "../PersonalSettings/ServiceAccountsPanel";
import UserManagementPanel from "../UserManagementPanel";
import TagManagement from "../TagManagement";
import ExtendedPropertiesLibrary from "../ExtendedPropertiesLibrary";
import ProjectManagement from "../ProjectManagement";
import DefaultLabProjectPanel from "../PersonalSettings/DefaultLabProjectPanel";
import { TestSettings } from "../../src-test/components/AppSettings";
import UnitPanel from "../../src-build/components/AppSettings/UnitPanel";
import GenusPanel from "../../src-build/components/GenusPanel";
import PublicationManagementPanel from "../PublicationManagementPanel";
import OrganismClassPanel from "../../src-build/components/OrganismClassPanel";
import SelectionMethodPanel from "../../src-build/components/SelectionMethodPanel";
import InductionMethodPanel from "../../src-build/components/InductionMethodPanel";
import GasCompositionPanel from "../../src-build/components/GasCompositionPanel";
import LabelMediaPanel from "../../src-build/components/AppSettings/LabelSettings/LabelMediaPanel";
import LabelFormatPanel from "../../src-build/components/AppSettings/LabelSettings/LabelFormatPanel";
import DesignApprovalPanel from "../../src-design/components/AppSettings/DesignApprovalPanel";
import AppConfigurationPanel from "../../src-design/components/AppSettings/AppConfigurationPanel";
import AssemblyParameterPresetsPanel from "../../src-design/components/AppSettings/AssemblyParameterPresetsPanel";
import CustomIconsPanel from "../../src-design/components/AppSettings/CustomIconsPanel";
import AdvancedFeatures from "../PersonalSettings/AdvancedFeatures";
import TypeSettingsContainer from "../../src-build/containers/TypeSettingsContainer";
import ApiKeyPanel from "../PersonalSettings/ApiKeyPanel";
import VendorPanel from "../../src-build/components/VendorPanel";
import LocationSettings from "../../src-build/components/AppSettings/LocationSettings";
import AccountPanel from "../PersonalSettings/AccountPanel";
import { inDevelopment } from "../utils/inDevelopment";
import ApiLoginPanel from "../PersonalSettings/ApiLoginPanel";
import { ActivateSegmentPanel } from "../PersonalSettings/ActivateSegmentPanel";
import { activateSegmentUtils } from "../utils/activateSegmentUtils";

export const unitTypes = [
  "volumetricUnit",
  "massUnit",
  "concentrationUnit",
  "molarityUnit",
  "materialConcentrationUnit",
  "cellConcentrationUnit"
];

export const locationTypes = [
  "site",
  "locationType",
  "equipmentType",
  "containerType"
];

const getTypeSettingsTitle = model =>
  modelNameToReadableName(model, {
    upperCase: true,
    plural: true
  });

export const typeSettings = [
  { name: "additiveType" },
  {
    name: "aliquotContainerType",
    disabled: window.frontEndConfig.disabledToolkits.freezerEquipmentManagement
  },
  { name: "plateType" },
  {
    name: "qualityControlCheck",
    disabled: window.frontEndConfig.disabledToolkits.workflowManagement
  },
  {
    name: "reactionType",
    disabled: window.frontEndConfig.disabledToolkits.workflowManagement
  },
  {
    name: "containerFormat",
    disabled: window.frontEndConfig.disabledToolkits.freezerEquipmentManagement
  },
  {
    name: "tubeRackType",
    disabled: window.frontEndConfig.disabledToolkits.freezerEquipmentManagement
  },
  {
    name: "requestType",
    disabled: window.frontEndConfig.disabledToolkits.workflowManagement
  }
].sort((modelA, modelB) =>
  getTypeSettingsTitle(modelA).localeCompare(getTypeSettingsTitle(modelB))
);

GasCompositionPanel;

const settingsTabsInfo = {
  "Personal Settings:": { divider: true, className: "topLevelDivider" },
  profile: {
    Component: ProfilePanel,
    id: "profile",
    title: "Profile",
    className: "settings-tab-panel"
  },

  account: {
    Component: AccountPanel,
    id: "account",
    isHidden: !get(window, "frontEndConfig.localLogin"),
    title: "Account",
    className: "settings-tab-panel"
  },
  ...(!window.frontEndConfig.singleUserLabs && {
    "my-lab": {
      Component: SingleLabPanel,
      id: "my-lab",
      title: "My Lab",
      className: "settings-tab-panel"
    }
  }),
  "default-project": {
    Component: DefaultUserProjectPanel,
    id: "default-project",
    isHidden: !window.frontEndConfig.projectTags,
    title: "Default Project",
    className: "settings-tab-panel"
  },
  notifications: {
    Component: NotificationSettings,
    id: "notifications",
    title: "Notifications",
    className: "settings-tab-panel"
  },
  apikey: {
    id: "apikey",
    title: "API Password",
    className: "settings-tab-panel",
    Component: ApiKeyPanel
  },
  ...(inDevelopment && {
    apiLogin: {
      id: "apiLogin",
      Component: ApiLoginPanel,
      title: "API Login",
      className: "settings-tab-panel"
    }
  }),
  ...(activateSegmentUtils && {
    ActivateSegment: {
      id: "ActivateSegment",
      title: "Activate Segment",
      className: "settings-tab-panel",
      Component: ActivateSegmentPanel
    }
  }),
  "TeselaGen Settings:": {
    divider: true,
    className: "topLevelDivider"
  },
  users: {
    id: "users",
    adminOnly: true,
    title: "Users",
    className: "settings-tab-panel",
    Component: UserManagementPanel
  },
  "service-accounts": {
    id: "service-accounts",
    adminOnly: true,
    title: "Service Accounts",
    className: "settings-tab-panel",
    Component: ServiceAccountsPanel
  },
  integrations: {
    id: "integrations",
    disabled: window.frontEndConfig.disabledToolkits.integrations,
    adminOnly: true,
    title: "Integrations",
    className: "settings-tab-panel",
    Component: IntegrationsManagementPanel
  },
  publicationTargets: {
    id: "publication-management",
    adminOnly: true,
    title: "Publication Management",
    className: "settings-tab-panel",
    Component: PublicationManagementPanel
  },
  "default-values": {
    adminOnly: true,
    id: "default-values",
    title: "Default Values",
    className: "settings-tab-panel",
    Component: DefaultValuesManagementPanel
  },
  "custom-help-text": {
    adminOnly: true,
    id: "custom-help-text",
    title: "Custom Help Text",
    className: "settings-tab-panel",
    Component: CustomHelpDocsManagementPanel
  },
  "codon-maps": {
    id: "codon-maps",
    adminOnly: true,
    title: "Codon Maps",
    className: "codonMaps",
    Component: CodonMapLibraryContainer
  },
  "lab-groups": {
    id: "lab-groups",
    adminOnly: true,
    title: "Lab Groups",
    className: "labgroup-tab-panel",
    Component: LabManagementPanel
  },
  "lab-logo": {
    id: "lab-logo",
    adminOnly: true,
    title: "Logo",
    className: "settings-tab-panel",
    Component: ChangeLabLogo
  },
  "tag-management": {
    id: "tag-management",
    title: "Tags",
    className: "settings-tab-panel",
    Component: TagManagement
  },
  "extended-properties": {
    id: "extended-properties",
    isHidden: !extendedPropertyModels.length,
    title: "Extended Properties",
    className: "settings-tab-panel",
    Component: ExtendedPropertiesLibrary
  },
  "project-management": {
    id: "project-management",
    isHidden: !window.frontEndConfig.projectTags,
    title: "Projects",
    className: "settings-tab-panel",
    Component: ProjectManagement
  },
  "default-lab-project": {
    id: "default-lab-project",
    isHidden: !window.frontEndConfig.projectTags,
    adminOnly: true,
    title: "Default Lab Project",
    className: "settings-tab-panel",
    Component: DefaultLabProjectPanel
  },
  "experiment-settings": {
    id: "experiment-settings",
    title: "Experiment Settings",
    className: "settings-tab-panel",
    Component: TestSettings,
    disabled: window.frontEndConfig.disabledToolkits.dataAnalysis
  },
  "bioshop-vendors": {
    id: "bioshop-vendors",
    adminOnly: true,
    title: "Bioshop Vendors",
    className: "settings-tab-panel",
    Component: VendorPanel
  },
  "Annotations:": {
    divider: true
  },
  "enzyme-management": {
    Component: RestrictionEnzymeManagementPanel,
    id: "enzyme-management",
    title: "Restriction Enzymes",
    className: "enzymes"
  },
  "feature-management": {
    Component: AnnotationManagementPanel,
    annotationType: "feature",
    id: "feature-management",
    title: "Features",
    className: "features"
  },
  "part-management": {
    Component: AnnotationManagementPanel,
    annotationType: "part",
    id: "part-management",
    title: "Parts",
    className: "parts"
  },
  "feature-types": {
    Component: FeatureTypeOverrides,
    id: "feature-types",
    title: "Feature Types",
    className: "settings-tab-panel"
  },
  "Unit Settings:": {
    divider: true
  },
  ...unitTypes.reduce((acc, unitType) => {
    acc[unitType] = {
      Component: UnitPanel,
      unitType,
      readOnly: true,
      readOnlyMessage:
        "Teselagen’s units are not editable by users, please contact TeselaGen Support for changes.",
      disabled:
        window.frontEndConfig.disabledToolkits.materialsAndInventoryManagement,
      id: kebabCase(pluralize(unitType)),
      title: modelNameToReadableName(unitType, {
        plural: true,
        upperCase: true
      }),
      className: "settings-tab-panel"
    };
    return acc;
  }, {}),

  genus: {
    Component: GenusPanel,
    id: "genus",
    nonAdminReadOnly: true,
    className: "settings-tab-panel",
    title: "Genera and Species"
  },
  [kebabCase(
    modelNameToReadableName("targetOrganismClass", {
      plural: true
    })
  )]: {
    Component: OrganismClassPanel,
    nonAdminReadOnly: true,
    id: kebabCase(
      modelNameToReadableName("targetOrganismClass", {
        plural: true
      })
    ),
    title: modelNameToReadableName("targetOrganismClass", {
      plural: true,
      upperCase: true
    }),
    className: "settings-tab-panel"
  },

  "selection-methods": {
    Component: SelectionMethodPanel,
    nonAdminReadOnly: true,
    disabled: window.frontEndConfig.disabledToolkits.workflowManagement,
    id: "selection-methods",
    className: "settings-tab-panel",
    title: "Selection Methods"
  },
  "induction-methods": {
    disabled: window.frontEndConfig.disabledToolkits.workflowManagement,
    Component: InductionMethodPanel,
    nonAdminReadOnly: true,
    id: "induction-methods",
    className: "settings-tab-panel",
    title: "Induction Methods"
  },
  "gas-compositions": {
    disabled: window.frontEndConfig.disabledToolkits.workflowManagement,
    Component: GasCompositionPanel,
    nonAdminReadOnly: true,
    id: "gas-compositions",
    className: "settings-tab-panel",
    title: "Gas Compositions"
  },
  "Location Settings:": {
    divider: true
  },
  ...locationTypes.reduce((acc, locationType) => {
    acc[locationType] = {
      disabled:
        window.frontEndConfig.disabledToolkits.freezerEquipmentManagement,
      Component: LocationSettings,
      nonAdminReadOnly: true,
      model: locationType,
      id: kebabCase(pluralize(locationType)),
      title: modelNameToReadableName(locationType, {
        plural: true,
        upperCase: true
      }),
      className: "settings-tab-panel"
    };
    return acc;
  }, {}),
  "Label Settings:": {
    divider: true
  },
  "label-media": {
    Component: LabelMediaPanel,
    nonAdminReadOnly: true,
    id: "label-media",
    title: "Label Media",
    className: "settings-tab-panel"
  },
  "label-format": {
    Component: LabelFormatPanel,
    nonAdminReadOnly: true,
    id: "label-format",
    title: "Label Format",
    className: "settings-tab-panel"
  },
  "Type Settings:": {
    divider: true
  },
  "rna-types": {
    id: "rna-types",
    nonAdminReadOnly: true,
    title: "RNA Types",
    className: "rnaTypes",
    Component: RNATypePanel
  },
  ...typeSettings.reduce((acc, { name: typeSetting, disabled }) => {
    const nameToModel = {
      plateType: "containerArrayType",
      tubeRackType: "containerArrayType"
    };
    acc[typeSetting] = {
      Component: TypeSettingsContainer({
        model: nameToModel[typeSetting] || typeSetting,
        label: typeSetting
      }),
      disabled,
      nonAdminReadOnly: true,
      id: kebabCase(
        modelNameToReadableName(typeSetting, {
          plural: true
        })
      ),
      title: getTypeSettingsTitle(typeSetting),
      className: "settings-tab-panel"
    };
    return acc;
  }, {}),
  "Design Settings:": {
    divider: true
  },
  "design-review": {
    Component: DesignApprovalPanel,
    adminOnly: true,
    id: "design-review",
    title: "Design Review"
  },
  "minimum-lengths": {
    Component: AppConfigurationPanel,
    id: "minimum-lengths",
    adminOnly: true,
    title: "Minimum Lengths",
    className: "settings-tab-panel"
  },
  "assembly-parameter-presets": {
    Component: AssemblyParameterPresetsPanel,
    id: "assembly-parameter-presets",
    title: "Assembly Parameter Presets",
    className: "settings-tab-panel"
  },
  "custom-icons": {
    Component: CustomIconsPanel,
    id: "custom-icons",
    title: "Custom Design Icons",
    className: "settings-tab-panel"
  },
  "Discover Settings:": {
    divider: true
  },
  "predictive-models": {
    disabled: window.frontEndConfig.disabledToolkits.discover,
    Component: AdvancedFeatures,
    id: "predictive-models",
    title: "Predictive Models",
    className: "settings-tab-panel"
  }
};

forEach(settingsTabsInfo, (value, key) => {
  value.key = key;
  if (!value.title) {
    value.title = key;
  }
  if (value.divider) {
    value.divider = key;
  }
});

export default settingsTabsInfo;
