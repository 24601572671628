/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { aaSequenceJSONtoGraphQLInput } from "./aaSequenceJSONtoGraphQLInput";
import { sequenceJSONtoGraphQLInput } from "./sequenceJSONtoGraphQLInput";

export const oveSeqDataToGraphQL = (seqData, opts) => {
  if (!seqData) return;
  const isProtein = seqData.isProtein;
  const jsonToGraphql = isProtein
    ? aaSequenceJSONtoGraphQLInput
    : sequenceJSONtoGraphQLInput;
  // aaSequenceJSONtoGraphQLInput() tnw - here for ease of finding
  // sequenceJSONtoGraphQLInput() tnw - here for ease of finding
  return jsonToGraphql(seqData, opts);
};
