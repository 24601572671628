/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import Helmet from "react-helmet";
import cn from "classnames";
import { compose } from "redux";
import { showConfirmationDialog } from "@teselagen/ui";

import withQuery from "../../../src-shared/withQuery";

import { Button, Intent } from "@blueprintjs/core";
import iconFragment from "../../../../tg-iso-design/graphql/fragments/iconFragment";
import { showDialog } from "../../../src-shared/GlobalDialog";
import { safeDelete } from "../../../src-shared/apolloMethods";

class CustomIconsPanel extends React.Component {
  state = {
    selectedIcons: {}
  };

  updateIcons = () => {
    const { refetchIcons } = this.props;
    refetchIcons();
  };

  toggleIconSelection = icon => {
    const newSelected = { ...this.state.selectedIcons };
    if (newSelected[icon.id]) {
      delete newSelected[icon.id];
    } else {
      newSelected[icon.id] = true;
    }
    this.setState({ selectedIcons: newSelected });
  };

  isSelected = icon => this.state.selectedIcons[icon.id];

  deleteSelected = async selectedCount => {
    const confirm = await showConfirmationDialog({
      text:
        selectedCount > 1
          ? `Are you sure you want to delete these custom icons?
             You cannot undo this action.`
          : `Are you sure you want to delete this custom icon?
             You cannot undo this action.`,
      intent: Intent.DANGER,
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
      canEscapeKeyCancel: true
    });
    if (confirm) {
      const iconIds = Object.keys(this.state.selectedIcons);
      safeDelete("icon", iconIds).then(async () => {
        const paths = this.props.icons
          .filter(
            icon =>
              this.state.selectedIcons[icon.id] &&
              icon.path.startsWith("uploadCustomIcon/")
          )
          .map(icon => icon.path);

        if (paths.length) {
          // TODO refactor this. We shouldn't have http calls directly inside UI
          // components. Maybe we should refactor these throughout the app to use
          // an API service
          const deletePromises = paths.map(path =>
            window.serverApi.request({
              method: "DELETE",
              url: path,
              withCredentials: true
            })
          );

          try {
            await Promise.all(deletePromises);
          } catch (e) {
            console.error(e);
            window.toastr.warning("Could not delete some image files");
          }
        }

        this.setState({ selectedIcons: {} });
        this.updateIcons();
        window.toastr.success(
          selectedCount > 1 ? "Custom icons deleted" : "Custom icon deleted"
        );
      });
    }
  };

  render() {
    const { icons = [] } = this.props;
    const selectedCount = Object.keys(this.state.selectedIcons).length;

    return (
      <div className="tg-card xtra-pad">
        <Helmet title="Custom Design Icons" />
        <h3>Custom Design Icons</h3>
        <div className="custom-icons-grid">
          {icons.map(icon => (
            <div
              className={cn("design-icon-cell", {
                "selected-icon": this.isSelected(icon)
              })}
              key={icon.name}
              onClick={() => this.toggleIconSelection(icon)}
            >
              {icon.path.startsWith("uploadCustomIcon") ? (
                <img src={icon.path} className="non-svg-icon" alt="" />
              ) : (
                <svg
                  viewBox={`-10 ${icon.center ? 10 : 25} 70 60`}
                  height={80}
                  width={80}
                >
                  <path
                    stroke="black"
                    strokeWidth="3"
                    fill="none"
                    d={icon.path}
                  />
                </svg>
              )}
              <span className="design-icon-name">{icon.name}</span>
            </div>
          ))}
        </div>
        <div className="custom-icons-bottom">
          <span className="custom-icon-count">
            {icons.length === 1
              ? "1 custom icon"
              : `${icons.length} custom icons`}
          </span>
          <span>
            <Button
              minimal
              className="icon-upload-btn"
              disabled={!selectedCount}
              icon="trash"
              intent={Intent.DANGER}
              onClick={() => this.deleteSelected(selectedCount)}
            >
              Delete {selectedCount || ""} Selected Icon
              {selectedCount === 1 ? "" : "s"}
            </Button>

            <Button
              minimal
              className="icon-upload-btn"
              icon="upload"
              intent={Intent.SUCCESS}
              onClick={() => {
                showDialog({
                  modalType: "UPLOAD_ICON",
                  modalProps: {
                    onIconCreate: this.updateIcons
                  }
                });
              }}
            >
              Upload New Icon
            </Button>
          </span>
        </div>
      </div>
    );
  }
}

export default compose(
  withQuery(iconFragment, {
    isPlural: true,
    variables: {
      filter: {
        isSbol: false
      }
    }
  })
)(CustomIconsPanel);
