/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";

import { getAuthToken } from "../auth/utils";

export function NodeRedTabLink({ tabId, style, text }) {
  if (!tabId) return <a>Node Red Tab Not Found..</a>;
  return (
    <a
      style={{ ...style }}
      {...(!window.Cypress && {
        target: "_blank",
        rel: "noopener noreferrer"
      })}
      href={`${
        window.frontEndConfig.nodeRedEditorUrl
      }/?access_token=${getAuthToken()}&#flow/${tabId}`}
    >
      {text || "View in Node Red"}
    </a>
  );
}
