/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { combineReducers } from "redux";
import { tg_modalState } from "@teselagen/ui";
import { reducer as formReducer } from "redux-form";
import { theme } from "./theme";
import { segment } from "./segment";
import notificationManager from "./notification";

const ui = combineReducers({
  theme,
  notificationManager
});

const platformReducer = combineReducers({ ui, segment });

export default {
  form: formReducer,
  platform: platformReducer,
  tg_modalState
};
