/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { handleActions } from "redux-actions";
import { keyBy, clone } from "lodash";
import humanizeDuration from "humanize-duration";

type Notification = {
  id: string;
  addedTimestamp: number;
  endTimestamp: number;
};

type SetNotificationType = {
  notifications: Notification[];
  forceOpen: boolean;
};

type SetTickTimersType = {
  id: string;
  timestamp: number;
  addedTimestamp: number;
  endTimestamp: number;
};

type PayloadType = boolean | number | SetNotificationType | SetTickTimersType;

type State = {
  notifications: Notification[];
  date: { [id: string]: string };
  unreadCount: number;
  activeCount: number;
  isNotificationManagerOpen: boolean;
  notificationsToUpdate?: Notification[];
};

const initialState: State = {
  notifications: [],
  date: {},
  unreadCount: 1,
  activeCount: 2,
  isNotificationManagerOpen: false
};

/**
 * Returns a human readable time
 * @param {*} dateDiff
 */
const getHumanizedTime = (dateDiff: number) => {
  let units: string[] = [];
  const options = {
    round: true
  };
  // dateDiff over 1h & less than 1d
  if (dateDiff >= 3600000 && dateDiff <= 86400000) {
    units = ["h", "m"]; // this shows only hours & minutes or minutes & seconds
  }
  // dateDiff over 1d
  if (dateDiff >= 86400000) {
    units = ["d", "h"]; // this shows only days & hours
  }

  if (units.length > 0) {
    Object.assign(options, { units });
  }

  return humanizeDuration(dateDiff, options);
};

const reducer = handleActions<State, PayloadType>(
  {
    NOTIFICATIONS_UPDATE_UNREAD_COUNT: (state, { payload }) => {
      return {
        ...state,
        unreadCount: payload as number
      };
    },
    NOTIFICATIONS_UPDATE_ACTIVE_COUNT: (state, { payload }) => {
      return {
        ...state,
        activeCount: payload as number
      };
    },
    NOTIFICATIONS_TOGGLE: (state, { payload }) => {
      return {
        ...state,
        isNotificationManagerOpen: payload as boolean
      };
    },
    NOTIFICATIONS_OPEN: state => {
      return {
        ...state,
        isNotificationManagerOpen: true
      };
    },
    NOTIFICATIONS_CLOSE: state => {
      return {
        ...state,
        isNotificationManagerOpen: false
      };
    },

    NOTIFICATIONS_RESET_TOGGLE: state => {
      return {
        ...state,
        isNotificationManagerOpen: false
      };
    },
    NOTIFICATIONS_CLEAR: state => {
      return {
        ...state,
        notifications: []
      };
    },
    NOTIFICATIONS_ADD_NOTIFICATION: (state, { payload }) => {
      const { notifications, forceOpen } = payload as SetNotificationType;
      return {
        ...state,
        notifications: [...state.notifications, ...notifications].sort(
          (a, b) => b.addedTimestamp - a.addedTimestamp
        ),
        isNotificationManagerOpen:
          !!forceOpen || state.isNotificationManagerOpen
      };
    },
    NOTIFICATIONS_SET_ALL_TICKS: (state, { payload }) => {
      const { notifications } = payload as SetNotificationType;
      const { date } = state;
      const newTickTimerNotifications = clone(date);

      notifications.forEach(notification => {
        const { id, addedTimestamp, endTimestamp } = notification;
        const lastDate = endTimestamp
          ? new Date(endTimestamp)
          : new Date(addedTimestamp);
        const dateDiff =
          lastDate.getTime() - new Date(addedTimestamp).getTime();

        const elapsedTimeHumanized = getHumanizedTime(dateDiff);

        // when notification is still running is preferable to show blank in first tick instead of "0 seconds"
        newTickTimerNotifications[id] = endTimestamp
          ? elapsedTimeHumanized
          : "";
      });
      return {
        ...state,
        date: newTickTimerNotifications
      };
    },

    NOTIFICATIONS_TICK_TIMERS: (state, { payload }) => {
      const { id, timestamp, addedTimestamp, endTimestamp } =
        payload as SetTickTimersType;
      const { date } = state;
      const newTickTimerNotifications = clone(date);

      const lastDate = endTimestamp
        ? new Date(endTimestamp)
        : new Date(timestamp);
      const dateDiff = lastDate.getTime() - new Date(addedTimestamp).getTime();

      const elapsedTimeHumanized = getHumanizedTime(dateDiff);

      newTickTimerNotifications[id] = elapsedTimeHumanized;

      return {
        ...state,
        date: newTickTimerNotifications
      };
    },

    NOTIFICATIONS_UPDATE_NOTIFICATION: (state, { payload }) => {
      const { notifications, forceOpen } = payload as SetNotificationType;
      const { notificationsToUpdate } = state;
      const idToUpdate = keyBy(notificationsToUpdate, "id");

      const newNotifications = [...notifications];
      for (let i = 0, ii = notifications.length; i < ii; i++) {
        const update = idToUpdate[notifications[i].id];
        if (update) {
          newNotifications[i] = { ...notifications[i], ...update };
        }
      }
      return {
        ...state,
        notifications: newNotifications.sort(
          (a, b) => b.addedTimestamp - a.addedTimestamp
        ),
        isNotificationManagerOpen:
          !!forceOpen || state.isNotificationManagerOpen
      };
    }
  },
  initialState
);

export default reducer;
