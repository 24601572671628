/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { mapKeys } from "lodash";
import { computeSequenceHash } from "./computeSequenceHash";

export const addHashPropToSeq = seq => {
  if (!seq.hash) {
    let rawSeq;
    if (typeof seq.fullSequence === "string") {
      rawSeq = seq.fullSequence;
    } else if (typeof seq.sequence === "string") {
      rawSeq = seq.sequence;
    } else {
      const orderedFragments = mapKeys(
        seq.sequenceFragments,
        ({ index }) => index
      );
      rawSeq = "";
      for (const index in orderedFragments) {
        rawSeq += orderedFragments[index].fragment;
      }
    }
    seq.hash = computeSequenceHash(rawSeq, seq.sequenceTypeCode);
  }
};
