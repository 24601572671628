/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import Helmet from "react-helmet";
import { compose } from "redux";
import { Button, Divider, Icon, Tooltip } from "@blueprintjs/core";
import { keyBy } from "lodash";
import { showConfirmationDialog } from "@teselagen/ui";
import withQuery from "../../../src-shared/withQuery";
import Tag from "../../../src-shared/Tag";

import "./style.css";
import { safeDelete } from "../../../src-shared/apolloMethods";
import { showDialog } from "../../../src-shared/GlobalDialog";
import AdminCallout from "../../../src-shared/SharedAppSettings/AdminCallout";

class DesignApprovalPanel extends React.Component {
  showAddOrEditReviewStepDialog = ({ otherStages, stageToEdit }) => {
    showDialog({
      modalType: "AddOrEditReviewStepDialog",
      modalProps: {
        refetch: this.props.refetchStages,
        intitialStageApprovers: stageToEdit && stageToEdit.stageApprovers,
        initialValues: {
          ...stageToEdit,
          stageApprovers:
            stageToEdit && stageToEdit.stageApprovers.map(({ user }) => user),
          prerequisiteStagesstages:
            stageToEdit &&
            stageToEdit.prerequisiteStagesstages.map(
              ({ prereqStageId }) => prereqStageId
            )
        },
        isEdit: !!stageToEdit,
        stageToEdit,
        otherStages
      }
    });
  };
  render() {
    let {
      stages
      // stages = [
      //   {
      //     id: 3,
      //     name: "Team Review",
      //     color: "red",
      //     prerequisiteStagesstages: [],
      //     howManyApprovers: 2,
      //     stageApprovers: [
      //       { name: "Colin" },
      //       { name: "Tiff" },
      //       { name: "Hector" }
      //     ],
      //     description:
      //       "All designs must first have at least 2 team members sign off on them"
      //   },
      //   {
      //     id: 9,
      //     name: "PI Review",
      //     color: "blue",
      //     howManyApprovers: 1,
      //     prerequisiteStagesstages: [3], //pointing to the id of the stage before
      //     stageApprovers: [
      //       { name: "Adam" },
      //       { name: "Nathan" },
      //       { name: "Mike" }
      //     ],
      //     description: "All designs must have the PI approve of it"
      //   }
      // ]
    } = this.props;
    stages = stages || [];
    const stagesById = keyBy(stages, "id");
    return (
      <div className="tg-card">
        <Helmet title="Design Approval Settings" />
        <AdminCallout>
          As an admin you can add or edit design review steps.
        </AdminCallout>
        <h3>Design Review</h3>
        {!stages.length && (
          <h5>
            There aren't any Review Steps set up yet. Hit "Add Review Step" to
            add one!
          </h5>
        )}
        {stages.map((stage, i) => {
          const {
            name,
            description,
            prerequisiteStagesstages = [],
            howManyApprovers,
            color,
            stageApprovers
          } = stage;
          return (
            <div key={i}>
              {i !== 0 && (
                <React.Fragment>
                  <br></br>
                  <Divider></Divider>
                  <br></br>
                </React.Fragment>
              )}
              <div>
                <div
                  style={{
                    margin: "0px 30px",
                    display: "flex",
                    justifyContent: "space-between"
                  }}
                >
                  <div>
                    <h4>
                      <Tag {...{ name, color }}></Tag>
                    </h4>
                    <div style={{ color: "darkgrey", fontSize: 13 }}>
                      {description}
                    </div>
                    <br></br>
                    {!!prerequisiteStagesstages.length && (
                      <h6>
                        {" "}
                        {prerequisiteStagesstages.map(
                          ({ prereqStageId }, i) => {
                            const { name, color } = stagesById[prereqStageId];
                            return (
                              <React.Fragment key={i}>
                                <Tag {...{ name, color }}></Tag>
                                {i !== 0 && "&"}
                              </React.Fragment>
                            );
                          }
                        )}
                        &nbsp; Must Be Finished First
                      </h6>
                    )}
                    <h6>
                      {" "}
                      <Tooltip
                        children={
                          <React.Fragment>
                            <Icon icon="user"></Icon> &nbsp;{howManyApprovers}
                          </React.Fragment>
                        }
                        content="Number of Reviewers Required"
                      ></Tooltip>{" "}
                    </h6>
                    <h6>
                      Possible Reviewers: &nbsp;&nbsp;
                      {stageApprovers.map(({ user }, i) => {
                        return (
                          <React.Fragment key={i}>
                            <Tag
                              {...{
                                name: user.username,
                                color: "lightgrey",
                                style: {
                                  display: "inline-block",
                                  margin: "3px 0px"
                                }
                              }}
                            ></Tag>{" "}
                            &nbsp;&nbsp;
                          </React.Fragment>
                        );
                      })}
                    </h6>
                  </div>
                  <div style={{ display: "flex", alignItems: "baseline" }}>
                    <Button
                      minimal
                      onClick={() => {
                        this.showAddOrEditReviewStepDialog({
                          stageToEdit: stage,
                          otherStages: stages.filter(s => s !== stage)
                        });
                      }}
                      intent="success"
                      icon="edit"
                    ></Button>{" "}
                    &nbsp;
                    <Button
                      minimal
                      onClick={async () => {
                        const confirm = await showConfirmationDialog({
                          text: `Are you sure you want to delete this review step?
                          You cannot undo this action.`,
                          intent: "danger",
                          confirmButtonText: "Delete",
                          cancelButtonText: "Cancel",
                          canEscapeKeyCancel: true
                        });
                        if (confirm) {
                          //delete review stage
                          await safeDelete("stage", stage.id);
                          await this.props.refetchStages();
                          window.toastr.success(
                            "Successfully Deleted Review Step"
                          );
                        }
                      }}
                      intent="danger"
                      icon="delete"
                    ></Button>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        <br></br>
        <br></br>
        <br></br>

        <Button
          onClick={() => {
            this.showAddOrEditReviewStepDialog({ otherStages: stages });
          }}
          intent="primary"
          icon="plus"
        >
          Add Review Step
        </Button>
      </div>
    );
  }
}

export default compose(
  withQuery(
    [
      "stage",
      `id name description howManyApprovers color
  stageApprovers {
    id
    user{
      id
      username
    }
  }
  prerequisiteStagesstages{
    id
    prereqStageId,
    stageId
    prereqStage{
      id
      name
    }
  }
  `
    ],
    {
      isPlural: true
    }
  )
)(DesignApprovalPanel);
