/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useEffect, useState } from "react";
import { compose } from "recompose";
import { tgFormValues } from "@teselagen/ui";
import { reduxForm, FieldArray } from "redux-form";
import { startCase, camelCase, omit, map, isString } from "lodash";
import Linkify from "react-linkify";
import { showConfirmationDialog, wrapDialog } from "@teselagen/ui";
import {
  DialogFooter,
  InputField,
  SuggestField,
  SwitchField,
  SelectField,
  InfoHelper
} from "@teselagen/ui";

import JsonView from "react-json-view";

import { safeDelete, safeUpsert, safeQuery } from "../apolloMethods";
import {
  Button,
  Classes,
  Callout,
  Tabs,
  Tab,
  Card,
  InputGroup,
  Intent,
  Code
} from "@blueprintjs/core";
import AsyncValidateFieldSpinner from "../AsyncValidateFieldSpinner";
import { flatMap } from "lodash";
import GenericSelect from "../GenericSelect";
import { urlResourceTypes } from "../../../tg-iso-shared/src/utils/urlResourceTypes";
import { validateUrl, validateUrlAllowEmpty } from "../utils/validateUrl";
import externalRecordTypeFragment from "../fragments/externalRecordTypeFragment";
import gql from "graphql-tag";
import { transformSubtypes } from "../../../tg-iso-shared/src/utils/integrationTypeSettingsMap/transformSubtypes";
import integrationTypeSettingsMap from "../../../tg-iso-shared/src/utils/integrationTypeSettingsMap";
import {
  getFlows,
  getHttpInsByUrlAndTabsAndSubflowsById
} from "../../../tg-iso-shared/src/utils/nodeRedUtils";
import { createExampleNodeRedFlowForIntegration } from "../../../tg-iso-shared/src/utils/nodeRedUtils/createExampleNodeRedFlowForIntegration";
import { NodeRedTabLink } from "../utils/nodeRedUtils";
import { jumpToErrorOnFail } from "../utils/jumpToErrorOnFail";

export const shortIntegrationFragment = gql`
  fragment shortIntegrationFragment on integration {
    id
    name
    integrationTypeCode
    subtype
    integrationEndpoints {
      id
      url
    }
  }
`;

export const getIntegrationType = code =>
  startCase(camelCase(code)).replace("Dna", "DNA").replace("Api", "API");

function EditIntegrationDialog({
  handleSubmit,
  submitting,
  hideModal,
  integrationTypeCode,
  initialValues = {},
  originalIntegration,
  asyncValidating,
  refetch,
  nodeRedConnected,
  change,
  integrationEndpoints,
  uniqueIdentifier,
  subtype
}) {
  const integrationTypeSettings =
    integrationTypeSettingsMap[integrationTypeCode] || {};
  const isUpdate = initialValues.id;
  const [possibleNodeRedUrls, setPossibleUrls] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await window.serverApi.request(
          `${window.frontEndConfig.nodeRedEditorUrl}/flows`
        );
        const httpInOptions = [];
        res.data.forEach(n => {
          if (n.type === "http in") {
            const val =
              "node-red://" + (n.url.startsWith("/") ? n.url.substr(1) : n.url);
            httpInOptions.push({
              __info: n,
              label: val,
              value: val
            });
          }
        });
        setPossibleUrls(httpInOptions);
      } catch (e) {
        console.error(e);
      }
    };
    fetchData();
  }, []);
  const onSubmit = async ({ nodeRedConnected, ...values }) => {
    try {
      let continueWithoutSettingUpNodeRedFlow;
      if (!isUpdate && values.uniqueIdentifier && nodeRedConnected) {
        const res = await getFlows();
        const { httpInsByUrl, tabsById } =
          getHttpInsByUrlAndTabsAndSubflowsById(res.data);
        const conflictTabs = {};
        map(httpInsByUrl, (d, url) => {
          if (url && url.replace("/", "").startsWith(values.uniqueIdentifier)) {
            if (tabsById[d.z]) {
              conflictTabs[d.z] = { ...tabsById[d.z], url };
            }
          }
        });
        if (map(conflictTabs).length) {
          const confirm = await showConfirmationDialog({
            text: (
              <div>
                <Callout intent="danger">
                  Warning! The Name "{values.name}" you've chosen has already
                  been used to set up a node-red flow:
                </Callout>
                <br></br>
                <br></br>
                {map(conflictTabs, ({ label, id, url }) => {
                  return (
                    <div key={id}>
                      <NodeRedTabLink
                        text={`Tab: ${label} -- Endpoint: ${url}`}
                        tabId={id}
                      ></NodeRedTabLink>
                    </div>
                  );
                })}
                <br></br>
                <br></br>
                <Callout intent="primary">
                  We recommend going back and changing the Name. If you know
                  what you're doing and choose to proceed, we will NOT set up a
                  node-red flow for you.
                </Callout>
              </div>
            ),
            intent: Intent.DANGER,
            confirmButtonText: "Proceed with Existing Name",
            cancelButtonText: "Go Back",
            cancelButtonIntent: "primary",
            canEscapeKeyCancel: true
          });
          if (confirm) {
            continueWithoutSettingUpNodeRedFlow = true;
          } else {
            return;
          }
        }
      }

      (values.integrationEndpoints || []).forEach(endpoint => {
        if (endpoint.externalRecordType) {
          endpoint.externalRecordTypeId = endpoint.externalRecordType.id;
          delete endpoint.externalRecordType;
        }
      });
      const filteredExampleEndpoints = {};

      const integrationEndpoints = (values.integrationEndpoints || [])
        .filter(endpoint => {
          // need to filter out hidden ones again in case the user changes subtype after filling out form
          const endpointConfig =
            integrationTypeSettings.endpoints[endpoint.endpointTypeCode];

          if (
            endpoint.includeEndpoint === false &&
            endpointConfig.isOptional &&
            nodeRedConnected
          ) {
            return false;
          }
          const hidden =
            endpointConfig.hiddenOnSubtypes &&
            endpointConfig.hiddenOnSubtypes.includes(subtype);

          if (hidden) return false;
          filteredExampleEndpoints[endpoint.endpointTypeCode] = endpointConfig;
          return true;
        })
        .map(endpoint => {
          delete endpoint.includeEndpoint;
          if (values.uniqueIdentifier && nodeRedConnected) {
            endpoint.url = getNodeRedEndpointValue({
              uniqueIdentifier,
              endpointTypeCode: endpoint.endpointTypeCode
            });
          }
          return endpoint;
        });

      if (isUpdate) {
        const integrationId = values.id;
        await safeDelete(
          "integrationHeader",
          originalIntegration.integrationHeaders.map(h => h.id)
        );
        await safeUpsert(
          "integrationHeader",
          (values.integrationHeaders || []).map(h => ({
            ...h,
            integrationId
          }))
        );

        await safeDelete(
          "integrationEndpoint",
          originalIntegration.integrationEndpoints.map(endpoint => endpoint.id)
        );

        await safeUpsert(
          "integrationEndpoint",
          integrationEndpoints.map(endpoint => {
            return { ...endpoint, integrationId };
          })
        );
        await safeUpsert(shortIntegrationFragment, {
          id: integrationId,
          name: values.name,
          subtype: values.subtype,

          ...(nodeRedConnected && {
            uniqueIdentifier: values.uniqueIdentifier
          }),
          includeUserIdHeader: values.includeUserIdHeader,
          includeUsernameHeader: values.includeUsernameHeader,
          includeLabIdHeader: values.includeLabIdHeader,
          includeProjectIdHeader: values.includeProjectIdHeader,
          includeAuthTokenHeader: values.includeAuthTokenHeader
        });
        window.toastr.success("Integration Updated Successfully");
      } else {
        //creating a new integration
        await safeUpsert("integration", {
          ...values,
          uniqueIdentifier: nodeRedConnected
            ? values.uniqueIdentifier
            : undefined,
          integrationEndpoints
        });
        if (nodeRedConnected && !continueWithoutSettingUpNodeRedFlow) {
          const nodeRedFile = createExampleNodeRedFlowForIntegration({
            ...integrationTypeSettings,
            endpoints: filteredExampleEndpoints,
            subtype: values.subtype,
            name: values.name,
            uniqueIdentifier: values.uniqueIdentifier
          });
          const res = await window.serverApi.request(
            `${window.frontEndConfig.nodeRedEditorUrl}/flow`,
            {
              method: "POST",
              data: {
                label: `${values.uniqueIdentifier} - ${values.name}`,
                nodes: nodeRedFile.slice(1)
              }
            }
          );
          if (res.data && res.data.id) {
            showConfirmationDialog({
              text: (
                <div>
                  <h5>Successfully Created a New Integration</h5>
                  We've set up a boilerplate{" "}
                  <NodeRedTabLink
                    text="node-red integration"
                    tabId={res.data.id}
                  ></NodeRedTabLink>{" "}
                  for you!
                  <br></br>
                  <br></br>
                  <i>
                    Please note, the node-red flow will need some work before it
                    is functional and useful for your use case.
                  </i>
                </div>
              ),
              intent: Intent.SUCCESS,
              confirmButtonText: "Ok",
              noCancelButton: true,
              canEscapeKeyCancel: true
            });
          } else {
            showConfirmationDialog({
              text: (
                <div>
                  <h5>Error Creating Boilerplate Node-Red Integration</h5>
                  Please contact a developer near you.
                </div>
              ),
              intent: Intent.SUCCESS,
              confirmButtonText: "Ok",
              cancelButtonText: "Cancel",
              canEscapeKeyCancel: true
            });
          }
        }

        refetch && (await refetch());
        window.toastr.success("Integration Created Successfully");
      }
      hideModal();
    } catch (error) {
      console.error("error:", error);
      window.toastr.error(
        `Error ${isUpdate ? "updating" : "creating"} integration`
      );
    }
  };

  return (
    <React.Fragment>
      <div className={Classes.DIALOG_BODY}>
        <h4>
          <InputField
            label="Name"
            name="name"
            onChange={e => {
              if (isUpdate) return;
              const v = e.target.value
                .toLowerCase()
                .replaceAll(" ", "_")
                .replace(/[^a-zA-Z0-9-_]/g, "");
              change("uniqueIdentifier", `${v}`);
            }}
            placeholder="Enter a name..."
            rightElement={
              <AsyncValidateFieldSpinner validating={asyncValidating} />
            }
            isRequired
          />
        </h4>
        <SwitchField
          beforeOnChange={async val => {
            if (!val) {
              const keepGoing = await showConfirmationDialog({
                text: "This is not recommended unless you are connecting to a pre-existing flow.  Proceed anyway?",
                intent: "danger" //applied to the right most confirm button
              });
              return { stopEarly: !keepGoing };
            }
            return;
          }}
          defaultValue={true}
          {...(isUpdate && { disabled: true })}
          {...(isUpdate && !uniqueIdentifier && { defaultValue: false })}
          style={{ marginTop: 0, marginBottom: 0 }}
          label="Auto-Generate Linked Node Red Flow *Recommended*"
          tooltipInfo="Creates a simple example node red integration flow to help you glue your services together. You might need to disable this option if you're trying to connect to a pre-existing flow in node-red or you have your own integration server already set up."
          name="nodeRedConnected"
        ></SwitchField>
        <h4>
          {integrationTypeSettings.subtypes && (
            <SelectField
              label="Subtype"
              isRequired
              name="subtype"
              defaultValue={integrationTypeSettings.defaultSubtype}
              options={integrationTypeSettings.subtypes}
            ></SelectField>
          )}
        </h4>

        <FieldArray
          name="integrationHeaders"
          title="Global Headers"
          component={HeaderSettings}
        />
        <Callout intent="primary">
          The following global headers are automatically added unless otherwise
          specified:{" "}
          <div
            style={{
              paddingTop: 5,
              display: "grid",
              gridTemplateColumns: "1fr 1fr"
            }}
          >
            <SwitchField
              {...(!isUpdate && { defaultValue: true })}
              style={{ marginTop: 0, marginBottom: 0 }}
              label="x-tg-user-id"
              name="includeUserIdHeader"
            ></SwitchField>
            <SwitchField
              {...(!isUpdate && { defaultValue: true })}
              style={{ marginTop: 0, marginBottom: 0 }}
              label="x-tg-user-name"
              name="includeUsernameHeader"
            ></SwitchField>
            <SwitchField
              {...(!isUpdate && { defaultValue: true })}
              style={{ marginTop: 0, marginBottom: 0 }}
              label="x-tg-lab-id"
              name="includeLabIdHeader"
            ></SwitchField>
            <SwitchField
              {...(!isUpdate && { defaultValue: true })}
              style={{ marginTop: 0, marginBottom: 0 }}
              label="x-tg-project-id"
              name="includeProjectIdHeader"
            ></SwitchField>
            <SwitchField
              {...(!isUpdate && { defaultValue: true })}
              style={{ marginTop: 0, marginBottom: 0 }}
              label="x-tg-auth-token"
              name="includeAuthTokenHeader"
            ></SwitchField>
          </div>
        </Callout>
        <FieldArray
          name="integrationEndpoints"
          possibleNodeRedUrls={possibleNodeRedUrls}
          uniqueIdentifier={uniqueIdentifier}
          nodeRedConnected={nodeRedConnected}
          integrationEndpoints={integrationEndpoints}
          subtype={subtype}
          change={change}
          isUpdate={isUpdate}
          component={Endpoints}
          settings={integrationTypeSettings.endpoints}
        />
        <DialogFooter
          text={initialValues.id ? "Update" : "Create"}
          hideModal={hideModal}
          onClick={handleSubmit(onSubmit)}
          submitting={submitting}
        />
      </div>
    </React.Fragment>
  );
}

function Endpoints({
  fields,
  uniqueIdentifier,
  nodeRedConnected,
  possibleNodeRedUrls,
  change,
  subtype,
  integrationEndpoints,
  isUpdate,
  settings = {}
}) {
  const fieldValues = fields.getAll();
  return fields.map((endpoint, index) => {
    const currentVals = integrationEndpoints[index];
    const includeEndpoint = currentVals && currentVals.includeEndpoint;

    const endpointValue = fieldValues[index] || {};
    const endpointConfig = settings[endpointValue.endpointTypeCode];
    if (!endpointConfig) return null;
    if (
      endpointConfig.hiddenOnSubtypes &&
      endpointConfig.hiddenOnSubtypes.includes(subtype)
    ) {
      return null;
    }
    return (
      <Card
        key={index}
        style={{
          marginTop: 15,
          marginBottom: 15
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "top"
          }}
        >
          <h4>
            {endpointConfig.name}
            {endpointConfig.isOptional && (
              <span style={{ fontStyle: "italic" }}>&nbsp; (Optional)</span>
            )}
            {endpointConfig.description && (
              <InfoHelper
                isPopover
                style={{ marginLeft: 5, marginTop: -3 }}
                isInline
                content={<Linkify>{endpointConfig.description}</Linkify>}
              ></InfoHelper>
            )}
          </h4>
          {nodeRedConnected && endpointConfig.isOptional && (
            <SwitchField
              defaultValue={isUpdate ? currentVals.url : true}
              onChange={async () => {
                if (
                  nodeRedConnected &&
                  isUpdate &&
                  !currentVals.includeEndpoint
                ) {
                  const confirm = await showConfirmationDialog({
                    text: (
                      <Callout intent="primary">
                        Please note that because we've already set up an
                        existing node-red flow for you, you will need to set up
                        a corresponding node-red endpoint.
                      </Callout>
                    ),
                    confirmButtonText: "Include Endpoint",
                    canEscapeKeyCancel: true
                  });
                  if (!confirm) {
                    change(`${endpoint}.includeEndpoint`, false); //change the includeEndpoint back to false
                  }
                }
              }}
              name={`${endpoint}.includeEndpoint`}
              label="Include endpoint"
            ></SwitchField>
          )}
        </div>
        {includeEndpoint || !endpointConfig.isOptional || !nodeRedConnected ? (
          <React.Fragment>
            <div
              style={{
                fontSize: 20,
                marginBottom: 10,
                paddingTop: 10,
                display: "flex",
                alignItems: "center"
              }}
            >
              URL:
              <div
                style={{ margin: "-10px 10px 0px 10px", flex: 1, fontSize: 14 }}
              >
                {nodeRedConnected ? (
                  <InputGroup
                    value={getNodeRedEndpointValue({
                      uniqueIdentifier,
                      endpointTypeCode: endpointValue.endpointTypeCode
                    })}
                    style={{ marginTop: 10 }}
                    disabled={true}
                  ></InputGroup>
                ) : (
                  <SuggestField
                    validate={
                      endpointConfig.isOptional
                        ? validateUrlAllowEmpty
                        : validateUrl
                    }
                    options={flatMap(possibleNodeRedUrls || [], n =>
                      n.__info.method ===
                      (endpointConfig.method || "").toLowerCase()
                        ? n.value
                        : []
                    )}
                    className="tg-no-form-group-margin"
                    name={`${endpoint}.url`}
                  />
                )}
              </div>
              <div style={{ fontSize: 12 }}>({endpointConfig.method})</div>
            </div>
            <FieldArray
              name={`${endpoint}.integrationEndpointHeaders`}
              title="Endpoint Specific Headers"
              component={HeaderSettings}
            />
            {endpointConfig.exampleRequest && (
              <div className="preserve-newline" style={{ marginTop: 12 }}>
                <h6>Example Request</h6>
                {isString(endpointConfig.exampleRequest) ? (
                  <Code
                    style={{
                      wordBreak: "break-all"
                    }}
                  >
                    {endpointConfig.exampleRequest}
                  </Code>
                ) : (
                  displayJsonObject(endpointConfig.exampleRequest, subtype)
                )}
              </div>
            )}
            {endpointConfig.exampleResponse && (
              <div className="preserve-newline" style={{ marginTop: 12 }}>
                <h6>Example Response</h6>
                {displayJsonObject(endpointConfig.exampleResponse, subtype)}
              </div>
            )}

            {false && endpointConfig.externalRecordType && (
              <div>
                <br></br>
                {/* <h6></h6> */}
                <GenericSelect
                  tooltipInfo={
                    <div style={{ width: 300 }}>
                      The sequence IDs returned in the response will be mapped
                      into the URL template and saved as external urls on the
                      imported sequences
                    </div>
                  }
                  label={
                    <div>
                      Select URL template.{" "}
                      <span style={{ fontSize: 11 }}>
                        If you haven't set any up yet, you can do that here:{" "}
                        <a
                          href={`${window.location.origin}/client/settings/url-management`}
                        >
                          url-management
                        </a>
                      </span>
                    </div>
                  }
                  name={`${endpoint}.externalRecordType`}
                  // isMultiSelect: true,
                  asReactSelect
                  // label: "Location",
                  schema={[
                    {
                      path: "externalSourceSystem.name",
                      render: (a, val) => {
                        return `${val.externalSourceSystem.url}${
                          val.identifierTypeCode === "FULL_URL"
                            ? ""
                            : val.urlTemplate
                        }`;
                      }
                    }
                  ]}
                  tableParamOptions={{
                    additionalFilter: {
                      name: urlResourceTypes.DNA_SEQUENCE.value
                    }
                  }}
                  fragment={externalRecordTypeFragment}
                />
              </div>
            )}
          </React.Fragment>
        ) : null}
      </Card>
    );
  });
}

function displayJsonObject(jsonObj, subtype) {
  if (!jsonObj) return null;
  if (jsonObj.__tgMultipleExamples__) {
    return (
      <Tabs id="__tgMultipleExamples__">
        {jsonObj.__tgMultipleExamples__.map((example, key) => {
          return (
            <Tab
              id={key}
              key={key}
              title={example.__tgTabTitle__ || "Example " + (key + 1)}
              panel={<div>{getJsonView(omit(example, "__tgTabTitle__"))}</div>}
            />
          );
        })}
      </Tabs>
    );
  }
  return getJsonView(jsonObj);

  function getJsonView(jsonObj) {
    return (
      <JsonView
        src={transformSubtypes(jsonObj, subtype || "DNA_SEQUENCE", true)}
        name={false}
        style={{
          overflow: "auto"
        }}
        shouldCollapse={field => {
          if (field.namespace.length < 2) return true;
          return false;
        }}
        theme="tomorrow"
        enableClipboard
        displayDataTypes={false}
        displayObjectSize={false}
      />
    );
  }
}

function HeaderSettings({ title, fields }) {
  return (
    <section style={{ marginBottom: 5 }}>
      <h4>
        {title}{" "}
        <Button
          // style={{ marginTop: 10 }}
          onClick={() => {
            fields.push({});
          }}
          intent="success"
          icon="add"
          minimal
        >
          Add
        </Button>
      </h4>
      {fields.map(renderEditableHeader)}
    </section>
  );
}

function renderEditableHeader(header, index, fields) {
  return (
    <div key={index} style={{ display: "flex", alignItems: "center" }}>
      <InputField
        tooltipError
        className="tg-no-form-group-margin"
        placeholder="Enter name..."
        isRequired
        name={`${header}.name`}
      />
      <div style={{ margin: "0 5px" }}>:</div>
      <InputField
        tooltipError
        className="tg-no-form-group-margin"
        placeholder="Enter value..."
        isRequired
        name={`${header}.value`}
      />
      {/* <CheckboxField
        style={{ marginLeft: 5 }}
        inlineLabel
        label="Encrypted"
        name={`${header}.isEncrypted`}
        className="tg-no-form-group-margin"
      /> */}
      <Button
        style={{ marginLeft: 5 }}
        intent="danger"
        minimal
        icon="trash"
        onClick={() => fields.remove(index)}
      />
    </div>
  );
}

async function asyncValidate(
  { name },
  dispatch,
  { initialValues = {}, integrationTypeCode }
) {
  if (name !== initialValues.name) {
    const res = await safeQuery(["integration", "id"], {
      variables: {
        pageSize: 1,
        filter: {
          name,
          integrationTypeCode
        }
      }
    });
    if (res.length) {
      const error = { name: "That name is already in use." };
      throw error;
    }
  }
}

export default compose(
  wrapDialog({
    getDialogProps: ({ initialValues = {}, integrationTypeCode }) => {
      return {
        style: {
          width: 600
        },
        title: `${initialValues.id ? "Update" : "Create"} ${getIntegrationType(
          integrationTypeCode
        )} Integration`
      };
    }
  }),
  reduxForm({
    form: "EditIntegrationForm",
    asyncBlurFields: ["name", "uniqueIdentifier"],
    asyncValidate,
    ...jumpToErrorOnFail()
  }),
  tgFormValues(
    "subtype",
    "nodeRedConnected",
    "uniqueIdentifier",
    "integrationEndpoints"
  )
)(EditIntegrationDialog);

// function formatUniqueIdentifier(val) {
//   if (val) {
//     return val.toLowerCase().replace(/[^a-zA-Z0-9-_]/g, "");
//   } else {
//     return "";
//   }
// }

function getNodeRedEndpointValue({ uniqueIdentifier, endpointTypeCode }) {
  return `node-red://${
    uniqueIdentifier || "identifier"
  }/${endpointTypeCode.toLowerCase()}`;
}
