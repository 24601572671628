/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import makeLazy from "./utils/initHelpers/makeLazy";

export default {
  "/sso-redirect": makeLazy(() => import("./auth/utils/SSORedirect")),
  "/login": makeLazy(() => import("./auth/pages/LoginPage")),
  "/logout": makeLazy(() => import("./auth/pages/LogoutPage")),
  "/password-reset/new-password": makeLazy(
    () => import("./auth/pages/ResetPasswordPage")
  ),
  "/password-reset": makeLazy(
    () => import("./auth/pages/ResetPasswordRequestPage")
  ),
  "/register": makeLazy(() => import("./auth/pages/SignUpPage")),
  "/api-docs": makeLazy(() => import("./ApiDocs"))
};
