/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useCallback, useState } from "react";
import { Classes } from "@blueprintjs/core";
import { compose } from "redux";
import { connect } from "react-redux";
import { isDirty } from "redux-form";
import { DialogFooter, wrapDialog } from "@teselagen/ui";

import AddExtendedProperty from "./AddExtendedProperty";
import RecordExtendedPropertiesTable from "./RecordExtendedPropertiesTable";
import EditExtendedProperty from "./EditExtendedProperty";
import withRecordExtendedProperties from "../enhancers/withRecordExtendedProperties";
import { strcmp } from "../utils/generalUtils";
import {
  collectAllExtendedValues,
  transformSingleExtendedValue
} from "../utils/extendedPropertyUtils";
import { safeQuery } from "../apolloMethods";
import { Link } from "react-router-dom";
import { camelCase } from "lodash";
import { mixedValueConstant } from "./utils";
import { withProps } from "recompose";

const UpdateRecordExtendedPropertiesDialog = ({
  extendedCategoryValues = [],
  extendedLinkValues = [],
  extendedMeasurementValues = [],
  extendedValues = [],
  hideModal,
  isAddDirty,
  libraryFragment,
  model,
  recordIds,
  recordsWithExtendedProperties = [],
  refetchExtendedProperties
}) => {
  const [propertyToEdit, setPropertyToEdit] = useState(undefined);

  const getExtendedPropertyIdsOnRecord = useCallback(() => {
    const ids = collectAllExtendedValues({
      extendedValues,
      extendedCategoryValues,
      extendedMeasurementValues
    })
      .concat(extendedLinkValues)
      .map(v => v.extendedProperty.id);

    return ids;
  }, [
    extendedCategoryValues,
    extendedLinkValues,
    extendedMeasurementValues,
    extendedValues
  ]);

  const refetchForLibrary = useCallback(async () => {
    if (libraryFragment && recordIds?.length) {
      await safeQuery(libraryFragment, {
        variables: {
          filter: {
            id: recordIds
          }
        }
      });
    }
  }, [libraryFragment, recordIds]);

  const fullRefetch = useCallback(async () => {
    await refetchExtendedProperties();
    await refetchForLibrary();
  }, [refetchExtendedProperties, refetchForLibrary]);
  /**
   * Render the data table containing all of the extended properties for the
   * record.
   */
  const renderDataTable = useCallback(() => {
    const propToVal = {};
    recordsWithExtendedProperties.forEach(r => {
      const {
        extendedCategoryValues = [],
        extendedValues = [],
        extendedMeasurementValues = [],
        extendedLinkValues = []
      } = r;
      extendedCategoryValues
        .concat(extendedValues, extendedMeasurementValues, extendedLinkValues)
        .forEach(v => {
          const valObj = transformSingleExtendedValue(v);
          const extendedPropertyId = valObj.extendedProperty.id;
          const value = valObj.value;
          if (!propToVal[extendedPropertyId]) {
            propToVal[extendedPropertyId] = valObj;
          } else {
            const current = propToVal[extendedPropertyId];
            propToVal[extendedPropertyId] = {
              ...current,
              otherExtValIds: (current.otherExtValIds || []).concat(valObj._id),
              value: current.value !== value ? mixedValueConstant : value
            };
          }
        });
    });

    const entities = Object.values(propToVal).sort((a, b) =>
      strcmp(a.property, b.property)
    );

    return (
      <RecordExtendedPropertiesTable
        propertyToEdit={propertyToEdit}
        startEdit={record => setPropertyToEdit(record)}
        stopEdit={() => setPropertyToEdit(undefined)}
        entities={entities}
        refetch={fullRefetch}
      />
    );
  }, [fullRefetch, propertyToEdit, recordsWithExtendedProperties]);

  const renderEdit = useCallback(() => {
    let initialValues = propertyToEdit;
    if (propertyToEdit.extendedProperty.extendedTypeCode === "boolean") {
      initialValues = {
        ...initialValues
      };
    }
    if (propertyToEdit.extendedProperty.extendedPropertyClassCode === "LINK") {
      const targetModel = propertyToEdit.extendedProperty.targetModel;

      initialValues = {
        ...initialValues,
        targetEntities: (propertyToEdit[targetModel + "Elvs"] || []).map(v => {
          return v[camelCase("source_" + targetModel)];
        })
      };
    }
    if (initialValues.value === mixedValueConstant) {
      initialValues = {
        ...initialValues,
        targetEntities: [],
        value: ""
      };
    }

    return (
      <EditExtendedProperty
        initialValues={initialValues}
        recordIds={recordIds}
        model={model}
        stopEdit={() => setPropertyToEdit(undefined)}
        refetch={fullRefetch}
      />
    );
  }, [fullRefetch, model, propertyToEdit, recordIds]);

  const renderAdd = useCallback(
    () => (
      <AddExtendedProperty
        model={model}
        recordIds={recordIds}
        extendedPropertyIdsToIgnore={getExtendedPropertyIdsOnRecord()}
        refetch={fullRefetch}
      />
    ),
    [fullRefetch, getExtendedPropertyIdsOnRecord, model, recordIds]
  );

  return (
    <>
      <div className={Classes.DIALOG_BODY}>
        <div style={{ marginBottom: 15, fontSize: 11 }}>
          <Link to="/settings/extended-properties">
            Extended Properties Settings
          </Link>
        </div>
        {propertyToEdit ? renderEdit() : renderAdd()}
        {renderDataTable()}
      </div>
      <DialogFooter
        disabled={!!propertyToEdit || !!isAddDirty}
        noCancel
        text="OK"
        onClick={hideModal}
      />
    </>
  );
};

export default compose(
  wrapDialog({
    title: "Update Extended Properties",
    className: "update-record-extended-property-dialog"
  }),
  withProps(({ recordId }) => {
    if (recordId) {
      return {
        recordId: null,
        recordIds: [recordId]
      };
    }
  }),
  withRecordExtendedProperties,
  connect(state => ({
    isAddDirty: isDirty("AddExtendedProperty")(state)
  }))
)(UpdateRecordExtendedPropertiesDialog);
