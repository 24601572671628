/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { compose } from "redux";
import LibraryInspector from "../../src-shared/AbstractLibrary/LibraryInspector";
import { showDialog } from "../../src-shared/GlobalDialog";
import gql from "graphql-tag";
import libraryEnhancer from "../../src-shared/libraryEnhancer";
import AbstractLibrary from "../../src-shared/AbstractLibrary";

const ViewerComponent = props => {
  const getParts = () => {
    const parts = [];
    for (const part of props.record.partsetParts) {
      parts.push(
        <li
          key={part.part.id}
          style={{
            wordBreak: "break-all",
            marginLeft: "18px",
            textIndent: "-18px"
          }}
        >
          {part.part.name}
        </li>
      );
    }
    return parts;
  };
  return (
    <LibraryInspector
      {...props}
      withDescription
      renderExtraItemsBottom={() => {
        return (
          <div>
            <span>Parts: </span>
            <br />
            <span>{getParts()}</span>
          </div>
        );
      }}
    />
  );
};

const schema = {
  model: "partset",
  fields: [
    { path: "name", displayName: "Name", type: "string" },
    {
      path: "description",
      displayName: "Description",
      type: "string"
    },
    { path: "numParts", displayName: "Size", type: "number" }
  ]
};

class PartSetLibrary extends Component {
  onNewItemClick = () => {
    showDialog({
      modalType: "UPDATE_PARTSET",
      modalProps: {
        refetchPartsets: this.props.refetch
      }
    });
  };

  onDoubleClick = partset => {
    showDialog({
      modalType: "UPDATE_PARTSET",
      modalProps: {
        initialValues: {
          ...partset
        },
        refetchPartsets: this.props.refetch,
        partSetId: partset.id
      }
    });
  };

  render() {
    return (
      <AbstractLibrary
        {...this.props}
        isLibraryTable
        noOpen
        onDoubleClick={this.onDoubleClick}
        ViewerComponent={ViewerComponent}
        onNewItemClick={this.onNewItemClick}
      />
    );
  }
}

const fragment = gql`
  fragment partSetLibraryFragment on partset {
    id
    name
    description
    numParts
    partsetParts {
      id
      part {
        id
        name
        partSource
      }
    }
  }
`;
const PartSetLibraryContainer = compose(
  libraryEnhancer({
    fragment,
    schema,
    withSelectedEntities: true
  })
)(PartSetLibrary);

export default PartSetLibraryContainer;
