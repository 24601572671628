/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { getSequenceOfFeature } from "./getSequenceOfFeature";

export const getSequenceOfPart = (part, seqStr) => {
  return getSequenceOfFeature(
    {
      start: part.start,
      end: part.end,
      strand: part.strand
    },
    seqStr
  );
};
