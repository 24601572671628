/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import getEnvIso from "../../utils/getEnvIso";

export const getMaxSeqLen = () => {
  const maxLen =
    (typeof window !== "undefined" && window.Cypress?.__maxBpImportLength) ||
    getEnvIso("maxBpImportLength") ||
    100000;
  return maxLen;
};
