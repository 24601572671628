/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { FullUserFragment } from "../../graphql/fragments/fullUserFragment.gql.generated";
import store from "../../redux/store";
import { activateSegmentUtils } from "../activateSegmentUtils";

export const identifySegmentUser = (currentUser: FullUserFragment) => {
  try {
    const state = store.getState();
    // activateSegmentUtils = true && active = true => true
    // activateSegmentUtils = true && active = false => false
    // activateSegmentUtils = false => true
    if (
      currentUser &&
      currentUser.id &&
      window.frontEndConfig.segmentAnalyticsKey &&
      ((activateSegmentUtils && state?.platform?.segment?.active) ||
        !activateSegmentUtils)
    ) {
      // set user for segment analytics
      const { id, ...rest } = currentUser;
      window.analytics.identify(id, rest);
    }
  } catch (error) {
    console.error("Error setting user for analytics");
    console.error(error);
  }
};
