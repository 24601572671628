/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { deleteSequenceDataAtRange } from "@teselagen/sequence-utils";
import { isBrowser } from "browser-or-node";
import { sequenceToVeInput } from "../sequenceToVeInput";
import { assign } from "lodash";
import { sequenceJSONtoGraphQLInput } from "./sequenceJSONtoGraphQLInput";

/**
 * This function will recursively delete all assembly_gap features on the sequence
 */
function deleteAssemblyGapFeature(seq) {
  const feature = Object.values(seq.features).find(
    feat => feat.type === "assembly_gap"
  );
  if (feature) {
    seq = deleteSequenceDataAtRange(seq, {
      start: feature.start,
      end: feature.end
    });
    return deleteAssemblyGapFeature(seq);
  } else {
    return seq;
  }
}

export const stripAssemblyGaps = async sequences => {
  // check for features of type assembly_gap
  // if there are features show confirmation to see if they want to strip
  // those features. (deleteSequenceDataAtRange) need to update all other features and parts
  // need to make sure the sequence still exists.
  // by default not in browser do not strip.
  if (isBrowser) {
    // maintain sequenceOrder
    const sequenceIndicesWithAssemblyGapFeatures = [];
    sequences.forEach((s, i) => {
      const hasAssemblyGap =
        s.sequenceFeatures &&
        s.sequenceFeatures.some(f => f.type === "assembly_gap");
      if (hasAssemblyGap) {
        sequenceIndicesWithAssemblyGapFeatures.push(i);
      }
    });

    if (sequenceIndicesWithAssemblyGapFeatures.length) {
      const stripFeatures = await window.showConfirmationDialog({
        text: "One or more assembly gap features were detected in sequence files, would you like to strip them from the sequences?",
        confirmButtonText: "Yes",
        cancelButtonText: "No"
      });
      if (stripFeatures) {
        sequenceIndicesWithAssemblyGapFeatures.forEach(index => {
          const sequence = sequences[index];
          const veStyleSequence = sequenceToVeInput(sequence);
          const cleanedSequence = deleteAssemblyGapFeature(veStyleSequence);
          cleanedSequence.sequenceTypeCode = sequence.sequenceTypeCode;
          // THIS NEEDS TO BE MUTATIVE. we are using this sequence in a map in parseSequenceFiles
          // which must be updated with this new hash and features
          assign(sequence, sequenceJSONtoGraphQLInput(cleanedSequence));
        });
      }
    }
  }
};
