/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";

import { Classes } from "@blueprintjs/core";
import {
  DialogFooter,
  InputField,
  NumericInputField,
  TextareaField
} from "@teselagen/ui";
import { reduxForm } from "redux-form";
import { tgFormValues } from "@teselagen/ui";
import { compose } from "redux";
import { get } from "lodash";

import EnzymeVisual from "./EnzymeVisual";

import "./style.css";
import {
  generalReverseComplement,
  toJsRegexStr,
  validRestrictionEnzyme
} from "../../../../../tg-iso-shared/utils/enzymeUtils";
import { safeUpsert } from "../../../apolloMethods";
import { wrapDialog } from "@teselagen/ui";
import { asyncValidateName, REQUIRED_ERROR } from "../../../utils/formUtils";
import { withProps } from "recompose";
class RestrictionEnzyme extends React.Component {
  onSubmit = async values => {
    try {
      const {
        refetchRestrictionEnzymes,
        initialValues,
        hideModal
      } = this.props;
      const {
        recognitionRegex,
        forwardSnipPosition,
        reverseSnipPosition
      } = values;

      // NOTE: not sure if this is true for all type-ii enzymes.
      // would be nice to add a reference to soemthing explaining where this logic came from
      // so other devs can know if this is always true or is just a guessing logic.
      const isTypeII =
        recognitionRegex.length <= forwardSnipPosition &&
        recognitionRegex.length <= reverseSnipPosition;

      await safeUpsert("restrictionEnzyme", {
        id: get(initialValues, "id"),
        name: values.name,
        recognitionLength: recognitionRegex.length,
        description: values.description,
        sequence: recognitionRegex,
        forwardSnipPosition,
        reverseSnipPosition,
        recognitionRegex: toJsRegexStr(recognitionRegex),
        reverseRecognitionRegex: toJsRegexStr(
          generalReverseComplement(recognitionRegex)
        ),
        enzymeTypeCode: isTypeII ? "TYPE_IIS" : "NOT_TYPE_IIS"
      });

      await refetchRestrictionEnzymes();
      hideModal();
    } catch (e) {
      console.error(e);
      window.toastr.error("Error creating/updating restriction enzyme.");
    }
  };

  render() {
    const {
      hideModal,
      submitting,
      handleSubmit,
      recognitionRegex,
      forwardSnipPosition,
      reverseSnipPosition
    } = this.props;

    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <div className={Classes.DIALOG_BODY}>
          <EnzymeVisual
            {...{
              recognitionRegex,
              forwardSnipPosition,
              reverseSnipPosition
            }}
          />
          <InputField label="Name" name="name" isRequired />
          <TextareaField label="Description" name="description" />
          <InputField
            label="Recognition Regex"
            name="recognitionRegex"
            isRequired
          />
          <NumericInputField
            min={0}
            max={100}
            label="Forward Snip Position"
            name="forwardSnipPosition"
          />
          <NumericInputField
            min={0}
            max={100}
            label="Reverse Snip Position"
            name="reverseSnipPosition"
          />
        </div>
        <DialogFooter hideModal={hideModal} submitting={submitting} />
      </form>
    );
  }
}

const validate = values => {
  const errors = validRestrictionEnzyme(values, { REQUIRED_ERROR });
  return errors;
};

export default compose(
  wrapDialog(props => {
    return {
      title: props.initialValues
        ? "Update Restriction Enzyme"
        : "Create Restriction Enzyme"
    };
  }),
  withProps(() => ({ model: "restrictionEnzyme" })), // needed for async validate
  reduxForm({
    form: "restrictionEnzymeForm", // a unique name for this form
    enableReinitialize: true,
    validate,
    ...asyncValidateName
  }),
  tgFormValues("recognitionRegex", "forwardSnipPosition", "reverseSnipPosition")
)(RestrictionEnzyme);
