/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { useEffect, useState } from "react";

import Helmet from "react-helmet";
import { showConfirmationDialog, Uploader } from "@teselagen/ui";

import { useTgQuery, safeQuery } from "../apolloMethods";
import { Button, Callout } from "@blueprintjs/core";
import { map, groupBy, pick } from "lodash";
import { showDialog } from "../GlobalDialog";
import EditIntegrationDialog, {
  shortIntegrationFragment,
  getIntegrationType
} from "./EditIntegrationDialog";
import integrationFragment from "../fragments/integrationFragment";
import integrationTypeSettingsMap from "../../../tg-iso-shared/src/utils/integrationTypeSettingsMap";
import { download } from "../utils/downloadTest";
import {
  checkIfBundlesHaveBeenInstalled,
  installIntegrationBundle
} from "../../../tg-iso-shared/src/utils/installIntegrationBundle";
import { getRequestHeaderKeys } from "@teselagen/auth-utils";

import { forEach } from "lodash";
import {
  getNodeRedInfoForIntegrationsById,
  getNodeRedInfoForSingleIntegration,
  getNodesAssociatedWithTab
} from "../../../tg-iso-shared/src/utils/nodeRedUtils";
import { NodeRedTabLink } from "../utils/nodeRedUtils";
import { handleIntegrationClick } from "../UserAccountButton/handleIntegrationClick";
import { isAdmin } from "../utils/generalUtils";
import { handleDeleteIntegrationClick } from "./handleDeleteIntegrationClick";
import { handleIntegrationImport } from "./handleIntegrationImport";
import AdminCallout from "../SharedAppSettings/AdminCallout";

function getIntegrationEndpoints(integrationTypeCode, existingEndpoints = []) {
  const integrationTypeSettings =
    integrationTypeSettingsMap[integrationTypeCode] || {};
  const endpoints = [];
  // initialize empty endpoint settings if needed
  if (integrationTypeSettings.endpoints) {
    Object.keys(integrationTypeSettings.endpoints).forEach(endpointTypeCode => {
      const existingEndpoint = existingEndpoints.find(
        e => e.endpointTypeCode === endpointTypeCode
      );
      if (!existingEndpoint) {
        endpoints.push({
          endpointTypeCode,
          integrationEndpointHeaders: []
        });
      } else {
        // pick the editable fields off. If you use the whole endpoint it will error because it will try to upsert objects with typenames
        endpoints.push({
          endpointTypeCode,
          externalRecordType: existingEndpoint.externalRecordType,
          url: existingEndpoint.url,
          integrationEndpointHeaders:
            existingEndpoint.integrationEndpointHeaders.map(h =>
              pick(h, ["name", "value", "isEncrypted"])
            )
        });
      }
    });
  }
  return endpoints;
}

function IntegrationsManagementPanel() {
  const [toInstallIdsMap, setToInstallIdsMap] = useState();
  const [integrationToNodeRedTab, setIntegrationToNodeRedTab] = useState({});
  const [bundleRefetchCounter, setBundleRefetchCounter] = useState(0);
  const [installingBundlesMap, setInstallingBundlesMap] = useState({});
  const { integrationTypes, ...rest } = useTgQuery(
    [
      "integrationType",
      /* GraphQL */ `
        {
          code
        }
      `
    ],
    {
      variables: { pageSize: 1000000 }
    }
  );
  const {
    integrations,
    refetch: refetchIntegrations,
    ...rest2
  } = useTgQuery(shortIntegrationFragment, {
    variables: { pageSize: 1000000 }
  });

  useEffect(() => {
    (async () => {
      try {
        if (!integrations || !integrations.length) return;
        const intIdToFlow =
          await getNodeRedInfoForIntegrationsById(integrations);
        setIntegrationToNodeRedTab(intIdToFlow);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [integrations]);

  const integrationsByCode = groupBy(integrations, "integrationTypeCode");
  const [editLoading, setEditLoading] = useState(false);
  useEffect(() => {
    (async () => {
      setToInstallIdsMap();
      const bundleIdsToCheck = [];
      forEach(integrationTypeSettingsMap, ({ bundles }) => {
        if (bundles) {
          bundles.forEach(({ id }) => {
            bundleIdsToCheck.push(id);
          });
        }
      });
      //check if bundles have been installed and only show ones that haven't been

      if (bundleIdsToCheck.length) {
        try {
          const toInstallIdsMap =
            await checkIfBundlesHaveBeenInstalled(bundleIdsToCheck);
          setToInstallIdsMap(toInstallIdsMap);
        } catch (error) {
          console.error(`error:`, error);
          window.toastr.error("Error checking for default integrations");
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bundleRefetchCounter]);

  const [deleting, setDeleting] = useState({});
  // const [temploading, setTemploading] = useState();

  if (useTgQuery.checkErrAndLoad(rest))
    return useTgQuery.handleErrAndLoad(rest);
  if (useTgQuery.checkErrAndLoad(rest2))
    return useTgQuery.handleErrAndLoad(rest2);

  return (
    <div className="tg-card">
      <Helmet title="Integrations" />
      <AdminCallout>
        Admins can configure various "Integrations" to hook external tools and
        data into the TeselaGen app. Each Integration consists of several
        endpoints. Each endpoint has a URL, method, and specifies the request
        and response JSON.
        <br></br>
        <br></br>
        We also provide a node-red server to glue our endpoints to yours via an
        easily debuggable plug-n-play interface. It allows making additional
        requests and running arbitrary code in a sandboxed environment -{" "}
        <a href="https://nodered.org/">https://nodered.org/</a>
      </AdminCallout>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h2>Integrations</h2> &nbsp;
        {window.frontEndConfig.nodeRedEditorUrl && isAdmin() && (
          <Button
            large
            minimal
            intent="primary"
            icon="send-to-graph"
            onClick={handleIntegrationClick}
          >
            Node Red Integration Server
          </Button>
        )}
        {/* <Button
          onClick={async () => {
            setTemploading(true);
            setTimeout(() => {
              setTemploading(false);
            }, 100);
            await rest?.refetch?.();
            await refetchIntegrations?.();
            setBundleRefetchCounter(bundleRefetchCounter + 1);
          }}
          loading={temploading || rest.loading || rest2.loading}
          large
          minimal
          icon="refresh"
        ></Button> */}
      </div>

      <br></br>
      <Uploader
        innerText="Upload Existing"
        readBeforeUpload
        beforeUpload={handleIntegrationImport({
          integrationsByCode,
          refetchIntegrations
        })}
        accept=".json"
        minimal
      ></Uploader>
      <hr className="tg-section-break" />
      {
        //or viewing the integration types
        integrationTypes.map(({ code }, i) => {
          const integrationTypeSettings = integrationTypeSettingsMap[code];
          if (!integrationTypeSettings) return null;

          return (
            <div
              key={code}
              className={`integration-setting-section integration-setting-section-${code}`}
            >
              <h3>{getIntegrationType(code)}</h3>
              {integrationTypeSettings.description && (
                <Callout intent="primary">
                  {integrationTypeSettings.description}
                </Callout>
              )}
              {map(integrationsByCode[code], integration => {
                const { id, name, subtype } = integration;
                async function exportIntegration() {
                  try {
                    const { formattedIntegration } = await getIntegration({
                      id,
                      code
                    });
                    const { tabsByIdForInt, subflowsByIdForInt, __allNodes } =
                      await getNodeRedInfoForSingleIntegration(integration);
                    if (map(tabsByIdForInt).length > 1) {
                      await showConfirmationDialog({
                        text: (
                          <div>
                            We Detected that the integration you are trying to
                            export spans multiple tabs in node-red. We
                            unfortunately do not support multi-tab node-red
                            exports at the moment. Please make sure the flow
                            spans only a single tab and try re-exporting
                          </div>
                        ),
                        noCancelButton: true,
                        confirmButtonText: "Okay"
                      });
                      return; //return early!
                    } else if (map(tabsByIdForInt).length === 1) {
                      let nodes = getNodesAssociatedWithTab(
                        __allNodes,
                        Object.keys(tabsByIdForInt)[0]
                      );
                      map(subflowsByIdForInt, (val, key) => {
                        const subflowNodes = getNodesAssociatedWithTab(
                          __allNodes,
                          key
                        );
                        nodes = nodes.concat(subflowNodes);
                      });

                      formattedIntegration.tgNodeRedFlow = nodes;
                    }

                    const jsonToDownload = JSON.stringify(
                      formattedIntegration,
                      null,
                      2
                    );

                    if (window.Cypress) {
                      window.Cypress.__tgIntegrationDownloadString =
                        jsonToDownload;
                    } else {
                      download(
                        jsonToDownload,
                        `${formattedIntegration.name}_${code}.json`
                      );
                    }
                    window.toastr.success(
                      "Integration JSON downloaded successfuly"
                    );
                  } catch (error) {
                    console.error("error:", error);
                    window.toastr.error("Error downloading integration json.");
                  }
                }
                return (
                  <div
                    key={id}
                    className="integration-setting-record"
                    style={{
                      marginTop: 2,
                      marginBottom: 2,
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    <Button
                      icon="trash"
                      intent="danger"
                      text="Delete"
                      minimal
                      loading={deleting[integration.id]}
                      onClick={handleDeleteIntegrationClick({
                        setDeleting,
                        deleting,
                        integration,
                        integrations,
                        exportIntegration,
                        refetchIntegrations,
                        setBundleRefetchCounter,
                        bundleRefetchCounter
                      })}
                    />
                    <Button
                      icon="edit"
                      text="Edit"
                      intent="warning"
                      minimal
                      loading={editLoading}
                      onClick={async () => {
                        try {
                          setEditLoading(true);
                          const { integration, formattedIntegration } =
                            await getIntegration({ id, code });
                          setEditLoading(false);
                          showDialog({
                            ModalComponent: EditIntegrationDialog,
                            modalProps: {
                              refetch: refetchIntegrations,
                              integrationTypeCode: code,
                              originalIntegration: integration,
                              initialValues: formattedIntegration
                            }
                          });
                        } catch (error) {
                          setEditLoading(false);
                          console.error("error:", error);
                          window.toastr.error(
                            "Error editing integration setting."
                          );
                        }
                      }}
                    />
                    <Button
                      icon="download"
                      text="Export"
                      minimal
                      onClick={exportIntegration}
                    />
                    <div
                      style={{ fontSize: 16, marginLeft: 12, marginRight: 12 }}
                    >
                      {name}
                    </div>
                    {subtype && (
                      <div
                        style={{
                          fontStyle: "italic",
                          fontSize: 11,
                          marginTop: 2
                        }}
                      >
                        ({subtype})
                      </div>
                    )}
                    {map(
                      integrationToNodeRedTab[integration.id]?.tabsByIdForInt,
                      (tab, tabId) => {
                        return (
                          <NodeRedTabLink
                            style={{ marginLeft: 10 }}
                            key={tabId}
                            tabId={tabId}
                          ></NodeRedTabLink>
                        );
                      }
                    )}
                  </div>
                );
              })}
              <div style={{ marginTop: 12, display: "flex" }}>
                <Button
                  intent="primary"
                  style={{ marginRight: 10 }}
                  onClick={() => {
                    showDialog({
                      ModalComponent: EditIntegrationDialog,
                      modalProps: {
                        refetch: refetchIntegrations,
                        integrationTypeCode: code,
                        initialValues: {
                          integrationTypeCode: code,
                          integrationHeaders: [],
                          integrationEndpoints: getIntegrationEndpoints(code)
                        }
                      }
                    });
                  }}
                  icon="add"
                >
                  Add
                </Button>
                {toInstallIdsMap &&
                  integrationTypeSettings.bundles &&
                  integrationTypeSettings.bundles.map(bundle => {
                    const { id, name } = bundle;
                    if (!toInstallIdsMap[id]) return null;

                    return (
                      <Button
                        key={id}
                        intent="success"
                        style={{ marginRight: 10 }}
                        loading={installingBundlesMap[id]}
                        onClick={async () => {
                          setInstallingBundlesMap({
                            ...installingBundlesMap,
                            [id]: true
                          });
                          await installIntegrationBundle({
                            ...bundle,
                            headers: getRequestHeaderKeys()
                          });
                          await refetchIntegrations();
                          setBundleRefetchCounter(bundleRefetchCounter + 1);
                          setInstallingBundlesMap({
                            ...installingBundlesMap,
                            [id]: false
                          });
                        }}
                        icon="download"
                      >
                        Install {name}
                      </Button>
                    );
                  })}
              </div>
              {i !== integrationTypes.length - 1 && (
                <hr className="tg-section-break" />
              )}
            </div>
          );
        })
      }
    </div>
  );
}

export default IntegrationsManagementPanel;

async function getIntegration({ id, code }) {
  const integration = await safeQuery(integrationFragment, {
    variables: { id }
  });

  return {
    integration,
    formattedIntegration: {
      ...integration,
      integrationTypeCode: code,
      integrationHeaders: integration.integrationHeaders.map(h =>
        pick(h, ["name", "value", "isEncrypted"])
      ),
      integrationEndpoints: getIntegrationEndpoints(
        code,
        integration.integrationEndpoints
      )
    }
  };
}
