/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { Classes } from "@blueprintjs/core";
import { generateField } from "@teselagen/ui";
import "./style.css";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// TODO: Change and remove react-draft-wyiwyg in favor of blocknote
class RichHtmlEditor extends Component {
  constructor(props) {
    super(props);
    const html =
      props.oldUnsavedValue ||
      props.initialValue ||
      props.input.value ||
      "<p></p>";
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      this.state = {
        editorState
      };
    }
  }

  componentDidUpdate(oldProps) {
    const { initialValue, input } = this.props;
    const oldInitialValue = oldProps.initialValue;
    const newInitialValue = initialValue;
    if (oldInitialValue !== newInitialValue) {
      this.setDirtyChanged(input.value);
    }
  }

  setDirtyChanged = value => {
    const { initialValue, setDirty } = this.props;
    if (!setDirty) return;
    // still empty
    if (value === "<p></p>" || value === "<p></p>\n") {
      value = "";
    }
    const isDirty = initialValue !== value;
    setDirty(isDirty);
  };

  onEditorStateChange = editorState => {
    const { input } = this.props;
    this.setState(
      {
        editorState
      },
      () => {
        const newHtmlVal = draftToHtml(
          convertToRaw(editorState.getCurrentContent())
        );
        this.setDirtyChanged(newHtmlVal);
        input.onChange(newHtmlVal);
      }
    );
  };

  render() {
    const { editorState } = this.state;
    return (
      <div>
        <Editor
          editorState={editorState}
          wrapperClassName="tg-react-html-wrapper"
          toolbar={{
            options: [
              "inline",
              "blockType",
              "fontSize",
              "fontFamily",
              "list",
              "textAlign",
              "colorPicker",
              "link",
              "embedded",
              "emoji",
              "image"
            ],
            inline: { inDropdown: true },
            list: { inDropdown: true },
            textAlign: { inDropdown: true },
            link: { inDropdown: true }
          }}
          editorStyle={{
            minHeight: 200
          }}
          editorClassName={Classes.INPUT}
          onEditorStateChange={this.onEditorStateChange}
        />
      </div>
    );
  }
}

export default generateField(RichHtmlEditor);
