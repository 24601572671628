/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { MenuItem } from "@blueprintjs/core";
import { startCase } from "lodash";
import AbstractLibrary from "../AbstractLibrary";
import extendedPropertyFragment from "../fragments/extendedPropertyFragment";
import AddExtendedPropertyDialog from "../AddExtendedPropertyDialog";
import getInitialValues from "../AddExtendedPropertyDialog/getInitialValues";
import libraryEnhancer from "../libraryEnhancer";
import dataTableSelectFilterMenu from "../dataTableSelectFilterMenu";
import { showDialog } from "../GlobalDialog";

class ExtendedProperties extends Component {
  onNewItemClick = () => {
    const {
      data: { refetch }
    } = this.props;
    showDialog({
      ModalComponent: AddExtendedPropertyDialog,
      modalProps: {
        refetch
      }
    });
  };

  editProperty = record => {
    const {
      data: { refetch },
      selectTableRecords
    } = this.props;

    const refetchFull = async () => {
      await refetch();
      selectTableRecords([record.id]);
    };

    showDialog({
      modalType: `EDIT_${record.extendedPropertyClassCode}_EXTENDED_PROPERTY`,
      modalProps: {
        refetch: refetchFull,
        initialValues: getInitialValues(record)
      }
    });
  };

  contextMenu = ({ deleteMenuItem, selectedRecords }) => {
    const editMenuItem = (
      <MenuItem
        key="edit"
        icon="edit"
        onClick={() => this.editProperty(selectedRecords[0])}
        text="Edit"
      />
    );
    const menuItems = [];
    if (selectedRecords.length === 1) {
      menuItems.push(editMenuItem);
    }
    menuItems.push(deleteMenuItem);

    return menuItems;
  };

  render() {
    return (
      <div className="tg-card">
        <AbstractLibrary
          {...this.props}
          libraryName="extendedProperty"
          contextMenu={this.contextMenu}
          onNewItemClick={this.onNewItemClick}
          onDoubleClick={this.editProperty}
        />
      </div>
    );
  }
}

const FilterMenu = dataTableSelectFilterMenu({
  options: [
    {
      value: "Measurement",
      label: "Measurement"
    },
    {
      value: "Category",
      label: "Category"
    },
    {
      value: "String",
      label: "String"
    },
    {
      value: "Number",
      label: "Number"
    },
    {
      value: "Flag",
      label: "True/False"
    },
    {
      value: "Date",
      label: "Date"
    }
  ],
  paramKey: "extendedType",
  label: "Type"
});

export const schema = {
  model: "extendedProperty",
  fields: [
    {
      path: "name",
      type: "string",
      displayName: "Name"
    },
    {
      type: "string",
      displayName: "Class",
      render: (v, r) => {
        const isValue = r.extendedPropertyClass.code === "VALUE";
        return isValue ? "Basic" : startCase(r.extendedPropertyClass.name);
      }
    },
    {
      path: "extendedType.name",
      type: "string",
      displayName: "Type",
      FilterMenu,
      filterKey: "extendedType",
      filterIsActive: currentParams => currentParams.extendedType,
      render(__, record) {
        if (record.extendedPropertyClassCode === "VALUE") {
          return record.extendedType.name;
        } else {
          return startCase(record.extendedPropertyClass.name);
        }
      }
    },
    {
      displayName: "Item Type",
      path: "modelType.name",
      type: "string"
    }
  ]
};

export default libraryEnhancer({
  urlConnected: false,
  schema,
  fragment: extendedPropertyFragment,
  additionalFilter: (props, qb, { extendedType }) => {
    if (extendedType) {
      const basicTypes = ["String", "Number", "Flag", "Date"];
      if (basicTypes.includes(extendedType)) {
        qb.whereAll({
          "extendedType.name": extendedType
        });
      } else {
        qb.whereAll({
          "extendedPropertyClass.name": extendedType.toLowerCase()
        });
      }
    }
  }
})(ExtendedProperties);
