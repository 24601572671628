/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import Big from "big.js";
import {
  convertVolumeBig,
  getCellCount,
  toFixedPico
} from "../../../utils/unitUtils";

/**
 * Given a list of aliquots, generate a map from source aliquot id
 * to a source information object. A source information object has the following fields:
 *    - `aliquot`: The full source aliquot.
 *    - `volumeUsed`: The volume removed from the source aliquot. In units of `aliquot.volumetricUnitCode`.
 * @param {Array<FullFormulation>} fullFormulations
 * @returns {Map<string, Object} A map from aliquot id to a source information object.
 */
function getIdToSourceInformationMap(fullFormulations) {
  const idToInfo = {};
  for (const f of fullFormulations) {
    for (const t of f.getTransfers()) {
      if (t.aliquot) {
        const key = t.aliquot.id;
        if (!idToInfo[key]) {
          idToInfo[key] = { aliquot: t.aliquot, volumeUsed: new Big(0) };
        }
        idToInfo[key].volumeUsed = idToInfo[key].volumeUsed.add(
          convertVolumeBig(
            t.volume,
            t.volumetricUnitCode,
            t.aliquot.volumetricUnitCode
          )
        );
      } else if (t.aliquotContainer) {
        const key = "ac" + t.aliquotContainer.id;
        if (!idToInfo[key]) {
          idToInfo[key] = {
            aliquotContainer: t.aliquotContainer,
            volumeUsed: new Big(0)
          };
        }
        idToInfo[key].volumeUsed = new Big(idToInfo[key].volumeUsed).add(
          convertVolumeBig(t.volume, t.volumetricUnitCode, "L")
        );
      }
    }
  }
  return idToInfo;
}

/**
 * Given a list of additives, update their volumes to reflect the
 * fluid that has been transferred out of them.
 *
 * This function does not validate that the transfer volume is less than or equal to the source
 * volume. Thus, it is possible to end up with negative aliquot volumes.
 * @param {Array<Object>} additives
 * @param {number} volumeUsed
 * @param {number} totalVolume
 * @param {writeBuffer} writeBuffer
 */
function computeNewAdditiveVolumes(
  additives,
  volumeUsed,
  totalVolume,
  writeBuffer
) {
  const { upsert } = writeBuffer;
  const fractionRemaining = new Big(totalVolume)
    .minus(new Big(volumeUsed))
    .div(new Big(totalVolume));
  for (const a of additives) {
    upsert("additive", {
      id: a.id,
      volume: a.volume && Number(new Big(a.volume).times(fractionRemaining)),
      mass: a.mass && Number(new Big(a.mass).times(fractionRemaining))
    });
  }
}

/**
 * Given a list of aliquot formulations, update the volumes of the source aliquots to reflect the
 * fluid that has been transferred out of them. Do the same for the additives in the source aliquots.
 *
 * This function does not validate that the transfer volume is less than or equal to the source
 * volume. Thus, it is possible to end up with negative aliquot volumes.
 * @param {Array<FullFormulation>} fullFormulations
 * @param {writeBuffer} writeBuffer
 */
function updateSourceAliquots(fullFormulations, writeBuffer) {
  const { upsert } = writeBuffer;

  // Map from source aliquot id to information including the full aliquot and
  // the amount transfered from that aliquot.
  const idToInfo = getIdToSourceInformationMap(fullFormulations);

  // Subtract the volumes used from the source aliquots.
  for (const { aliquot, aliquotContainer, volumeUsed } of Object.values(
    idToInfo
  )) {
    if (aliquot) {
      // Remember that volumeUsed is already in the units used by the source aliquot.
      const newVolume = Number(
        toFixedPico(new Big(aliquot.volume).minus(volumeUsed))
      );
      const update = {
        id: aliquot.id,
        volume: newVolume
      };
      if (aliquot.cellConcentration) {
        update.cellCount = getCellCount({
          cellConcentration: aliquot.cellConcentration,
          cellConcentrationUnitCode: aliquot.cellConcentrationUnitCode,
          volume: newVolume,
          volumetricUnitCode: aliquot.volumetricUnitCode
        });
      }
      upsert("aliquot", update);

      // Update the additives on the source aliquots.
      computeNewAdditiveVolumes(
        aliquot.additives,
        volumeUsed,
        aliquot.volume,
        writeBuffer
      );
    } else if (aliquotContainer) {
      const totalVolumeOfAdditives = aliquotContainer.additives.reduce(
        (acc, additive) => {
          if (additive.volume) {
            acc = acc.plus(
              convertVolumeBig(
                additive.volume,
                additive.volumetricUnitCode,
                "L"
              )
            );
          }
          return acc;
        },
        new Big(0)
      );
      computeNewAdditiveVolumes(
        aliquotContainer.additives,
        volumeUsed,
        totalVolumeOfAdditives,
        writeBuffer
      );
    }
  }
}

export default updateSourceAliquots;
