/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { reduxForm } from "redux-form";
import { InputField, TextareaField, DialogFooter } from "@teselagen/ui";
import { Classes } from "@blueprintjs/core";
import { asyncValidateName } from "../../../../src-shared/utils/formUtils";

function LocationTypeDialog({ handleSubmit, hideModal }) {
  return (
    <form onSubmit={handleSubmit}>
      <div className={Classes.DIALOG_BODY}>
        <InputField name="name" label="Name" isRequired />
        <TextareaField name="description" label="Description" />
      </div>
      <DialogFooter hideModal={hideModal} />
    </form>
  );
}

export default reduxForm({
  form: "locationTypeDialog",
  ...asyncValidateName
})(LocationTypeDialog);
