/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

export default gql`
  fragment inductionMethodFragment on inductionMethod {
    id
    name
    description
    createdAt
    updatedAt
    user {
      id
      username
    }
  }
`;
