/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { compose } from "recompose";
import Helmet from "react-helmet";
import { MenuItem } from "@blueprintjs/core";
import { withTableParams } from "@teselagen/ui";
import withQuery from "../../../src-shared/withQuery";
import { pick } from "lodash";
import AbstractLibrary from "../../../src-shared/AbstractLibrary";
import { dateModifiedColumn } from "../../../src-shared/utils/libraryColumns";
import inductionMethodFragment from "../../graphql/fragments/inductionMethodFragment";
import { showDialog } from "../../../src-shared/GlobalDialog";
import UploadItemWithNameAndDescriptionDialog from "../AppSettings/UploadItemWithNameAndDescriptionDialog";

const schema = {
  model: "inductionMethod",
  fields: [
    { path: "name", type: "string", displayName: "Name" },
    { path: "description", type: "string", displayName: "Description" },
    dateModifiedColumn
  ]
};

class InductionMethodPanel extends Component {
  openEditWindow = record => {
    const { refetchInductionMethods } = this.props;
    showDialog({
      modalType: "CREATE_NEW_INDUCTION_METHOD",
      modalProps: {
        refetch: refetchInductionMethods,
        initialValues: {
          ...pick(record, ["id", "name", "description"])
        }
      }
    });
  };

  contextMenu = ({ deleteMenuItem, selectedRecords }) => {
    const menuItems = [];
    if (selectedRecords.length === 1) {
      const record = selectedRecords[0];
      menuItems.push(
        <MenuItem
          key="edit"
          icon="edit"
          onClick={() => this.openEditWindow(record)}
          text="Edit"
        />
      );
    }
    menuItems.push(deleteMenuItem);
    return menuItems;
  };

  onUpload = () => {
    const { refetchInductionMethods } = this.props;
    showDialog({
      ModalComponent: UploadItemWithNameAndDescriptionDialog,
      modalProps: {
        refetch: refetchInductionMethods,
        model: "inductionMethod"
      }
    });
  };

  render() {
    return (
      <div className="tg-card">
        <Helmet title="Induction Methods" />
        <AbstractLibrary
          {...this.props}
          contextMenu={this.contextMenu}
          onDoubleClick={this.openEditWindow}
          showUploadFunction={this.onUpload}
          libraryName="inductionMethod"
          onNewItemClick={this.openEditWindow}
        />
      </div>
    );
  }
}

export default compose(
  withTableParams({
    urlConnected: false,
    schema,
    formName: "inductionMethodsLibrary",
    withDisplayOptions: true
  }),
  withQuery(inductionMethodFragment, {
    isPlural: true
  })
)(InductionMethodPanel);
