/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { reduxForm } from "redux-form";
import { Classes } from "@blueprintjs/core";
import { DialogFooter, wrapDialog, FileUploadField } from "@teselagen/ui";
import Color from "color";
import { safeQuery, safeUpsert } from "../apolloMethods";
import caseInsensitiveFilter from "../../../tg-iso-shared/src/utils/caseInsensitiveFilter";
import { keyBy } from "lodash";

import { compose } from "recompose";
import { getDownloadTemplateFileHelpers } from "../components/DownloadTemplateFileButton";

function UploadTagsDialog(props) {
  const { hideModal, handleSubmit, submitting, refetch } = props;
  async function onSubmit(values) {
    try {
      const { csvFile } = values;
      const newTags = {};
      for (const row of csvFile[0].parsedData) {
        const { name, color, option } = row;

        const key = name.toLowerCase();
        if (!newTags[key]) {
          newTags[key] = {
            name,
            tagOptions: []
          };
        }
        const cleanedColor = color && Color(color.toLowerCase()).hex();
        if (option) {
          newTags[key].tagOptions.push({
            name: option,
            color: cleanedColor
          });
        } else {
          newTags[key].color = cleanedColor;
        }
      }
      const existingTags = await safeQuery(
        ["tag", "id name color tagOptions { id name color }"],
        {
          variables: {
            filter: caseInsensitiveFilter("tag", "name", Object.keys(newTags))
          }
        }
      );
      const keyedExisting = keyBy(existingTags, t => t.name.toLowerCase());
      const newTagOptions = [];
      for (const key of Object.keys(newTags)) {
        const newTag = newTags[key];
        const existingTag = keyedExisting[key];
        if (existingTag) {
          delete newTags[key];
          // users can upload new tag options to existing tags.
          // they cannot add options to a tag that doesn't have them.
          if (newTag.tagOptions.length && existingTag.tagOptions.length) {
            newTag.tagOptions.forEach(op => {
              const alreadyHas = existingTag.tagOptions.find(
                to => to.name.toLowerCase() === op.name.toLowerCase()
              );
              if (!alreadyHas) {
                newTagOptions.push({
                  ...op,
                  tagId: existingTag.id
                });
              }
            });
          }
        }
      }
      await safeUpsert("tagOption", newTagOptions);
      await safeUpsert("tag", Object.values(newTags));
      await refetch();
      hideModal();
    } catch (error) {
      console.error(`error:`, error);
      window.toastr.error("Error uploading tags.");
    }
  }

  return (
    <React.Fragment>
      <div className={Classes.DIALOG_BODY}>
        <FileUploadField
          name="csvFile"
          isRequired
          fileLimit={1}
          accept={getDownloadTemplateFileHelpers({
            fileName: "Download Example File",
            validateAgainstSchema: {
              fields: [
                {
                  path: "name",
                  isRequired: true,
                  example: ["SomeTag", "Tree", "Tree"]
                },
                {
                  path: "color",
                  isRequired: true,
                  validate: color => {
                    try {
                      color && Color(color.toLowerCase()).hex();
                    } catch (error) {
                      return "Please specify a valid color.";
                    }
                  },
                  example: ["#bd34eb", "blue", "green"]
                },
                {
                  path: "option",
                  isRequired: false,
                  example: ["", "Oak", "Cypress"]
                }
              ]
            }
          })}
        />
      </div>
      <DialogFooter
        submitting={submitting}
        hideModal={hideModal}
        onClick={handleSubmit(onSubmit)}
      />
    </React.Fragment>
  );
}

export default compose(
  wrapDialog({
    title: "Upload Tags"
  }),
  reduxForm({
    form: "uploadTags"
  })
)(UploadTagsDialog);
