/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { useMemo } from "react";
import { compose } from "recompose";
import { capitalize, camelCase, isObject, isEmpty } from "lodash";
import QueryBuilder from "tg-client-query-builder";
import { Link } from "react-router-dom";
import withQuery from "../withQuery";
import RecordInfoDisplay from "../RecordInfoDisplay";
import modelNameToLink from "../utils/modelNameToLink";
import { parseErrorToJson } from "../utils/parseErrorToJson";
import microserviceTaskLibraryBuildFragment from "../graphql/fragments/microserviceTaskLibraryBuildFragment";
import { displayDateTime, displayDuration } from "../utils/dateUtils";
import recordViewEnhancer from "../recordViewEnhancer";
import { getServiceSpecificInput } from "./utils";

const MicroserviceTaskRecordView = props => {
  const { microserviceQueue, notifications, readOnly } = props;

  const {
    id,
    service,
    status,
    microserviceIoFiles,
    result: _result,
    createdAt,
    startedOn,
    completedOn
  } = microserviceQueue;

  // The 'result' field was removed from the 'microserviceQueueFrag' fragment
  // apparently bc a client asked for it to be removed bc it was disclosing too much
  // information. However, the Partition Tool service needs it bc it contains error messages
  // useful for the user. We also need it for the Blast service.
  const result = [
    "partition-tool",
    "homology-path",
    "crickit-manager",
    "blast"
  ].includes(service)
    ? microserviceQueue.result
    : _result;

  const { taskName, serviceSpecificInput } = useMemo(
    () => getServiceSpecificInput(microserviceQueue),
    [microserviceQueue]
  );

  const displayedStatus = useMemo(() => {
    const statusMap = {
      "completed-successfully": "Completed",
      "completed-failed": "Failed",
      "completed-cancelled": "Canceled"
    };
    // any other status
    if (!statusMap[status]) {
      return status;
    } else {
      return statusMap[status];
    }
  }, [status]);

  // Error messsages are prevented from being shown by the hiding of the results
  const error = useMemo(() => {
    let error = "";
    if (status === "completed-failed") {
      if (isObject(result) && isEmpty(result)) {
        // usually task abandoned error or 503 service unavailable error
        // these errors are currently getting saved in microserviceQueue as result = {}
        error = "Task not processed by the microservice";
      } else if (isObject(result)) {
        error = result.message
          ? result.message
          : JSON.stringify(result, null, 2);
      } else if (["partition-tool", "blast"].includes(service)) {
        let parsedError;
        try {
          parsedError = parseErrorToJson(result);
        } catch (e) {
          parsedError = { message: result };
        }
        error = parsedError?.message || parsedError;
      } else if (
        service === "homology-path" ||
        (service === "ds-tools" && input.job === "assemblyqc-tool")
      ) {
        error = result;
      } else if (service === "crickit-manager" && isObject(result)) {
        error = result?.errors || result?.data?.message;
      } else if (isObject(result)) {
        error = result.message || JSON.stringify(result, null, 2);
      }
    } else if (status === "completed-successfully") {
      if (
        service === "crickit-manager" &&
        isObject(result) &&
        result?.data?.message
      ) {
        if (result?.data?.message.includes("No Candidates found")) {
          error = result.data.message;
        } else if (
          !result?.data?.message.includes("command ran successfully")
        ) {
          error = `CrickIt Internal Task ID: ${result?.data?.task_id}\nCrickIt Internal Error: ${result.data.message}`;
        }
      }
    }
    return error;
  }, [result, service, status]);

  const output = useMemo(() => {
    let output;

    let item;
    const ioItem = microserviceQueue.ioItem;
    if (ioItem) {
      if (ioItem.dataItem) {
        item = ioItem.dataItem[camelCase(ioItem.dataItem.dataItemTypeCode)];
      }
    }
    if (item) {
      output = (
        <Link to={modelNameToLink(item.__typename, item.id)}>{item.name}</Link>
      );
    }

    return output;
  }, [microserviceQueue.ioItem]);

  const links = useMemo(
    () =>
      notifications?.map(notification => {
        const { id, link } = notification;
        return (
          <div key={id}>
            <Link to={link} className="reportLink" key={id}>
              View Report
            </Link>
          </div>
        );
      }) || [],
    [notifications]
  );

  // Compilates full record info
  const recordInfo = useMemo(
    () => [
      ["Task ID", id],
      ["Service", capitalize(service.replace(/-/g, " "))],
      ["Status", displayedStatus],
      output && ["Output", output],
      ["Enqueued", displayDateTime(createdAt)],
      ["Started", displayDateTime(startedOn)],
      ["Completed", displayDateTime(completedOn)],
      ["Time In Queue", displayDuration(createdAt, completedOn)],
      ["Time In Progress", displayDuration(startedOn, completedOn)],
      ...serviceSpecificInput,
      links.length && ["Link", links],
      error && !isObject(error) && ["Error", error]
    ],
    [
      id,
      service,
      displayedStatus,
      output,
      createdAt,
      startedOn,
      completedOn,
      serviceSpecificInput,
      links,
      error
    ]
  );

  return (
    <div className="task-record-view">
      <h2>{taskName}</h2>
      <RecordInfoDisplay
        recordInfo={recordInfo}
        readOnly={readOnly}
        record={microserviceQueue}
      />
    </div>
  );
};

const microserviceQueueFrag = microserviceTaskLibraryBuildFragment;

export default compose(
  recordViewEnhancer(microserviceQueueFrag, {
    withQueryOptions: {
      options: () => {
        return {
          pollInterval: 5000
        };
      }
    }
  }),
  withQuery(["notification", "id link"], {
    isPlural: true,
    options: ({ microserviceQueue }) => {
      const qb = new QueryBuilder("notification");
      const filter = qb
        .whereAll({
          microserviceQueueId: microserviceQueue.id,
          link: qb.notNull()
        })
        .toJSON();
      return {
        variables: {
          filter
        },
        pollInterval: 5000
      };
    }
  }),
  WrappedComponent => props => {
    if (props.data.loading) return <></>;

    return (
      <div
        className="task-record-view-container"
        style={{ width: "100%", padding: "40px" }}
      >
        <WrappedComponent {...props} />
      </div>
    );
  }
)(MicroserviceTaskRecordView);
