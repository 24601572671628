/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

export default function basicHandleActionsWithFullState(
  handlers: { [key: string]: Function },
  defaultState: any
) {
  return (state = defaultState, action: { type: string }, fullState: any) => {
    const { type } = action;
    const handler = handlers[type];
    if (handler) {
      return handler(state, action, fullState);
    } else {
      return state;
    }
  };
}
