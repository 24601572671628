/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useState } from "react";
import { Button, Classes, Slider } from "@blueprintjs/core";
import AvatarEditor from "react-avatar-editor";
import { DialogFooter, wrapDialog } from "@teselagen/ui";
import "./style.css";

const CropImageDialog = ({ saveImageFile, hideModal, image }) => {
  const [rotate, setRotate] = useState(0);
  const [scale, setScale] = useState(1);
  const [loading, setLoading] = useState(true);
  const [editor, setEditor] = useState(null);

  const saveImage = async () => {
    setLoading(true);
    try {
      if (!editor) return;
      const canvas = editor.getImage();
      const blob = await new Promise(resolve => canvas.toBlob(resolve));
      await saveImageFile(blob);
      hideModal();
    } catch (error) {
      setLoading(false);
      console.error("Error uploading file:", error);
      window.toastr.error("Error uploading file");
    }
  };

  return (
    <>
      <div className={Classes.DIALOG_BODY}>
        <div className="avatar-editor-container">
          <AvatarEditor
            crossOrigin="anonymous"
            ref={setEditor}
            image={image}
            onLoadSuccess={() => setLoading(false)}
            onImageReady={() => setLoading(false)}
            rotate={rotate}
            scale={scale}
            height={250}
            width={250}
          />
        </div>
        <div className="avatar-edit-controls">
          <div>
            <Slider
              value={scale}
              onChange={newScale => setScale(newScale)}
              renderLabel={false}
              showTrackFill={false}
              min={1}
              max={5}
              stepSize={0.3}
            />
          </div>
          <div>
            <Button
              icon="image-rotate-left"
              className={Classes.MINIMAL}
              onClick={() => setRotate(prev => prev - 90)}
            />
            <Button
              icon="image-rotate-right"
              className={Classes.MINIMAL}
              onClick={() => setRotate(prev => prev + 90)}
            />
          </div>
        </div>
      </div>
      <DialogFooter
        text="Save"
        loading={loading}
        onClick={saveImage}
        hideModal={hideModal}
      />
    </>
  );
};

export default wrapDialog({
  title: "Crop Image"
})(CropImageDialog);
