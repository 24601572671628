/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { MenuItem } from "@blueprintjs/core";
import { pick } from "lodash";
import AbstractLibrary from "../AbstractLibrary";
import TagOrTagOptions from "../TagOrTagOptions";
import { showDialog } from "../GlobalDialog";
import { compose } from "recompose";
import { withTableParams } from "@teselagen/ui";
import withQuery from "../withQuery";
import tagFragment from "../fragments/tagFragment";
import AddTagDialog from "../AddTagDialog";
import UploadTagsDialog from "./UploadTagsDialog";

class TagManagementLibrary extends Component {
  onNewItemClick = () => {
    const {
      data: { refetch }
    } = this.props;
    showDialog({
      ModalComponent: AddTagDialog,
      modalProps: {
        refetch
      }
    });
  };

  editTag = record => {
    const {
      data: { refetch }
    } = this.props;
    showDialog({
      ModalComponent: AddTagDialog,
      modalProps: {
        refetch,
        initialValues: {
          ...pick(record, [
            "id",
            "name",
            "isSystemType",
            "description",
            "color",
            "tagOptions"
          ]),
          tagHasOptions: !!record.tagOptions.length
        }
      }
    });
  };

  contextMenu = ({ deleteMenuItem, selectedRecords }) => {
    const editMenuItem = (
      <MenuItem
        key="edit"
        icon="edit"
        onClick={() => this.editTag(selectedRecords[0])}
        text="Edit"
      />
    );
    const menuItems = [];
    if (selectedRecords.length === 1) {
      menuItems.push(editMenuItem);
    }
    if (selectedRecords.some(r => r.isSystemType)) {
      return menuItems;
    }
    menuItems.push(deleteMenuItem);

    return menuItems;
  };

  render() {
    const { refetchTags } = this.props;

    return (
      <div className=" tg-card">
        <AbstractLibrary
          {...this.props}
          showUploadFunction={() => {
            showDialog({
              ModalComponent: UploadTagsDialog,
              modalProps: {
                refetch: refetchTags
              }
            });
          }}
          isEmbedded
          compact
          libraryName="tag"
          contextMenu={this.contextMenu}
          onNewItemClick={this.onNewItemClick}
          onDoubleClick={this.editTag}
        />
      </div>
    );
  }
}

const schema = {
  model: "tag",
  fields: [
    {
      path: "name",
      type: "string",
      displayName: "Tags",
      render: (value, record) => (
        <TagOrTagOptions showDescTooltip record={record} />
      )
    }
  ]
};

export default compose(
  withTableParams({
    urlConnected: false,
    schema,
    formName: "tagManagementTable",
    withDisplayOptions: true,
    defaults: {
      order: ["tags"]
    }
  }),
  withQuery(tagFragment, {
    isPlural: true
  })
)(TagManagementLibrary);
