/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { chunk, get, upperFirst } from "lodash-es";
import gql from "graphql-tag";
import pascalCase from "pascal-case";
import generateFragmentWithFields from "./utils/generateFragmentWithFields";
import { SAFE_UPSERT_PAGE_SIZE } from "./constants";

/**
 * withUpsert
 * @param {string | gql fragment} nameOrFragment supply either a name or a top-level fragment
 * @param {options} options
 * @typedef {object} options
 * @property {boolean} showError - default=true -- whether or not to show a default error message on failure
 * @property {obj | function} extraMutateArgs - obj or function that returns obj to get passed to the actual mutation call
 * @property {boolean} asMutationObj - if true, this gives you back an array of [query, variables, getResponse] that you can use with any http client
 * @property {string} idAs - if not using a fragment, you get an id field back as default. But, if the record doesn't have an id field, and instead has a 'code', you can set idAs: 'code'
 * @property {boolean} forceCreate - sometimes the thing you're creating won't have an id field (it might have a code or something else as its primary key). This lets you override the default behavior of updating if no id is found
 * @property {boolean} forceUpdate - sometimes the thing you're updating might have an id field. This lets you override that. This lets you override the default behavior of creating if an id is found
 * @property {boolean} excludeResults - don't fetch back result entities after update or create
 * @return upsertXXXX function that takes an object or array of objects to upsert. It returns a promise resolving to an array of created/updated outputs
 */

export default function withUpsert(nameOrFragment, options = {}) {
  return function upsert(valueOrValues, functionCallOptions) {
    const {
      idAs: _idAs,
      asMutationObj,
      forceCreate: topLevelForceCreate,
      forceUpdate: topLevelForceUpdate,
      client,
      excludeResults = false,
      pageSize,
      optimistic = false,
      optimisticValues,
      options: upsertOptions,
      ...rest
    } = options;

    const values = Array.isArray(valueOrValues)
      ? valueOrValues
      : [valueOrValues];

    let idAs = _idAs;
    if (!idAs && values?.[0]?.code) {
      idAs = "code";
    }
    let fragment = typeof nameOrFragment === "string" ? null : nameOrFragment;
    if (Array.isArray(fragment)) {
      fragment = generateFragmentWithFields(...fragment);
    }
    const modelName = fragment
      ? get(fragment, "definitions[0].typeCondition.name.value")
      : nameOrFragment;
    const name = fragment
      ? fragment.definitions[0].typeCondition.name.value
      : nameOrFragment;

    // const {fragment, extraMutateArgs} = options
    const fragName = fragment && fragment.definitions[0].name.value;
    const pascalCaseName = pascalCase(name);
    const createName = `create${pascalCaseName}`;
    const resultString = `${
      !excludeResults
        ? `results {
          ${fragName ? `...${fragName}` : idAs || "id"}
          __typename
        }`
        : ""
    }
    totalResults`;

    const createMutation = gql`
      mutation ${createName}($input: [${createName}Input]) {
        ${createName}(input: $input) {
          createdItemsCursor {
            ${resultString}
          }
        }
      }
      ${fragment ? fragment : ``}
    `;

    const updateName = `update${pascalCaseName}`;

    const updateMutation = gql`
      mutation ${updateName}($input: [${updateName}Input]) {
        ${updateName}(input: $input) {
          updatedItemsCursor {
            ${resultString}
          }
        }
      }
      ${fragment ? fragment : ``}
    `;

    if (!client && !asMutationObj)
      return console.error(
        "You need to pass the apollo client to withUpsert if using as a function"
      );

    if (!valueOrValues || !values.length) return [];

    let isUpdate = !!(values[0].id || values[0].code);

    if (topLevelForceCreate) {
      isUpdate = false;
    }
    if (topLevelForceUpdate) {
      isUpdate = true;
    }
    const mutation = isUpdate ? updateMutation : createMutation;
    if (asMutationObj) {
      return [
        //query
        mutation,
        //variables
        {
          input: values
        },
        //getResponse
        _res => {
          const res = (_res && _res.body) || _res;

          const returnInfo =
            res.data[isUpdate ? updateName : createName][
              isUpdate ? "updatedItemsCursor" : "createdItemsCursor"
            ];
          let results = returnInfo.results;
          results = [...results];
          results.totalResults = returnInfo.totalResults;
          return excludeResults ? results.totalResults : results;
        }
      ];
    }
    if (!valueOrValues || !values.length) return Promise.resolve([]);

    const upsertFn = values => {
      const customOptions = {};
      if (optimistic) {
        customOptions.optimisticResponse = getOptimisticResponse(
          modelName,
          optimisticValues || values
        );
      }
      return client.mutate({
        mutation,
        ...upsertOptions,
        variables: {
          input: values
        },
        ...customOptions,
        ...rest,
        ...functionCallOptions
      });
    };

    return getSafeUpsertResults({
      upsertFn,
      values,
      createName,
      pageSize,
      updateName,
      isUpdate,
      modelName,
      excludeResults,
      idAs
    });
  };
}

async function getSafeUpsertResults({
  upsertFn,
  values,
  createName,
  updateName,
  modelName,
  pageSize = SAFE_UPSERT_PAGE_SIZE,
  userOptions: _userOptions,
  isUpdate,
  excludeResults,
  idAs
}) {
  const userOptions = _userOptions || [];
  let results = [];

  const addToResults = res => {
    const data = res.data[isUpdate ? updateName : createName];
    if (!data) {
      console.error("Upsert failing:", isUpdate ? updateName : createName);
      console.error("Upsert values:", JSON.stringify(values, null, 2));
    }

    // Apollo Client's errors object is an array,
    // I think that usually there's just one error
    // so return the first one.
    if (res.errors && res.errors.length && !data) {
      const errorMessage =
        get(res.errors, "0.message") || "Unknown Apollo Client GraphQL error";
      throw new Error(errorMessage);
    }

    const returnInfo =
      data[isUpdate ? "updatedItemsCursor" : "createdItemsCursor"];
    results = results.concat(returnInfo.results);
    results.totalResults = returnInfo.totalResults;
  };

  // if excludeResults is true we can just do a single upsert because it is fast
  if (values.length > pageSize && !excludeResults) {
    const groupedVals = chunk(values, pageSize);
    for (const valGroup of groupedVals) {
      addToResults(await upsertFn(valGroup, ...userOptions));
    }
  } else {
    addToResults(await upsertFn(values, ...userOptions));
  }
  if (values[0]?.code && idAs !== "code") {
    throw new Error(
      `Upsert failed. Code model ${modelName} did not pass idAs: 'code' to withUpsert`
    );
  }
  if (
    idAs === "code" &&
    !excludeResults &&
    isUpdate &&
    (!results.length || results.length !== values.length)
  ) {
    throw new Error(
      `Upsert failed. Code model ${modelName} attempted to update a record that does not exist.`
    );
  }

  return results;
}

function getOptimisticResponse(model, values) {
  const upperedModel = upperFirst(model);

  return {
    [`update${upperedModel}`]: {
      __typename: `update${upperedModel}Payload`,
      updatedItemsCursor: {
        __typename: `${model}CursorResult`,
        results: values.map(value => ({
          ...value,
          __typename: model
        })),
        totalResults: values.length
      }
    }
  };
}
