/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { compose } from "recompose";
import Helmet from "react-helmet";
import { MenuItem } from "@blueprintjs/core";
import customJ5ParameterFragment from "../../../../../tg-iso-design/graphql/fragments/customJ5ParameterFragment";
import AdminCallout from "../../../../src-shared/SharedAppSettings/AdminCallout";
import libraryEnhancer from "../../../../src-shared/libraryEnhancer";
import AbstractLibrary from "../../../../src-shared/AbstractLibrary";
import { showDialog } from "../../../../src-shared/GlobalDialog";
import { isAdmin } from "../../../../src-shared/utils/generalUtils";
import EditJ5Parameters from "../../Dialogs/EditJ5Parameters";
import { safeUpsert } from "../../../../src-shared/apolloMethods";

const schema = [
  {
    path: "name",
    displayName: "Name"
  }
];

const AssemblyParameterPresetsPanel = props => {
  const openCreateUpdateAssemblyParameterDialog = async assemblyParameters => {
    const { __typename, user, updatedByUser, ...customJ5Parameter } =
      assemblyParameters || {};
    showDialog({
      ModalComponent: EditJ5Parameters,
      modalProps: {
        refetch: props.refetch,
        customJ5Parameter,
        readOnly: !isAdmin(),
        isPreset: true,
        onSubmit: async newParams => {
          const customJ5ParameterToUpsert = {
            ...customJ5Parameter,
            ...newParams
          };
          await safeUpsert("customJ5Parameter", customJ5ParameterToUpsert);
        }
      }
    });
  };

  const onDoubleClick = selectedRecord =>
    openCreateUpdateAssemblyParameterDialog(selectedRecord);

  const contextMenuItems = contextMenuItems => {
    const _isAdmin = isAdmin();
    const { selectedRecords, deleteMenuItem } = contextMenuItems;
    const menuItems = [];
    // if (isAdmin()) {
    if (selectedRecords.length === 1) {
      menuItems.push(
        <MenuItem
          key="edit-assembly-parameters"
          icon={_isAdmin ? "edit" : "eye-open"}
          text={_isAdmin ? "Edit" : "View"}
          onClick={() =>
            openCreateUpdateAssemblyParameterDialog(selectedRecords[0])
          }
        />
      );
    }
    _isAdmin && menuItems.push(deleteMenuItem);
    // }
    return menuItems;
  };

  return (
    <div className="tg-card xtra-pad">
      <Helmet title="Minimum Lengths" />
      {isAdmin() && (
        <AdminCallout intent="primary">
          As an admin you can create/update assembly parameter presets. <br />{" "}
        </AdminCallout>
      )}

      <AbstractLibrary
        {...props}
        noNewItem={!isAdmin()}
        onNewItemClick={() => openCreateUpdateAssemblyParameterDialog()}
        contextMenu={contextMenuItems}
        libraryName="Parameter Preset"
        onDoubleClick={onDoubleClick}
      />
    </div>
  );
};

export default compose(
  libraryEnhancer({
    schema,
    fragment: customJ5ParameterFragment,
    additionalFilter: (_, qb) => {
      const filter = qb
        .whereAll({
          isLocalToThisDesignId: qb.isNull()
        })
        .andWhereAny({ cid: qb.isNull() }, { cid: qb.notEquals("default") });
      return {
        variables: {
          filter
        }
      };
    }
  })
)(AssemblyParameterPresetsPanel);
