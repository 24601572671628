/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

export default gql`
  fragment customHelpDocFragment on customHelpDoc {
    code
    markdown
    user {
      id
      username
    }
  }
`;
