/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import WidgetCard from "./components/WidgetCard";
import TargetAndFeaturesWidget from "./components/widgets/TargetAndFeaturesWidget";
import ModelSummaryWidget from "./components/widgets/ModelSummaryWidget";
import ModelPerformanceStats from "./components/widgets/ModelPerformanceStatsWidget";
import ModelResultsWidget from "./components/widgets/ModelResultsWidget";
import ModelResultsWidgetHeader from "./components/widgets/ModelResultsWidget/ModelResultsWidgetHeader";
import ModelScatterPlotWidget from "./components/widgets/ModelScatterPlotWidget";
import ModelGroupedHistogramWidget from "./components/widgets/ModelGroupedHistogramWidget";
import ModelGroupedHistogramWidgetHeader from "./components/widgets/ModelGroupedHistogramWidget/ModelGroupedHistogramWidgetHeader";
import ModelSigmaHistogramWidgetHeader from "./components/widgets/ModelSigmaHistogramWidget/ModelSigmaHistogramWidgetHeader";
import ModelSigmaHistogramWidget from "./components/widgets/ModelSigmaHistogramWidget";
import ModelSigmaSeqLengthHistogramWidgetHeader from "./components/widgets/ModelSigmaSeqLengthHistogramWidget/ModelSigmaSeqLengthHistogramWidgetHeader";
import ModelSigmaSeqLengthHistogramWidget from "./components/widgets/ModelSigmaSeqLengthHistogramWidget";
import ModelSigmaAminoAcidHistogramWidgetHeader from "./components/widgets/ModelSigmaAminoAcidHistogramWidget/ModelSigmaAminoAcidHistogramWidgetHeader";
import ModelSigmaAminoAcidHistogramWidget from "./components/widgets/ModelSigmaAminoAcidHistogramWidget";
import modelDetailViewStyles from "./ModelDetailViewStyles.module.css";
import { Colors, Classes } from "@blueprintjs/core";
import { EvolveConfig, tooltips } from "../../configs/config";
import cypressTags from "../../configs/cypressTags.json";
import ReactMarkDown from "react-markdown";
import remarkGfm from "remark-gfm";
import WidgetCanvas from "./components/WidgetCanvas";
import LabelWithTooltip from "../../../src-shared/LabelWithTooltip";

import WIDGET_CANVAS_LAYOUTS from "./model-detail-layouts.json";
import { isNil, isEmpty, size, get } from "lodash";

const TAG = "[ModelDetailView]"; // eslint-disable-line
const UNKNOWN_ERROR_OBJECT = {
  message:
    "Encountered an unkown error while training the model. Please try to train your model again.\n If this keeps happening, please do not hesitate to contact us. Sorry for any inconvenience that this could cause."
};

const MISSING_EVOLVE_MODEL_RESPONSE_OBJECT = {
  message:
    "Error retrieving the model's information. Please try to train your model again.\n If this keeps happening, please do not hesitate to contact us. Sorry for any inconvenience that this could cause."
};

/**
 * ModelDetailView is an abstract class, and its implementation (renderization) depending on different flags and model types
 */
class ModelDetailView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      evolveModel: undefined
    };
  }

  // Its best to have the component mounted before making any async requests.
  async componentDidMount() {
    const evolveModel = await this.getEvolveModel();

    this.setState(() => {
      return { evolveModel };
    });
  }

  isEvolveModelResponseValid = evolveModel => {
    let evolveModelResponseValidations = true;
    if (evolveModel) {
      if (!evolveModel.status) evolveModelResponseValidations = false;
      if (!size(evolveModel.evolveModelDatapoints)) {
        evolveModelResponseValidations = false;
      }
      if (evolveModel.evolveModelInfo) {
        if (
          !evolveModel.evolveModelInfo.modelStats ||
          Object.keys(evolveModel.evolveModelInfo.modelStats).length === 0
        )
          evolveModelResponseValidations = false;
      }
      if (!evolveModel.lastCheckIn) evolveModelResponseValidations = false;
      if (!evolveModel.createdAt) evolveModelResponseValidations = false;
    }

    return evolveModelResponseValidations;
  };

  getEvolveModel = async () => {
    // return mockGenerativeModel.data;
    const evolveModelId = this.props.match.params.id;
    let fullEvolveModelData = {};
    let evolveModelDatapoints = [];
    try {
      // #region Fetching Model Information and Statistics.
      const evolveModelResponse = await window.serverApi.post(
        EvolveConfig.constants.GET_MODEL_ENDPOINT,
        { id: evolveModelId }
      );
      if (evolveModelResponse.data.data === undefined) {
        window.toastr.warning(
          "Error loading model. Please contact TeselaGen DS Team."
        );
        this.props.history.push(EvolveConfig.constants.EVOLVE_APP_ROUTE);
        throw new Error(evolveModelResponse.data.error);
      }
      fullEvolveModelData = { ...evolveModelResponse.data.data };
      // #endregion

      // #region Fetching Model Input datapoints.
      const evolveModelInputDatapointsResponse = await window.serverApi.post(
        EvolveConfig.constants.GET_MODEL_DATAPOINTS_ENDPOINT,
        {
          modelId: evolveModelId,
          datapointType: "input",
          batchSize: 400,
          batchNumber: 1
        }
      );
      evolveModelDatapoints = evolveModelDatapoints.concat(
        evolveModelInputDatapointsResponse.data.data
      );
      // #endregion

      // #region Fetching Model Output datapoints.
      const evolveModelOutputDatapointsResponse = await window.serverApi.post(
        EvolveConfig.constants.GET_MODEL_DATAPOINTS_ENDPOINT,
        {
          modelId: evolveModelId,
          datapointType: "output",
          batchSize: 400,
          batchNumber: 1
        }
      );
      evolveModelDatapoints = evolveModelDatapoints.concat(
        evolveModelOutputDatapointsResponse.data.data
      );

      // Filter any undefined elements in the 'evolveModelDatapoints' array.
      // This may happen when the model has not evolve model datapoints associated for any reason.
      evolveModelDatapoints = evolveModelDatapoints.filter(obj => obj);

      fullEvolveModelData = { ...fullEvolveModelData, evolveModelDatapoints };
      // #endregion
      return fullEvolveModelData;
    } catch (error) {
      console.error(TAG, error.message);
    }
  };

  renderHeader = props => {
    const { isLoading, title, description } = props;

    return (
      <div className={modelDetailViewStyles.modelDetailHeader}>
        <h2 className={isLoading ? Classes.SKELETON : ""}>
          {!isLoading ? title : "&nbsp"}
        </h2>
        <div
          className={isLoading ? Classes.SKELETON : ""}
          style={{ color: Colors.GRAY3, textAlign: "justify" }}
        >
          <ReactMarkDown
            className={modelDetailViewStyles.tableMarkdown}
            // eslint-disable-next-line react/no-children-prop
            children={description}
            remarkPlugins={[remarkGfm]}
            disallowedTypes={["linkReference", "delete", "html"]}
          />
        </div>
      </div>
    );
  };

  getEvolveModelSummary = evolveModel => {
    if (!evolveModel) {
      return { statusCode: undefined, modelStats: undefined };
    } else if (!evolveModel.evolveModelInfo) {
      return { statusCode: evolveModel.status, modelStats: undefined };
    } else {
      return {
        statusCode: evolveModel.status,
        modelStats: evolveModel.evolveModelInfo.modelStats,
        startTime: evolveModel.startedOn || evolveModel.createdAt,
        lastCheckIn: evolveModel.lastCheckIn
      };
    }
  };

  filterWidgets(widgets, modelType) {
    const widgetKeys = WIDGET_CANVAS_LAYOUTS[modelType].lg.map(
      widget => widget.i
    );
    return widgets.filter(widget => widgetKeys.includes(widget.key));
  }

  myErrorHandler = error => {
    console.error(error);
    window.location.assign(`/client`);
    window.toastr.error(
      EvolveConfig.noticeMessages.EVOLVE_GENERAL_UNKNOWN_ERROR
    );
    this.props.history.push(EvolveConfig.constants.EVOLVE_APP_ROUTE);
  };

  render() {
    const { evolveModel } = this.state;
    const isLoading = isNil(evolveModel) || isEmpty(evolveModel);
    const evolveModelSummary = this.getEvolveModelSummary(evolveModel);
    let isCompleted = false;
    let error = undefined;
    let dataSchema = undefined;
    let evolveModelDatapoints = undefined;
    let modelType = undefined;
    let filteredWidgets = [];
    if (!isLoading) {
      if (this.isEvolveModelResponseValid(evolveModel)) {
        if (evolveModel.status === "completed-successfully") isCompleted = true;
        else if (evolveModel.status === "completed-failed") {
          // microservices are returning errors on their "result" field.
          error =
            !evolveModel.result || isEmpty(evolveModel.result)
              ? UNKNOWN_ERROR_OBJECT
              : evolveModel.result;
        }
      } else {
        if (evolveModel.status === "completed-failed") {
          // microservices are returning errors on their "result" field.
          error =
            !evolveModel.result || isEmpty(evolveModel.result)
              ? UNKNOWN_ERROR_OBJECT
              : evolveModel.result;
        } else {
          error = MISSING_EVOLVE_MODEL_RESPONSE_OBJECT;
        }
      }
      evolveModelDatapoints = evolveModel.evolveModelDatapoints;
      dataSchema = evolveModel.evolveModelInfo.dataSchema;
      modelType = evolveModel.modelType;
    }

    // #region The following 4 const, are show/not_show flags for the different widgets.
    const showTargetAndFeaturesWidget =
      modelType === EvolveConfig.constants.EVOLUTIVE_MODEL ||
      modelType === EvolveConfig.constants.PREDICTIVE_MODEL;

    const showStatsWidget =
      evolveModel?.evolveModelInfo?.modelStats &&
      Object.keys(get(evolveModel, "evolveModelInfo.modelStats")).length !== 0;

    const showGroupedHistogramWidget =
      isCompleted && modelType === EvolveConfig.constants.EVOLUTIVE_MODEL;

    const showSigmaSeqLengthHistogramWidget =
      isCompleted && modelType === EvolveConfig.constants.GENERATIVE_MODEL;

    const showSigmaAminoAcidFreqHistogramWidget =
      isCompleted && modelType === EvolveConfig.constants.GENERATIVE_MODEL;

    const showSigmaSeqLengthFreqHistogramWidget =
      isCompleted && modelType === EvolveConfig.constants.GENERATIVE_MODEL;

    const showSigmaHistogramWidget =
      isCompleted && modelType === EvolveConfig.constants.GENERATIVE_MODEL;

    const showTargetVsPredictionChartWidget =
      (isCompleted && modelType === EvolveConfig.constants.EVOLUTIVE_MODEL) ||
      (isCompleted && modelType === EvolveConfig.constants.PREDICTIVE_MODEL);
    // #endregion

    // #region The following are the 8 evolve widgets.
    const widgetSummary = (
      <WidgetCard
        id={cypressTags.SUMMARY_WIDGET_ID}
        className={
          isCompleted || error
            ? "standardHeightStandardWidthWidgetCard"
            : "shortHeightStandardWidthWidgetCard"
        }
        title="Summary"
        widgetContentProps={{ evolveModelSummary, error, modelType }}
        Widget={ModelSummaryWidget}
      />
    );

    const widgetStats = showStatsWidget && (
      <WidgetCard
        id={cypressTags.PERFORMANCE_STATISTICS_WIDGET_ID}
        className="standardHeightStandardWidthWidgetCard"
        title="Performance Statistics"
        widgetContentProps={{
          modelStats: get(evolveModel, "evolveModelInfo.modelStats"),
          modelType
        }}
        Widget={ModelPerformanceStats}
      />
    );

    const widgetResults = isCompleted && (
      <WidgetCard
        id={cypressTags.RESULTS_WIDGET_ID}
        className="standardHeightMediumWidthWidgetCard"
        WidgetCardHeader={ModelResultsWidgetHeader}
        widgetHeaderProps={{
          modelType
        }}
        title="Results"
        widgetContentProps={{
          dataSchema,
          evolveModelDatapoints,
          modelType
        }}
        Widget={ModelResultsWidget}
      />
    );

    const widgetLenHistogram = showSigmaSeqLengthHistogramWidget && (
      <WidgetCard
        id={cypressTags.SEQ_LENGTH_HISTOGRAM_WIDGET_ID}
        className="tallHeightMediumWidthWidgetCard"
        WidgetCardHeader={ModelSigmaSeqLengthHistogramWidgetHeader}
        widgetHeaderProps={{
          title: "Sequence Length Histogram",
          data: evolveModelSummary.modelStats.charts
        }}
        widgetContentProps={{
          data: evolveModelSummary.modelStats.charts
        }}
        Widget={ModelSigmaSeqLengthHistogramWidget}
      />
    );

    const widgetFreqHistogram = showSigmaAminoAcidFreqHistogramWidget && (
      <WidgetCard
        id={cypressTags.AMINOACID_HISTOGRAM_WIDGET_ID}
        className="tallHeightMediumWidthWidgetCard"
        WidgetCardHeader={ModelSigmaAminoAcidHistogramWidgetHeader}
        widgetHeaderProps={{
          title: "Amino Acid Histogram",
          data: evolveModelSummary.modelStats.charts
        }}
        widgetContentProps={{
          data: evolveModelSummary.modelStats.charts
        }}
        Widget={ModelSigmaAminoAcidHistogramWidget}
      />
    );

    const widgetSigmaHistogram = showSigmaHistogramWidget && (
      <WidgetCard
        id={cypressTags.SIGMA_HISTOGRAM_WIDGET_ID}
        className="mediumHeightLargeWidthWidgetCard"
        WidgetCardHeader={ModelSigmaHistogramWidgetHeader}
        widgetHeaderProps={{
          title: "Novelty Histogram",
          data: evolveModelSummary.modelStats.charts
        }}
        widgetContentProps={{
          data: evolveModelSummary.modelStats.charts
        }}
        Widget={ModelSigmaHistogramWidget}
      />
    );

    const widgetDataSchema = showTargetAndFeaturesWidget && (
      <WidgetCard
        id={cypressTags.TARGET_AND_FEATURES_WIDGET_ID}
        className={`${"standardHeightStandardWidthWidgetCard"}`}
        title="Target & features"
        widgetContentProps={{ dataSchema }}
        Widget={TargetAndFeaturesWidget}
      />
    );

    const widgetTargetVsPredChart = showTargetVsPredictionChartWidget && (
      <WidgetCard
        id={cypressTags.TARGET_VS_PREDICTION_CHART_WIDGET_ID}
        className="tallHeightMediumWidthWidgetCard"
        // title="Target vs Prediction"
        title={
          <LabelWithTooltip
            label="Target vs Prediction"
            tooltip={tooltips.targetVsPredictionChart}
          />
        }
        widgetContentProps={{ evolveModelDatapoints, dataSchema }}
        Widget={ModelScatterPlotWidget}
      />
    );

    const widgetGroupedHistogram = showGroupedHistogramWidget && (
      <WidgetCard
        className="tallHeightMediumWidthWidgetCard"
        WidgetCardHeader={ModelGroupedHistogramWidgetHeader}
        widgetHeaderProps={{ data: evolveModelSummary.modelStats }}
        widgetContentProps={{ data: evolveModelSummary.modelStats }}
        Widget={ModelGroupedHistogramWidget}
      />
    );
    // #endregion

    const widgets = [
      {
        show: true,
        component: widgetSummary,
        key: "summaryWidget"
      },
      {
        show: true,
        component: widgetStats,
        key: "statsWidget"
      },
      {
        show: isCompleted,
        component: widgetResults,
        key: "resultsWidget"
      },
      {
        show: showSigmaSeqLengthFreqHistogramWidget,
        component: widgetLenHistogram,
        key: "seqLengthHistogramWidget"
      },
      {
        show: showSigmaAminoAcidFreqHistogramWidget,
        component: widgetFreqHistogram,
        key: "aminoacidHistogramWidget"
      },
      {
        show: showSigmaHistogramWidget,
        component: widgetSigmaHistogram,
        key: "noveltyHistogramWidget"
      },
      {
        show: showTargetAndFeaturesWidget,
        component: widgetDataSchema,
        key: "targetAndFeaturesWidget"
      },
      {
        show: showTargetVsPredictionChartWidget,
        component: widgetTargetVsPredChart,
        key: "targetVsPredictionChartWidget"
      },
      {
        show: showGroupedHistogramWidget,
        component: widgetGroupedHistogram,
        key: "groupedHistogram"
      }
    ];
    filteredWidgets = modelType ? this.filterWidgets(widgets, modelType) : [];

    return (
      <div className="view-container">
        <div className={modelDetailViewStyles.modelDetailGridContainer}>
          {this.renderHeader({
            isLoading,
            title: isLoading ? "" : evolveModel.name,
            description: isLoading ? "" : evolveModel.description
          })}
          <WidgetCanvas
            widgets={filteredWidgets}
            widgetLayout={WIDGET_CANVAS_LAYOUTS[modelType]}
          />
        </div>
      </div>
    );
  }
}

export default ModelDetailView;
export { UNKNOWN_ERROR_OBJECT };
