/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { compose } from "redux";
// import EnzymeLibraryViewer from '../components/EnzymeLibraryViewer'
import { regexStrToRegularSequence } from "../../../tg-iso-shared/utils/enzymeUtils";
import { showDialog } from "../GlobalDialog";
import AbstractLibrary from "../AbstractLibrary";
import libraryEnhancer from "../libraryEnhancer";
import { Tab, Tabs } from "@blueprintjs/core";
import EnzymeGroupPanel from "./EnzymeGroupPanel";
import CreateRestrictionEnzymeDialog from "../components/Dialogs/CreateRestrictionEnzymeDialog";
import EnzymeVisual from "../components/Dialogs/CreateRestrictionEnzymeDialog/EnzymeVisual";
import { withRouter } from "react-router-dom";
import LibraryInspector from "../AbstractLibrary/LibraryInspector";
import { enzymeFragment, GlobalFiltersPanel } from "./GlobalFiltersPanel";

const ViewerComponent = props => (
  <LibraryInspector
    {...props}
    withDescription
    withSharingInformation={false}
    renderExtraItemsTop={enzyme => (
      <EnzymeVisual
        {...enzyme}
        recognitionRegex={regexStrToRegularSequence(enzyme.recognitionRegex)}
        label={null}
      />
    )}
    // additionalFields={[{ path: 'size', displayName: 'Size' }]}
  />
);

const schema = {
  model: "restrictionEnzyme",
  fields: [
    { path: "name", type: "string", displayName: "Name" },
    {
      path: "enzymeTypeCode",
      type: "string",
      displayName: "Type",
      isHidden: true
    },
    // NOTE: looks like something is broken with the libraryEnhancer
    // additionalFilter prop.
    // {
    //   path: "enzymeTypeCode",
    //   type: "boolean",
    //   displayName: "Type II",
    //   filterKey: "enzymeTypeCode",
    //   render: v => {
    //     return v === "TYPE_IIS" ? "True" : "False";
    //   }
    // },
    {
      path: "description",
      type: "string",
      displayName: "Description",
      isHidden: true
    },
    {
      path: "forwardSnipPosition",
      type: "number",
      displayName: "Forward Snip Position"
    },
    {
      path: "reverseSnipPosition",
      type: "number",
      displayName: "Reverse Snip Position"
    },
    {
      path: "recognitionLength",
      type: "number",
      displayName: "Recognition Length",
      isHidden: true
    },
    {
      path: "recognitionRegex",
      type: "string",
      displayName: "Recognition Regex",
      isHidden: true
    },
    {
      path: "recognitionStart",
      type: "number",
      displayName: "Recognition Start",
      isHidden: true
    },
    { path: "sequence", type: "string", displayName: "Sequence" }
  ]
};

const EnzymeLibrary = props => {
  const { refetch, history, location, match } = props;
  const onNewItemClick = () => {
    showDialog({
      ModalComponent: CreateRestrictionEnzymeDialog,
      modalProps: {
        refetchRestrictionEnzymes: refetch
      }
    });
  };

  const onDoubleClick = enzyme => {
    showDialog({
      ModalComponent: CreateRestrictionEnzymeDialog,
      modalProps: {
        refetchRestrictionEnzymes: refetch,
        initialValues: {
          ...enzyme,
          recognitionRegex: regexStrToRegularSequence(enzyme.recognitionRegex)
        }
      }
    });
  };
  const subsection = match.params.subsection || "enzymes";
  const onTabChange = newTabId => {
    let newPathname;
    if (location.pathname.includes(subsection)) {
      newPathname = location.pathname.replace(subsection, newTabId);
    } else {
      newPathname = location.pathname + `/${newTabId}`;
    }
    history.replace({
      pathname: newPathname
    });
  };

  return (
    <div className="tg-card">
      <Tabs
        renderActiveTabPanelOnly
        large
        selectedTabId={subsection}
        onChange={onTabChange}
      >
        <Tab
          id="enzymes"
          title="Enzymes"
          panel={
            <AbstractLibrary
              {...props}
              isLibraryTable
              noOpen
              noRename
              alwaysShowInspector
              inspectorWidth={350}
              onEdit={onDoubleClick}
              onDoubleClick={onDoubleClick}
              ViewerComponent={ViewerComponent}
              onNewItemClick={onNewItemClick}
            />
          }
        />
        <Tab
          id="enzyme-groups"
          title="Enzyme Groups"
          panel={<EnzymeGroupPanel />}
        />
        <Tab
          id="cutsite-filter"
          title="Cutsite Filter"
          panel={<GlobalFiltersPanel />}
        />
      </Tabs>
    </div>
  );
};

export default compose(
  withRouter,
  libraryEnhancer({
    fragment: enzymeFragment,
    schema,
    withSelectedEntities: true,
    noAddedBy: true
    // NOTE: figure out why additionalFilter isnt passing
    // the expected customParams down.
    // additionalFilter: (props, qb, customParams) => {}
  })
)(EnzymeLibrary);
