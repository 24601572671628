/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { FileUploadField } from "@teselagen/ui";
import { Button, Intent, Classes, Callout } from "@blueprintjs/core";
import { reduxForm } from "redux-form";
import shortid from "shortid";
import cn from "classnames";
import Helmet from "react-helmet";
import "./style.css";

const checkFileOK = file => {
  if (!file) return false;
  const isProperType = ["image/jpeg", "image/png"].includes(file.type);
  if (!isProperType) {
    window.toastr && window.toastr.error("Please upload a PNG or JPG file");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    window.toastr &&
      window.toastr.error("Please upload an image smaller than 2MB!");
  }

  return isProperType && isLt2M;
};

export const serverLogoURI = "/lab_uploads/logo";

class ChangeLogo extends Component {
  state = {
    hasCustomLogo: true,
    previewedLogo: null,
    removingLogo: false
  };

  noCustomLogo = () => {
    this.setState({
      hasCustomLogo: false
    });
  };

  setCustomLogo = () => {
    this.setState({
      hasCustomLogo: true
    });
  };

  onSuccessfullUpload = () => {
    this.forceUpdate();
    window.toastr.success("Logo successfully updated");
    this.setState({
      hasCustomLogo: true
    });
  };

  removeCustomLogo = async () => {
    this.setState({
      removingLogo: true
    });

    let defaultLogoFileName = "tg-large-default-white.png";

    if (window.frontEndConfig.federatedSso) {
      defaultLogoFileName = "tg-small-default-color.png";
    }

    try {
      const defaultLogo = await window.serverApi.request({
        method: "GET",
        url: `/lab_uploads/other/${defaultLogoFileName}`,
        responseType: "blob"
      });
      const data = new FormData();
      data.append("file", defaultLogo.data);
      await window.serverApi.request({
        method: "POST",
        url: "/lab_uploads/logo",
        data
      });

      // await window.serverApi.delete("/lab_uploads/logo");
      this.forceUpdate();
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error removing logo.");
    }
    this.setState({
      removingLogo: false,
      hasCustomLogo: true
    });
  };

  beforeUpload = fileList => {
    const file = fileList[0];
    if (!checkFileOK(file)) {
      return false;
    }

    if (this.previewedLogo) {
      this.previewedLogo.cancelUpload();
    }

    return new Promise(resolve => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
        // console.log(reader.result);
        // Logs data:<type>;base64,wL2dvYWwgbW9yZ...
      };
      reader.readAsDataURL(file.originalFileObj);
    }).then(preview => {
      return new Promise(resolve => {
        this.setState({
          previewedLogo: {
            preview,
            confirmUpload: () => {
              resolve(true);
              this.setState({ previewedLogo: null });
            },
            cancelUpload: () => {
              resolve(false);
              this.setState({ previewedLogo: null });
            }
          }
        });
      });
    });
  };

  render() {
    const { hasCustomLogo, previewedLogo, removingLogo } = this.state;
    return (
      <div
        className={cn("tg-card xtra-pad", {
          "with-logo": hasCustomLogo || previewedLogo,
          "is-uploaded-logo": hasCustomLogo,
          "is-preview": previewedLogo
        })}
      >
        <Helmet title="Logo" />
        <h3>Logo</h3>
        <Callout intent="primary" style={{ marginBottom: 15 }}>
          This logo will be displayed on the login page
        </Callout>
        <div className="tg-logo-upload-container">
          <FileUploadField
            action="/lab_uploads/logo"
            showUploadList={false}
            onFieldSubmit={this.onSuccessfullUpload}
            beforeUpload={this.beforeUpload}
            fileLimit={1}
            name="labLogo"
            className={cn("tg-logo-upload", {
              "with-logo":
                (hasCustomLogo || previewedLogo) &&
                !window.frontEndConfig.federatedSso,
              "with-sso-logo":
                (hasCustomLogo || previewedLogo) &&
                window.frontEndConfig.federatedSso,
              "is-uploaded-logo": hasCustomLogo,
              "is-preview": previewedLogo
            })}
            accept={[".jpg", ".jpeg", ".png"]}
            contentOverride={
              hasCustomLogo || previewedLogo ? (
                <img
                  src={
                    (previewedLogo && previewedLogo.preview) ||
                    (!previewedLogo && serverLogoURI + `?${shortid()}`)
                  }
                  onError={this.noCustomLogo}
                  alt=""
                  crossOrigin="Anonymous"
                />
              ) : undefined
            }
          />
          <div>
            Please upload a .jpeg or .png file under 2MB in size. Recommended
            maximum dimensions are 250px wide by 150px tall.
          </div>
        </div>
        <div className="tg-flex justify-flex-end logo-buttons">
          {previewedLogo && (
            <Button
              intent={Intent.DEFAULT}
              className={Classes.MINIMAL}
              icon="small-cross"
              text="Cancel New Logo"
              onClick={previewedLogo && previewedLogo.cancelUpload}
            />
          )}
          {previewedLogo && (
            <Button
              intent={Intent.SUCCESS}
              className={Classes.MINIMAL}
              icon="small-tick"
              text="Save New Logo"
              onClick={previewedLogo && previewedLogo.confirmUpload}
            />
          )}
          {!previewedLogo && !hasCustomLogo && (
            <Button
              // disabled={!hasCustomLogo}
              intent={Intent.DEFAULT}
              className={Classes.MINIMAL}
              text="Cancel"
              icon="small-cross"
              onClick={this.setCustomLogo}
            />
          )}
          {!previewedLogo && hasCustomLogo && (
            <Button
              // disabled={!hasCustomLogo}
              intent={Intent.DEFAULT}
              className={Classes.MINIMAL}
              text="Revert to Default"
              icon="undo"
              loading={removingLogo}
              onClick={this.removeCustomLogo}
            />
          )}
          {!previewedLogo && hasCustomLogo && (
            <Button
              // disabled={!hasCustomLogo}
              intent={Intent.PRIMARY}
              className={Classes.MINIMAL}
              text="Change Logo"
              icon="upload"
              // loading={removingLogo}
              onClick={this.noCustomLogo}
            />
          )}
        </div>
      </div>
    );
  }
}

export default reduxForm({
  enableReinitialize: true,
  form: "changeLogo"
})(ChangeLogo);
