/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";

import { Classes } from "@blueprintjs/core";
import { DialogFooter, InputField, TextareaField } from "@teselagen/ui";
import { reduxForm } from "redux-form";
import { compose } from "redux";
import { get } from "lodash";
import { safeUpsert } from "../../../apolloMethods";
import { wrapDialog } from "@teselagen/ui";
import { asyncValidateName } from "../../../utils/formUtils";
import { withProps } from "recompose";
class CreateRNAType extends React.Component {
  onSubmit = async values => {
    try {
      const { refetchRnaTypes, initialValues, hideModal } = this.props;

      await safeUpsert("rnaType", {
        id: get(initialValues, "id"),
        name: values.name,
        description: values.description
      });
      await refetchRnaTypes();
      hideModal();
    } catch (e) {
      console.error(e);
      window.toastr.error("Error creating/updating RNA type.");
    }
  };

  render() {
    const { hideModal, submitting, handleSubmit } = this.props;

    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <div className={Classes.DIALOG_BODY}>
          <InputField label="Name" name="name" isRequired />
          <TextareaField label="Description" name="description" />
        </div>
        <DialogFooter hideModal={hideModal} submitting={submitting} />
      </form>
    );
  }
}

export default compose(
  wrapDialog(props => {
    return {
      title: props.initialValues ? "Update RNA Type" : "Create RNA Type"
    };
  }),
  withProps(() => ({ model: "rnaType" })), // needed for async validate
  reduxForm({
    form: "rnaTypeForm", // a unique name for this form
    enableReinitialize: true,
    ...asyncValidateName
  })
)(CreateRNAType);
