/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose } from "redux";
// import registeredAnnotationLibraryViewer from '../components/registeredAnnotationLibraryViewer'
import { showDialog } from "../GlobalDialog";
import AbstractLibrary from "../AbstractLibrary";
import gql from "graphql-tag";
import libraryEnhancer from "../libraryEnhancer";
import { Tab, Tabs, Tag } from "@blueprintjs/core";
import AnnotationGroupPanel from "./AnnotationGroupPanel";
import RegisterAnnotationDialog, {
  EditRegisteredAnnotationDialog
} from "../dialogs/RegisterAnnotationDialog";
import { withRouter } from "react-router-dom";
import LibraryInspector from "../AbstractLibrary/LibraryInspector";
import { startCase } from "lodash";
import pluralize from "pluralize";
import { branch } from "recompose";

const additionalFields = [
  {
    path: "registeredAnnotationToGroups.registeredAnnotationGroup.name",
    displayName: "Groups",
    render: (a, rec) =>
      rec.registeredAnnotationToGroups.map((r, i) => (
        <Tag style={{ margin: "0px 1px" }} key={i}>
          {r.registeredAnnotationGroup.name}
        </Tag>
      ))
  },
  { path: "sequence", type: "string", displayName: "Sequence" }
];

const ViewerComponent = props => (
  <LibraryInspector
    {...props}
    withDescription
    withSharingInformation={false}
    additionalFields={additionalFields}
  />
);

const schema = {
  model: "registeredAnnotation",
  fields: [
    { path: "name", type: "string", displayName: "Name" },
    {
      path: "isRegex",
      isHidden: true,
      type: "boolean",
      displayName: "Is Regex"
    },
    {
      path: "description",
      type: "string",
      displayName: "Description",
      isHidden: true
    },
    ...additionalFields
  ]
};

const registeredAnnotationLibrary = props => {
  const { refetch, annotationType, history, location, match } = props;
  const handleClick = isEdit => registeredAnnotation => {
    if (isEdit) {
      showDialog({
        ModalComponent: EditRegisteredAnnotationDialog,
        modalProps: {
          annotationType,
          refetch,
          initialValues: {
            ...registeredAnnotation,
            selectedAnnotationGroups:
              registeredAnnotation.registeredAnnotationToGroups.map(g => ({
                id: g.registeredAnnotationGroup.id,
                name: g.registeredAnnotationGroup.name,
                value: g.registeredAnnotationGroup.id
              }))
          }
        }
      });
    } else {
      showDialog({
        ModalComponent: RegisterAnnotationDialog,
        modalProps: {
          annotationType,
          refetch
        }
      });
    }
  };

  const subsection =
    match.params.subsection || `registered-${pluralize(annotationType)}`;
  const onTabChange = newTabId => {
    let newPathname;
    if (location.pathname.includes(subsection)) {
      newPathname = location.pathname.replace(subsection, newTabId);
    } else {
      newPathname = location.pathname + `/${newTabId}`;
    }
    history.replace({
      pathname: newPathname
    });
  };

  return (
    <div className="tg-card">
      <Tabs
        renderActiveTabPanelOnly
        large
        selectedTabId={subsection}
        onChange={onTabChange}
      >
        <Tab
          id={`registered-${pluralize(annotationType)}`}
          title={`Registered ${pluralize(startCase(annotationType))}`}
          panel={
            <AbstractLibrary
              {...props}
              libraryName={`Registered ${annotationType}`}
              newItemText={`Register ${pluralize(startCase(annotationType))}`}
              isLibraryTable
              noOpen
              noRename
              alwaysShowInspector
              inspectorWidth={350}
              onEdit={handleClick(true)}
              onDoubleClick={handleClick(true)}
              ViewerComponent={ViewerComponent}
              onNewItemClick={handleClick()}
            />
          }
        ></Tab>
        <Tab
          id={`${annotationType}-groups`}
          title={`${startCase(annotationType)} Groups`}
          panel={
            <AnnotationGroupPanel
              annotationType={annotationType}
            ></AnnotationGroupPanel>
          }
        ></Tab>
        <Tabs.Expander />
        {/* {isAdmin() && (
          <Button
            onClick={() => {
              showDialog({
                ModalComponent: MinLengthsDialog
              });
            }}
            icon="settings"
          >
            Length Settings
          </Button>
        )} */}
      </Tabs>
    </div>
  );
};

const fragment = gql`
  fragment registeredAnnotationLibraryFragment on registeredAnnotation {
    id
    name
    type
    description
    isRegex
    sequence
    registeredAnnotationToGroups {
      id
      registeredAnnotationGroup {
        id
        name
      }
    }
  }
`;

const sharedFields = {
  hideCreatedFields: true,
  fragment,
  withSelectedEntities: true,
  noAddedBy: true,
  additionalFilter: (props, qb) => {
    qb.whereAll({
      registeredAnnotationTypeCode: props.annotationType.toUpperCase()
    });
  }
};

export default compose(
  withRouter,
  branch(
    props => props.annotationType === "feature",
    libraryEnhancer({
      schema: {
        ...schema,
        fields: [
          ...schema.fields.slice(0, 1),
          "type",
          ...schema.fields.slice(1)
        ]
      },
      ...sharedFields
    }),
    libraryEnhancer({
      schema,
      ...sharedFields
    })
  )
)(registeredAnnotationLibrary);

// const MinLengthsDialog = wrapDialog({ title: "Configure Minimum Lengths" })(
//   function() {
//     return (
//       <div className="bp3-dialog-body">
//         Note: These changes will only affect newly registered annotations.
//         <br></br>
//         <br></br>
//         <ChangeAutoAnnotateMinimumForm
//           onFinish={hideDialog}
//         ></ChangeAutoAnnotateMinimumForm>
//       </div>
//     );
//   }
// );
