/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
/**
 * Given an extended property object from the extended properties library,
 * generate the appropriate `intialValues` object for editing the extended
 * property.
 * @param {Object} record An extended property object.
 */
export default function getInitialValues(record) {
  if (record.extendedPropertyClassCode === "VALUE") {
    return record;
  } else if (record.extendedPropertyClassCode === "CATEGORY") {
    return {
      ...record,
      options: record.extendedCategoryClass.extendedCategories
    };
  } else if (record.extendedPropertyClassCode === "MEASUREMENT") {
    const baseUnitId =
      record.extendedMeasurementUnitClass.baseMeasurementUnitId;
    return {
      ...record,
      units: record.extendedMeasurementUnitClass.measurementUnits.map(u => ({
        ...u,
        isBaseUnit: u.id === baseUnitId
      }))
    };
  }
}
