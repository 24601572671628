/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { compose } from "recompose";
import { wrapDialog } from "@teselagen/ui";
import { lowerCase } from "lodash";
import { withHandlers, withProps } from "recompose";
import shortid from "shortid";
import { safeUpsert } from "../../src-shared/apolloMethods";
import stripFields from "../../src-shared/utils/stripFields";

export default function ({
  model,
  Dialog,
  wrapDialogOptions = {},
  dialogOptions: {
    prepareInitialValues,
    prepareEditInput,
    prepareCreateInput
  } = {},
  codeModels
}) {
  const isCodeModel = codeModels[model];
  return compose(
    wrapDialog(wrapDialogOptions),
    withHandlers({
      onSubmit: props => async values => {
        const { hideModal, refetch, initialValues: iv = {} } = props;
        const editing = isCodeModel ? iv.code : iv.id;
        try {
          const valuesToUse = editing
            ? stripFields(values, ["__typename"])
            : values;
          const initialValues = prepareInitialValues
            ? prepareInitialValues({ initialValues: iv, model, default: true })
            : iv;
          let valuesToUpsert;
          if (!editing && prepareCreateInput) {
            valuesToUpsert = await prepareCreateInput({
              values: valuesToUse,
              model
            });
          } else if (editing && prepareEditInput) {
            valuesToUpsert = await prepareEditInput({
              values: valuesToUse,
              initialValues,
              isCode: codeModels[model],
              model
            });
          } else {
            valuesToUpsert = valuesToUse;
          }

          if (!editing && isCodeModel) {
            valuesToUpsert.code = shortid();
          }
          await safeUpsert(model, valuesToUpsert, {
            idAs: isCodeModel ? "code" : undefined,
            forceCreate: !editing
          });
          await refetch();
          hideModal();
          window.toastr.success(
            `Successfully ${editing ? "edited" : "created"} ${lowerCase(model)}`
          );
        } catch (error) {
          console.error("error:", error);
          window.toastr.error(
            `Error ${editing ? "editing" : "creating"} ${lowerCase(model)}`
          );
        }
      }
    }),
    withProps(props => {
      const { initialValues: iv } = props;
      const initialValues = prepareInitialValues
        ? prepareInitialValues({ initialValues: iv, model, default: true })
        : iv;
      return {
        idAs: isCodeModel ? "code" : "id",
        initialValues
      };
    })
  )(Dialog);
}
