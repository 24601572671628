/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { map, startCase } from "lodash";

const fieldFilter = {
  id: true,
  code: true,
  __typename: true
};

function DescriptionComponent({
  record,
  extraFieldFilter = {},
  fieldNames = {}
}) {
  return (
    <div>
      {map(record, (value, field) => {
        if (
          !value ||
          fieldFilter[field] ||
          extraFieldFilter[field] ||
          Array.isArray(value)
        )
          return null;
        return (
          <div key={field} className="location-setting-description">
            {fieldNames[field] || startCase(field)}: {value}
          </div>
        );
      })}
    </div>
  );
}

export default DescriptionComponent;
