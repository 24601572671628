/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { forwardRef, useMemo } from "react";
import { formValues } from "redux-form";
import { compose, withProps } from "recompose";
import { ReactSelectField, generateField } from "@teselagen/ui";
import PhoneInput from "react-phone-number-input/input";
import { Classes, InputGroup } from "@blueprintjs/core";
import "react-phone-number-input/style.css";
import {
  getCountries,
  getCountryCallingCode
} from "react-phone-number-input/input";
import en from "react-phone-number-input/locale/en.json";
import {
  isPossiblePhoneNumber,
  formatPhoneNumber
} from "react-phone-number-input";

const CustomInput = forwardRef((props, ref) => {
  const { className, autoComplete, input, intentClass, placeholder } = props;
  return (
    <InputGroup
      {...input}
      {...{
        inputRef: ref,
        className: className + " " + intentClass,

        autoComplete,
        placeholder
      }}
    />
  );
});

function RenderPhoneInput(props) {
  const {
    input,
    intent,
    onFieldSubmit,
    asyncValidating,
    rightElement,
    clickToEdit,
    countryCode,
    ...rest
  } = props;

  const { countryOptions, defaultPhoneFormatCode } = useMemo(() => {
    const countryOptions = [];
    let defaultPhoneFormatCode = "US";
    const defSetting =
      window.frontEndConfig.appSettings.DEFAULT_PHONE_FORMAT_CODE;
    if (defSetting) {
      defaultPhoneFormatCode = defSetting;
    }
    getCountries().forEach(country => {
      const label = en[country] + " " + getPrefix(country);
      const op = {
        label,
        value: country
      };
      if (country === "US") {
        countryOptions.unshift(op);
      } else {
        countryOptions.push(op);
      }
    });
    return {
      countryOptions,
      defaultPhoneFormatCode
    };
  }, []);

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <ReactSelectField
        className="tg-no-form-group-margin"
        style={{
          width: 100
        }}
        defaultValue={defaultPhoneFormatCode}
        isRequired={!!input.value}
        name="countryCode"
        options={countryOptions}
      />
      <div style={{ marginLeft: 5 }}>
        <label className={Classes.LABEL}></label>
        <PhoneInput
          placeholder="Enter phone number"
          inputComponent={CustomInput}
          onChange={input.onChange}
          withCountryCallingCode
          value={input.value}
          input={input}
          indicateInvalid
          {...rest}
        />
      </div>
    </div>
  );
}

function getPrefix(country) {
  return "+" + getCountryCallingCode(country);
}

export function getFullPhoneNumber(values) {
  if (values.phoneNumber && values.countryCode) {
    return (
      getPrefix(values.countryCode) + values.phoneNumber.replace(/\D+/g, "")
    );
  }
}

export function validatePhoneNumber(values) {
  if (values.phoneNumber && values.countryCode) {
    if (!isPossiblePhoneNumber(getFullPhoneNumber(values)))
      return "Enter valid phone number";
  }
}

export function normalizePhoneNumber(val, countryCode) {
  if (countryCode && val) {
    const maybeVal = formatPhoneNumber(getPrefix(countryCode) + val);
    if (maybeVal) {
      return maybeVal;
    } else {
      return val;
    }
  } else {
    return val;
  }
}

export default compose(
  formValues("countryCode"),
  withProps(props => {
    return {
      normalize: val => {
        return normalizePhoneNumber(val, props.countryCode);
      }
    };
  }),
  generateField
)(RenderPhoneInput);
