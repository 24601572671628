/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { filterSequenceString } from "@teselagen/sequence-utils";
import { pick } from "lodash";
import { sequenceJSONtoGraphQLInput } from "./sequenceJSONtoGraphQLInput";
import getExternalReferenceFieldsAndTags from "../../utils/getExternalReferenceFieldsAndTags";
import { computeSequenceHash } from "./computeSequenceHash";
import { calculateMolecularWeightAndExtinctionCoefficient } from "./calculateMolecularWeightAndExtinctionCoefficient";

export const aaSequenceJSONtoGraphQLInput = (
  aaSequenceJSON = {},
  options = {}
) => {
  const { strippedAATracker, keepIds } = options;
  const passedBps =
    aaSequenceJSON.proteinSequence || aaSequenceJSON.sequence || "";
  const [aminoAcidSequence] = filterSequenceString(passedBps, {
    name: aaSequenceJSON.name,
    isProtein: true
  });
  const feats =
    aaSequenceJSON.features &&
    aaSequenceJSON.features.map(ann =>
      pick(ann, [
        ...(keepIds ? ["id"] : []),
        "start",
        "end",
        "name",
        "type",
        "locations"
      ])
    );

  // Pre-process coding dna sequences
  let codingDnaSequences;
  if (aaSequenceJSON.codingDnaSequences) {
    codingDnaSequences = aaSequenceJSON.codingDnaSequences.map(cds =>
      sequenceJSONtoGraphQLInput(cds)
    );
  }

  const gqlInput = {
    ...(keepIds && { id: aaSequenceJSON.id }),
    ...getExternalReferenceFieldsAndTags(aaSequenceJSON),
    name: aaSequenceJSON.name,
    size: aminoAcidSequence.length,
    hash: computeSequenceHash(aminoAcidSequence, "AA"),
    description: aaSequenceJSON.description || "",
    ...(!!aminoAcidSequence.length &&
      calculateMolecularWeightAndExtinctionCoefficient(aminoAcidSequence)),
    regionAnnotations: feats,
    proteinSequence: aminoAcidSequence,
    codingDnaSequences: codingDnaSequences,
    aminoAcidParts:
      aaSequenceJSON.parts &&
      aaSequenceJSON.parts.map(ann =>
        pick(ann, [...(keepIds ? ["id"] : []), "start", "end", "name", "type"])
      )
  };
  if (strippedAATracker) {
    if (passedBps.length > aminoAcidSequence.length) {
      strippedAATracker.push(gqlInput);
    }
  }
  return gqlInput;
};
