/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useContext } from "react";
import { Button, Intent } from "@blueprintjs/core";
import { InputField } from "@teselagen/ui";
import { isEmail } from "validator";
import { InjectedFormProps, reduxForm } from "redux-form";
import { get } from "lodash";
import AsyncValidateFieldSpinner from "../AsyncValidateFieldSpinner";
import { safeQuery } from "../apolloMethods";
import CurrentUserContext, {
  CurrentUserType
} from "../context/CurrentUserContext";

type Props = {
  currentUser: CurrentUserType;
};

type ValuesType = {
  newEmail: string;
  confirmNewEmail: string;
};

const ChangeEmailForm = (
  props: Props & InjectedFormProps<ValuesType, Props>
) => {
  const { currentUser, refetchCurrentUser } = useContext(CurrentUserContext);
  const { asyncValidating, handleSubmit, reset } = props;
  const onSubmit = async (data: ValuesType) => {
    return window.serverApi
      .request({
        method: "POST",
        url: "/change-email",
        data: { newEmail: data.newEmail }
      })
      .then(async function () {
        refetchCurrentUser!();
        window.toastr.success("User email updated");
        reset();
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div style={{ marginBottom: "10px" }}>
        Current email: {currentUser!.email}
      </div>
      <InputField
        label="New email"
        name="newEmail"
        type="email"
        rightElement={
          <AsyncValidateFieldSpinner validating={asyncValidating} />
        }
        loading={true}
        placeholder="Enter new email address..."
        leftIcon="envelope"
        tooltipError
        tooltipProps={{
          position: "left"
        }}
      />
      <InputField
        label="Confirm new email"
        name="confirmNewEmail"
        type="email"
        placeholder="Confirm email address..."
        leftIcon="envelope"
        tooltipError
        tooltipProps={{
          position: "left"
        }}
      />
      <div className="settings-footer">
        <Button intent={Intent.PRIMARY} type="submit" text="Update Email" />
      </div>
    </form>
  );
};

const validate = (
  values: ValuesType,
  { currentUser }: { currentUser: CurrentUserType }
) => {
  const currentEmail = get(currentUser, "email");
  const errors: { newEmail?: string; confirmNewEmail?: string } = {};
  if (currentEmail && values.newEmail === currentEmail) {
    errors.newEmail = "Please enter a new email";
  }
  if (!values.newEmail) {
    errors.newEmail = "Required";
  } else if (!isEmail(values.newEmail)) {
    errors.newEmail = "Invalid email address";
  }
  if (!values.confirmNewEmail) {
    errors.confirmNewEmail = "Required";
  } else if (values.newEmail !== values.confirmNewEmail) {
    errors.confirmNewEmail = "Emails do not match.";
  }
  return errors;
};

function asyncValidate({ newEmail }: ValuesType) {
  return safeQuery(["user", "id"], {
    variables: {
      filter: {
        email: newEmail
      }
    }
  }).then(res => {
    if (res.length) {
      const error = { newEmail: "Email is already in use." };
      throw error;
    }
  });
}

const formConnectedChangeEmailForm = reduxForm<
  ValuesType,
  { currentUser: CurrentUserType }
>({
  enableReinitialize: true,
  form: "ChangeEmailForm",
  validate,
  asyncValidate,
  asyncBlurFields: ["email"]
})(ChangeEmailForm);

export default formConnectedChangeEmailForm;
