/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

/**
 * This function will be used in both the server and client
 * @param {*} sequence
 * @param {*} sequenceTypeCode
 * @returns
 */
export const calculateDNAMolecularWeight = (
  sequence = "",
  sequenceTypeCode
) => {
  let numA = 0;
  let numT = 0;
  let numG = 0;
  let numC = 0;
  let numU = 0;

  const doubleStrandedTypeCodes = ["CIRCULAR_DNA", "LINEAR_DNA", "CDS"];

  for (const char of sequence.toUpperCase()) {
    if (char === "A") numA++;
    if (char === "T") numT++;
    if (char === "G") numG++;
    if (char === "C") numC++;
    if (char === "U") numU++;
  }
  let molecularWeight =
    numA * 313.21 + numU * 306.2 + numT * 304.2 + numG * 329.21 + numC * 289.18;
  if (doubleStrandedTypeCodes.includes(sequenceTypeCode)) {
    molecularWeight +=
      numA * 304.2 + numT * 313.21 + numG * 289.18 + numC * 329.21;
  } else if (sequenceTypeCode === "OLIGO") {
    molecularWeight -= 61.96;
  }
  return molecularWeight;
};
